import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import ILogItemAction from "../../interfaces/action/ILogItemAction";
import { ECounts, ICounts } from "../../interfaces/domain/ICounts";
import { EActionTypes } from "../EActionTypes";
import { ILogItem } from "./../../interfaces/domain/ILogItem";

type TAction = ILogItemAction;
const apiPath = "/json/logitem";

export const incrementCount = (incrementCount: ECounts): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_INCREMENT_COUNT,
    incrementCount,
  };
};

const listTestMissionLogItemsStart = (): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_TEST_MISSION_LIST_START,
  };
};

const listTestMissionLogItemsSuccess = (logItems: ILogItem[]): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_TEST_MISSION_LIST_SUCCESS,
    logItems,
  };
};

const listTestMissionLogItemsFail = (error: string): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_TEST_MISSION_LIST_FAIL,
    error,
  };
};

export const listTestMissionLogItems = (search: ILogItemSearch) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listTestMissionLogItemsStart());
    try {
      const res = await axios.get<ILogItem[]>(`${apiPath}/testmission/list?year=${search.year ?? ""}&userId=${search.userId ?? ""}&testMissionId=${search.testMissionId ?? ""}`);
      dispatch(listTestMissionLogItemsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listTestMissionLogItemsFail((response as AxiosResponse).data));
    }
  };
};

const getTestMissionCountStart = (): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_TEST_MISSION_COUNT_START,
  };
};

const getTestMissionCountSuccess = (count: ICounts): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_TEST_MISSION_COUNT_SUCCESS,
    count,
  };
};

const getTestMissionCountFail = (error: string): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_TEST_MISSION_COUNT_FAIL,
    error,
  };
};

export interface ILogItemSearch {
  year?: number;
  userId?: number;
  testMissionId?: string;
  //action?: ELogItemAction;
}
// returns counts for all actions
export const getTestMissionCount = (search: ILogItemSearch) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getTestMissionCountStart());
    try {
      const res = await axios.post(`${apiPath}/testmission/count`, search);
      dispatch(getTestMissionCountSuccess(res.data));
    } catch ({ response }) {
      dispatch(getTestMissionCountFail((response as AxiosResponse).data));
    }
  };
};

const getReportsStart = (): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_GET_REPORTS_START,
  };
};

const getReportsSuccess = (reports: ICounts[]): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_GET_REPORTS_SUCCESS,
    reports,
  };
};

const getReportsFail = (error: string): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_GET_REPORTS_FAIL,
    error,
  };
};

export const getReports = (search: ILogItemSearch) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getReportsStart());
    try {
      const res = await axios.post<ICounts[]>(`${apiPath}/testmission/reports`, search);
      dispatch(getReportsSuccess(res.data));
    } catch ({ response }) {
      dispatch(getReportsFail((response as AxiosResponse).data));
    }
  };
};