import React from "react";
import { roleOptions } from "../../../../data/select-data";
import {
  defaultParticipant,
  IParticipant,
} from "../../../../interfaces/domain/IParticipant";
import { ERoleName } from "../../../../interfaces/domain/IRole";
import TestMissionParticipant from "../TestMissionParticipants/TestMissionParticipant/TestMissionParticipant";

interface IProps {
  participant?: IParticipant;
  participants: IParticipant[];
  onSave: (participant: IParticipant) => void;
  onDelete: (participantId: string, participantUserId?: string) => void;
  canEdit: boolean;
}

const TestMissionParticipantsDialog: React.FC<IProps> = ({
  participant,
  participants,
  onSave,
  onDelete,
  canEdit,
}) => {
  const noLeadDCO = !participants?.find(
    (p) => p.participantType.roleName === ERoleName.LEAD_DCO
  );
  const fieldPersonOptions = [...roleOptions].filter(
    (r) => r.value !== ERoleName.LEAD_DCO
  );

  return (
    <TestMissionParticipant
      onSave={onSave}
      onDelete={() => {
        if (participant?.id) onDelete(participant.id, participant.user?.id);
      }}
      options={
        noLeadDCO ||
        participant?.participantType.roleName === ERoleName.LEAD_DCO
          ? roleOptions
          : fieldPersonOptions
      }
      participant={
        participant?.id ? participant : defaultParticipant(participant)
      }
      isNew={!participant?.id}
      canEdit={canEdit}
    />
  );
};

export default TestMissionParticipantsDialog;
