import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useMemo, useState } from "react";
import { EInputUpdateAction } from "../../../../context/InputContext";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { useWorkLog } from "../../../../hooks/useWorkLog";
import { ILinkedWorkLog } from "../../../../interfaces/domain/IWorkLog";
import { ETranslation } from "../../../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../../../UI/Button/Button";
import { EInputType, IInputField, IOption } from "../../../UI/Input/Input";
import { initForm, validateInputs } from "../../../UI/Input/input-utils";

enum EInputs {
  id = "id",
  percentage = "percentage",
}

interface IProps {
  workLog: ILinkedWorkLog;
  onDelete: () => void;
  workLogOptions: IOption[];
  workLogOptionsLoading: boolean;
  linkedWorkLogIds: string[];
}

const LinkedWorkLog: React.FC<IProps> = ({ workLog, onDelete, workLogOptions, workLogOptionsLoading, linkedWorkLogIds }) => {
  const { disableInputs } = useWorkLog();
  const { createInput } = useWorkLogInputs(workLog, disableInputs);

  const workLogMemo = useMemo(() => {
    let options = [...workLogOptions];
    if (linkedWorkLogIds) {
      options = options.filter(log => log.value === workLog.id || !linkedWorkLogIds.includes(log.value));
    }
    return options;
  }, [workLogOptions, workLog, linkedWorkLogIds]);

  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      {createInput(EInputs.id, {
        options: workLogMemo,
        loading: workLogOptionsLoading,
        containerStyles: { width: "225px" },
      })}
      {createInput(EInputs.percentage, { containerStyles: { width: "100px" } })}
      {!disableInputs && (
        <div>
          <Button
            onClick={onDelete}
            icon={faTrash}
            size={EButtonSize.SMALL}
            color={EButtonColor.DANGER}
          />
        </div>
      )}
    </div>
  );
};

const useWorkLogInputs = (data?: ILinkedWorkLog, disabled?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.id]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.TEST_MISSION_ID,
      placeholderTranslation: ETranslation.TEST_MISSION_ID,
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.percentage]: {
      type: EInputType.number,
      labelTranslation: ETranslation.PERCENTAGE,
      placeholderTranslation: ETranslation.PERCENTAGE,
      value: "",
      min: "0",
      post: "%",
      validation: {
        required: true,
      },
    },
  });

  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = useCreateInput(inputs, setInputs, {
    updateAction: EInputUpdateAction.WORKLOG_PASSENGER,
    data,
    disabled,
    showValidation: !isValid,
  });

  useEffect(() => {
    // console.log(data);
    if (data) {
      initForm(setInputs, data);
    }
  }, [data]);

  return { createInput };
};

export default LinkedWorkLog;
