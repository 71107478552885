import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import { IImport } from "../../../interfaces/domain/IImport";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../UI/Table/Table";

interface IProps {
  summaries: IImport[];
}

const ImportHistory: React.FC<IProps> = ({ summaries }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Table hover style={{ marginTop: "1rem" }}>
      <thead>
        <tr>
          <th>{t(ETranslation.IMPORT_HISTORY)}</th>
        </tr>
      </thead>
      <tbody>
        {summaries.map((summary) => {
          const { id, generatedName } = summary;
          return (
            <tr key={id} onClick={() => navigate(Routes.IMPORT(id))}>
              <td>{generatedName}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ImportHistory;
