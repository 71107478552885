import IImportAction from "../../interfaces/action/IImportAction";
import IImportState from "../../interfaces/state/IImportState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = IImportState;
type ReducerSignature = (
  state: ReducerState,
  action: IImportAction
) => ReducerState;

const initialState: ReducerState = {
  summary: null,
  summaries: null,
  id: null,
  summariesLoading: false,
  progress: null,
  success: false,
  loading: false,
  error: null,
  automatchLoading: false,
  automatchError: null,
};

const uploadStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, summariesLoading: true };
};

const uploadSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    id: action.id || null,
    success: true,
    summariesLoading: false,
    progress: null,
  };
};

const uploadProgress: ReducerSignature = (state, action): ReducerState => {
  let progress = null;
  if (action.progress && action.progress >= 0 && action.progress < 100) {
    progress = action.progress;
  }

  return {
    ...state,
    progress,
  };
};

const uploadFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    success: false,
    summariesLoading: false,
    progress: null,
    error: action.error || null,
  };
};

const listImportsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const listImportsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    summaries: action.summaries || null,
    error: null,
    loading: false,
  };
};

const listImportsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    summaries: null,
    loading: false,
    error: action.error || null,
  };
};

const getImportStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, id: null, error: null, loading: true };
};

const getImportSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    summary: action.summary || null,
    error: null,
    loading: false,
  };
};

const getImportFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    summary: null,
    loading: false,
    error: action.error || null,
  };
};

const importAutomatchStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, id: null, automatchError: null, automatchLoading: true };
};

const importAutomatchSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  const summary = state.summary
    ? { ...state.summary, automatchingStarted: true }
    : null;
  return {
    ...state,
    summary,
    automatchError: null,
    automatchLoading: false,
  };
};

const importAutomatchFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    automatchLoading: false,
    automatchError: action.error || null,
  };
};

const importDeleteStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const importDeleteSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    error: null,
    loading: false,
  };
};

const importDeleteFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    id: null,
    loading: false,
    error: action.error,
  };
};

const reducer = (state = initialState, action: IImportAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.IMPORT_UPLOAD_START:
      return uploadStart(state, action);
    case EActionTypes.IMPORT_UPLOAD_SUCCESS:
      return uploadSuccess(state, action);
    case EActionTypes.IMPORT_UPLOAD_FAIL:
      return uploadFail(state, action);
    case EActionTypes.IMPORT_UPLOAD_PROGRESS:
      return uploadProgress(state, action);
    case EActionTypes.IMPORT_LIST_START:
      return listImportsStart(state, action);
    case EActionTypes.IMPORT_LIST_SUCCESS:
      return listImportsSuccess(state, action);
    case EActionTypes.IMPORT_LIST_FAIL:
      return listImportsFail(state, action);
    case EActionTypes.IMPORT_GET_START:
      return getImportStart(state, action);
    case EActionTypes.IMPORT_GET_SUCCESS:
      return getImportSuccess(state, action);
    case EActionTypes.IMPORT_GET_FAIL:
      return getImportFail(state, action);
    case EActionTypes.IMPORT_AUTOMATCH_START:
      return importAutomatchStart(state, action);
    case EActionTypes.IMPORT_AUTOMATCH_SUCCESS:
      return importAutomatchSuccess(state, action);
    case EActionTypes.IMPORT_AUTOMATCH_FAIL:
      return importAutomatchFail(state, action);
    case EActionTypes.IMPORT_DELETE_START:
      return importDeleteStart(state, action);
    case EActionTypes.IMPORT_DELETE_SUCCESS:
      return importDeleteSuccess(state, action);
    case EActionTypes.IMPORT_DELETE_FAIL:
      return importDeleteFail(state, action);
    default:
      return state;
  }
};

export default reducer;
