import IUnavailabilityAction from "../../interfaces/action/IUnavailabilityAction";
import IUnavailabilityState from "../../interfaces/state/IUnavailabilityState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = IUnavailabilityState;
type ReducerSignature = (
  state: ReducerState,
  action: IUnavailabilityAction
) => ReducerState;

const initialState: ReducerState = {
  unavailability: null,
  unavailabilities: null,
  myUnavailabilities: null,
  loading: false,
  error: null,
  saveOrUpdateOk: false,
  saveOrUpdateLoading: false,
  myUnavailabilitiesLoading: false,
};

const clearUnavailability: ReducerSignature = (state, action): ReducerState => {
  return { ...initialState };
};

const listUnavailabilitiesStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailabilities: null,
    unavailability: null,
    error: null,
    loading: true,
  };
};

const listUnavailabilitiesSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailabilities: action.unavailabilities || null,
    error: null,
    loading: false,
  };
};

const listUnavailabilitiesFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailabilities: null,
    loading: false,
    error: action.error,
  };
};

const getUnavailabilityStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, unavailability: null, error: null, loading: true };
};

const getUnavailabilitySuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailability: action.unavailability || null,
    error: null,
    loading: false,
  };
};

const getUnavailabilityFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailability: null,
    loading: false,
    error: action.error,
  };
};

const updateUnavailabilityStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    saveOrUpdateOk: false,
    saveOrUpdateLoading: true,
    error: null,
  };
};

const updateUnavailabilitySuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailability: action.unavailability || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    saveOrUpdateLoading: false,
  };
};

const updateUnavailabilityFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailability: null,
    loading: false,
    error: action.error,
    saveOrUpdateOk: false,
    saveOrUpdateLoading: false,
  };
};

const saveUnavailabilityStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    saveOrUpdateOk: false,
    saveOrUpdateLoading: true,
    error: null,
  };
};

const saveUnavailabilitySuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailability: action.unavailability || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    saveOrUpdateLoading: false,
  };
};

const saveUnavailabilityFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailability: null,
    loading: false,
    error: action.error,
    saveOrUpdateOk: false,
    saveOrUpdateLoading: false,
  };
};

const deleteUnavailabilityStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, unavailability: null, error: null, loading: true };
};

const deleteUnavailabilitySuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailability: null,
    error: null,
    loading: false,
  };
};

const deleteUnavailabilityFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    unavailability: null,
    loading: false,
    error: action.error,
  };
};

const listMyUnavailabilitiesStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    myUnavailabilities: null,
    error: null,
    myUnavailabilitiesLoading: true,
  };
};

const listMyUnavailabilitiesSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    myUnavailabilities: action.unavailabilities || null,
    error: null,
    myUnavailabilitiesLoading: false,
  };
};

const listMyUnavailabilitiesFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    myUnavailabilities: null,
    myUnavailabilitiesLoading: false,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: IUnavailabilityAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.UNAVAILABILITY_CLEAR:
      return clearUnavailability(state, action);
    case EActionTypes.UNAVAILABILITY_LIST_START:
      return listUnavailabilitiesStart(state, action);
    case EActionTypes.UNAVAILABILITY_LIST_SUCCESS:
      return listUnavailabilitiesSuccess(state, action);
    case EActionTypes.UNAVAILABILITY_LIST_FAIL:
      return listUnavailabilitiesFail(state, action);
    case EActionTypes.UNAVAILABILITY_GET_START:
      return getUnavailabilityStart(state, action);
    case EActionTypes.UNAVAILABILITY_GET_SUCCESS:
      return getUnavailabilitySuccess(state, action);
    case EActionTypes.UNAVAILABILITY_GET_FAIL:
      return getUnavailabilityFail(state, action);
    case EActionTypes.UNAVAILABILITY_UPDATE_START:
      return updateUnavailabilityStart(state, action);
    case EActionTypes.UNAVAILABILITY_UPDATE_SUCCESS:
      return updateUnavailabilitySuccess(state, action);
    case EActionTypes.UNAVAILABILITY_UPDATE_FAIL:
      return updateUnavailabilityFail(state, action);
    case EActionTypes.UNAVAILABILITY_SAVE_START:
      return saveUnavailabilityStart(state, action);
    case EActionTypes.UNAVAILABILITY_SAVE_SUCCESS:
      return saveUnavailabilitySuccess(state, action);
    case EActionTypes.UNAVAILABILITY_SAVE_FAIL:
      return saveUnavailabilityFail(state, action);
    case EActionTypes.UNAVAILABILITY_DELETE_START:
      return deleteUnavailabilityStart(state, action);
    case EActionTypes.UNAVAILABILITY_DELETE_SUCCESS:
      return deleteUnavailabilitySuccess(state, action);
    case EActionTypes.UNAVAILABILITY_DELETE_FAIL:
      return deleteUnavailabilityFail(state, action);
    case EActionTypes.UNAVAILABILITY_MY_LIST_START:
      return listMyUnavailabilitiesStart(state, action);
    case EActionTypes.UNAVAILABILITY_MY_LIST_SUCCESS:
      return listMyUnavailabilitiesSuccess(state, action);
    case EActionTypes.UNAVAILABILITY_MY_LIST_FAIL:
      return listMyUnavailabilitiesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
