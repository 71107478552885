import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IOption } from "../components/UI/Input/Input";
import { createOptions } from "../shared/option-utils";

import { EWorkLogStatus } from "../interfaces/domain/IWorkLog";
import * as actions from "../store/actions";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

interface IParams {
  status?: EWorkLogStatus;
  userId?: string;
  replacementId?: string;
}

const defaultParams: IParams = {
  status: EWorkLogStatus.draft,
  userId: "",
  replacementId: "",
};

export const useWorkLogLinkOptions = ({ status, userId, replacementId } = defaultParams) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [workLogOptions, setWorkLogOptions] = useState<IOption[]>([]);
  const { workLogLinkOptions, workLogLinkOptionsLoading } = useAppSelector((state) => state.workLog);

  useEffect(() => {
    if (!workLogLinkOptions && !workLogLinkOptionsLoading) {
      dispatch(actions.listWorkLogLinkOptions({ status, userId, replacementId }));
    }
  }, [dispatch, workLogLinkOptions, workLogLinkOptionsLoading, status, userId, replacementId]);

  useEffect(() => {
    if (workLogLinkOptions) {
      let optionWorkLogs = [...workLogLinkOptions];
      setWorkLogOptions(createOptions(optionWorkLogs, (log) => log.testMissionId));
    } else {
      setWorkLogOptions([]);
    }
  }, [workLogLinkOptions, t]);

  return { workLogOptions, workLogOptionsLoading: workLogLinkOptionsLoading };
};
