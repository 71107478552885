import { AxiosResponse } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../axios";
import ModalContext, { EModalSize } from "../context/ModalContext";
import { ETranslation } from "../translations/translation-keys";
import { useCheckboxConfirmModal } from "./useCheckboxConfirmModal";

export const useModocSync = () => {
  const [modocLoading, setModocLoading] = useState(false);
  const [modocError, setModocError] = useState<string | null>(null);
  const openConfirmModal = useCheckboxConfirmModal();
  const { t } = useTranslation();
  const { setModal, closeModal } = useContext(ModalContext);

  useEffect(() => {
    if (modocError) {
      setModal({
        isOpen: true,
        size: EModalSize.SMALL,
        title: t(ETranslation.MODOC_SYNC_TITLE),
        content: <p>{modocError}</p>,
      });
    }
    return () => {
      setModocError(null);
    };
  }, [closeModal, setModal, t, modocError]);

  const modocSync = async (_: React.MouseEvent, modocMissionId?: string) => {
    if (modocLoading) return;

    const isConfirm = await openConfirmModal(
      t(ETranslation.MODOC_SYNC_TEXT),
      t(ETranslation.MODOC_SYNC_TITLE),
      t(ETranslation.MODOC_SYNC_BUFFER_NOTIFICATIONS)
    );

    if (isConfirm.success) {
      if (modocError) setModocError(null);
      setModocLoading(true);
      try {
        let url = `/json/testmission/modoc-sync?bufferNotifications=${isConfirm.checked}`;
        if (modocMissionId) {
          url += `&modocMissionId=${modocMissionId}`;
        }
        await axios.get(url);
        setModal({
          isOpen: true,
          size: EModalSize.SMALL,
          title: t(ETranslation.MODOC_SYNC_TITLE),
          content: <p>{t(ETranslation.MODOC_SYNC_SUCCESS_TEXT)}</p>,
        });
      } catch ({ response }: any) {
        setModocError((response as AxiosResponse).data);
      }
      setModocLoading(false);
    }
  };

  return { modocLoading, modocError, modocSync };
};
