import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import TestMissionComments from "../../../components/TestMissions/TestMission/TestMissionComments/TestMissionComments";
import TestMissionParticipants from "../../../components/TestMissions/TestMission/TestMissionParticipants/TestMissionParticipants";
import TestMissionParticipantsDialog from "../../../components/TestMissions/TestMission/TestMissionParticipantsDialog/TestMissionParticipantsDialog";
import Button, { EButtonColor, EButtonSize } from "../../../components/UI/Button/Button";
import ConfirmDialog from "../../../components/UI/ConfirmDialog/ConfirmDialog";
import Container from "../../../components/UI/Container/Container";
import {
  EInputType,
  IInputField,
  IOption,
  TInputValue,
} from "../../../components/UI/Input/Input";
import {
  initForm,
  validateInputs,
} from "../../../components/UI/Input/input-utils";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import WorkLogsTable from "../../../components/WorkLogs/WorkLogsTable/WorkLogsTable";
import ModalContext, { EModalSize } from "../../../context/ModalContext";
import { DATEPATTERN_FIN } from "../../../data/constants";
import {
  genderRequirementOptions,
  regionOptions,
  sportsOptions,
  testMissionStatusOptions,
} from "../../../data/select-data";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useBlurBeforeUnload } from "../../../hooks/useBlurBeforeUnload";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { useModocSync } from "../../../hooks/useModocSync";
import { IParticipant } from "../../../interfaces/domain/IParticipant";
import {
  ETestMissionStatus
} from "../../../interfaces/domain/ITestMission";
import { createOptions } from "../../../shared/option-utils";
import { ETestMissionView } from "../../../shared/test-mission";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";

export enum ETestMission {
  testMissionId = "testMissionId",
  status = "status",
  sportsCategory = "sportsCategory",
  sportsDiscipline = "sportsDiscipline",
  startDate = "startDate",
  endDate = "endDate",
  numberOfTests = "numberOfTests",
  leadDCO = "leadDCO",
  inCompetition = "inCompetition",
  testPlanner = "testPlanner",
  region = "region",
  genderRequirement = "genderRequirement",
  urgent = "urgent",
  city = "city",
  ignoreInCompetition = "ignoreInCompetition",
}

const TestMission: React.FC = () => {
  useBlurBeforeUnload();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { setModal, closeModal } = useContext(ModalContext);
  const { modocSync, modocLoading } = useModocSync();

  const [searchParams] = useSearchParams();
  const initialView = useMemo(() => {
    let param = searchParams.get("view");
    if (!param) return undefined;
    const view = param.toUpperCase() as ETestMissionView;
    if (Object.values(ETestMissionView).includes(view)) return view;
    return undefined;
  }, [searchParams]);

  const [view, setView] = useState<ETestMissionView>(
    initialView ?? ETestMissionView.TEAM
  );

  const { loading: workLogsLoading, workLogs } = useAppSelector(
    (state) => state.workLog
  );

  const { loading, testMission } = useAppSelector((state) => state.testMission);
  const { loading: sportsLoading, sports } = useAppSelector(
    (state) => state.sports
  );

  const { testPlanners, testPlannersLoading } = useAppSelector(
    (state) => state.user
  );

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch(actions.getTestMission(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (
      testMission &&
      testMission.status === ETestMissionStatus.COMPLETE &&
      testMission.testMissionId
    ) {
      dispatch(
        actions.listWorkLog({ testMissionId: testMission.testMissionId })
      );
    }
    return () => {
      dispatch(actions.clearWorkLog());
    };
  }, [dispatch, testMission]);

  const [inputs, setInputs] = useState<IInputField>({
    [ETestMission.testMissionId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.TEST_MISSION_ID,
      placeholderTranslation: ETranslation.TEST_MISSION_ID,
      value: "",
      validation: {
        required: true,
      },
    },
    [ETestMission.status]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.STATUS,
      placeholderTranslation: ETranslation.SELECT_STATUS_PLACEHOLDER,
      value: "",
      options: testMissionStatusOptions,
      validation: {
        required: true,
      },
    },
    [ETestMission.sportsCategory]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.SPORT,
      placeholderTranslation: ETranslation.SELECT_SPORT_PLACEHOLDER,
      value: "",
      options: sportsOptions,
      validation: {
        required: true,
      },
    },
    [ETestMission.sportsDiscipline]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DISCIPLINE,
      placeholderTranslation: ETranslation.DISCIPLINE,
      value: "",
    },
    [ETestMission.startDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.START_DATE,
      placeholderTranslation: ETranslation.START_DATE,
      value: "",
      validation: {
        required: true,
      },
    },
    [ETestMission.endDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.END_DATE,
      placeholderTranslation: ETranslation.END_DATE,
      value: "",
      validation: {
        required: true,
      },
    },
    [ETestMission.numberOfTests]: {
      type: EInputType.number,
      labelTranslation: ETranslation.NUMBER_OF_TESTS,
      placeholderTranslation: ETranslation.NUMBER_OF_TESTS,
      value: "",
    },
    [ETestMission.inCompetition]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.IN_COMPETITION,
      placeholderTranslation: ETranslation.IN_COMPETITION,
      value: "",
    },
    [ETestMission.testPlanner]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.TEST_PLANNER,
      placeholderTranslation: ETranslation.SELECT_USER_PLACEHOLDER,
      value: "",
      options: [],
    },
    [ETestMission.region]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.REGION,
      placeholderTranslation: ETranslation.SELECT_REGION_PLACEHOLDER,
      value: "",
      options: regionOptions,
      validation: {
        required: true,
      },
    },
    [ETestMission.genderRequirement]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.GENDER_REQUIREMENT,
      placeholderTranslation: ETranslation.GENDER_REQUIREMENT,
      value: "",
      options: genderRequirementOptions,
      validation: {
        required: true,
      },
    },
    [ETestMission.urgent]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.URGENT,
      placeholderTranslation: ETranslation.URGENT,
      value: "",
    },
    [ETestMission.city]: {
      type: EInputType.text,
      labelTranslation: ETranslation.CITY,
      placeholderTranslation: ETranslation.CITY,
      value: "",
    },
    [ETestMission.ignoreInCompetition]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.IGNORE_IN_COMPETITION,
      placeholderTranslation: ETranslation.IGNORE_IN_COMPETITION,
      value: "",
    },
  });

  useEffect(() => {
    dispatch(actions.listTestPlanners());
    dispatch(actions.listMyNotifications());
  }, [dispatch]);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  useEffect(() => {
    if (testMission) {
      initForm(setInputs, testMission);
    }
  }, [testMission]);

  const onUpdate = useCallback(
    async (field: ETestMission, value: TInputValue) => {
      // console.log("update", field, value);
      if (!params.id) return Promise.resolve(true);
      if (testMission && testMission[field] === value)
        return Promise.resolve(true);
      await dispatch(
        actions.updateTestMission(params.id, field, value ? value : "")
      );
      return Promise.resolve(true);
    },
    [dispatch, params.id, testMission]
  );

  const disableFields = useMemo(
    () => !!testMission?.modocMissionId,
    [testMission?.modocMissionId]
  );

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation: !isValid,
    onBlur: (value, inputName) => onUpdate(inputName as ETestMission, value),
    disabled: disableFields,
  });

  const editParticipant = (participant?: IParticipant) =>
    setModal({
      isOpen: true,
      title: t(ETranslation.PARTICIPANTS),
      size: EModalSize.MEDIUM,
      content: (
        <TestMissionParticipantsDialog
          participant={participant}
          participants={testMission?.participants || []}
          onSave={participantUpdateHandler}
          onDelete={participantDeleteHandler}
          canEdit={isValid}
        />
      ),
    });

  const acceptParticipant = (participant?: IParticipant) =>
    setModal({
      isOpen: true,
      title: t(ETranslation.PARTICIPANTS),
      size: EModalSize.MEDIUM,
      content: (
        <ConfirmDialog
          onCancel={closeModal}
          onConfirm={() => {
            if (params?.id && participant?.user?.id) {
              dispatch(
                actions.acceptTestMissionParticipant(
                  params.id,
                  participant.user.id
                )
              );
            }
            closeModal();
          }}
          body={<p>{t(ETranslation.TEST_MISSION_PARTICIPANT_ACCEPT)}</p>}
        />
      ),
    });

  const participantUpdateHandler = useCallback(
    async (participant: IParticipant) => {
      console.log("update", participant);
      if (params.id) {
        if (!participant.offeredDate) {
          participant.offeredDate = moment(new Date()).format(DATEPATTERN_FIN);
        }
        await dispatch(
          actions.updateTestMissionParticipants(
            params.id,
            participant.id,
            participant.participantType.roleName,
            participant.user?.id,
            participant.offeredDate
          )
        );
        return Promise.resolve(true);
      }
    },
    [dispatch, params.id]
  );

  const participantDeleteHandler = (
    participantId: string,
    participantUserId?: string
  ) => {
    if (testMission) {
      dispatch(
        actions.deleteTestMissionParticipant(
          testMission.id,
          participantId,
          participantUserId
        )
      );
    }
  };

  const commentAddHandler = (value: TInputValue) => {
    if (testMission && value) {
      dispatch(actions.addTestMissionComment(testMission.id, value as string));
    }
  };

  const commentDeleteHandler = (id: string) => {
    if (testMission) {
      dispatch(actions.deleteTestMissionComment(testMission.id, id));
    }
  };

  const showDeleteDialog = () =>
    setModal({
      isOpen: true,
      title: t(ETranslation.COMMON_DELETE),
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          onCancel={closeModal}
          onConfirm={() => {
            dispatch(actions.deleteTestMission(params.id as string));
            closeModal();
            navigate(Routes.TEST_MISSIONS);
          }}
          body={<p>{t(ETranslation.CONFIRM_TEST_MISSION_DELETE)}</p>}
        />
      ),
    });

  const viewOptions: IOption[] = useMemo(
    () =>
      [
        { value: ETestMissionView.TEAM, labelTranslation: ETranslation.TEAM },
        {
          value: ETestMissionView.COMPENSATION,
          labelTranslation: ETranslation.COMPENSATION,
        },
      ] as IOption[],
    []
  );

  const inCompetition = useMemo(
    () => inputs[ETestMission.inCompetition].value as boolean,
    [inputs]
  );

  return (
    <Container>
      {loading || sportsLoading ? (
        <Spinner />
      ) : testMission && sports ? (
        <>
          {/* {workLogs && (
            <RadioButton
              value={view as string}
              onChange={(value) => setView(value as ETestMissionView)}
              options={viewOptions}
              containerStyles={{ marginBottom: "1rem" }}
            />
          )} */}
          {view === ETestMissionView.TEAM ? (
            <>
              <InputGroup>
                {createInput(ETestMission.testMissionId)}
                {testMission.modocMissionId && (
                  <Button
                    size={EButtonSize.SMALL}
                    icon={faExternalLink}
                    onClick={() =>
                      window.open(
                        `https://adno.pwc-modoc.com/missions/${testMission.modocMissionId}/edit/detail`
                      )
                    }
                  >
                    {t(ETranslation.TEST_MISSION_OPEN_IN_MODOC)}
                  </Button>
                )}
              </InputGroup>
              {createInput(ETestMission.status, { disabled: false })}
              {createInput(ETestMission.sportsCategory)}
              {createInput(ETestMission.sportsDiscipline)}
              {createInput(ETestMission.startDate)}
              {createInput(ETestMission.endDate)}
              {createInput(ETestMission.numberOfTests, {
                info: testMission?.testTypes,
              })}
              <InputGroup>
                {createInput(ETestMission.inCompetition)}
                {inCompetition &&
                  createInput(ETestMission.ignoreInCompetition, {
                    disabled: false,
                  })}
                {createInput(ETestMission.urgent, { disabled: false })}
              </InputGroup>
              {createInput(ETestMission.testPlanner, {
                options:
                  testPlanners && testPlanners.length > 0
                    ? createOptions(testPlanners, (u) => u.name || u.id)
                    : [],
                loading: testPlannersLoading,
              })}
              {createInput(ETestMission.region)}
              {createInput(ETestMission.city)}
              {createInput(ETestMission.genderRequirement)}
              <TestMissionParticipants
                participants={testMission.participants}
                onEdit={editParticipant}
                onAccept={acceptParticipant}
              />
              <TestMissionComments
                comments={testMission.comments}
                onAdd={commentAddHandler}
                onDelete={commentDeleteHandler}
              />
            </>
          ) : testMission.status === ETestMissionStatus.COMPLETE &&
            workLogsLoading ? (
            <Spinner center />
          ) : workLogs ? (
            <>
              <p>{t(ETranslation.COMPENSATIONS)}</p>
              <WorkLogsTable workLogs={workLogs} testMissionId={testMission.testMissionId} />
            </>
          ) : null}
        </>
      ) : (
        <p>{t(ETranslation.NOT_FOUND_ERROR)}</p>
      )}
      <InputGroup>
        <Button onClick={() => navigate(-1)}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        {testMission && testMission.id && (
          <>
            <Button color={EButtonColor.DANGER} onClick={showDeleteDialog}>
              {t(ETranslation.COMMON_DELETE)}
            </Button>
            {testMission?.modocMissionId && (
              <Button onClick={(e) => modocSync(e, testMission.modocMissionId)} loading={modocLoading}>
                {t(ETranslation.MODOC_SYNC_TITLE)}
              </Button>
            )}
          </>
        )}
      </InputGroup>
    </Container>
  );
};

export default TestMission;
