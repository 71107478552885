import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ITestMissionSearch } from "../components/TestMissions/TestMissionSearch/TestMissionSearch";
import IAppState from "../interfaces/state/IAppState";
import * as actions from "../store/actions";
import { ITestMission } from "./../interfaces/domain/ITestMission";
import { useAppDispatch } from "./useAppDispatch";

export const usePagination = function () {
  const dispatch = useAppDispatch();

  const [searchOptions, setSearchOptions] = useState<ITestMissionSearch | null>(
    null
  );
  const [testMissions, setTestMissions] = useState<ITestMission[] | null>(null);

  const {
    testMissions: stateTestMissions,
    paginationCursor,
    hasMoreTestMissions,
    loading,
  } = useSelector((state: IAppState) => state.testMission);

  useEffect(() => {
    setTestMissions(stateTestMissions);
  }, [stateTestMissions]);

  const searchHandler = useCallback(
    (search: ITestMissionSearch) => {
      dispatch(actions.listTestMissions(search));
      setSearchOptions(search);
    },
    [dispatch]
  );

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (
        !hasMoreTestMissions ||
        !searchOptions ||
        loading ||
        stateTestMissions?.length === 0
      ) {
        return;
      }
      const timeoutId = setTimeout(() => {
        const target = entries[0];
        if (target.isIntersecting) {
          searchHandler({
            ...searchOptions,
            paginationCursor: paginationCursor ?? "",
          });
        }
      }, 500);
      return () => clearTimeout(timeoutId);
    },
    [
      loading,
      stateTestMissions,
      searchHandler,
      searchOptions,
      hasMoreTestMissions,
      paginationCursor,
    ]
  );

  return {
    testMissions,
    loading,
    searchHandler,
    hasMoreTestMissions,
    paginationCursor,
    handleObserver,
  };
};
