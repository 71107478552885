import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import IWorkLogAction from "../../interfaces/action/IWorkLogAction";
import { IWorkLog, IWorkLogExpense } from "../../interfaces/domain/IWorkLog";
import { EActionTypes } from "../EActionTypes";
import { objectToQueryString } from "../../shared/qs-utils";

type TAction = IWorkLogAction;
const apiPath = "/json/worklog";

const getWorkLogStart = (): TAction => {
  return {
    type: EActionTypes.WORKLOG_GET_START,
  };
};

const getWorkLogSuccess = (workLog: IWorkLog): TAction => {
  return {
    type: EActionTypes.WORKLOG_GET_SUCCESS,
    workLog,
  };
};

const getWorkLogFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_GET_FAIL,
    error,
  };
};

export const getWorkLog = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getWorkLogStart());
    try {
      const res = await axios.get<IWorkLog>(`${apiPath}/get?id=${id}`);
      dispatch(getWorkLogSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(getWorkLogFail((response as AxiosResponse).data));
    }
  };
};

const listWorkLogStart = (): TAction => {
  return {
    type: EActionTypes.WORKLOG_LIST_START,
  };
};

const listWorkLogSuccess = (workLogs: IWorkLog[]): TAction => {
  return {
    type: EActionTypes.WORKLOG_LIST_SUCCESS,
    workLogs,
  };
};

const listWorkLogFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_LIST_FAIL,
    error,
  };
};

export interface IWorkLogSearchOptions {
  testMissionId?: string;
  status?: string;
  userId?: string;
  replacementId?: string;
}

export const listWorkLog = (search: IWorkLogSearchOptions) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listWorkLogStart());
    try {
      const res = await axios.get<IWorkLog[]>(
        `${apiPath}/list${objectToQueryString(search)}`
      );
      dispatch(listWorkLogSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(listWorkLogFail((response as AxiosResponse).data));
    }
  };
};

const saveWorkLogStart = (): TAction => {
  return {
    type: EActionTypes.WORKLOG_SAVE_START,
  };
};

const saveWorkLogSuccess = (workLog: IWorkLog): TAction => {
  return {
    type: EActionTypes.WORKLOG_SAVE_SUCCESS,
    workLog,
  };
};

const saveWorkLogFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_SAVE_FAIL,
    error,
  };
};

export const saveWorkLog = (workLog: IWorkLog) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(saveWorkLogStart());
    try {
      const res = await axios.post<IWorkLog>(`${apiPath}/save`, workLog);
      dispatch(saveWorkLogSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(saveWorkLogFail((response as AxiosResponse).data));
    }
  };
};

const updateWorkLogStart = (): TAction => {
  return {
    type: EActionTypes.WORKLOG_UPDATE_START,
  };
};

const updateWorkLogSuccess = (workLog: IWorkLog): TAction => {
  return {
    type: EActionTypes.WORKLOG_UPDATE_SUCCESS,
    workLog,
  };
};

const updateWorkLogFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_UPDATE_FAIL,
    error,
  };
};

export const updateWorkLog = (workLog: IWorkLog) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateWorkLogStart());
    try {
      const res = await axios.put<IWorkLog>(`${apiPath}/update`, workLog);
      dispatch(updateWorkLogSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(updateWorkLogFail((response as AxiosResponse).data));
    }
  };
};

const myWorkLogStart = (): TAction => {
  return {
    type: EActionTypes.WORKLOG_MY_START,
  };
};

const myWorkLogSuccess = (workLog: IWorkLog): TAction => {
  return {
    type: EActionTypes.WORKLOG_MY_SUCCESS,
    workLog,
  };
};

const myWorkLogFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_MY_FAIL,
    error,
  };
};

export const myWorkLog = (testMissionId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(myWorkLogStart());
    try {
      const res = await axios.get<IWorkLog>(
        `${apiPath}/my?testMissionId=${testMissionId}`
      );
      dispatch(myWorkLogSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(myWorkLogFail((response as AxiosResponse).data));
    }
  };
};

const saveWorkLogExpenseStart = (): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_SAVE_START,
  };
};

const saveWorkLogExpenseSuccess = (workLog: IWorkLog): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_SAVE_SUCCESS,
    workLog,
  };
};

const saveWorkLogExpenseFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_SAVE_FAIL,
    error,
  };
};

export const saveWorkLogExpense = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(saveWorkLogExpenseStart());
    try {
      const res = await axios.post<IWorkLog>(
        `${apiPath}/expenses/save?id=${id}`
      );
      dispatch(saveWorkLogExpenseSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(saveWorkLogExpenseFail((response as AxiosResponse).data));
    }
  };
};

const uploadAttachmentStart = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_UPLOAD_START,
    id,
  };
};

const uploadAttachmentSuccess = (workLog: IWorkLog): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_UPLOAD_SUCCESS,
    workLog,
  };
};

const uploadAttachmentFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_UPLOAD_FAIL,
    error,
  };
};

export const uploadAttachment = (
  files: File[],
  testMissionId: string,
  id: string,
  expenseId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(uploadAttachmentStart(expenseId));

    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    try {
      const res = await axios.post<IWorkLog>(
        `${apiPath}/expenses/upload?testMissionId=${testMissionId}&id=${id}&expenseId=${expenseId}`,
        data
      );
      dispatch(uploadAttachmentSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(uploadAttachmentFail((response as AxiosResponse)?.data ?? ""));
    }
  };
};

const updateWorkLogExpenseStart = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_UPDATE_START,
    id,
  };
};

const updateWorkLogExpenseSuccess = (workLog: IWorkLog): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_UPDATE_SUCCESS,
    workLog,
  };
};

const updateWorkLogExpenseFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_UPDATE_FAIL,
    error,
  };
};

export const updateWorkLogExpense = (
  id: string,
  expenseId: string,
  expense: IWorkLogExpense
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateWorkLogExpenseStart(expenseId));
    try {
      const res = await axios.put<IWorkLog>(
        `${apiPath}/expenses/update?id=${id}&expenseId=${expenseId}`,
        expense
      );
      dispatch(updateWorkLogExpenseSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(updateWorkLogExpenseFail((response as AxiosResponse).data));
    }
  };
};

const deleteWorkLogExpenseStart = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_DELETE_START,
    id,
  };
};

const deleteWorkLogExpenseSuccess = (workLog: IWorkLog): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_DELETE_SUCCESS,
    workLog,
  };
};

const deleteWorkLogExpenseFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_DELETE_FAIL,
    error,
  };
};

export const deleteWorkLogExpense = (id: string, expenseId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteWorkLogExpenseStart(expenseId));
    try {
      const res = await axios.delete<IWorkLog>(
        `${apiPath}/expenses/delete?id=${id}&expenseId=${expenseId}`
      );
      dispatch(deleteWorkLogExpenseSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(deleteWorkLogExpenseFail((response as AxiosResponse).data));
    }
  };
};

export const clearWorkLog = (): TAction => {
  return {
    type: EActionTypes.WORKLOG_CLEAR,
  };
};

const acceptWorkLogStart = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_ACCEPT_START,
    id,
  };
};

const acceptWorkLogSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_ACCEPT_SUCCESS,
    id,
  };
};

const acceptWorkLogFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_ACCEPT_FAIL,
    error,
  };
};

export const acceptWorkLog = (id: string, testMissionId?: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(acceptWorkLogStart(id));
    try {
      await axios.put<boolean>(`${apiPath}/accept?id=${id}&testMissionId=${testMissionId ?? ""}`);
      dispatch(acceptWorkLogSuccess(id));
    } catch ({ response }: any) {
      dispatch(acceptWorkLogFail((response as AxiosResponse)?.data ?? ""));
    }
  };
};

const returnWorkLogStart = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_RETURN_START,
    id,
  };
};

const returnWorkLogSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_RETURN_SUCCESS,
    id,
  };
};

const returnWorkLogFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_RETURN_FAIL,
    error,
  };
};

export const returnWorkLog = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(returnWorkLogStart(id));
    try {
      await axios.put<boolean>(`${apiPath}/return?id=${id}`);
      dispatch(returnWorkLogSuccess(id));
    } catch ({ response }: any) {
      dispatch(returnWorkLogFail((response as AxiosResponse)?.data ?? ""));
    }
  };
};

export const clearWorkLogError = (): TAction => {
  return {
    type: EActionTypes.WORKLOG_CLEAR_ERROR,
  };
};

const markWorkLogPendingStart = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_MARK_PENDING_START,
    id,
  };
};

const markWorkLogPendingSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_MARK_PENDING_SUCCESS,
    id,
  };
};

const markWorkLogPendingFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_MARK_PENDING_FAIL,
    error,
  };
};

export const markWorkLogPending = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(markWorkLogPendingStart(id));
    try {
      await axios.put<boolean>(`${apiPath}/mark-pending?id=${id}`);
      dispatch(markWorkLogPendingSuccess(id));
    } catch ({ response }: any) {
      dispatch(markWorkLogPendingFail((response as AxiosResponse).data));
    }
  };
};

const deleteWorkLogAttachmentStart = (id: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_ATTACHMENT_DELETE_START,
    id,
  };
};

const deleteWorkLogAttachmentSuccess = (
  id: string,
  attachmentId: string
): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_ATTACHMENT_DELETE_SUCCESS,
    id,
    attachmentId,
  };
};

const deleteWorkLogAttachmentFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_EXPENSE_ATTACHMENT_DELETE_FAIL,
    error,
  };
};

export const deleteWorkLogAttachment = (
  id: string,
  expenseId: string,
  attachmentId: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteWorkLogAttachmentStart(attachmentId));
    try {
      await axios.delete(
        `${apiPath}/expenses/delete-attachment${objectToQueryString({
          id,
          expenseId,
          attachmentId,
        })}`
      );
      dispatch(deleteWorkLogAttachmentSuccess(expenseId, attachmentId));
    } catch ({ response }: any) {
      dispatch(deleteWorkLogAttachmentFail((response as AxiosResponse)?.data ?? ""));
    }
  };
};

const listWorkLogLinkOptionsStart = (): TAction => {
  return {
    type: EActionTypes.WORKLOG_LIST_LINK_OPTIONS_START,
  };
};

const listWorkLogLinkOptionsSuccess = (workLogs: IWorkLog[]): TAction => {
  return {
    type: EActionTypes.WORKLOG_LIST_LINK_OPTIONS_SUCCESS,
    workLogs,
  };
};

const listWorkLogLinkOptionsFail = (error: string): TAction => {
  return {
    type: EActionTypes.WORKLOG_LIST_LINK_OPTIONS_FAIL,
    error,
  };
};

export const listWorkLogLinkOptions = (search: IWorkLogSearchOptions) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listWorkLogLinkOptionsStart());
    try {
      const res = await axios.get<IWorkLog[]>(
        `${apiPath}/link-options${objectToQueryString(search)}`
      );
      dispatch(listWorkLogLinkOptionsSuccess(res.data));
    } catch ({ response }: any) {
      dispatch(listWorkLogLinkOptionsFail((response as AxiosResponse).data));
    }
  };
};
