import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalContext from "../../../../../context/ModalContext";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { useCreateInput } from "../../../../../hooks/useCreateInput";
import {
  IParticipant
} from "../../../../../interfaces/domain/IParticipant";
import { ERoleName } from "../../../../../interfaces/domain/IRole";
import { IUser } from "../../../../../interfaces/domain/IUser";
import { createOptions } from "../../../../../shared/option-utils";
import * as actions from "../../../../../store/actions";
import { ETranslation } from "../../../../../translations/translation-keys";
import Alert from "../../../../UI/Alert/Alert";
import Button, { EButtonColor } from "../../../../UI/Button/Button";
import { EInputType, IInputField, IOption } from "../../../../UI/Input/Input";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import { updateInputHandler } from "../../../../UI/Input/input-utils";

export enum ETestMissionParticipant {
  roleName = "roleName",
  user = "user",
}

interface IProps {
  participant: IParticipant;
  options: IOption[];
  onSave: (participant: IParticipant) => void;
  onDelete: () => void;
  isNew: boolean;
  canEdit: boolean;
}

const TestMissionParticipant: React.FC<IProps> = ({
  participant,
  options,
  onSave,
  onDelete,
  isNew,
  canEdit,
}) => {
  const { t } = useTranslation();
  const [userOptions, setUserOptions] = useState<IOption[]>([]);
  const { closeModal } = useContext(ModalContext);

  const { id, participantType, user } = participant;
  const { roleName } = participantType;

  const dispatch = useAppDispatch();
  const { testMission } = useAppSelector((state) => state.testMission);
  const { searchedUsersLoading, searchedUsers, top5Users } = useAppSelector(
    (state) => state.user
  );

  const [inputs, setInputs] = useState<IInputField>({
    [ETestMissionParticipant.roleName]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.ROLE_NAME,
      placeholderTranslation: ETranslation.SELECT_ROLE_PLACEHOLDER,
      options: [],
      value: roleName,
    },
    [ETestMissionParticipant.user]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.USER,
      placeholderTranslation: ETranslation.SELECT_USER_PLACEHOLDER,
      options: [],
      value: user?.id,
    },
  });

  const roleNameValue = inputs[ETestMissionParticipant.roleName]
    .value as ERoleName;

  useEffect(() => {
    if (canEdit && roleNameValue) {
      dispatch(
        actions.listUsersSearch(roleNameValue, undefined, testMission?.id)
      );
    } else {
      setUserOptions([]);
    }
  }, [dispatch, canEdit, roleNameValue, testMission?.id]);

  useEffect(() => {
    const testMissionUsers = [...(testMission?.participants || [])]
      .filter((p) => p.id !== id && p.user?.id)
      .map((p) => p.user?.id);
    const filteredUsers = [...(searchedUsers || [])].filter(
      (u) => !testMissionUsers.includes(u.id)
    );
    setUserOptions(createOptions(filteredUsers || [], (u) => u.name || u.id));
  }, [searchedUsers, roleName, testMission?.participants, id]);

  const createInput = useCreateInput(inputs, setInputs);

  const saveHandler = () => {
    const userId = inputs[ETestMissionParticipant.user].value as string;
    const user = searchedUsers?.find((u) => u.id === userId);
    const userDto: Partial<IUser> = {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      roles: user?.roles,
    };
    onSave({
      ...participant,
      user: user ? (userDto as IUser) : null,
      participantType: {
        ...participant.participantType,
        roleName: roleNameValue,
      },
    });
    closeModal();
  };

  const deleteHandler = () => {
    onDelete();
    closeModal();
  };

  return (
    <div style={{ overflow: "visible" }}>
      {!canEdit && (
        <Alert noHorizontalMargin>
          {t(ETranslation.TEST_MISSION_FILL_REQUIRED_FIELDS)}
        </Alert>
      )}
      {createInput(ETestMissionParticipant.roleName, {
        disabled: !!roleName && (roleName === ERoleName.LEAD_DCO || !isNew),
        options,
      })}
      {createInput(ETestMissionParticipant.user, {
        disabled: !canEdit,
        options: userOptions,
        loading: searchedUsersLoading,
      })}
      {roleNameValue ? (
        searchedUsersLoading ? null : top5Users?.length ? (
          <>
            <p>{t(ETranslation.TEST_MISSION_AUTOMATCH_RECOMMENDED_USERS)}</p>
            <ul>
              {top5Users.map((user) => (
                <li key={user.id}>
                  <span style={{cursor:"pointer", color:"blue", textDecoration:"underline"}}
                  onClick={()=>updateInputHandler(ETestMissionParticipant.user, user.id, setInputs)}>
                    {user.name}
                  </span>
                  <span> {user.userDescription}</span>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p>{t(ETranslation.TEST_MISSION_AUTOMATCH_FOUND_NO_USERS)}</p>
        )
      ) : null}
      <InputGroup>
        <Button color={EButtonColor.PRIMARY} onClick={saveHandler}>
          {t(ETranslation.COMMON_SAVE)}
        </Button>
        {!isNew &&
          participant.participantType.roleName !== ERoleName.LEAD_DCO && (
            <Button color={EButtonColor.DANGER} onClick={deleteHandler}>
              {t(ETranslation.COMMON_DELETE)}
            </Button>
          )}
        <Button onClick={closeModal}>{t(ETranslation.COMMON_CLOSE)}</Button>
      </InputGroup>
    </div>
  );
};

export default TestMissionParticipant;
