import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ERouteStaticParams, Routes } from "../../classes/Routes";
import FieldPersonBox from "../../components/FieldPersons/FieldPerson/FieldPersonBox/FieldPersonBox";
import FieldPersonList from "../../components/FieldPersons/FieldPersonList/FieldPersonList";
import FieldPersonSearch, {
  IFieldPersonSearch,
} from "../../components/FieldPersons/FieldPersonSearch/FieldPersonSearch";
import Title from "../../components/Title/Title";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMediaQuery from "../../hooks/useMediaQuery";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";

const FieldPersons: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const { searchedUsersLoading, searchedUsers } = useAppSelector((state) => state.user);

  const searchHandler = useCallback(
    (search: IFieldPersonSearch) => {
      dispatch(actions.listUsersSearch(search.role, search.region));
    },
    [dispatch]
  );

  return (
    <>
      <FieldPersonSearch onSearch={searchHandler} />
      {isMobile ? (
        searchedUsersLoading ? (
          <Spinner />
        ) : searchedUsers ? (
          searchedUsers.map((fieldPerson) => (
            <FieldPersonBox key={fieldPerson.id} fieldPerson={fieldPerson} />
          ))
        ) : (
          <Container>{t(ETranslation.NO_FIELD_PERSONS)}</Container>
        )
      ) : (
        <Container>
          <Title
            translation={ETranslation.FIELD_PERSONS}
            route={Routes.FIELD_PERSON(ERouteStaticParams.new)}
          />
          {searchedUsersLoading ? (
            <Spinner />
          ) : searchedUsers ? (
            <FieldPersonList users={searchedUsers} />
          ) : (
            t(ETranslation.NO_FIELD_PERSONS)
          )}
        </Container>
      )}
    </>
  );
};

export default FieldPersons;
