import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  regionOptions,
  sportsOptions,
  testMissionSearchOptions,
  testMissionStatusOptions,
} from "../../../data/select-data";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { ETestMissionSearchOption } from "../../../interfaces/domain/ITestMission";
import { createOptions } from "../../../shared/option-utils";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonSize } from "../../UI/Button/Button";
import Container from "../../UI/Container/Container";
import { EInputType, IInputField, IOption } from "../../UI/Input/Input";
import { getInputData } from "../../UI/Input/input-utils";
import InputGroup from "../../UI/InputGroup/InputGroup";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import * as actions from "../../../store/actions";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { useModocSync } from "../../../hooks/useModocSync";
import { ESearchStorageKey } from "../../../data/constants";

export interface ITestMissionSearch {
  orderBy?: ETestMissionSearchOption;
  value?: string;

  testMissionId?: string;
  status?: string;
  region?: string;
  sportsCategory?: string;
  testPlannerId?: string;
  participantId?: string;
  startDate?: string;
  paginationCursor?: string | null;
}

interface IProps {
  onSearch: (search: ITestMissionSearch) => void;
}

enum ETestMissionSearch {
  orderBy = "orderBy",
  value = "value",

  teetMissionId = "testMissionId",
  status = "status",
  region = "region",
  sportsCategory = "sportsCategory",
  testPlannerId = "testPlannerId",
  participantId = "participantId",
  startDate = "startDate",
}

const TestMissionSearch: React.FC<IProps> = ({ onSearch }) => {
  const initialInputs: IInputField = useMemo(
    () => ({
      [ETestMissionSearch.orderBy]: {
        type: EInputType.reactSelect,
        labelTranslation: ETranslation.SEARCH_ORDER_BY,
        placeholderTranslation: ETranslation.SEARCH_ORDER_BY,
        value: ETestMissionSearchOption.filter,
        options: testMissionSearchOptions,
        isClearable: false,
      },
      [ETestMissionSearchOption.sportsCategory]: {
        type: EInputType.reactSelect,
        labelTranslation: ETranslation.SEARCH_SPORT,
        placeholderTranslation: ETranslation.SELECT_SPORT_PLACEHOLDER,
        value: localStorage.getItem(ESearchStorageKey.TEST_MISSIONS_SPORTS_CATEGORY) || '',
        options: sportsOptions,
      },
      [ETestMissionSearchOption.testPlanner]: {
        type: EInputType.reactSelect,
        labelTranslation: ETranslation.SEARCH_TEST_PLANNER,
        placeholderTranslation: ETranslation.SELECT_USER_PLACEHOLDER,
        value: localStorage.getItem(ESearchStorageKey.TEST_MISSIONS_TEST_PLANNER) || '',
        options: [],
      },
      [ETestMissionSearchOption.status]: {
        type: EInputType.reactSelect,
        labelTranslation: ETranslation.SEARCH_STATUS,
        placeholderTranslation: ETranslation.SELECT_STATUS_PLACEHOLDER,
        value: localStorage.getItem(ESearchStorageKey.TEST_MISSIONS_STATUS) || '',
        options: testMissionStatusOptions,
      },
      [ETestMissionSearchOption.participants]: {
        type: EInputType.reactSelect,
        labelTranslation: ETranslation.PARTICIPANTS,
        placeholderTranslation: ETranslation.SELECT_USER_PLACEHOLDER,
        value: localStorage.getItem(ESearchStorageKey.TEST_MISSIONS_PARTICIPANT) || '',
        options: [],
      },
      [ETestMissionSearchOption.region]: {
        type: EInputType.reactSelect,
        labelTranslation: ETranslation.REGION,
        placeholderTranslation: ETranslation.SELECT_REGION_PLACEHOLDER,
        value: localStorage.getItem(ESearchStorageKey.TEST_MISSIONS_REGION) || '',
        options: regionOptions,
      },
      [ETestMissionSearchOption.testMissionId]: {
        type: EInputType.text,
        labelTranslation: ETranslation.SEARCH_TEST_MISSION_ID,
        placeholderTranslation: ETranslation.SEARCH_TEST_MISSION_ID,
        value: localStorage.getItem(ESearchStorageKey.TEST_MISSIONS_TEST_MISSION_ID) || '',
      },
      [ETestMissionSearchOption.startDate]: {
        type: EInputType.date,
        labelTranslation: ETranslation.SEARCH_START_DATE,
        placeholderTranslation: ETranslation.SEARCH_START_DATE,
        value: localStorage.getItem(ESearchStorageKey.TEST_MISSIONS_START_DATE) || '',
      },
    }),
    []
  );

  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);

  const { loading } = useAppSelector((state) => state.testMission);
  const createInput = useCreateInput(inputs, setInputs, { disabled: loading });
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  const searchHandler = useCallback(() => {
    dispatch(actions.clearTestMissions());

    const data = getInputData(inputs);
    if (data.orderBy) {
      data.value = data[data.orderBy];

      const status = data[ETestMissionSearchOption.status];
      localStorage.setItem(ESearchStorageKey.TEST_MISSIONS_STATUS, status);
      if (status) {
        data.status = status;
      }

      const region = data[ETestMissionSearchOption.region];
      localStorage.setItem(ESearchStorageKey.TEST_MISSIONS_REGION, region);
      if (region) {
        data.region = region;
      }

      const sportsCategory = data[ETestMissionSearchOption.sportsCategory];
      localStorage.setItem(ESearchStorageKey.TEST_MISSIONS_SPORTS_CATEGORY, sportsCategory);
      if (sportsCategory) {
        data.sportsCategory = sportsCategory;
      }

      const testPlannerId = data[ETestMissionSearchOption.testPlanner];
      localStorage.setItem(ESearchStorageKey.TEST_MISSIONS_TEST_PLANNER, testPlannerId);
      if (testPlannerId) {
        data.testPlannerId = testPlannerId;
      }

      const participantId = data[ETestMissionSearchOption.participants];
      localStorage.setItem(ESearchStorageKey.TEST_MISSIONS_PARTICIPANT, participantId);
      if (participantId) {
        data.participantId = participantId;
      }

      const startDate = data[ETestMissionSearchOption.startDate];
      localStorage.setItem(ESearchStorageKey.TEST_MISSIONS_START_DATE, startDate);
      if (startDate) {
        data.startDate = startDate;
      }

      const testMissionId = data[ETestMissionSearchOption.testMissionId];
      localStorage.setItem(ESearchStorageKey.TEST_MISSIONS_TEST_MISSION_ID, testMissionId);
    }

    onSearch(data);
  }, [dispatch, inputs, onSearch]);

  useEffect(() => {
    dispatch(actions.clearTestMissions());
    onSearch(getInputData(initialInputs));
  }, [onSearch, initialInputs, dispatch]);

  const orderBy = inputs[ETestMissionSearch.orderBy]
    .value as ETestMissionSearchOption;

  const {
    loading: usersLoading,
    users,
    searchedUsers,
    searchedUsersLoading,
  } = useAppSelector((state) => state.user);

  const [fieldPersonOptions, setFieldPersonOptions] = useState<
    IOption[] | undefined
  >();
  const [testPlannerOptions, setTestPlannerOptions] = useState<
    IOption[] | undefined
  >();

  useEffect(() => {
    if (users)
      setFieldPersonOptions(createOptions(users, (u) => u.name || u.id));
  }, [users]);

  useEffect(() => {
    if (searchedUsers)
      setTestPlannerOptions(
        createOptions(searchedUsers, (u) => u.name || u.id)
      );
  }, [searchedUsers]);

  const { modocLoading, modocSync } = useModocSync();

  return (
    <Container>
      {createInput(ETestMissionSearch.orderBy)}

      {orderBy === ETestMissionSearchOption.filter && (
        <>
          <InputGroup>
            {createInput(ETestMissionSearchOption.testPlanner, {
              options: testPlannerOptions,
              loading: searchedUsersLoading,
            })}
            {createInput(ETestMissionSearchOption.participants, {
              options: fieldPersonOptions,
              loading: usersLoading,
            })}
            {createInput(ETestMissionSearchOption.region)}
          </InputGroup>
          <InputGroup>
            {createInput(ETestMissionSearchOption.testMissionId)}
            {createInput(ETestMissionSearchOption.status)}
            {createInput(ETestMissionSearchOption.sportsCategory)}
            {createInput(ETestMissionSearchOption.startDate)}
          </InputGroup>
        </>
      )}

      <InputGroup>
        <Button
          onClick={searchHandler}
          size={EButtonSize.SMALL}
          disabled={loading}
        >
          {t(ETranslation.COMMON_SEARCH)}
        </Button>
        {authUser?.isTestPlanner && (
          <Button
            onClick={modocSync}
            size={EButtonSize.SMALL}
            disabled={modocLoading}
            loading={modocLoading}
          >
            {t(ETranslation.MODOC_SYNC_TITLE)}
          </Button>
        )}
      </InputGroup>
    </Container>
  );
};

export default TestMissionSearch;
