import React from "react";
import { IWorkLog } from "../../../../interfaces/domain/IWorkLog";
import { useTranslation } from "react-i18next";
import Table from "../../../UI/Table/Table";
import { ETranslation } from "../../../../translations/translation-keys";

interface IProps {
  workLog: IWorkLog | null;
  style?: React.CSSProperties;
}

const WorkLogSummary: React.FC<IProps> = ({ workLog, style }) => {
  const { t } = useTranslation();

  if (!workLog) return null;

  const showMileageAllowance = !!workLog.mileageAllowance;
  const showExpensesTotal = !!workLog.expensesTotal;
  const showOvertimeExtra = !!workLog.overtimeExtra;
  const showMidweekHolidayExtra = !!workLog.midweekHolidayExtra;

  return (
    <div style={style}>
      <p style={{ fontWeight: "bold" }}>{t(ETranslation.WORKLOG_SUMMARY)}</p>
      <Table>
        <thead>
          <tr>
            <th>{t(ETranslation.WORK_HOURS)}</th>
            {showMileageAllowance && <th>{t(ETranslation.MILEAGE_ALLOWANCE)}</th>}
            {showExpensesTotal && <th>{t(ETranslation.EXPENSES_TOTAL)}</th>}
            {showOvertimeExtra && <th>{t(ETranslation.OVERTIME_EXTRA)}</th>}
            {showMidweekHolidayExtra && <th>{t(ETranslation.MIDWEEK_HOLIDAY_EXTRA)}</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{workLog.workHours}</td>
            {showMileageAllowance && <td>{workLog.mileageAllowance} NOK</td>}
            {showExpensesTotal && <td>{workLog.expensesTotal} NOK</td>}
            {showOvertimeExtra && <td>{workLog.overtimeExtra} %</td>}
            {showMidweekHolidayExtra && <td>{workLog.midweekHolidayExtra} %</td>}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default WorkLogSummary;
