import { IOption } from "../components/UI/Input/Input";
import { ERoleName } from "../interfaces/domain/IRole";
import { EGenderRequirement, ESportsCategory, ETestMissionSearchOption, ETestMissionStatus } from "../interfaces/domain/ITestMission";
import { EGender, ELevel } from "../interfaces/domain/IUser";
import { ETranslation } from "../translations/translation-keys";

export const testMissionSearchOptions: IOption[] = [
  { value: ETestMissionSearchOption.filter, labelTranslation: ETranslation.SEARCH_FILTER },
  { value: ETestMissionSearchOption.unfulfilledRoles, labelTranslation: ETranslation.SEARCH_UNFULFILLED_ROLES },
  { value: ETestMissionSearchOption.overdue, labelTranslation: ETranslation.SEARCH_OVERDUE },
  /*
  { value: ETestMissionSearchOption.testMissionId, labelTranslation: ETranslation.SEARCH_TEST_MISSION_ID },
  { value: ETestMissionSearchOption.startDate, labelTranslation: ETranslation.SEARCH_START_DATE },
  { value: ETestMissionSearchOption.sportsCategory, labelTranslation: ETranslation.SEARCH_SPORT },
  { value: ETestMissionSearchOption.testPlanner, labelTranslation: ETranslation.SEARCH_TEST_PLANNER },
  { value: ETestMissionSearchOption.status, labelTranslation: ETranslation.SEARCH_STATUS },
  { value: ETestMissionSearchOption.participants, labelTranslation: ETranslation.SEARCH_ASSIGNED_FIELD_PERSON },
  { value: ETestMissionSearchOption.region, labelTranslation: ETranslation.SEARCH_REGION },
  */
];

export const testMissionStatusOptions: IOption[] = [
  // { value: "", labelTranslation: ETranslation.SELECT_STATUS_PLACEHOLDER },
  { value: ETestMissionStatus.ISSUED, labelTranslation: ETranslation.STATUS_ISSUED },
  { value: ETestMissionStatus.MODOC, labelTranslation: ETranslation.STATUS_MODOC },
  { value: ETestMissionStatus.COMPLETE, labelTranslation: ETranslation.STATUS_COMPLETE },
  { value: ETestMissionStatus.CANCELLED, labelTranslation: ETranslation.STATUS_CANCELLED },
  { value: ETestMissionStatus.PLANNING, labelTranslation: ETranslation.STATUS_PLANNING },
];

export const genderRequirementOptions: IOption[] = [
  { value: EGenderRequirement.FEMALE, labelTranslation: ETranslation.GENDER_REQUIREMENT_FEMALE },
  { value: EGenderRequirement.MALE, labelTranslation: ETranslation.GENDER_REQUIREMENT_MALE },
  { value: EGenderRequirement.BOTH, labelTranslation: ETranslation.GENDER_REQUIREMENT_BOTH },
  { value: EGenderRequirement.ANY, labelTranslation: ETranslation.GENDER_REQUIREMENT_ANY },
];

export const regionOptions: IOption[] = [
  // { value: "", labelTranslation: ETranslation.SELECT_REGION_PLACEHOLDER },
  { value: "Administration", label: "Administration - Oslo, SA" },
  { value: "0", label: "0 - Abroad, outside of Norway" },
  { value: "1", label: "1 - Nordland, Troms og Finmark, Nord-Norge" },
  { value: "2", label: "2 - Trøndelag, Midt-Norge" },
  { value: "3", label: "3 - Vestland, Vestlandet" },
  { value: "4", label: "4 - Rogaland, Sør-Vestlandet" },
  { value: "5", label: "5 - Vestfold, Buskerud og Telemark, Østlandet" },
  { value: "6", label: "6 - Oslo og Akershus vest, Østlandet" },
  { value: "7", label: "7 - Agder, Sør-Vestlandet" },
  { value: "8", label: "8 - Innlandet, Innlandet" },
];

export const genderOptions: IOption[] = [
  // { value: "", labelTranslation: ETranslation.SELECT_GENDER_PLACEHOLDER },
  { value: EGender.FEMALE, labelTranslation: ETranslation.FEMALE },
  { value: EGender.MALE, labelTranslation: ETranslation.MALE },
  { value: EGender.OTHER_UNKNOWN, labelTranslation: ETranslation.OTHER_UNKNOWN },
];

export const roleOptions: IOption[] = [
  // { value: "", labelTranslation: ETranslation.SELECT_ROLE_PLACEHOLDER },
  // { value: ERoleName.ADVISOR, labelTranslation: ETranslation.ADVISOR },
  // { value: ERoleName.ADVISOR_INTERNATIONAL, labelTranslation: ETranslation.ADVISOR_INTERNATIONAL },
  { value: ERoleName.ASSISTANT, labelTranslation: ETranslation.ASSISTANT },
  { value: ERoleName.BLOOD_COLLECTION_OFFICER, labelTranslation: ETranslation.BLOOD_COLLECTION_OFFICER },
  { value: ERoleName.DCO, labelTranslation: ETranslation.DCO },
  { value: ERoleName.LEAD_DCO, labelTranslation: ETranslation.LEAD_DCO },
];

export const levelOptions: IOption[] = [
  // { value: "", labelTranslation: ETranslation.SELECT_LEVEL_PLACEHOLDER },
  { value: ELevel.FIELD_PERSON, labelTranslation: ETranslation.LEVEL_FIELD_PERSON },
  { value: ELevel.TEST_PLANNER, labelTranslation: ETranslation.LEVEL_TEST_PLANNER },
  // { value: ELevel.REPORTING, labelTranslation: ETranslation.LEVEL_REPORTING },
];

export const sportsOptions: IOption[] = [
  // { value: "", labelTranslation: ETranslation.SELECT_SPORT_PLACEHOLDER },
  { value: ESportsCategory.AESTHETIC_GROUP_GYMNASTICS, labelTranslation: ETranslation.AESTHETIC_GROUP_GYMNASTICS },
  { value: ESportsCategory.AIKIDO, labelTranslation: ETranslation.AIKIDO },
  { value: ESportsCategory.AIR_SPORTS, labelTranslation: ETranslation.AIR_SPORTS },
  { value: ESportsCategory.AMERICAN_FOOTBALL, labelTranslation: ETranslation.AMERICAN_FOOTBALL },
  { value: ESportsCategory.AQUATICS, labelTranslation: ETranslation.AQUATICS },
  { value: ESportsCategory.ARCHERY, labelTranslation: ETranslation.ARCHERY },
  { value: ESportsCategory.ARM_WRESTLING, labelTranslation: ETranslation.ARM_WRESTLING },
  { value: ESportsCategory.ATHLETICS, labelTranslation: ETranslation.ATHLETICS },
  { value: ESportsCategory.AUSTRALIAN_RULES_FOOTBALL, labelTranslation: ETranslation.AUSTRALIAN_RULES_FOOTBALL },
  { value: ESportsCategory.AUTOMOBILE_SPORTS, labelTranslation: ETranslation.AUTOMOBILE_SPORTS },
  { value: ESportsCategory.BADMINTON, labelTranslation: ETranslation.BADMINTON },
  { value: ESportsCategory.BALLE_AU_TAMBOURIN, labelTranslation: ETranslation.BALLE_AU_TAMBOURIN },
  { value: ESportsCategory.BALLON_AU_POING, labelTranslation: ETranslation.BALLON_AU_POING },
  { value: ESportsCategory.BANDY, labelTranslation: ETranslation.BANDY },
  { value: ESportsCategory.BASEBALL, labelTranslation: ETranslation.BASEBALL },
  { value: ESportsCategory.BASKETBALL, labelTranslation: ETranslation.BASKETBALL },
  { value: ESportsCategory.BASQUE_PELOTA, labelTranslation: ETranslation.BASQUE_PELOTA },
  { value: ESportsCategory.BASQUE_SPORTS, labelTranslation: ETranslation.BASQUE_SPORTS },
  { value: ESportsCategory.BEACH_TENNIS, labelTranslation: ETranslation.BEACH_TENNIS },
  { value: ESportsCategory.BIATHLON, labelTranslation: ETranslation.BIATHLON },
  { value: ESportsCategory.BILLIARDS_SPORTS, labelTranslation: ETranslation.BILLIARDS_SPORTS },
  { value: ESportsCategory.BOBSLEIGH, labelTranslation: ETranslation.BOBSLEIGH },
  { value: ESportsCategory.BOCCIA, labelTranslation: ETranslation.BOCCIA },
  { value: ESportsCategory.BODYBUILDING, labelTranslation: ETranslation.BODYBUILDING },
  { value: ESportsCategory.BOOT_THROWING, labelTranslation: ETranslation.BOOT_THROWING },
  { value: ESportsCategory.BOULES_SPORTS, labelTranslation: ETranslation.BOULES_SPORTS },
  { value: ESportsCategory.BOWLING, labelTranslation: ETranslation.BOWLING },
  { value: ESportsCategory.BOWLS, labelTranslation: ETranslation.BOWLS },
  { value: ESportsCategory.BOXING, labelTranslation: ETranslation.BOXING },
  { value: ESportsCategory.BRAZILIAN_JIU_JITSU, labelTranslation: ETranslation.BRAZILIAN_JIU_JITSU },
  { value: ESportsCategory.BRIDGE, labelTranslation: ETranslation.BRIDGE },
  { value: ESportsCategory.BROOMBALL, labelTranslation: ETranslation.BROOMBALL },
  { value: ESportsCategory.BUDO, labelTranslation: ETranslation.BUDO },
  { value: ESportsCategory.CISS_GOLF, labelTranslation: ETranslation.CISS_GOLF },
  { value: ESportsCategory.CISS_SAILING, labelTranslation: ETranslation.CISS_SAILING },
  { value: ESportsCategory.COLEO, labelTranslation: ETranslation.COLEO },
  { value: ESportsCategory.CP_FOOTBALL, labelTranslation: ETranslation.CP_FOOTBALL },
  { value: ESportsCategory.CAMEL_RACING, labelTranslation: ETranslation.CAMEL_RACING },
  { value: ESportsCategory.CANICROSS, labelTranslation: ETranslation.CANICROSS },
  { value: ESportsCategory.CANOE, labelTranslation: ETranslation.CANOE },
  { value: ESportsCategory.CASTING, labelTranslation: ETranslation.CASTING },
  { value: ESportsCategory.CHANBARA, labelTranslation: ETranslation.CHANBARA },
  { value: ESportsCategory.CHEER, labelTranslation: ETranslation.CHEER },
  { value: ESportsCategory.CHEERLEADING, labelTranslation: ETranslation.CHEERLEADING },
  { value: ESportsCategory.CHESS, labelTranslation: ETranslation.CHESS },
  { value: ESportsCategory.CHIN_UP, labelTranslation: ETranslation.CHIN_UP },
  { value: ESportsCategory.CLAY_TARGET_SHOOTING, labelTranslation: ETranslation.CLAY_TARGET_SHOOTING },
  { value: ESportsCategory.COLLEGE_SPORTS, labelTranslation: ETranslation.COLLEGE_SPORTS },
  { value: ESportsCategory.COMBAT_SPORTS, labelTranslation: ETranslation.COMBAT_SPORTS },
  { value: ESportsCategory.COMPANY_SPORTS, labelTranslation: ETranslation.COMPANY_SPORTS },
  { value: ESportsCategory.COURSE_CAMARGUAISE, labelTranslation: ETranslation.COURSE_CAMARGUAISE },
  { value: ESportsCategory.COURSE_LANDAISE, labelTranslation: ETranslation.COURSE_LANDAISE },
  { value: ESportsCategory.CRICKET, labelTranslation: ETranslation.CRICKET },
  { value: ESportsCategory.CROSSFIT, labelTranslation: ETranslation.CROSSFIT },
  { value: ESportsCategory.CROSSBOW, labelTranslation: ETranslation.CROSSBOW },
  { value: ESportsCategory.CROSSWORD, labelTranslation: ETranslation.CROSSWORD },
  { value: ESportsCategory.CURLING, labelTranslation: ETranslation.CURLING },
  { value: ESportsCategory.CYCLING, labelTranslation: ETranslation.CYCLING },
  { value: ESportsCategory.DANCESPORT, labelTranslation: ETranslation.DANCESPORT },
  { value: ESportsCategory.DARTS, labelTranslation: ETranslation.DARTS },
  { value: ESportsCategory.DET_FRIVILLIGE_SKYTTERVESEN, labelTranslation: ETranslation.DET_FRIVILLIGE_SKYTTERVESEN },
  { value: ESportsCategory.DODGEBALL, labelTranslation: ETranslation.DODGEBALL },
  { value: ESportsCategory.DOMIMO, labelTranslation: ETranslation.DOMIMO },
  { value: ESportsCategory.DRAGON_BOAT, labelTranslation: ETranslation.DRAGON_BOAT },
  { value: ESportsCategory.DRAGON_AND_LION_DANCE, labelTranslation: ETranslation.DRAGON_AND_LION_DANCE },
  { value: ESportsCategory.DRAUGHTS, labelTranslation: ETranslation.DRAUGHTS },
  { value: ESportsCategory.ELECTRONIC_SPORTS, labelTranslation: ETranslation.ELECTRONIC_SPORTS },
  { value: ESportsCategory.EQUESTRIAN, labelTranslation: ETranslation.EQUESTRIAN },
  { value: ESportsCategory.EXTREME_SPORTS, labelTranslation: ETranslation.EXTREME_SPORTS },
  { value: ESportsCategory.FIFA_ESPORTS, labelTranslation: ETranslation.FIFA_ESPORTS },
  { value: ESportsCategory.FENCING, labelTranslation: ETranslation.FENCING },
  { value: ESportsCategory.FIELD_HOCKEY, labelTranslation: ETranslation.FIELD_HOCKEY },
  { value: ESportsCategory.FIREFIGHTING_AND_RESCUING, labelTranslation: ETranslation.FIREFIGHTING_AND_RESCUING },
  { value: ESportsCategory.FISTBALL, labelTranslation: ETranslation.FISTBALL },
  { value: ESportsCategory.FITNESS, labelTranslation: ETranslation.FITNESS },
  { value: ESportsCategory.FLOORBALL, labelTranslation: ETranslation.FLOORBALL },
  { value: ESportsCategory.FLYING_DISC, labelTranslation: ETranslation.FLYING_DISC },
  { value: ESportsCategory.FOOT_VOLLEY, labelTranslation: ETranslation.FOOT_VOLLEY },
  { value: ESportsCategory.FOOTBALL, labelTranslation: ETranslation.FOOTBALL },
  { value: ESportsCategory.FOOTBALL_7_A_SIDE, labelTranslation: ETranslation.FOOTBALL_7_A_SIDE },
  { value: ESportsCategory.FOOTBALL_IBSA_FOOTBALL, labelTranslation: ETranslation.FOOTBALL_IBSA_FOOTBALL },
  { value: ESportsCategory.FRENCH_BOXING, labelTranslation: ETranslation.FRENCH_BOXING },
  { value: ESportsCategory.FRISKIS_SVETTIS, labelTranslation: ETranslation.FRISKIS_SVETTIS },
  { value: ESportsCategory.FULL_CONTACT, labelTranslation: ETranslation.FULL_CONTACT },
  { value: ESportsCategory.FUNCTIONAL_FITNESS, labelTranslation: ETranslation.FUNCTIONAL_FITNESS },
  { value: ESportsCategory.GAELIC_GAMES, labelTranslation: ETranslation.GAELIC_GAMES },
  { value: ESportsCategory.GIGATHLON, labelTranslation: ETranslation.GIGATHLON },
  { value: ESportsCategory.GIRA, labelTranslation: ETranslation.GIRA },
  { value: ESportsCategory.GLORY_KICKBOXING, labelTranslation: ETranslation.GLORY_KICKBOXING },
  { value: ESportsCategory.GO, labelTranslation: ETranslation.GO },
  { value: ESportsCategory.GOALBALL, labelTranslation: ETranslation.GOALBALL },
  { value: ESportsCategory.GOLF, labelTranslation: ETranslation.GOLF },
  { value: ESportsCategory.GYMNASTICS, labelTranslation: ETranslation.GYMNASTICS },
  { value: ESportsCategory.HANDBALL, labelTranslation: ETranslation.HANDBALL },
  { value: ESportsCategory.HEAVY_ATHLETICS, labelTranslation: ETranslation.HEAVY_ATHLETICS },
  { value: ESportsCategory.HEAVY_EVENTS, labelTranslation: ETranslation.HEAVY_EVENTS },
  { value: ESportsCategory.HIGHLAND_GAMES, labelTranslation: ETranslation.HIGHLAND_GAMES },
  { value: ESportsCategory.HORNUSSEN, labelTranslation: ETranslation.HORNUSSEN },
  { value: ESportsCategory.HORSE_RACING, labelTranslation: ETranslation.HORSE_RACING },
  { value: ESportsCategory.HUNTING_SPORTS, labelTranslation: ETranslation.HUNTING_SPORTS },
  { value: ESportsCategory.ICE_HOCKEY, labelTranslation: ETranslation.ICE_HOCKEY },
  { value: ESportsCategory.ICESTOCKSPORT, labelTranslation: ETranslation.ICESTOCKSPORT },
  { value: ESportsCategory.JET_SKI, labelTranslation: ETranslation.JET_SKI },
  { value: ESportsCategory.JIU_JITSU, labelTranslation: ETranslation.JIU_JITSU },
  { value: ESportsCategory.JU_JITSU, labelTranslation: ETranslation.JU_JITSU },
  { value: ESportsCategory.JUDO, labelTranslation: ETranslation.JUDO },
  { value: ESportsCategory.JUMP_ROPE, labelTranslation: ETranslation.JUMP_ROPE },
  { value: ESportsCategory.KAATSEN, labelTranslation: ETranslation.KAATSEN },
  { value: ESportsCategory.KABADDI, labelTranslation: ETranslation.KABADDI },
  { value: ESportsCategory.KARATE, labelTranslation: ETranslation.KARATE },
  { value: ESportsCategory.KEMPO, labelTranslation: ETranslation.KEMPO },
  { value: ESportsCategory.KENDO, labelTranslation: ETranslation.KENDO },
  { value: ESportsCategory.KETTLEBELL_LIFTING, labelTranslation: ETranslation.KETTLEBELL_LIFTING },
  { value: ESportsCategory.KHO_KHO, labelTranslation: ETranslation.KHO_KHO },
  { value: ESportsCategory.KICKBOXING, labelTranslation: ETranslation.KICKBOXING },
  { value: ESportsCategory.KIN_BALL, labelTranslation: ETranslation.KIN_BALL },
  { value: ESportsCategory.KORFBALL, labelTranslation: ETranslation.KORFBALL },
  { value: ESportsCategory.KRACHTBAL, labelTranslation: ETranslation.KRACHTBAL },
  { value: ESportsCategory.KUDO, labelTranslation: ETranslation.KUDO },
  { value: ESportsCategory.KUNG_FU, labelTranslation: ETranslation.KUNG_FU },
  { value: ESportsCategory.KURASH, labelTranslation: ETranslation.KURASH },
  { value: ESportsCategory.KYUDO, labelTranslation: ETranslation.KYUDO },
  { value: ESportsCategory.LACROSSE, labelTranslation: ETranslation.LACROSSE },
  { value: ESportsCategory.LEONES_BOWLS, labelTranslation: ETranslation.LEONES_BOWLS },
  { value: ESportsCategory.LIFESAVING, labelTranslation: ETranslation.LIFESAVING },
  { value: ESportsCategory.LONGUE_PAUME, labelTranslation: ETranslation.LONGUE_PAUME },
  { value: ESportsCategory.LUGE, labelTranslation: ETranslation.LUGE },
  { value: ESportsCategory.MODOC_TRAINING_SPORT, labelTranslation: ETranslation.MODOC_TRAINING_SPORT },
  { value: ESportsCategory.MILITARY_PATROL, labelTranslation: ETranslation.MILITARY_PATROL },
  { value: ESportsCategory.MILITARY_SPORT_PENTATHLON, labelTranslation: ETranslation.MILITARY_SPORT_PENTATHLON },
  { value: ESportsCategory.MINIGOLF, labelTranslation: ETranslation.MINIGOLF },
  { value: ESportsCategory.MIXED_MARTIAL_ARTS, labelTranslation: ETranslation.MIXED_MARTIAL_ARTS },
  { value: ESportsCategory.MODERN_PENTATHLON, labelTranslation: ETranslation.MODERN_PENTATHLON },
  { value: ESportsCategory.MOTOBALL, labelTranslation: ETranslation.MOTOBALL },
  { value: ESportsCategory.MOTORCYCLE_RACING, labelTranslation: ETranslation.MOTORCYCLE_RACING },
  { value: ESportsCategory.MOUNTAINEERING_AND_CLIMBING, labelTranslation: ETranslation.MOUNTAINEERING_AND_CLIMBING },
  { value: ESportsCategory.MUAYTHAI, labelTranslation: ETranslation.MUAYTHAI },
  { value: ESportsCategory.MULTIFUNCTIONAL_TRAINING, labelTranslation: ETranslation.MULTIFUNCTIONAL_TRAINING },
  { value: ESportsCategory.NAGINATA, labelTranslation: ETranslation.NAGINATA },
  { value: ESportsCategory.NETBALL, labelTranslation: ETranslation.NETBALL },
  { value: ESportsCategory.NORGES_BILSPORTFORBUND, labelTranslation: ETranslation.NORGES_BILSPORTFORBUND },
  { value: ESportsCategory.NORGES_RYTTERFORBUN, labelTranslation: ETranslation.NORGES_RYTTERFORBUN },
  { value: ESportsCategory.NORSK_GALOPP, labelTranslation: ETranslation.NORSK_GALOPP },
  { value: ESportsCategory.NORSKE_TRAVSELSKAP, labelTranslation: ETranslation.NORSKE_TRAVSELSKAP },
  { value: ESportsCategory.OBSTACLE_SPORTS, labelTranslation: ETranslation.OBSTACLE_SPORTS },
  { value: ESportsCategory.ORIENTEERING, labelTranslation: ETranslation.ORIENTEERING },
  { value: ESportsCategory.PADEL, labelTranslation: ETranslation.PADEL },
  { value: ESportsCategory.PARA_ALPINE_SKIING, labelTranslation: ETranslation.PARA_ALPINE_SKIING },
  { value: ESportsCategory.PARA_ATHLETICS, labelTranslation: ETranslation.PARA_ATHLETICS },
  { value: ESportsCategory.PARA_BIATHLON, labelTranslation: ETranslation.PARA_BIATHLON },
  { value: ESportsCategory.PARA_BOWLING, labelTranslation: ETranslation.PARA_BOWLING },
  { value: ESportsCategory.PARA_BOWLS, labelTranslation: ETranslation.PARA_BOWLS },
  { value: ESportsCategory.PARA_CROSS_COUNTRY_SKIING, labelTranslation: ETranslation.PARA_CROSS_COUNTRY_SKIING },
  { value: ESportsCategory.PARA_DANCESPORT, labelTranslation: ETranslation.PARA_DANCESPORT },
  { value: ESportsCategory.PARA_GOLF, labelTranslation: ETranslation.PARA_GOLF },
  { value: ESportsCategory.PARA_ICE_HOCKEY, labelTranslation: ETranslation.PARA_ICE_HOCKEY },
  { value: ESportsCategory.PARA_NORDIC_SKIING, labelTranslation: ETranslation.PARA_NORDIC_SKIING },
  { value: ESportsCategory.PARA_POWERLIFTING, labelTranslation: ETranslation.PARA_POWERLIFTING },
  { value: ESportsCategory.PARA_SNOWBOARD, labelTranslation: ETranslation.PARA_SNOWBOARD },
  { value: ESportsCategory.PARA_SWIMMING, labelTranslation: ETranslation.PARA_SWIMMING },
  { value: ESportsCategory.PARAVOLLEY, labelTranslation: ETranslation.PARAVOLLEY },
  { value: ESportsCategory.PENCAK_SILAT, labelTranslation: ETranslation.PENCAK_SILAT },
  { value: ESportsCategory.POKER, labelTranslation: ETranslation.POKER },
  { value: ESportsCategory.POLE_SPORTS, labelTranslation: ETranslation.POLE_SPORTS },
  { value: ESportsCategory.POLO, labelTranslation: ETranslation.POLO },
  { value: ESportsCategory.POWER_PRESS, labelTranslation: ETranslation.POWER_PRESS },
  { value: ESportsCategory.POWERBOATING, labelTranslation: ETranslation.POWERBOATING },
  { value: ESportsCategory.POWERLIFTING, labelTranslation: ETranslation.POWERLIFTING },
  { value: ESportsCategory.PRACTICAL_SHOOTING, labelTranslation: ETranslation.PRACTICAL_SHOOTING },
  { value: ESportsCategory.QAZAQ_KURESI, labelTranslation: ETranslation.QAZAQ_KURESI },
  { value: ESportsCategory.RACQUETBALL, labelTranslation: ETranslation.RACQUETBALL },
  { value: ESportsCategory.RAFTING, labelTranslation: ETranslation.RAFTING },
  { value: ESportsCategory.RINGETTE, labelTranslation: ETranslation.RINGETTE },
  { value: ESportsCategory.RINKBALL, labelTranslation: ETranslation.RINKBALL },
  { value: ESportsCategory.ROLLER_SPORTS, labelTranslation: ETranslation.ROLLER_SPORTS },
  { value: ESportsCategory.ROWING, labelTranslation: ETranslation.ROWING },
  { value: ESportsCategory.RUGBY_LEAGUE, labelTranslation: ETranslation.RUGBY_LEAGUE },
  { value: ESportsCategory.RUGBY_UNION, labelTranslation: ETranslation.RUGBY_UNION },
  { value: ESportsCategory.SAILING, labelTranslation: ETranslation.SAILING },
  { value: ESportsCategory.SAMBO, labelTranslation: ETranslation.SAMBO },
  { value: ESportsCategory.SAVATE, labelTranslation: ETranslation.SAVATE },
  { value: ESportsCategory.SCHOOL_SPORTS, labelTranslation: ETranslation.SCHOOL_SPORTS },
  { value: ESportsCategory.SEPAKTAKRAW, labelTranslation: ETranslation.SEPAKTAKRAW },
  { value: ESportsCategory.SHEARING_SPORTS, labelTranslation: ETranslation.SHEARING_SPORTS },
  { value: ESportsCategory.SHOE_RACING, labelTranslation: ETranslation.SHOE_RACING },
  { value: ESportsCategory.SHOOTING, labelTranslation: ETranslation.SHOOTING },
  { value: ESportsCategory.SHOOTING_PARA_SPORT, labelTranslation: ETranslation.SHOOTING_PARA_SPORT },
  { value: ESportsCategory.SHOWDOWN, labelTranslation: ETranslation.SHOWDOWN },
  { value: ESportsCategory.SHUTTLECOCK, labelTranslation: ETranslation.SHUTTLECOCK },
  { value: ESportsCategory.SITBALL, labelTranslation: ETranslation.SITBALL },
  { value: ESportsCategory.SITTING_VOLLEYBALL, labelTranslation: ETranslation.SITTING_VOLLEYBALL },
  { value: ESportsCategory.SKATING, labelTranslation: ETranslation.SKATING },
  { value: ESportsCategory.SKI_MOUNTAINEERING, labelTranslation: ETranslation.SKI_MOUNTAINEERING },
  { value: ESportsCategory.SKIBOB, labelTranslation: ETranslation.SKIBOB },
  { value: ESportsCategory.SKIING, labelTranslation: ETranslation.SKIING },
  { value: ESportsCategory.SKYBALL, labelTranslation: ETranslation.SKYBALL },
  { value: ESportsCategory.SKYRUNNING, labelTranslation: ETranslation.SKYRUNNING },
  { value: ESportsCategory.SLEDDOG, labelTranslation: ETranslation.SLEDDOG },
  { value: ESportsCategory.SNOWMOBILE, labelTranslation: ETranslation.SNOWMOBILE },
  { value: ESportsCategory.SOFT_TENNIS, labelTranslation: ETranslation.SOFT_TENNIS },
  { value: ESportsCategory.SOFTBALL, labelTranslation: ETranslation.SOFTBALL },
  { value: ESportsCategory.SPEEDBALL, labelTranslation: ETranslation.SPEEDBALL },
  { value: ESportsCategory.SPELEOLOGY, labelTranslation: ETranslation.SPELEOLOGY },
  { value: ESportsCategory.SPORT_CLIMBING, labelTranslation: ETranslation.SPORT_CLIMBING },
  { value: ESportsCategory.SPORT_FISHING, labelTranslation: ETranslation.SPORT_FISHING },
  { value: ESportsCategory.SQUASH, labelTranslation: ETranslation.SQUASH },
  { value: ESportsCategory.STILTS_RACING, labelTranslation: ETranslation.STILTS_RACING },
  { value: ESportsCategory.STREET_AND_BALL_HOCKEY, labelTranslation: ETranslation.STREET_AND_BALL_HOCKEY },
  { value: ESportsCategory.STRONGEST_MAN, labelTranslation: ETranslation.STRONGEST_MAN },
  { value: ESportsCategory.SUMO, labelTranslation: ETranslation.SUMO },
  { value: ESportsCategory.SURFING, labelTranslation: ETranslation.SURFING },
  { value: ESportsCategory.SWISS_NATIONAL_GYMNASTICS, labelTranslation: ETranslation.SWISS_NATIONAL_GYMNASTICS },
  { value: ESportsCategory.TABLE_SOCCER, labelTranslation: ETranslation.TABLE_SOCCER },
  { value: ESportsCategory.TABLE_TENNIS, labelTranslation: ETranslation.TABLE_TENNIS },
  { value: ESportsCategory.TAEKWONDO, labelTranslation: ETranslation.TAEKWONDO },
  { value: ESportsCategory.TAEKWONDO_ITF_AUSTRIA, labelTranslation: ETranslation.TAEKWONDO_ITF_AUSTRIA },
  { value: ESportsCategory.TAIDO, labelTranslation: ETranslation.TAIDO },
  { value: ESportsCategory.TARUNG_DERAJAT, labelTranslation: ETranslation.TARUNG_DERAJAT },
  { value: ESportsCategory.TCHOUKBALL, labelTranslation: ETranslation.TCHOUKBALL },
  { value: ESportsCategory.TENNIS, labelTranslation: ETranslation.TENNIS },
  { value: ESportsCategory.TEQBALL, labelTranslation: ETranslation.TEQBALL },
  { value: ESportsCategory.TEST_SPORT, labelTranslation: ETranslation.TEST_SPORT },
  { value: ESportsCategory.THAI_TRADITIONAL_SPORTS, labelTranslation: ETranslation.THAI_TRADITIONAL_SPORTS },
  { value: ESportsCategory.THE_NORWEGIAN_POLICE_ACADEMY, labelTranslation: ETranslation.THE_NORWEGIAN_POLICE_ACADEMY },
  { value: ESportsCategory.TOUCH_FOOTBALL, labelTranslation: ETranslation.TOUCH_FOOTBALL },
  { value: ESportsCategory.TRADITIONAL_ARCHERY, labelTranslation: ETranslation.TRADITIONAL_ARCHERY },
  { value: ESportsCategory.TRADITIONAL_KARATE, labelTranslation: ETranslation.TRADITIONAL_KARATE },
  { value: ESportsCategory.TRADITIONAL_MARTIAL_ARTS, labelTranslation: ETranslation.TRADITIONAL_MARTIAL_ARTS },
  { value: ESportsCategory.TRAPEZE, labelTranslation: ETranslation.TRAPEZE },
  { value: ESportsCategory.TRIATHLON, labelTranslation: ETranslation.TRIATHLON },
  { value: ESportsCategory.TUG_OF_WAR, labelTranslation: ETranslation.TUG_OF_WAR },
  { value: ESportsCategory.TWIRLING, labelTranslation: ETranslation.TWIRLING },
  { value: ESportsCategory.ULTIMATE_FIGHTING, labelTranslation: ETranslation.ULTIMATE_FIGHTING },
  { value: ESportsCategory.ULTRA_TRIATHLON, labelTranslation: ETranslation.ULTRA_TRIATHLON },
  { value: ESportsCategory.UNDERWATER_SPORTS, labelTranslation: ETranslation.UNDERWATER_SPORTS },
  { value: ESportsCategory.UNICYCLING, labelTranslation: ETranslation.UNICYCLING },
  { value: ESportsCategory.UNIFIGHT, labelTranslation: ETranslation.UNIFIGHT },
  { value: ESportsCategory.VA_A, labelTranslation: ETranslation.VA_A },
  { value: ESportsCategory.VOLLEYBALL, labelTranslation: ETranslation.VOLLEYBALL },
  { value: ESportsCategory.VOVINAM, labelTranslation: ETranslation.VOVINAM },
  { value: ESportsCategory.WSLEAGUE_SURFING, labelTranslation: ETranslation.WSLEAGUE_SURFING },
  { value: ESportsCategory.WATERSKIING, labelTranslation: ETranslation.WATERSKIING },
  { value: ESportsCategory.WEIGHTLIFTING, labelTranslation: ETranslation.WEIGHTLIFTING },
  { value: ESportsCategory.WHEELCHAIR_SPORTS, labelTranslation: ETranslation.WHEELCHAIR_SPORTS },
  { value: ESportsCategory.WOODBALL, labelTranslation: ETranslation.WOODBALL },
  { value: ESportsCategory.WOODSMAN, labelTranslation: ETranslation.WOODSMAN },
  { value: ESportsCategory.WRESTLING, labelTranslation: ETranslation.WRESTLING },
  { value: ESportsCategory.WUSHU, labelTranslation: ETranslation.WUSHU },
  { value: ESportsCategory.XIANGQI, labelTranslation: ETranslation.XIANGQI },
  { value: ESportsCategory.CROSS_TRAINING_MILITARY_CROSS_TRAINING, labelTranslation: ETranslation.CROSS_TRAINING_MILITARY_CROSS_TRAINING },
  { value: ESportsCategory.PARA_BADMINTON, labelTranslation: ETranslation.PARA_BADMINTON },
  { value: ESportsCategory.PARA_BASKETBALL, labelTranslation: ETranslation.PARA_BASKETBALL },
  { value: ESportsCategory.BILSPORT, labelTranslation: ETranslation.BILSPORT },
  { value: ESportsCategory.CANOE_KAYAK, labelTranslation: ETranslation.CANOE_KAYAK },
  { value: ESportsCategory.PARA_CURLING, labelTranslation: ETranslation.PARA_CURLING },
  { value: ESportsCategory.DFS, labelTranslation: ETranslation.DFS },
  { value: ESportsCategory.FLERIDRETT, labelTranslation: ETranslation.FLERIDRETT },
  { value: ESportsCategory.FLY_CASTING, labelTranslation: ETranslation.FLY_CASTING },
  { value: ESportsCategory.GALOPP, labelTranslation: ETranslation.GALOPP },
  { value: ESportsCategory.PARA_GYMNASTICS, labelTranslation: ETranslation.PARA_GYMNASTICS },
  { value: ESportsCategory.PARA_JUDO, labelTranslation: ETranslation.PARA_JUDO },
  { value: ESportsCategory.PHS, labelTranslation: ETranslation.PHS },
  { value: ESportsCategory.POLESPORT, labelTranslation: ETranslation.POLESPORT },
  { value: ESportsCategory.PARA_SAILING, labelTranslation: ETranslation.PARA_SAILING },
  { value: ESportsCategory.PARA_TABLE_TENNIS, labelTranslation: ETranslation.PARA_TABLE_TENNIS },
  { value: ESportsCategory.TAEKWONDO_NTN, labelTranslation: ETranslation.TAEKWONDO_NTN },
  { value: ESportsCategory.PARA_TENNIS, labelTranslation: ETranslation.PARA_TENNIS },
  { value: ESportsCategory.TRAV, labelTranslation: ETranslation.TRAV },
  { value: ESportsCategory.TRENINGSSENTER, labelTranslation: ETranslation.TRENINGSSENTER },
  { value: ESportsCategory.STUDENT_SPORTS, labelTranslation: ETranslation.STUDENT_SPORTS },
];

const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  let year = 2022;
  const options: IOption[] = [];
  while (year <= currentYear) {
    const yearString = year.toString();
    options.push({ value: yearString, label: yearString });
    year++;
  }
  return options;
}

export const yearOptions: IOption[] = [...getYearOptions()];
