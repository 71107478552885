import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ETestMission } from "../../../../containers/TestMission/TestMission/TestMission";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { ITestMission } from "../../../../interfaces/domain/ITestMission";
import { ETranslation } from "../../../../translations/translation-keys";
import Button from "../../../UI/Button/Button";
import { EInputType, IInputField } from "../../../UI/Input/Input";
import { getInputData, validateInputs } from "../../../UI/Input/input-utils";
import InputGroup from "../../../UI/InputGroup/InputGroup";

interface IProps {
  onConfirm: (testMission: ITestMission) => void;
  onCancel: () => void;
}

const TestMissionSaveDialog: React.FC<IProps> = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);

  const [inputs, setInputs] = useState<IInputField>({
    [ETestMission.testMissionId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.TEST_MISSION_ID,
      placeholderTranslation: ETranslation.TEST_MISSION_ID,
      value: "",
      validation: {
        required: true,
      },
    },
  });

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation: !isValid,
  });

  const saveHandler = () => {
    const data = getInputData<ITestMission>(inputs);
    onConfirm(data);
  }

  return (
    <>
      {createInput(ETestMission.testMissionId)}
      <InputGroup>
        <Button onClick={saveHandler} disabled={!isValid}>
          {t(ETranslation.COMMON_YES)}
        </Button>
        <Button onClick={onCancel}>{t(ETranslation.COMMON_NO)}</Button>
      </InputGroup>
    </>
  );
};

export default TestMissionSaveDialog;
