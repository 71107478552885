export enum ECounts {
    created = "created",
    canceled = "canceled",
    offered = "offered",
    refusedOrcanceled = "refusedOrcanceled",
    accepted = "accepted",
    completed = "completed",
    total = "total",
    refused = "refused",
}

export interface ICounts {
    created: number;
    canceled: number;
    offered: number;
    refusedOrcanceled: number;
    accepted: number;
    completed: number;
    total: number;
    user: string;
    refused: number;
}