import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faClipboard,
  faClipboardCheck,
  faClock,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { EParticipantStatus } from "../interfaces/domain/IParticipant";
import { ETestMissionStatus } from "../interfaces/domain/ITestMission";
import { ETranslation } from "../translations/translation-keys";

interface IProps {
  status: EParticipantStatus;
  testMissionStatus?: ETestMissionStatus;
  size?: SizeProp;
}

export const TestMissionParticipantBadge: React.FC<IProps> = ({
  status,
  testMissionStatus,
  size,
}) => {
  const { t } = useTranslation();
  switch (status) {
    case EParticipantStatus.ACCEPTED:
      if (testMissionStatus === ETestMissionStatus.COMPLETE) return <FontAwesomeIcon icon={faClipboardCheck} color="#8fbb30" size={size} title={t(ETranslation.PARTICIPANT_STATUS_COMPLETED)} />;
      return <FontAwesomeIcon icon={faClipboard} color="#136AAC" size={size} title={t(ETranslation.PARTICIPANT_STATUS_ACCEPTED)} />;
    case EParticipantStatus.PENDING:
      return <FontAwesomeIcon icon={faClock} color="#FFD700" size={size} title={t(ETranslation.PARTICIPANT_STATUS_PENDING)} />;
    case EParticipantStatus.REFUSED:
      return <FontAwesomeIcon icon={faTimesCircle} color="#bb4c30" size={size} title={t(ETranslation.PARTICIPANT_STATUS_REFUSED)} />;
  }
};

export const getRegion = (t: TFunction, region: string) => {
  switch (region) {
    case "Administration":
      return "Administration - Oslo, SA";
    case "1":
      return "1 - Nordland, Troms og Finmark, Nord-Norge";
    case "2":
      return "2 - Trøndelag, Midt-Norge";
    case "3":
      return "3 - Vestland, Vestlandet";
    case "4":
      return "4 - Rogaland, Sør-Vestlandet";
    case "5":
      return "5 - Vestfold, Buskerud og Telemark, Østlandet";
    case "6":
      return "6 - Oslo og Akershus vest, Østlandet";
    case "7":
      return "7 - Agder, Sør-Vestlandet";
    case "8":
      return "8 - Innlandet, Innlandet";
  }
};

export enum ETestMissionView {
  TEAM = "TEAM",
  COMPENSATION = "COMPENSATION",
};
