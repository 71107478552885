import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button, { EButtonColor } from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import { EInputType, IInputField } from "../../../components/UI/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs,
} from "../../../components/UI/Input/input-utils";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {
  genderOptions,
  levelOptions,
  regionOptions,
} from "../../../data/select-data";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useBlurBeforeUnload } from "../../../hooks/useBlurBeforeUnload";
import { useCreateInput } from "../../../hooks/useCreateInput";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import { Routes } from "../../../classes/Routes";
import { IUser } from "../../../interfaces/domain/IUser";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Alert, { EAlertColor } from "../../../components/UI/Alert/Alert";

export enum EFieldPerson {
  firstName = "firstName",
  lastName = "lastName",
  address = "address",
  postcode = "postcode",
  place = "place",
  mobile = "mobile",
  email = "email",
  region = "region",
  DCO = "DCO",
  DCO_training = "DCO_training",
  assistant = "assistant",
  assistant_training = "assistant_training",
  blood_collection_officer = "blood_collection_officer",
  blood_collection_officer_training = "blood_collection_officer_training",
  lead_DCO = "lead_DCO",
  lead_DCO_training = "lead_DCO_training",
  workTime = "workTime",
  allowLogin = "allowLogin",
  gender = "gender",
  level = "level",
  password = "password",
  notificationEmail = "notificationEmail",
  notificationSMS = "notificationSMS",
  modocUserId = "modocUserId",
  vismaPayrollPersonnelId = "vismaPayrollPersonnelId",
}

const FieldPerson: React.FC = () => {
  useBlurBeforeUnload();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { loading, user, saveOrUpdateLoading, saveOrUpdateOk, error } = useAppSelector(
    (state) => state.user
  );

  const [isValid, setIsValid] = useState(false);

  const id = params.id as string;

  useEffect(() => {
    if (saveOrUpdateOk) {
      navigate(Routes.FIELD_PERSONS);
    }
    return () => {
      dispatch(actions.clearUser());
    };
  }, [dispatch, saveOrUpdateOk, navigate]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getUser(id));
    }
  }, [dispatch, id]);

  const [inputs, setInputs] = useState<IInputField>({
    [EFieldPerson.firstName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.FIRST_NAME,
      placeholderTranslation: ETranslation.FIRST_NAME,
      value: "",
      validation: {
        required: true,
      },
    },
    [EFieldPerson.lastName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.LAST_NAME,
      placeholderTranslation: ETranslation.LAST_NAME,
      value: "",
      validation: {
        required: true,
      },
    },
    [EFieldPerson.address]: {
      type: EInputType.text,
      labelTranslation: ETranslation.ADDRESS,
      placeholderTranslation: ETranslation.ADDRESS,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.postcode]: {
      type: EInputType.text,
      labelTranslation: ETranslation.POSTCODE,
      placeholderTranslation: ETranslation.POSTCODE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.place]: {
      type: EInputType.text,
      labelTranslation: ETranslation.PLACE,
      placeholderTranslation: ETranslation.PLACE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.mobile]: {
      type: EInputType.tel,
      labelTranslation: ETranslation.MOBILE,
      placeholderTranslation: ETranslation.MOBILE_PLACEHOLDER,
      value: "",
      validation: {
        required: true,
      },
    },
    [EFieldPerson.email]: {
      type: EInputType.email,
      labelTranslation: ETranslation.EMAIL,
      placeholderTranslation: ETranslation.EMAIL,
      value: "",
      validation: {
        required: true,
      },
    },
    [EFieldPerson.region]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.REGION,
      placeholderTranslation: ETranslation.SELECT_REGION_PLACEHOLDER,
      value: "",
      options: regionOptions,
      validation: {
        required: false,
      },
    },
    [EFieldPerson.workTime]: {
      type: EInputType.text,
      labelTranslation: ETranslation.WORK_TIME,
      placeholderTranslation: ETranslation.WORK_TIME,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.allowLogin]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.ALLOW_LOGIN,
      placeholderTranslation: ETranslation.ALLOW_LOGIN,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.gender]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.GENDER,
      placeholderTranslation: ETranslation.SELECT_GENDER_PLACEHOLDER,
      value: "",
      options: genderOptions,
      validation: {
        required: false,
      },
    },
    [EFieldPerson.level]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.LEVEL,
      placeholderTranslation: ETranslation.SELECT_LEVEL_PLACEHOLDER,
      value: "",
      options: levelOptions,
      validation: {
        required: true,
      },
    },
    [EFieldPerson.DCO]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.DCO,
      placeholderTranslation: ETranslation.DCO,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.DCO_training]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.IN_TRAINING,
      placeholderTranslation: ETranslation.IN_TRAINING,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.assistant]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.ASSISTANT,
      placeholderTranslation: ETranslation.ASSISTANT,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.assistant_training]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.IN_TRAINING,
      placeholderTranslation: ETranslation.IN_TRAINING,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.blood_collection_officer]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.BLOOD_COLLECTION_OFFICER,
      placeholderTranslation: ETranslation.BLOOD_COLLECTION_OFFICER,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.blood_collection_officer_training]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.IN_TRAINING,
      placeholderTranslation: ETranslation.IN_TRAINING,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.lead_DCO]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.LEAD_DCO,
      placeholderTranslation: ETranslation.LEAD_DCO,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.lead_DCO_training]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.IN_TRAINING,
      placeholderTranslation: ETranslation.IN_TRAINING,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.password]: {
      type: EInputType.password,
      labelTranslation: ETranslation.PASSWORD,
      placeholderTranslation: ETranslation.PASSWORD,
      value: "",
      isNewPassword: true,
      validation: {
        required: false,
      },
    },
    [EFieldPerson.notificationEmail]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.NOTIFICATION_EMAIL,
      placeholderTranslation: ETranslation.NOTIFICATION_EMAIL,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.notificationSMS]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.NOTIFICATION_SMS,
      placeholderTranslation: ETranslation.NOTIFICATION_SMS,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.modocUserId]: {
      type: EInputType.number,
      labelTranslation: ETranslation.MODOC_USER_ID,
      placeholderTranslation: ETranslation.MODOC_USER_ID,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.vismaPayrollPersonnelId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.VISMA_PAYROLL_PERSONNEL_ID,
      placeholderTranslation: ETranslation.VISMA_PAYROLL_PERSONNEL_ID,
      value: "",
      validation: {
        required: false,
      },
    },
  });

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  useEffect(() => {
    if (user) initForm(setInputs, user);
  }, [user]);

  const saveHandler = () => {
    const data = getInputData<IUser>(inputs);
    if (Routes.isNew(id)) {
      dispatch(actions.saveUser(data));
    } else {
      data.id = id;
      dispatch(actions.updateUser(data));
    }
  };

  const testSMS = () => {
    if (user) {
      dispatch(actions.testSMS(user?.mobile));
    }
  };

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation: !isValid,
    disabled: saveOrUpdateLoading,
  });

  const lead_dco = inputs[EFieldPerson.lead_DCO].value;
  const dco = inputs[EFieldPerson.DCO].value;
  const assistant = inputs[EFieldPerson.assistant].value;
  const blood_collection_officer =
    inputs[EFieldPerson.blood_collection_officer].value;

  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <InputGroup>
            {createInput(EFieldPerson.firstName)}
            {createInput(EFieldPerson.lastName)}
          </InputGroup>
          {createInput(EFieldPerson.address)}
          <InputGroup>
            {createInput(EFieldPerson.postcode)}
            {createInput(EFieldPerson.place)}
          </InputGroup>
          <InputGroup>
          {createInput(EFieldPerson.mobile, {
            disabled: !!user?.isMultiFactor && !!user?.id,
          })}
          <Button disabled={!user?.isMultiFactor || !user?.id} onClick={()=> testSMS()}>
            {t(ETranslation.TEST_SMS)}
          </Button>
          </InputGroup>
          {createInput(EFieldPerson.email)}
          {createInput(EFieldPerson.region)}
          {createInput(EFieldPerson.workTime)}
          {createInput(EFieldPerson.allowLogin)}
          {createInput(EFieldPerson.gender)}
          {createInput(EFieldPerson.level)}
          <InputGroup>
            {createInput(EFieldPerson.modocUserId)}
            {createInput(EFieldPerson.vismaPayrollPersonnelId)}
          </InputGroup>
          {createInput(EFieldPerson.notificationEmail)}
          {createInput(EFieldPerson.notificationSMS)}
          <InputGroup>
            {createInput(EFieldPerson.lead_DCO)}
            {lead_dco && createInput(EFieldPerson.lead_DCO_training)}
          </InputGroup>
          <InputGroup>
            {createInput(EFieldPerson.DCO)}
            {dco && createInput(EFieldPerson.DCO_training)}
          </InputGroup>
          <InputGroup>
            {createInput(EFieldPerson.assistant)}
            {assistant && createInput(EFieldPerson.assistant_training)}
          </InputGroup>
          <InputGroup>
            {createInput(EFieldPerson.blood_collection_officer)}
            {blood_collection_officer &&
              createInput(EFieldPerson.blood_collection_officer_training)}
          </InputGroup>
          {Routes.isNew(id) && createInput(EFieldPerson.password)}
        </>
      )}
      {error && (
        <Alert
          color={EAlertColor.DANGER}
          style={{ marginTop: 0 }}
          noHorizontalMargin
        >
          {error}
        </Alert>
      )}
      <InputGroup>
        <Button onClick={() => navigate(-1)}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        <Button
          disabled={loading || !isValid || saveOrUpdateLoading}
          color={EButtonColor.SUCCESS}
          onClick={saveHandler}
          loading={saveOrUpdateLoading}
        >
          {t(ETranslation.COMMON_SAVE)}
        </Button>
      </InputGroup>
    </Container>
  );
};

export default FieldPerson;
