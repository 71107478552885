import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import classes from "./Spinner.module.scss";

export enum ESpinnerSize {
  SMALL,
  MEDIUM,
  LARGE,
}

export enum ESpinnerColor {
  CONTENT,
  PRIMARY,
  SECONDARY,
}

interface IProps {
  color?: ESpinnerColor;
  size?: ESpinnerSize;
  center?: boolean;
  style?: CSSProperties;
}

const Spinner: React.FC<IProps> = ({
  center = true,
  color = ESpinnerColor.PRIMARY,
  size = ESpinnerSize.MEDIUM,
  style,
}) => {
  const containerClassNames: string[] = [];

  if (center) {
    containerClassNames.push(classes.Center);
  }

  switch (size) {
    case ESpinnerSize.SMALL:
      containerClassNames.push(classes.SizeSmall);
      break;
    case ESpinnerSize.MEDIUM:
      containerClassNames.push(classes.SizeMedium);
      break;
    case ESpinnerSize.LARGE:
      containerClassNames.push(classes.SizeLarge);
      break;
  }

  switch (color) {
    case ESpinnerColor.CONTENT:
      containerClassNames.push(classes.ColorContent);
      break;
    case ESpinnerColor.SECONDARY:
      containerClassNames.push(classes.ColorSecondary);
      break;
    default:
      containerClassNames.push(classes.ColorPrimary);
      break;
  }

  return (
    <div className={containerClassNames.join(" ")} style={style}>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  );
};

export default Spinner;
