import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ModalContext, { EModalSize } from "../context/ModalContext";
import {
  EWorkLogStatus,
  IWorkLog,
  IWorkLogExpense,
} from "../interfaces/domain/IWorkLog";
import * as actions from "../store/actions";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export const useWorkLog = (id?: string) => {
  const dispatch = useAppDispatch();
  const {
    workLog: stateWorkLog,
    loading,
    updateWorkLogError,
    deleteError,
    attachmentDeleteError,
  } = useAppSelector((state) => state.workLog);
  const { setModal } = useContext(ModalContext);
  const [workLog, setWorkLog] = useState<IWorkLog | null>(null);

  useEffect(() => {
    if (id) {
      dispatch(actions.getWorkLog(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (stateWorkLog) {
      setWorkLog(stateWorkLog);
    }
  }, [stateWorkLog]);

  useEffect(() => {
    if (updateWorkLogError || deleteError || attachmentDeleteError) {
      setModal({
        isOpen: true,
        title: "Error",
        size: EModalSize.SMALL,
        autoScroll: true,
        content: updateWorkLogError || deleteError || attachmentDeleteError,
      });
    }
    return () => {
      dispatch(actions.clearWorkLogError());
    };
  }, [
    updateWorkLogError,
    deleteError,
    attachmentDeleteError,
    setModal,
    dispatch,
  ]);

  const updateWorkLogHandler = useCallback(
    (log: IWorkLog) => {
      if (workLog?.id) {
        log.id = workLog?.id;
        dispatch(actions.updateWorkLog(log));
      }
    },
    [dispatch, workLog?.id]
  );

  const saveWorkLogExpenseHandler = useCallback(() => {
    if (workLog?.id) {
      dispatch(actions.saveWorkLogExpense(workLog.id));
    }
  }, [dispatch, workLog?.id]);

  const uploadAttachmentsHandler = useCallback(
    (files: File[], expenseId: string) => {
      if (workLog?.testMissionId && workLog?.id) {
        dispatch(
          actions.uploadAttachment(
            files,
            workLog.testMissionId,
            workLog.id,
            expenseId
          )
        );
      }
    },
    [dispatch, workLog?.id, workLog?.testMissionId]
  );

  const updateWorkLogExpenseHandler = useCallback(
    (expenseId: string, expense: IWorkLogExpense) => {
      if (workLog?.id) {
        dispatch(actions.updateWorkLogExpense(workLog.id, expenseId, expense));
      }
    },
    [dispatch, workLog?.id]
  );

  const deleteWorkLogExpenseHandler = useCallback(
    (expenseId: string) => {
      if (workLog?.id) {
        dispatch(actions.deleteWorkLogExpense(workLog.id, expenseId));
      }
    },
    [dispatch, workLog?.id]
  );

  const markWorkLogPendingHandler = useCallback(() => {
    if (workLog?.id) {
      dispatch(actions.markWorkLogPending(workLog.id));
    }
  }, [dispatch, workLog?.id]);

  const disableInputs = useMemo(
    () =>
      workLog?.status
        ? [EWorkLogStatus.pending, EWorkLogStatus.accepted].includes(
            workLog.status
          )
        : false,
    [workLog?.status]
  );

  const deleteWorkLogExpenseAttachmentHandler = useCallback(
    (expenseId: string, attachmentId: string) => {
      if (workLog?.id) {
        dispatch(
          actions.deleteWorkLogAttachment(workLog.id, expenseId, attachmentId)
        );
      }
    },
    [dispatch, workLog?.id]
  );

  return {
    workLog,
    loading,
    updateWorkLogHandler,
    uploadAttachmentsHandler,
    markWorkLogPendingHandler,
    saveWorkLogExpenseHandler,
    deleteWorkLogExpenseHandler,
    updateWorkLogExpenseHandler,
    disableInputs,
    deleteWorkLogExpenseAttachmentHandler,
  };
};
