import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "../../../../hooks/useAuthUser";
import { ITestMission } from "../../../../interfaces/domain/ITestMission";
import { ETranslation } from "../../../../translations/translation-keys";
import Button, { EButtonSize } from "../../../UI/Button/Button";
import MyPageTestMission from "../MyPageTestMission/MyPageTestMission";

interface IProps {
  testMissions: ITestMission[];
}

const MyPageTestMissionsList: React.FC<IProps> = ({ testMissions }) => {
  const authUser = useAuthUser();
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll((showAll) => !showAll);

  const hiddenStyle: React.CSSProperties = {
    maxHeight: "30rem",
    overflow: "hidden",
  };

  return (
    <>
      <div style={testMissions.length > 5 && !showAll ? hiddenStyle : {}}>
        {testMissions.map((testMission) => (
          <MyPageTestMission
            key={testMission.id}
            testMission={testMission}
            status={
              testMission.participants.find((p) => p.user?.id === authUser?.id)
                ?.status
            }
            clickable
          />
        ))}
      </div>
      {testMissions.length > 5 && (
        <Button
          size={EButtonSize.SMALL}
          onClick={toggleShowAll}
          icon={showAll ? faCaretUp : faCaretDown}
          style={{ width: "100%" }}
        >
          {showAll ? t(ETranslation.SHOW_LESS) : t(ETranslation.SHOW_MORE)}
        </Button>
      )}
    </>
  );
};

export default MyPageTestMissionsList;
