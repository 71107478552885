import React from "react";
import { useTranslation } from "react-i18next";
import { ITestMission } from "../../../../interfaces/domain/ITestMission";
import { ETranslation } from "../../../../translations/translation-keys";
import Container from "../../../UI/Container/Container";
import { TestMissionParticipantBadge } from "../../../../shared/test-mission";

interface IProps {
  testMission: ITestMission;
  onClick: () => void;
}

const TestMissionBoxText: React.FC<{ name: string; value: string }> = ({
  name,
  value,
}) => (
  <div>
    {name}: {value}
  </div>
);

const TestMissionBox: React.FC<IProps> = ({ testMission, onClick }) => {
  const { t } = useTranslation();
  const {
    testMissionId,
    startDate,
    endDate,
    leadDCO,
    participants,
    sportsDescription,
  } = testMission;

  return (
    <Container onClick={onClick}>
      <h3 style={{ margin: 0 }}>{testMissionId}</h3>
      <TestMissionBoxText name={t(ETranslation.START_DATE)} value={startDate} />
      <TestMissionBoxText name={t(ETranslation.END_DATE)} value={endDate} />
      <TestMissionBoxText
        name={t(ETranslation.LEAD_DCO)}
        value={leadDCO?.name}
      />
      <div>
        {t(ETranslation.ROLES)}:{" "}
        {participants?.length > 0
          ? participants.map((participant) => (
              <p key={participant.id} style={{ margin: 0 }}>
                {participant.participantType.roleName &&
                  t(participant.participantType.roleName)}{" "}
                {participant.user?.name && (
                  <>
                    <TestMissionParticipantBadge status={participant.status} />{" "}
                    {participant.user.name}
                  </>
                )}
              </p>
            ))
          : ""}
      </div>
      <TestMissionBoxText
        name={t(ETranslation.SPORT)}
        value={sportsDescription}
      />
    </Container>
  );
};

export default TestMissionBox;
