import IWorkLogAction from "../../interfaces/action/IWorkLogAction";
import { EWorkLogStatus } from "../../interfaces/domain/IWorkLog";
import IWorkLogState from "../../interfaces/state/IWorkLogState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = IWorkLogState;
type ReducerSignature = (
  state: ReducerState,
  action: IWorkLogAction
) => ReducerState;

const initialState: ReducerState = {
  workLog: null,
  workLogs: null,
  loading: false,
  error: null,
  updateWorkLogLoading: false,
  updateWorkLogError: null,
  saveWorkLogExpenseLoading: false,
  saveWorkLogExpenseError: null,
  uploadLoadingId: null,
  uploadError: null,
  updateLoadingId: null,
  updateError: null,
  deleteLoadingId: null,
  deleteError: null,
  acceptLoadingId: null,
  acceptError: null,
  returnLoadingId: null,
  returnError: null,
  pendingLoading: false,
  pendingError: null,
  attachmentDeleteLoadingId: null,
  attachmentDeleteError: null,
  testMissionStartDate: null,
  testMissionEndDate: null,
  testMissionStartDateString: null,
  testMissionEndDateString: null,
  allLoading: false,
  workLogLinkOptions: null,
  workLogLinkOptionsLoading: false,
  workLogLinkOptionsError: null,
};

const getWorkLogStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: null,
    loading: true,
    testMissionStartDate: null,
    testMissionEndDate: null,
    testMissionStartDateString: null,
    testMissionEndDateString: null,
  };
};

const getWorkLogSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLog: action.workLog || null,
    error: null,
    loading: false,
    testMissionStartDate: action.workLog?.testMissionStartDate ?? "",
    testMissionEndDate: action.workLog?.testMissionEndDate ?? "",
    testMissionStartDateString: action.workLog?.testMissionStartDateString ?? "",
    testMissionEndDateString: action.workLog?.testMissionEndDateString ?? "",
  };
};

const getWorkLogFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLog: null,
    loading: false,
    error: action.error,
    testMissionStartDate: null,
    testMissionEndDate: null,
    testMissionStartDateString: null,
    testMissionEndDateString: null,
  };
};

const listWorkLogStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const listWorkLogSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLogs: action.workLogs || null,
    error: null,
    loading: false,
  };
};

const listWorkLogFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLogs: null,
    loading: false,
    error: action.error,
  };
};

const saveWorkLogStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const saveWorkLogSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLog: action.workLog || null,
    error: null,
    loading: false,
  };
};

const saveWorkLogFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLog: null,
    loading: false,
    error: action.error,
  };
};

const updateWorkLogStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, updateWorkLogError: null, updateWorkLogLoading: true };
};

const updateWorkLogSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    workLog: action.workLog || null,
    updateWorkLogError: null,
    updateWorkLogLoading: false,
  };
};

const updateWorkLogFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    updateWorkLogLoading: false,
    updateWorkLogError: action.error,
  };
};

const myWorkLogStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: null,
    loading: true,
    testMissionStartDate: null,
    testMissionEndDate: null,
    testMissionStartDateString: null,
    testMissionEndDateString: null,
  };
};

const myWorkLogSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLog: action.workLog || null,
    error: null,
    loading: false,
    testMissionStartDate: action.workLog?.testMissionStartDate ?? "",
    testMissionEndDate: action.workLog?.testMissionEndDate ?? "",
    testMissionStartDateString: action.workLog?.testMissionStartDateString ?? "",
    testMissionEndDateString: action.workLog?.testMissionEndDateString ?? "",
  };
};

const myWorkLogFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLog: null,
    loading: false,
    error: action.error,
    testMissionStartDate: null,
    testMissionEndDate: null,
    testMissionStartDateString: null,
    testMissionEndDateString: null,
  };
};

const saveWorkLogExpenseStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    saveWorkLogExpenseError: null,
    saveWorkLogExpenseLoading: true,
  };
};

const saveWorkLogExpenseSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  const workLog = action.workLog || null;
  if (workLog && workLog.expenses) {
    workLog.expenses[workLog.expenses.length - 1].open = true;
  }
  return {
    ...state,
    workLog,
    saveWorkLogExpenseError: null,
    saveWorkLogExpenseLoading: false,
  };
};

const saveWorkLogExpenseFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    saveWorkLogExpenseLoading: false,
    saveWorkLogExpenseError: action.error,
  };
};

const uploadAttachmentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, uploadError: null, uploadLoadingId: action.id };
};

const uploadAttachmentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  const workLog = state.workLog;
  if (workLog && action.workLog && action.workLog.expenses) {
    for (let i = 0; i < action.workLog.expenses.length; i++) {
      const item = action.workLog.expenses[i];
      const log = workLog.expenses.find((l) => l.id === item.id);
      if (log) {
        log.attachments = item.attachments;
      }
    }
  }
  return {
    ...state,
    workLog,
    uploadError: null,
    uploadLoadingId: null,
  };
};

const uploadAttachmentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    uploadLoadingId: null,
    uploadError: action.error,
  };
};

const updateWorkLogExpenseStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, updateError: null, updateLoadingId: action.id };
};

const updateWorkLogExpenseSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    workLog: action.workLog || null,
    updateError: null,
    updateLoadingId: null,
  };
};

const updateWorkLogExpenseFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    updateLoadingId: null,
    updateError: action.error,
  };
};

const deleteWorkLogExpenseStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, deleteError: null, deleteLoadingId: action.id };
};

const deleteWorkLogExpenseSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    workLog: action.workLog || null,
    deleteError: null,
    deleteLoadingId: null,
  };
};

const deleteWorkLogExpenseFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    deleteLoadingId: null,
    deleteError: action.error,
  };
};

const clearWorkLog: ReducerSignature = (state, action): ReducerState => {
  return initialState;
};

const acceptWorkLogStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, acceptError: null, acceptLoadingId: action.id, allLoading: action.id ? false : true };
};

const acceptWorkLogSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let workLogs = state.workLogs;
  let workLog = state.workLog;
  if (workLog) {
    workLog.status = EWorkLogStatus.accepted;
  }
  if (workLogs) {
    if (action.id) {
      const findWorkLog = workLogs.find((log) => log.id === action.id);
      if (findWorkLog) {
        findWorkLog.status = EWorkLogStatus.accepted;
      }
    } else {
      for (let i = 0; i < workLogs.length; i++) {
        workLogs[i].status = EWorkLogStatus.accepted;
      }
    }
  }
  return {
    ...state,
    workLogs,
    workLog,
    acceptError: null,
    acceptLoadingId: null,
    allLoading: false,
  };
};

const acceptWorkLogFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    acceptLoadingId: null,
    acceptError: action.error,
    allLoading: false,
  };
};

const returnWorkLogStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, returnError: null, returnLoadingId: action.id };
};

const returnWorkLogSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let workLogs = state.workLogs;
  let workLog = state.workLog;
  if (workLog) {
    workLog.status = EWorkLogStatus.returned;
  }
  if (workLogs && action.id) {
    const findWorkLog = workLogs.find((log) => log.id === action.id);
    if (findWorkLog) {
      findWorkLog.status = EWorkLogStatus.returned;
    }
  }
  return {
    ...state,
    workLogs,
    workLog,
    returnError: null,
    returnLoadingId: null,
  };
};

const returnWorkLogFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    returnLoadingId: null,
    returnError: action.error,
  };
};

const clearWorkLogError: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: null,
    updateWorkLogError: null,
    saveWorkLogExpenseError: null,
    uploadError: null,
    updateError: null,
    deleteError: null,
    acceptError: null,
    returnError: null,
    attachmentDeleteError: null,
  };
};

const markWorkLogPendingStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, pendingError: null, pendingLoading: true };
};

const markWorkLogPendingSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let workLog = state.workLog ? { ...state.workLog } : null;
  if (workLog) {
    workLog.status = EWorkLogStatus.pending;
  }
  return {
    ...state,
    workLog,
    pendingError: null,
    pendingLoading: false,
  };
};

const markWorkLogPendingFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    pendingLoading: false,
    pendingError: action.error,
  };
};

const deleteAttachmentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    attachmentDeleteError: null,
    attachmentDeleteLoadingId: action.id,
  };
};

const deleteAttachmentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  const workLog = state.workLog;
  if (workLog && workLog.expenses && action.id && action.attachmentId) {
    const log = workLog.expenses.find((l) => l.id === action.id);
    if (log) {
      log.attachments = log.attachments.filter(
        (a) => a.id !== action.attachmentId
      );
    }
  }
  return {
    ...state,
    workLog,
    attachmentDeleteError: null,
    attachmentDeleteLoadingId: null,
  };
};

const deleteAttachmentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    attachmentDeleteLoadingId: null,
    attachmentDeleteError: action.error,
  };
};

const listWorkLogLinkOptionsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, workLogLinkOptionsError: null, workLogLinkOptionsLoading: true };
};

const listWorkLogLinkOptionsSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLogLinkOptions: action.workLogs || null,
    workLogLinkOptionsError: null,
    workLogLinkOptionsLoading: false,
  };
};

const listWorkLogLinkOptionsFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    workLogLinkOptions: null,
    workLogLinkOptionsLoading: false,
    workLogLinkOptionsError: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: IWorkLogAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.WORKLOG_GET_START:
      return getWorkLogStart(state, action);
    case EActionTypes.WORKLOG_GET_SUCCESS:
      return getWorkLogSuccess(state, action);
    case EActionTypes.WORKLOG_GET_FAIL:
      return getWorkLogFail(state, action);
    case EActionTypes.WORKLOG_LIST_START:
      return listWorkLogStart(state, action);
    case EActionTypes.WORKLOG_LIST_SUCCESS:
      return listWorkLogSuccess(state, action);
    case EActionTypes.WORKLOG_LIST_FAIL:
      return listWorkLogFail(state, action);
    case EActionTypes.WORKLOG_SAVE_START:
      return saveWorkLogStart(state, action);
    case EActionTypes.WORKLOG_SAVE_SUCCESS:
      return saveWorkLogSuccess(state, action);
    case EActionTypes.WORKLOG_SAVE_FAIL:
      return saveWorkLogFail(state, action);
    case EActionTypes.WORKLOG_UPDATE_START:
      return updateWorkLogStart(state, action);
    case EActionTypes.WORKLOG_UPDATE_SUCCESS:
      return updateWorkLogSuccess(state, action);
    case EActionTypes.WORKLOG_UPDATE_FAIL:
      return updateWorkLogFail(state, action);
    case EActionTypes.WORKLOG_MY_START:
      return myWorkLogStart(state, action);
    case EActionTypes.WORKLOG_MY_SUCCESS:
      return myWorkLogSuccess(state, action);
    case EActionTypes.WORKLOG_MY_FAIL:
      return myWorkLogFail(state, action);
    case EActionTypes.WORKLOG_EXPENSE_SAVE_START:
      return saveWorkLogExpenseStart(state, action);
    case EActionTypes.WORKLOG_EXPENSE_SAVE_SUCCESS:
      return saveWorkLogExpenseSuccess(state, action);
    case EActionTypes.WORKLOG_EXPENSE_SAVE_FAIL:
      return saveWorkLogExpenseFail(state, action);
    case EActionTypes.WORKLOG_EXPENSE_UPLOAD_START:
      return uploadAttachmentStart(state, action);
    case EActionTypes.WORKLOG_EXPENSE_UPLOAD_SUCCESS:
      return uploadAttachmentSuccess(state, action);
    case EActionTypes.WORKLOG_EXPENSE_UPLOAD_FAIL:
      return uploadAttachmentFail(state, action);
    case EActionTypes.WORKLOG_EXPENSE_UPDATE_START:
      return updateWorkLogExpenseStart(state, action);
    case EActionTypes.WORKLOG_EXPENSE_UPDATE_SUCCESS:
      return updateWorkLogExpenseSuccess(state, action);
    case EActionTypes.WORKLOG_EXPENSE_UPDATE_FAIL:
      return updateWorkLogExpenseFail(state, action);
    case EActionTypes.WORKLOG_EXPENSE_DELETE_START:
      return deleteWorkLogExpenseStart(state, action);
    case EActionTypes.WORKLOG_EXPENSE_DELETE_SUCCESS:
      return deleteWorkLogExpenseSuccess(state, action);
    case EActionTypes.WORKLOG_EXPENSE_DELETE_FAIL:
      return deleteWorkLogExpenseFail(state, action);
    case EActionTypes.WORKLOG_CLEAR:
      return clearWorkLog(state, action);
    case EActionTypes.WORKLOG_ACCEPT_START:
      return acceptWorkLogStart(state, action);
    case EActionTypes.WORKLOG_ACCEPT_SUCCESS:
      return acceptWorkLogSuccess(state, action);
    case EActionTypes.WORKLOG_ACCEPT_FAIL:
      return acceptWorkLogFail(state, action);
    case EActionTypes.WORKLOG_RETURN_START:
      return returnWorkLogStart(state, action);
    case EActionTypes.WORKLOG_RETURN_SUCCESS:
      return returnWorkLogSuccess(state, action);
    case EActionTypes.WORKLOG_RETURN_FAIL:
      return returnWorkLogFail(state, action);
    case EActionTypes.WORKLOG_CLEAR_ERROR:
      return clearWorkLogError(state, action);
    case EActionTypes.WORKLOG_MARK_PENDING_START:
      return markWorkLogPendingStart(state, action);
    case EActionTypes.WORKLOG_MARK_PENDING_SUCCESS:
      return markWorkLogPendingSuccess(state, action);
    case EActionTypes.WORKLOG_MARK_PENDING_FAIL:
      return markWorkLogPendingFail(state, action);
    case EActionTypes.WORKLOG_EXPENSE_ATTACHMENT_DELETE_START:
      return deleteAttachmentStart(state, action);
    case EActionTypes.WORKLOG_EXPENSE_ATTACHMENT_DELETE_SUCCESS:
      return deleteAttachmentSuccess(state, action);
    case EActionTypes.WORKLOG_EXPENSE_ATTACHMENT_DELETE_FAIL:
      return deleteAttachmentFail(state, action);
    case EActionTypes.WORKLOG_LIST_LINK_OPTIONS_START:
      return listWorkLogLinkOptionsStart(state, action);
    case EActionTypes.WORKLOG_LIST_LINK_OPTIONS_SUCCESS:
      return listWorkLogLinkOptionsSuccess(state, action);
    case EActionTypes.WORKLOG_LIST_LINK_OPTIONS_FAIL:
      return listWorkLogLinkOptionsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
