import { BaseDomain } from "./IBaseDomain";
import { IRegion } from "./IRegion";
import { IRole } from "./IRole";

export enum EGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER_UNKNOWN = "OTHER_UNKNOWN"
}

export enum ELevel {
  FIELD_PERSON = "FIELD_PERSON",
  TEST_PLANNER = "TEST_PLANNER",
  REPORTING = "REPORTING",
}

export interface IUser extends BaseDomain {
  // firebaseUserId: string;
  firstName: string;
  lastName: string;
  address: string;
  postcode: string;
  place: string;
  mobile: string;
  email: string;
  region: IRegion;
  roles: IRole[];
  workTime: string;
  allowLogin: boolean;
  gender: EGender;
  level: ELevel
  name: string;
  DCO: boolean;
  DCO_training: boolean;
  assistant: boolean;
  assistant_training: boolean;
  blood_collection_officer: boolean;
  blood_collection_officer_training: boolean;
  lead_DCO: boolean;
  lead_DCO_training: boolean;
  isMultiFactor: boolean;
  userDescription?: string;
  notificationEmail: boolean;
  notificationSMS: boolean;
}
