import React, { useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ImportSummary from "../../../components/Import/ImportSummary/ImportSummary";
import Button, { EButtonColor } from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Alert from "../../../components/UI/Alert/Alert";
import ModalContext, { EModalSize } from "../../../context/ModalContext";
import ConfirmDialog from "../../../components/UI/ConfirmDialog/ConfirmDialog";

const Import: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading, summary, automatchError, automatchLoading, error } =
    useAppSelector((state) => state.import);
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setModal, closeModal } = useContext(ModalContext);

  useEffect(() => {
    if (params.id) {
      dispatch(actions.getImport(params.id));
    }
  }, [dispatch, params.id]);

  const startAutomatch = useCallback(() => {
    if (!params.id) return;
    dispatch(actions.importAutomatch(params.id));
  }, [dispatch, params.id]);

  const deleteHandler = useCallback(async () => {
    if (params.id) {
      await dispatch(actions.deleteImport(params.id));
    }
    closeModal();
    navigate(-1);
  }, [closeModal, dispatch, navigate, params.id]);

  const showDeleteDialog = () =>
    setModal({
      isOpen: true,
      title: t(ETranslation.COMMON_DELETE),
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          onCancel={closeModal}
          onConfirm={() => deleteHandler()}
          body={<p>{t(ETranslation.IMPORT_DELETE)}</p>}
        />
      ),
    });

  return (
    <>
      {automatchError && <Alert>{automatchError}</Alert>}
      <Container>
        {loading ? (
          <Spinner />
        ) : summary ? (
          <ImportSummary summary={summary} />
        ) : (
          <p>{t(ETranslation.NOT_FOUND_ERROR)}</p>
        )}
        <InputGroup>
          <Button onClick={() => navigate(-1)}>
            {t(ETranslation.COMMON_RETURN)}
          </Button>
          {!loading && !error && !summary?.automatchingStarted && (
            <>
              {!summary?.noNewTestMissions && (
                <Button
                  onClick={startAutomatch}
                  loading={automatchLoading}
                  disabled={automatchLoading || loading}
                  color={EButtonColor.SUCCESS}
                >
                  {t(ETranslation.IMPORT_START_AUTOMATCH)}
                </Button>
              )}
              <Button
                onClick={() => showDeleteDialog()}
                loading={loading}
                disabled={automatchLoading || loading}
                color={EButtonColor.DANGER}
              >
                {t(ETranslation.IMPORT_DELETE)}
              </Button>
            </>
          )}
        </InputGroup>
      </Container>
    </>
  );
};

export default Import;
