import {
  faExclamationTriangle,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import { DATEPATTERN_FIN } from "../../../data/constants";
import { EParticipantStatus } from "../../../interfaces/domain/IParticipant";
import {
  ETestMissionStatus,
  ITestMission,
} from "../../../interfaces/domain/ITestMission";
import { TestMissionParticipantBadge } from "../../../shared/test-mission";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../UI/Table/Table";
import StatusLabel from "../StatusLabel/StatusLabel";
import classes from "./TestMissionList.module.scss";

interface IProps {
  testMissions: ITestMission[];
}

enum ETestMissionListSortBy {
  TEST_MISSION_ID = "testMissionId",
  START_DATE = "startDate",
  END_DATE = "endDate",
}

const now = moment();

const TestMissionList: React.FC<IProps> = ({ testMissions }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [sortedTestMissions, setSortedTestMissions] = useState<ITestMission[]>(
    []
  );
  const [sort, setSort] = useState<ETestMissionListSortBy>();
  // true = up, false = down
  const [sortDirection, setSortDirection] = useState<boolean>(false);

  useEffect(() => {
    const newTestMissions = [...testMissions];

    if (sort && newTestMissions) {
      if (sortDirection) {
        newTestMissions.reverse();
        setSortedTestMissions(newTestMissions);
        return;
      }

      newTestMissions.sort((a, b) => {
        if (a[sort] === "" || a[sort] === null || a[sort] === undefined)
          return 1;
        if (b[sort] === "" || b[sort] === null || b[sort] === undefined)
          return -1;
        if (a[sort] === b[sort]) return 0;
        if (
          sort ===
          (ETestMissionListSortBy.START_DATE || ETestMissionListSortBy.END_DATE)
        )
          return (a[sort] || "") < (b[sort] || "") ? 1 : -1;
        return (a[sort] || "") > (b[sort] || "") ? 1 : -1;
      });
      setSortedTestMissions(newTestMissions);
      return;
    }

    setSortedTestMissions(newTestMissions);
  }, [sort, sortDirection, testMissions]);

  const changeSortOrDirection = (sortBy: ETestMissionListSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  };

  const getChevron = () => {
    return (
      <FontAwesomeIcon
        className={classes.Icon}
        icon={sortDirection ? faChevronUp : faChevronDown}
      />
    );
  };

  return (
    <Table hover>
      <thead>
        <tr className={classes.TableHeader}>
          <th
            className={classes.Pointer}
            onClick={() =>
              changeSortOrDirection(ETestMissionListSortBy.TEST_MISSION_ID)
            }
          >
            {t(ETranslation.TEST_MISSION_ID)}
            {sort === ETestMissionListSortBy.TEST_MISSION_ID && getChevron()}
          </th>
          <th
            className={classes.Pointer}
            onClick={() =>
              changeSortOrDirection(ETestMissionListSortBy.START_DATE)
            }
          >
            {t(ETranslation.START_DATE)}
            {sort === ETestMissionListSortBy.START_DATE && getChevron()}
          </th>
          <th
            className={classes.Pointer}
            onClick={() =>
              changeSortOrDirection(ETestMissionListSortBy.END_DATE)
            }
          >
            {t(ETranslation.END_DATE)}
            {sort === ETestMissionListSortBy.END_DATE && getChevron()}
          </th>
          <th>{t(ETranslation.LEAD_DCO)}</th>
          <th>{t(ETranslation.ROLES)}</th>
          <th>{t(ETranslation.SPORT)}</th>
          <th>{t(ETranslation.STATUS)}</th>
        </tr>
      </thead>
      <tbody>
        {sortedTestMissions.map((testMission) => {
          const {
            id,
            testMissionId,
            startDate,
            endDate,
            leadDCO,
            participants,
            status,
            sportsDescription,
            urgent,
          } = testMission;
          return (
            <tr
              key={id}
              onClick={() => navigate(Routes.TEST_MISSION(id))}
              style={
                status === ETestMissionStatus.MODOC
                  ? { background: "#E4E9E2" }
                  : {}
              }
            >
              <td style={{ whiteSpace: "nowrap" }}>
                {testMissionId}
                {urgent ? " URGENT" : ""}
              </td>
              <td>{startDate && moment(startDate).format(DATEPATTERN_FIN)}</td>
              <td
                style={
                  [
                    ETestMissionStatus.ISSUED,
                    ETestMissionStatus.MODOC,
                  ].includes(status) &&
                  endDate &&
                  moment(endDate).isBefore(now)
                    ? { color: "#bb4c30" }
                    : {}
                }
              >
                {endDate && moment(endDate).format(DATEPATTERN_FIN)}
              </td>
              <td>{leadDCO?.name}</td>
              <td style={{ whiteSpace: "pre" }}>
                {participants?.length > 0 &&
                  participants.map((participant) => (
                    <React.Fragment key={participant.id}>
                      {participant.participantType.roleName &&
                        t(participant.participantType.roleName)}{" "}
                      {participant.user?.name ? (
                        participant.status === EParticipantStatus.PENDING ? (
                          <i>{participant.user.name}</i>
                        ) : participant.status ===
                          EParticipantStatus.REFUSED ? (
                          <>
                            <TestMissionParticipantBadge
                              status={participant.status}
                            />{" "}
                            <s>{participant.user.name}</s>
                          </>
                        ) : (
                          <b>{participant.user.name}</b>
                        )
                      ) : (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          color="#FFD700"
                        />
                      )}
                      <br />
                    </React.Fragment>
                  ))}
              </td>
              <td>{sportsDescription}</td>
              <td>{status && <StatusLabel status={status} />}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TestMissionList;
