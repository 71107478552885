import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button, { EButtonColor } from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import Spinner from "../../../components/UI/Spinner/Spinner";
import WorkLogEdit from "../../../components/WorkLogs/WorkLog/WorkLogEdit";
import { useWorkLog } from "../../../hooks/useWorkLog";
import { ETranslation } from "../../../translations/translation-keys";
import { EWorkLogStatus } from "../../../interfaces/domain/IWorkLog";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { useAppSelector } from "../../../hooks/useAppSelector";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import * as actions from "../../../store/actions";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAuthUser } from "../../../hooks/useAuthUser";

const WorkLog: React.FC = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authUser = useAuthUser();

  const { workLog, loading, markWorkLogPendingHandler } = useWorkLog(params.id);
  const { pendingLoading, acceptLoadingId, returnLoadingId, allLoading } = useAppSelector((state) => state.workLog);
  const openConfirmModal = useConfirmModal();
  const dispatch = useAppDispatch();

  const markPendingHandler = useCallback(async () => {
    const success = await openConfirmModal(
      t(ETranslation.WORKLOG_CONFIRM_MARK_PENDING),
      t(ETranslation.MARK_WORKLOG_PENDING)
    );
    if (success) {
      markWorkLogPendingHandler();
    }
  }, [markWorkLogPendingHandler, openConfirmModal, t]);

  const acceptHandler = useCallback(
    async (e: React.MouseEvent, id: string, testMissionId?: string) => {
      e.stopPropagation();
      const success = await openConfirmModal(
        t(ETranslation.WORKLOG_CONFIRM_ACCEPT),
        t(ETranslation.WORKLOG_ACCEPT)
      );
      if (success) {
        dispatch(actions.acceptWorkLog(id, testMissionId));
      }
    },
    [openConfirmModal, dispatch, t]
  );

  const returnHandler = useCallback(
    async (e: React.MouseEvent, id: string) => {
      e.stopPropagation();
      const success = await openConfirmModal(
        t(ETranslation.WORKLOG_CONFIRM_RETURN),
        t(ETranslation.WORKLOG_RETURN)
      );
      if (success) {
        dispatch(actions.returnWorkLog(id));
      }
    },
    [openConfirmModal, dispatch, t]
  );

  const isAcceptLoading = useMemo(
    () => acceptLoadingId === workLog?.id,
    [acceptLoadingId, workLog?.id]
  );

  const isReturnLoading = useMemo(
    () => returnLoadingId === workLog?.id,
    [returnLoadingId, workLog?.id]
  );

  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : workLog ? (
        <WorkLogEdit />
      ) : (
        <p>{t(ETranslation.NOT_FOUND_ERROR)}</p>
      )}
      <InputGroup>
        <Button onClick={() => navigate(-1)}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        {workLog?.status &&
          authUser?.isFieldPerson &&
          [EWorkLogStatus.draft, EWorkLogStatus.returned].includes(
            workLog.status
          ) && (
            <Button
              onClick={markPendingHandler}
              loading={pendingLoading}
              disabled={pendingLoading}
              color={EButtonColor.SUCCESS}
            >
              {t(ETranslation.MARK_WORKLOG_PENDING)}
            </Button>
          )}
        {workLog?.status &&
          (authUser?.isTestPlanner || authUser?.isReporting) && (
            <>
              {[EWorkLogStatus.pending, EWorkLogStatus.returned].includes(
                workLog.status
              ) && (
                <Button
                  onClick={(e) => acceptHandler(e, workLog.id)}
                  color={EButtonColor.SUCCESS}
                  loading={isAcceptLoading || allLoading}
                >
                  {t(ETranslation.WORKLOG_ACCEPT)}
                </Button>
              )}
              {[EWorkLogStatus.pending].includes(
                workLog.status
              ) && (
                <Button
                  onClick={(e) => returnHandler(e, workLog.id)}
                  color={EButtonColor.DANGER}
                  loading={isReturnLoading}
                >
                  {t(ETranslation.WORKLOG_RETURN)}
                </Button>
              )}
            </>
          )}
      </InputGroup>
    </Container>
  );
};

export default WorkLog;
