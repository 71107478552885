import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../../interfaces/domain/IUser";
import { ETranslation } from "../../../../translations/translation-keys";
import Container from "../../../UI/Container/Container";
import FieldPersonBoxText from "./FieldPersonBoxText/FieldPersonBoxText";

interface IProps {
  fieldPerson: IUser;
}

const FieldPersonBox: React.FC<IProps> = ({ fieldPerson }) => {
  const { t } = useTranslation();
  const { name, roles, allowLogin } = fieldPerson;
  
  return (
    <Container>
      <FieldPersonBoxText
        name={t(ETranslation.NAME)}
        value={name}
      />
      <FieldPersonBoxText
        name={t(ETranslation.ROLES)}
        value={roles?.map(role => role.roleName).join(", ")}
      />
      <FieldPersonBoxText
        name={t(ETranslation.ALLOW_LOGIN)}
        value={allowLogin ? <FontAwesomeIcon icon={faCheck}/> :""}
      />
    </Container>
  );
}

export default FieldPersonBox;
