import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MyPageTestMission from "../../components/MyPage/MyPageTestMissions/MyPageTestMission/MyPageTestMission";
import TestMissionComments from "../../components/TestMissions/TestMission/TestMissionComments/TestMissionComments";
import TestMissionParticipants from "../../components/TestMissions/TestMission/TestMissionParticipants/TestMissionParticipants";
import Alert, { EAlertColor } from "../../components/UI/Alert/Alert";
import Button, { EButtonColor } from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import { IOption, TInputValue } from "../../components/UI/Input/Input";
import RadioButton from "../../components/UI/RadioButton/RadioButton";
import Spinner from "../../components/UI/Spinner/Spinner";
import WorkLogEdit from "../../components/WorkLogs/WorkLog/WorkLogEdit";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAuthUser } from "../../hooks/useAuthUser";
import { useWorkLog } from "../../hooks/useWorkLog";
import { ETestMissionStatus } from "../../interfaces/domain/ITestMission";
import { EWorkLogStatus } from "../../interfaces/domain/IWorkLog";
import { ETestMissionView } from "../../shared/test-mission";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import { useConfirmModal } from "../../hooks/useConfirmModal";
import InputGroup from "../../components/UI/InputGroup/InputGroup";

const MissionDetails: React.FC = () => {
  const [searchParams] = useSearchParams();
  const initialView = useMemo(() => {
    let param = searchParams.get("view");
    if (!param) return undefined;
    const view = param.toUpperCase() as ETestMissionView;
    if (Object.values(ETestMissionView).includes(view)) return view;
    return undefined;
  }, [searchParams]);

  const [view, setView] = useState<ETestMissionView>(
    initialView ?? ETestMissionView.TEAM
  );

  const params = useParams();
  const dispatch = useAppDispatch();
  const { testMission, loading, error } = useAppSelector(
    (state) => state.testMission
  );
  const { t } = useTranslation();

  const authUser = useAuthUser();
  const navigate = useNavigate();

  const { loading: workLogsLoading, workLog, markWorkLogPendingHandler } = useWorkLog();
  const { pendingLoading } = useAppSelector((state) => state.workLog);
  const openConfirmModal = useConfirmModal();

  useEffect(() => {
    if (params.id) dispatch(actions.getTestMission(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (
      testMission &&
      testMission.status === ETestMissionStatus.COMPLETE &&
      testMission.testMissionId
    ) {
      dispatch(actions.myWorkLog(testMission.testMissionId));
    }
    return () => {
      dispatch(actions.clearWorkLog());
    };
  }, [dispatch, testMission]);

  useEffect(() => {
    if (testMission) dispatch(actions.listMyNotifications());
  }, [dispatch, testMission]);

  const commentAddHandler = (value: TInputValue) => {
    if (testMission && value) {
      dispatch(actions.addTestMissionComment(testMission.id, value as string));
    }
  };

  const commentDeleteHandler = (id: string) => {
    if (testMission) {
      dispatch(actions.deleteTestMissionComment(testMission.id, id));
    }
  };

  const getViewColor = useCallback(() => {
    if (!workLog?.status) return undefined;
    switch (workLog?.status) {
      case EWorkLogStatus.draft:
        return EAlertColor.WARNING;
      case EWorkLogStatus.pending:
        return EAlertColor.SECONDARY;
      case EWorkLogStatus.accepted:
        return EAlertColor.SUCCESS;
      case EWorkLogStatus.returned:
        return EAlertColor.DANGER;
      default:
        return undefined;
    }
  }, [workLog?.status]);

  const viewOptions: IOption[] = useMemo(
    () =>
      [
        { value: ETestMissionView.TEAM, labelTranslation: ETranslation.TEAM },
        {
          value: ETestMissionView.COMPENSATION,
          labelTranslation: ETranslation.COMPENSATION,
          color: getViewColor(),
        },
      ] as IOption[],
    [getViewColor]
  );

  const markPendingHandler = useCallback(async () => {
    const success = await openConfirmModal(
      t(ETranslation.WORKLOG_CONFIRM_MARK_PENDING),
      t(ETranslation.MARK_WORKLOG_PENDING)
    );
    if (success) {
      markWorkLogPendingHandler();
    }
  }, [markWorkLogPendingHandler, openConfirmModal, t]);

  return (
    <Container>
      {error && (
        <Alert color={EAlertColor.DANGER} noHorizontalMargin>
          {error}
        </Alert>
      )}
      {loading ? (
        <Spinner />
      ) : testMission ? (
        <>
          {workLog && (
            <RadioButton
              value={view as string}
              onChange={(value) => setView(value as ETestMissionView)}
              options={viewOptions}
            />
          )}
          {view === ETestMissionView.TEAM ? (
            <>
              <MyPageTestMission
                testMission={testMission}
                status={
                  testMission?.participants?.find(
                    (p) => p.user?.id === authUser?.id
                  )?.status
                }
              />
              <TestMissionParticipants
                participants={testMission.participants}
              />
              <TestMissionComments
                comments={testMission.comments}
                onAdd={commentAddHandler}
                onDelete={commentDeleteHandler}
              />
            </>
          ) : testMission.status === ETestMissionStatus.COMPLETE &&
            workLogsLoading ? (
            <Spinner center />
          ) : workLog ? (
            <WorkLogEdit />
          ) : null}
        </>
      ) : (
        <p>{t(ETranslation.NOT_FOUND_ERROR)}</p>
      )}
      <InputGroup>
        <Button onClick={() => navigate(-1)}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        {view === ETestMissionView.COMPENSATION && workLog?.status &&
          authUser?.isFieldPerson &&
          [EWorkLogStatus.draft, EWorkLogStatus.returned].includes(
            workLog.status
          ) && (
            <Button
              onClick={markPendingHandler}
              loading={pendingLoading}
              disabled={pendingLoading}
              color={EButtonColor.SUCCESS}
            >
              {t(ETranslation.MARK_WORKLOG_PENDING)}
            </Button>
          )}
      </InputGroup>
    </Container>
  );
};

export default MissionDetails;
