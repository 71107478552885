import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { regionOptions, roleOptions } from "../../../data/select-data";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { ERoleName } from "../../../interfaces/domain/IRole";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonSize } from "../../UI/Button/Button";
import Container from "../../UI/Container/Container";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData } from "../../UI/Input/input-utils";

export interface IFieldPersonSearch {
  region?: string;
  role?: ERoleName;
}

interface IProps {
  onSearch: (search: IFieldPersonSearch) => void;
}

enum EFieldPersonSearch {
  region = "region",
  role = "role",
}

const initialInputs: IInputField = {
  [EFieldPersonSearch.region]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.SEARCH_REGION,
    placeholderTranslation: ETranslation.SELECT_REGION_PLACEHOLDER,
    value: "",
    options: regionOptions,
  },
  [EFieldPersonSearch.role]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.SEARCH_ROLE,
    placeholderTranslation: ETranslation.SELECT_ROLE_PLACEHOLDER,
    value: "",
    options: roleOptions,
  },
};

const FieldPersonSearch: React.FC<IProps> = ({ onSearch }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);
  const createInput = useCreateInput(inputs, setInputs);

  useEffect(() => {
    onSearch(getInputData(initialInputs));
  }, [onSearch]);

  return (
    <Container>
      {createInput(EFieldPersonSearch.region)}
      {createInput(EFieldPersonSearch.role)}
      <Button
        onClick={() => onSearch(getInputData(inputs))}
        size={EButtonSize.SMALL}
      >
        {t(ETranslation.COMMON_SEARCH)}
      </Button>
    </Container>
  );
};

export default FieldPersonSearch;
