import { ETranslation } from "./translation-keys";

const commonTranslations = {
  [ETranslation.COMMON_EMAIL]: "Email",
  [ETranslation.COMMON_SEND]: "Send",
  [ETranslation.COMMON_CANCEL]: "Cancel",
  [ETranslation.COMMON_CLOSE]: "Close",
  [ETranslation.COMMON_RETURN]: "Return",
  [ETranslation.COMMON_DELETE]: "Delete",
  [ETranslation.COMMON_YES]: "Yes",
  [ETranslation.COMMON_NO]: "No",
  [ETranslation.COMMON_CHECK]: "Check",
  [ETranslation.COMMON_SAVE]: "Save",
  [ETranslation.COMMON_ACCEPT]: "Accept",
  [ETranslation.COMMON_REFUSE]: "Refuse",
  [ETranslation.COMMON_ADD]: "Add",
  [ETranslation.COMMON_SEARCH]: "Search",
  [ETranslation.COMMON_SETTINGS]: "Settings",
};

const authTranslations = {
  [ETranslation.TITLE]: "ADNO",
  [ETranslation.LOGIN]: "Login",
  [ETranslation.FORGOT_PASSWORD]: "Forgot your password?",
  [ETranslation.RESET_PASSWORD]: "Reset password",
  [ETranslation.USERNAME]: "Username",
  [ETranslation.PASSWORD]: "Password",
  [ETranslation.PASSWORD_MIN_LENGTH_MESSAGE]:
    "Password must contain at least 6 characters",
  [ETranslation.PASSWORD_RESET_TEXT]:
    "Are you sure you want to send a change password link to your email?",
};

const headerTranslations = {
  [ETranslation.MY_PAGE]: "My page",
  [ETranslation.TEST_MISSIONS]: "Test missions",
  [ETranslation.IMPORT]: "Import",
  [ETranslation.LOG]: "Log",
  [ETranslation.FIELD_PERSONS]: "Field persons",
  [ETranslation.UNAVAILABILITIES]: "Unavailabilities",
  [ETranslation.UNAVAILABILITY]: "Unavailability",
  [ETranslation.LOGOUT]: "Log out",
  [ETranslation.PROFILE]: "Profile",
  [ETranslation.REPORTS]: "Reports",
  [ETranslation.COMPENSATIONS]: "Compensations",
  [ETranslation.COMPENSATION]: "Compensation",
  [ETranslation.DCO_REPORT]: "DCO Report",
};

const testMissionsTranslations = {
  [ETranslation.START_DATE]: "Start date",
  [ETranslation.END_DATE]: "End date",
  [ETranslation.LEAD_DCO]: "Lead DCO",
  [ETranslation.ROLES]: "Roles",
  [ETranslation.SPORT]: "Sport",
  [ETranslation.NO_TEST_MISSIONS]: "No test missions.",
  [ETranslation.DATE]: "Date",
  [ETranslation.TEST_MISSIONS_HAS_MORE]: "Show more test missions...",
  [ETranslation.TEST_MISSIONS_ALL_FETCHED]: "All results fetched.",
};

const testMissionTranslations = {
  [ETranslation.TEST_MISSION_ID]: "Id",
  [ETranslation.STATUS]: "Status",
  [ETranslation.NUMBER_OF_TESTS]: "Number of tests",
  [ETranslation.IN_COMPETITION]: "In competition",
  [ETranslation.TEST_PLANNER]: "Test planner",
  [ETranslation.PROVINCE]: "Province",
  [ETranslation.REGION]: "Region",
  [ETranslation.PARTICIPANTS]: "Participants",
  [ETranslation.COMMENTS]: "Comments",
  [ETranslation.DISCIPLINE]: "Discipline",
  [ETranslation.GENDER_REQUIREMENT]: "Gender requirement",
  [ETranslation.URGENT]: "Urgent",
  [ETranslation.CITY]: "City",
  [ETranslation.COMPLETED_DATE]: "Completed date",
  [ETranslation.IGNORE_IN_COMPETITION]: "Ignore in competition",
  [ETranslation.TEST_MISSION_OPEN_IN_MODOC]: "Open in Modoc",
};

const testMissionParticipantTranslations = {
  [ETranslation.IS_LEAD_DCO]: "Is lead DCO",
  [ETranslation.ROLE_NAME]: "Role",
};

const testMissionCommentsTranslations = {
  [ETranslation.MESSAGE]: "Message",
  [ETranslation.COMMENT]: "Comment",
  [ETranslation.ADD_COMMENT]: "Add comment",
};

const testMissionSearchTranslations = {
  [ETranslation.SEARCH_ORDER_BY]: "Order by",
  [ETranslation.SEARCH_FILTER]: "Filter",
  [ETranslation.SEARCH_START_DATE]: "Start date",
  [ETranslation.SEARCH_SPORT]: "Sport",
  [ETranslation.SEARCH_TEST_PLANNER]: "Test planner",
  [ETranslation.SEARCH_STATUS]: "Status",
  [ETranslation.SEARCH_ASSIGNED_FIELD_PERSON]: "Assigned field person",
  [ETranslation.SEARCH_UNFULFILLED_ROLES]: "Unfulfilled roles",
  [ETranslation.SEARCH_OVERDUE]: "Overdue",
  [ETranslation.SEARCH_DISCIPLINE]: "Discipline",
  [ETranslation.SEARCH_TEST_MISSION_ID]: "Test mission Id",
};

const testMissionStatusTranslations = {
  [ETranslation.STATUS_ISSUED]: "Issued",
  [ETranslation.STATUS_MODOC]: "Issued & in Modoc",
  [ETranslation.STATUS_COMPLETE]: "Complete",
  [ETranslation.STATUS_CANCELLED]: "Cancelled",
  [ETranslation.STATUS_PLANNING]: "In Creation-Planning in progress",
};

const genderRequirementTranslations = {
  [ETranslation.GENDER_REQUIREMENT_FEMALE]: "Female",
  [ETranslation.GENDER_REQUIREMENT_MALE]: "Male",
  [ETranslation.GENDER_REQUIREMENT_BOTH]: "Both",
  [ETranslation.GENDER_REQUIREMENT_ANY]: "Any",
};

const fieldPersonsTranslations = {
  [ETranslation.NAME]: "Name",
  [ETranslation.EMAIL]: "Email",
  [ETranslation.MOBILE]: "Mobile",
  [ETranslation.ALLOW_LOGIN]: "Allow login",
  [ETranslation.NO_FIELD_PERSONS]: "No field persons.",
  [ETranslation.MOBILE_PLACEHOLDER]: "+4781512345",
};

const fieldPersonTranslations = {
  [ETranslation.FIRST_NAME]: "First name",
  [ETranslation.LAST_NAME]: "Last name",
  [ETranslation.ADDRESS]: "Address",
  [ETranslation.POSTCODE]: "Postcode",
  [ETranslation.PLACE]: "Place",
  [ETranslation.WORK_TIME]: "Work Time %",
  [ETranslation.GENDER]: "Gender",
  [ETranslation.LEVEL]: "Level",
  [ETranslation.IN_TRAINING]: "In training",
  [ETranslation.NOTIFICATION_EMAIL]: "Notification email",
  [ETranslation.NOTIFICATION_SMS]: "Notification SMS",
  [ETranslation.TEST_SMS]: "Send test SMS",
  [ETranslation.MODOC_USER_ID]: "MODOC user id",
  [ETranslation.VISMA_PAYROLL_PERSONNEL_ID]: "Visma Payroll personnel id",
};

const fieldPersonSearchTranslations = {
  [ETranslation.SEARCH_REGION]: "Region",
  [ETranslation.SEARCH_ROLE]: "Role",
};

const reactSelectTranslations = {
  [ETranslation.REACT_SELECT_PLACEHOLDER]: "Select",
  [ETranslation.REACT_SELECT_ADD_NEW]: "Add",
};

const reactDropzoneTranslations = {
  [ETranslation.REACT_DROPZONE_DRAG_TEXT]: "Click here or drop a file.",
  [ETranslation.REACT_DROPZONE_DRAG_ACTIVE_TEXT]: "Drop file.",
};

const errorTranslations = {
  [ETranslation.NOT_FOUND_ERROR]: "No results.",
  [ETranslation.NO_ACCESS_ERROR]: "No access.",
};

const validationTranslations = {
  [ETranslation.VALIDATION_EMAIL]: "Check email",
  [ETranslation.VALIDATION_MIN_LENGTH]:
    "Input minimum of {{minLength}} characters.",
  [ETranslation.VALIDATION_MAX_LENGTH]:
    "Input maximum {{maxLength}} characters.",
  [ETranslation.VALIDATION_MIN_AMOUNT]:
    "Amount cannot be lesser than {{minAmount}}.",
  [ETranslation.VALIDATION_MAX_AMOUNT]:
    "Amount cannot be greater than {{maxAmount}}.",
};

const roleNameTranslations = {
  [ETranslation.ADVISOR]: "Advisor",
  [ETranslation.ADVISOR_INTERNATIONAL]: "Advisor international",
  [ETranslation.ASSISTANT]: "Assistant",
  [ETranslation.ASSISTANT_TRAINING]: "Assistant in training",
  [ETranslation.BLOOD_COLLECTION_OFFICER]: "Blood Collection Officer",
  [ETranslation.BLOOD_COLLECTION_OFFICER_TRAINING]:
    "Blood Collection Officer in training",
  [ETranslation.DCO]: "DCO",
  [ETranslation.DCO_TRAINING]: "DCO in training",
};

const genderTranslations = {
  [ETranslation.FEMALE]: "Female",
  [ETranslation.MALE]: "Male",
  [ETranslation.OTHER_UNKNOWN]: "Other",
};

const levelTranslations = {
  [ETranslation.LEVEL_FIELD_PERSON]: "Field person",
  [ETranslation.LEVEL_TEST_PLANNER]: "Test planner",
  [ETranslation.LEVEL_REPORTING]: "Reporting",
};

const sportsCategoryTranslations = {
  [ETranslation.AESTHETIC_GROUP_GYMNASTICS]: "Aesthetic Group Gymnastics",
  [ETranslation.AIKIDO]: "Aikido",
  [ETranslation.AIR_SPORTS]: "Air Sports",
  [ETranslation.AMERICAN_FOOTBALL]: "American Football",
  [ETranslation.AQUATICS]: "Aquatics",
  [ETranslation.ARCHERY]: "Archery",
  [ETranslation.ARM_WRESTLING]: "Arm Wrestling",
  [ETranslation.ATHLETICS]: "Athletics",
  [ETranslation.AUSTRALIAN_RULES_FOOTBALL]: "Australian Rules Football",
  [ETranslation.AUTOMOBILE_SPORTS]: "Automobile Sports",
  [ETranslation.BADMINTON]: "Badminton",
  [ETranslation.BALLE_AU_TAMBOURIN]: "Balle au Tambourin",
  [ETranslation.BALLON_AU_POING]: "Ballon au Poing",
  [ETranslation.BANDY]: "Bandy",
  [ETranslation.BASEBALL]: "Baseball",
  [ETranslation.BASKETBALL]: "Basketball",
  [ETranslation.BASQUE_PELOTA]: "Basque Pelota",
  [ETranslation.BASQUE_SPORTS]: "Basque Sports",
  [ETranslation.BEACH_TENNIS]: "Beach Tennis",
  [ETranslation.BIATHLON]: "Biathlon",
  [ETranslation.BILLIARDS_SPORTS]: "Billiards Sports",
  [ETranslation.BOBSLEIGH]: "Bobsleigh",
  [ETranslation.BOCCIA]: "Boccia",
  [ETranslation.BODYBUILDING]: "Bodybuilding",
  [ETranslation.BOOT_THROWING]: "Boot-Throwing",
  [ETranslation.BOULES_SPORTS]: "Boules Sports",
  [ETranslation.BOWLING]: "Bowling",
  [ETranslation.BOWLS]: "Bowls",
  [ETranslation.BOXING]: "Boxing",
  [ETranslation.BRAZILIAN_JIU_JITSU]: "Brazilian Jiu-Jitsu",
  [ETranslation.BRIDGE]: "Bridge",
  [ETranslation.BROOMBALL]: "Broomball",
  [ETranslation.BUDO]: "Budo",
  [ETranslation.CISS_GOLF]: "CISS Golf",
  [ETranslation.CISS_SAILING]: "CISS Sailing",
  [ETranslation.COLEO]: "COLEO",
  [ETranslation.CP_FOOTBALL]: "CP Football",
  [ETranslation.CAMEL_RACING]: "Camel Racing",
  [ETranslation.CANICROSS]: "Canicross",
  [ETranslation.CANOE]: "Canoe",
  [ETranslation.CASTING]: "Casting",
  [ETranslation.CHANBARA]: "Chanbara",
  [ETranslation.CHEER]: "Cheer",
  [ETranslation.CHEERLEADING]: "Cheerleading",
  [ETranslation.CHESS]: "Chess",
  [ETranslation.CHIN_UP]: "Chin-up",
  [ETranslation.CLAY_TARGET_SHOOTING]: "Clay Target Shooting",
  [ETranslation.COLLEGE_SPORTS]: "College Sports",
  [ETranslation.COMBAT_SPORTS]: "Combat Sports",
  [ETranslation.COMPANY_SPORTS]: "Company Sports",
  [ETranslation.COURSE_CAMARGUAISE]: "Course Camarguaise",
  [ETranslation.COURSE_LANDAISE]: "Course Landaise",
  [ETranslation.CRICKET]: "Cricket",
  [ETranslation.CROSSFIT]: "CrossFit",
  [ETranslation.CROSSBOW]: "Crossbow",
  [ETranslation.CROSSWORD]: "Crossword",
  [ETranslation.CURLING]: "Curling",
  [ETranslation.CYCLING]: "Cycling",
  [ETranslation.DANCESPORT]: "DanceSport",
  [ETranslation.DARTS]: "Darts",
  [ETranslation.DET_FRIVILLIGE_SKYTTERVESEN]: "Det Frivillige Skyttervesen",
  [ETranslation.DODGEBALL]: "Dodgeball",
  [ETranslation.DOMIMO]: "Domimó",
  [ETranslation.DRAGON_BOAT]: "Dragon Boat",
  [ETranslation.DRAGON_AND_LION_DANCE]: "Dragon and Lion Dance",
  [ETranslation.DRAUGHTS]: "Draughts",
  [ETranslation.ELECTRONIC_SPORTS]: "Electronic Sports",
  [ETranslation.EQUESTRIAN]: "Equestrian",
  [ETranslation.EXTREME_SPORTS]: "Extreme Sports",
  [ETranslation.FIFA_ESPORTS]: "FIFA eSports",
  [ETranslation.FENCING]: "Fencing",
  [ETranslation.FIELD_HOCKEY]: "Field Hockey",
  [ETranslation.FIREFIGHTING_AND_RESCUING]: "Firefighting and Rescuing",
  [ETranslation.FISTBALL]: "Fistball",
  [ETranslation.FITNESS]: "Fitness",
  [ETranslation.FLOORBALL]: "Floorball",
  [ETranslation.FLYING_DISC]: "Flying Disc",
  [ETranslation.FOOT_VOLLEY]: "Foot Volley",
  [ETranslation.FOOTBALL]: "Football",
  [ETranslation.FOOTBALL_7_A_SIDE]: "Football 7-a-Side",
  [ETranslation.FOOTBALL_IBSA_FOOTBALL]: "Football - IBSA Football",
  [ETranslation.FRENCH_BOXING]: "French Boxing",
  [ETranslation.FRISKIS_SVETTIS]: "Friskis & Svettis",
  [ETranslation.FULL_CONTACT]: "Full contact",
  [ETranslation.FUNCTIONAL_FITNESS]: "Functional Fitness",
  [ETranslation.GAELIC_GAMES]: "Gaelic Games",
  [ETranslation.GIGATHLON]: "Gigathlon",
  [ETranslation.GIRA]: "Gira",
  [ETranslation.GLORY_KICKBOXING]: "Glory Kickboxing",
  [ETranslation.GO]: "Go",
  [ETranslation.GOALBALL]: "Goalball",
  [ETranslation.GOLF]: "Golf",
  [ETranslation.GYMNASTICS]: "Gymnastics",
  [ETranslation.HANDBALL]: "Handball",
  [ETranslation.HEAVY_ATHLETICS]: "Heavy Athletics",
  [ETranslation.HEAVY_EVENTS]: "Heavy Events",
  [ETranslation.HIGHLAND_GAMES]: "Highland Games",
  [ETranslation.HORNUSSEN]: "Hornussen",
  [ETranslation.HORSE_RACING]: "Horse Racing",
  [ETranslation.HUNTING_SPORTS]: "Hunting sports",
  [ETranslation.ICE_HOCKEY]: "Ice Hockey",
  [ETranslation.ICESTOCKSPORT]: "Icestocksport",
  [ETranslation.JET_SKI]: "Jet-Ski",
  [ETranslation.JIU_JITSU]: "Jiu-Jitsu",
  [ETranslation.JU_JITSU]: "Ju-Jitsu",
  [ETranslation.JUDO]: "Judo",
  [ETranslation.JUMP_ROPE]: "Jump Rope",
  [ETranslation.KAATSEN]: "Kaatsen",
  [ETranslation.KABADDI]: "Kabaddi",
  [ETranslation.KARATE]: "Karate",
  [ETranslation.KEMPO]: "Kempo",
  [ETranslation.KENDO]: "Kendo",
  [ETranslation.KETTLEBELL_LIFTING]: "Kettlebell Lifting",
  [ETranslation.KHO_KHO]: "Kho Kho",
  [ETranslation.KICKBOXING]: "Kickboxing",
  [ETranslation.KIN_BALL]: "Kin-Ball",
  [ETranslation.KORFBALL]: "Korfball",
  [ETranslation.KRACHTBAL]: "Krachtbal",
  [ETranslation.KUDO]: "Kudo",
  [ETranslation.KUNG_FU]: "Kung Fu",
  [ETranslation.KURASH]: "Kurash",
  [ETranslation.KYUDO]: "Kyudo",
  [ETranslation.LACROSSE]: "Lacrosse",
  [ETranslation.LEONES_BOWLS]: "Leones Bowls",
  [ETranslation.LIFESAVING]: "Lifesaving",
  [ETranslation.LONGUE_PAUME]: "Longue Paume",
  [ETranslation.LUGE]: "Luge",
  [ETranslation.MODOC_TRAINING_SPORT]: "MODOC Training Sport",
  [ETranslation.MILITARY_PATROL]: "Military Patrol",
  [ETranslation.MILITARY_SPORT_PENTATHLON]: "Military Sport Pentathlon",
  [ETranslation.MINIGOLF]: "Minigolf",
  [ETranslation.MIXED_MARTIAL_ARTS]: "Mixed Martial Arts",
  [ETranslation.MODERN_PENTATHLON]: "Modern Pentathlon",
  [ETranslation.MOTOBALL]: "Motoball",
  [ETranslation.MOTORCYCLE_RACING]: "Motorcycle Racing",
  [ETranslation.MOUNTAINEERING_AND_CLIMBING]: "Mountaineering and Climbing",
  [ETranslation.MUAYTHAI]: "Muaythai",
  [ETranslation.MULTIFUNCTIONAL_TRAINING]: "Multifunctional Training",
  [ETranslation.NAGINATA]: "Naginata",
  [ETranslation.NETBALL]: "Netball",
  [ETranslation.NORGES_BILSPORTFORBUND]: "Norges Bilsportforbund",
  [ETranslation.NORGES_RYTTERFORBUN]: "Norges Rytterforbun",
  [ETranslation.NORSK_GALOPP]: "Norsk Galopp",
  [ETranslation.NORSKE_TRAVSELSKAP]: "Norske Travselskap",
  [ETranslation.OBSTACLE_SPORTS]: "Obstacle Sports",
  [ETranslation.ORIENTEERING]: "Orienteering",
  [ETranslation.PADEL]: "Padel",
  [ETranslation.PARA_ALPINE_SKIING]: "Para-Alpine Skiing",
  [ETranslation.PARA_ATHLETICS]: "Para-Athletics",
  [ETranslation.PARA_BIATHLON]: "Para-Biathlon",
  [ETranslation.PARA_BOWLING]: "Para-Bowling",
  [ETranslation.PARA_BOWLS]: "Para-Bowls",
  [ETranslation.PARA_CROSS_COUNTRY_SKIING]: "Para-Cross Country Skiing",
  [ETranslation.PARA_DANCESPORT]: "Para-DanceSport",
  [ETranslation.PARA_GOLF]: "Para-Golf",
  [ETranslation.PARA_ICE_HOCKEY]: "Para-Ice Hockey",
  [ETranslation.PARA_NORDIC_SKIING]: "Para-Nordic Skiing",
  [ETranslation.PARA_POWERLIFTING]: "Para-Powerlifting",
  [ETranslation.PARA_SNOWBOARD]: "Para-Snowboard",
  [ETranslation.PARA_SWIMMING]: "Para-Swimming",
  [ETranslation.PARAVOLLEY]: "ParaVolley",
  [ETranslation.PENCAK_SILAT]: "Pencak Silat",
  [ETranslation.POKER]: "Poker",
  [ETranslation.POLE_SPORTS]: "Pole sports",
  [ETranslation.POLO]: "Polo",
  [ETranslation.POWER_PRESS]: "Power Press",
  [ETranslation.POWERBOATING]: "Powerboating",
  [ETranslation.POWERLIFTING]: "Powerlifting",
  [ETranslation.PRACTICAL_SHOOTING]: "Practical Shooting",
  [ETranslation.QAZAQ_KURESI]: "Qazaq Kuresi",
  [ETranslation.RACQUETBALL]: "Racquetball",
  [ETranslation.RAFTING]: "Rafting",
  [ETranslation.RINGETTE]: "Ringette",
  [ETranslation.RINKBALL]: "Rinkball",
  [ETranslation.ROLLER_SPORTS]: "Roller Sports",
  [ETranslation.ROWING]: "Rowing",
  [ETranslation.RUGBY_LEAGUE]: "Rugby League",
  [ETranslation.RUGBY_UNION]: "Rugby Union",
  [ETranslation.SAILING]: "Sailing",
  [ETranslation.SAMBO]: "Sambo",
  [ETranslation.SAVATE]: "Savate",
  [ETranslation.SCHOOL_SPORTS]: "School Sports",
  [ETranslation.SEPAKTAKRAW]: "Sepaktakraw",
  [ETranslation.SHEARING_SPORTS]: "Shearing Sports",
  [ETranslation.SHOE_RACING]: "Shoe Racing",
  [ETranslation.SHOOTING]: "Shooting",
  [ETranslation.SHOOTING_PARA_SPORT]: "Shooting Para Sport",
  [ETranslation.SHOWDOWN]: "Showdown",
  [ETranslation.SHUTTLECOCK]: "Shuttlecock",
  [ETranslation.SITBALL]: "Sitball",
  [ETranslation.SITTING_VOLLEYBALL]: "Sitting Volleyball",
  [ETranslation.SKATING]: "Skating",
  [ETranslation.SKI_MOUNTAINEERING]: "Ski Mountaineering",
  [ETranslation.SKIBOB]: "Skibob",
  [ETranslation.SKIING]: "Skiing",
  [ETranslation.SKYBALL]: "Skyball",
  [ETranslation.SKYRUNNING]: "Skyrunning",
  [ETranslation.SLEDDOG]: "Sleddog",
  [ETranslation.SNOWMOBILE]: "Snowmobile",
  [ETranslation.SOFT_TENNIS]: "Soft Tennis",
  [ETranslation.SOFTBALL]: "Softball",
  [ETranslation.SPEEDBALL]: "Speedball",
  [ETranslation.SPELEOLOGY]: "Speleology",
  [ETranslation.SPORT_CLIMBING]: "Sport Climbing",
  [ETranslation.SPORT_FISHING]: "Sport Fishing",
  [ETranslation.SQUASH]: "Squash",
  [ETranslation.STILTS_RACING]: "Stilts Racing",
  [ETranslation.STREET_AND_BALL_HOCKEY]: "Street and Ball Hockey",
  [ETranslation.STRONGEST_MAN]: "Strongest Man",
  [ETranslation.SUMO]: "Sumo",
  [ETranslation.SURFING]: "Surfing",
  [ETranslation.SWISS_NATIONAL_GYMNASTICS]: "Swiss National Gymnastics",
  [ETranslation.TABLE_SOCCER]: "Table Soccer",
  [ETranslation.TABLE_TENNIS]: "Table Tennis",
  [ETranslation.TAEKWONDO]: "Taekwondo",
  [ETranslation.TAEKWONDO_ITF_AUSTRIA]: "Taekwondo ITF-Austria",
  [ETranslation.TAIDO]: "Taido",
  [ETranslation.TARUNG_DERAJAT]: "Tarung Derajat",
  [ETranslation.TCHOUKBALL]: "Tchoukball",
  [ETranslation.TENNIS]: "Tennis",
  [ETranslation.TEQBALL]: "Teqball",
  [ETranslation.TEST_SPORT]: "Test Sport",
  [ETranslation.THAI_TRADITIONAL_SPORTS]: "Thai Traditional Sports",
  [ETranslation.THE_NORWEGIAN_POLICE_ACADEMY]: "The Norwegian Police academy",
  [ETranslation.TOUCH_FOOTBALL]: "Touch Football",
  [ETranslation.TRADITIONAL_ARCHERY]: "Traditional Archery",
  [ETranslation.TRADITIONAL_KARATE]: "Traditional Karate",
  [ETranslation.TRADITIONAL_MARTIAL_ARTS]: "Traditional Martial Arts",
  [ETranslation.TRAPEZE]: "Trapeze",
  [ETranslation.TRIATHLON]: "Triathlon",
  [ETranslation.TUG_OF_WAR]: "Tug of War",
  [ETranslation.TWIRLING]: "Twirling",
  [ETranslation.ULTIMATE_FIGHTING]: "Ultimate Fighting",
  [ETranslation.ULTRA_TRIATHLON]: "Ultra Triathlon",
  [ETranslation.UNDERWATER_SPORTS]: "Underwater Sports",
  [ETranslation.UNICYCLING]: "Unicycling",
  [ETranslation.UNIFIGHT]: "Unifight",
  [ETranslation.VA_A]: "VA'A",
  [ETranslation.VOLLEYBALL]: "Volleyball",
  [ETranslation.VOVINAM]: "Vovinam",
  [ETranslation.WSLEAGUE_SURFING]: "WSLeague - Surfing",
  [ETranslation.WATERSKIING]: "Waterskiing",
  [ETranslation.WEIGHTLIFTING]: "Weightlifting",
  [ETranslation.WHEELCHAIR_SPORTS]: "Wheelchair Sports",
  [ETranslation.WOODBALL]: "Woodball",
  [ETranslation.WOODSMAN]: "Woodsman",
  [ETranslation.WRESTLING]: "Wrestling",
  [ETranslation.WUSHU]: "Wushu",
  [ETranslation.XIANGQI]: "Xiangqi",
  [ETranslation.CROSS_TRAINING_MILITARY_CROSS_TRAINING]: "cross training/military cross training", 
  [ETranslation.PARA_BADMINTON]: "Para-Badminton",
  [ETranslation.PARA_BASKETBALL]: "Para-Basketball",
  [ETranslation.BILSPORT]: "Bilsport",
  [ETranslation.CANOE_KAYAK]: "Canoe / Kayak",
  [ETranslation.PARA_CURLING]: "Para-Curling",
  [ETranslation.DFS]: "DFS",
  [ETranslation.FLERIDRETT]: "Fleridrett",
  [ETranslation.FLY_CASTING]: "Fly casting",
  [ETranslation.GALOPP]: "Galopp",
  [ETranslation.PARA_GYMNASTICS]: "Para-Gymnastics",
  [ETranslation.PARA_JUDO]: "Para-Judo",
  [ETranslation.PHS]: "PHS",
  [ETranslation.POLESPORT]: "Polesport",
  [ETranslation.PARA_SAILING]: "Para-Sailing",
  [ETranslation.PARA_TABLE_TENNIS]: "Para-Table Tennis",
  [ETranslation.TAEKWONDO_NTN]: "Taekwondo NTN",
  [ETranslation.PARA_TENNIS]: "Para-Tennis",
  [ETranslation.TRAV]: "Trav",
  [ETranslation.TRENINGSSENTER]: "Treningssenter",
  [ETranslation.STUDENT_SPORTS]: "Student Sports",
};

const unavailabilitiesTranslations = {
  [ETranslation.INFO]: "Info",
  [ETranslation.USER]: "User",
  [ETranslation.NO_UNAVAILABILITIES]: "No unavailabilities.",
};

const selectTranslations = {
  [ETranslation.SELECT_USER_PLACEHOLDER]: "Choose user",
  [ETranslation.SELECT_ROLE_PLACEHOLDER]: "Choose role",
  [ETranslation.SELECT_REGION_PLACEHOLDER]: "Choose region",
  [ETranslation.SELECT_GENDER_PLACEHOLDER]: "Choose gender",
  [ETranslation.SELECT_SPORT_PLACEHOLDER]: "Choose sport",
  [ETranslation.SELECT_STATUS_PLACEHOLDER]: "Choose status",
  [ETranslation.SELECT_LEVEL_PLACEHOLDER]: "Choose level",
  [ETranslation.SELECT_DISCIPLINE_PLACEHOLDER]: "Choose discipline",
};

const importTranslations = {
  [ETranslation.IMPORT_HISTORY]: "Import history",
  [ETranslation.PROCESSED]: "processed",
  [ETranslation.IMPORT_TEST_MISSIONS]: "Import test missions",
  [ETranslation.IMPORT_PARTICIPANTS]: "Import participants",
  [ETranslation.IMPORT_START_AUTOMATCH]: "Start automatching",
  [ETranslation.IMPORT_DELETE]: "Delete import and test missions",
};

const confirmTranslations = {
  [ETranslation.CONFIRM_DELETE_UNAVAILABILITY]:
    "Delete unavailability? You will not be able to undo this action.",
  [ETranslation.CONFIRM_DELETE_COMMENT]:
    "Delete selected comment? You will not be able to undo this action.",
  [ETranslation.CONFIRM_TEST_MISSION_ACCEPT]: "Accept test mission?",
  [ETranslation.CONFIRM_TEST_MISSION_REFUSE]:
    "Refuse test mission? You will not be able to undo this action.",
  [ETranslation.CONFIRM_TEST_MISSION_COMPLETE]: "Test mission is completed?",
  [ETranslation.CONFIRM_TEST_MISSION_CANCEL]:
    "Cancel your participation on this test mission? Only use this if you’re unexpectedly not able to complete your part on this test mission.",
  [ETranslation.CONFIRM_TEST_MISSION_DELETE]:
    "Delete selected test mission? You will not be able to undo this action.",
};

const myPageTranslations = {
  [ETranslation.OFFERED_TEST_MISSIONS]: "Offered Test Missions",
  [ETranslation.MY_TEST_MISSIONS]: "My Test Missions",
  [ETranslation.MY_COMPLETED_TEST_MISSIONS]: "My Completed Missions",
  [ETranslation.MARK_COMPLETED]: "Mark completed",
  [ETranslation.SHOW_MORE]: "Show more",
  [ETranslation.SHOW_LESS]: "Show less",
};

const statisticsTranslations = {
  [ETranslation.YEAR]: "Year",
  [ETranslation.ACTION]: "Action",
  [ETranslation.ACTION_TRIGGER]: "Action trigger",
  [ETranslation.COUNT_CREATED]: "Created",
  [ETranslation.COUNT_CANCELED]: "Canceled",
  [ETranslation.COUNT_OFFERED]: "Offered",
  [ETranslation.COUNT_REFUSED]: "Refused",
  [ETranslation.COUNT_ACCEPTED]: "Accepted",
  [ETranslation.COUNT_COMPLETED]: "Completed",
  [ETranslation.COUNT_TOTAL]: "Total",
  [ETranslation.STATISTICS_OFFERED]: "Offered roles",
  [ETranslation.STATISTICS_REFUSED_CANCELED]: "Refused or canceled roles",
  [ETranslation.STATISTICS_COMPLETED]: "Completed test missions",
  [ETranslation.STATISTICS_REFUSED]: "Refused roles",
  [ETranslation.STATISTICS_CANCELED]: "Canceled test missions",
}

const testMissionParticipantsDialogTranslations = {
  [ETranslation.TEST_MISSION_FILL_REQUIRED_FIELDS]:
    "Please fill mandatory fields before selecting field persons.",
  [ETranslation.TEST_MISSION_AUTOMATCH_RECOMMENDED_USERS]:
    "Auto match recommends these users:",
  [ETranslation.TEST_MISSION_AUTOMATCH_FOUND_NO_USERS]:
    "No available field persons found in this region.",
  [ETranslation.TEST_MISSION_PARTICIPANT_ACCEPT]:
    "Mark accepted on behalf of the participant? The participant will be notified.",
};

const testMissionSaveDialogTranslations = {
  [ETranslation.TEST_MISSION_SAVE]:
    "Save new test mission",
};

const multiFactorAuthDialogTranslations = {
  [ETranslation.MULTI_FACTOR_AUTH_DIALOG_TITLE]: "Enter code",
  [ETranslation.MULTI_FACTOR_AUTH_DIALOG_CODE]: "You will receive the code by SMS shortly.",
  [ETranslation.MULTI_FACTOR_AUTH_DIALOG_CODE_PLACEHOLDER]: "The code is 6 digits long",
}

const firebaseErrorTranslations = {
  [ETranslation.FIREBASE_INVALID_EMAIL]: "Invalid email.",
  [ETranslation.FIREBASE_INVALID_PASSWORD]: "Invalid password.",
  [ETranslation.FIREBASE_INVALID_CODE]: "Invalid code.",
  [ETranslation.FIREBASE_TOO_MANY_REQUESTS]: "Login failed.",
  [ETranslation.FIREBASE_USER_DISABLED]: "Unauthorized"
}

const participantStatusTranslations = {
  [ETranslation.PARTICIPANT_STATUS_COMPLETED]: "Completed",
  [ETranslation.PARTICIPANT_STATUS_ACCEPTED]: "Accepted",
  [ETranslation.PARTICIPANT_STATUS_PENDING]: "Pending",
  [ETranslation.PARTICIPANT_STATUS_REFUSED]: "Refused",
}

const notificationTranslations = {
  [ETranslation.NOTIFICATIONS]: "Notifications",
  [ETranslation.NO_NOTIFICATIONS]: "No new notifications",
  [ETranslation.READ_ALL_NOTIFICATIONS]: "Mark all as read"
}

const workLogTranslations = {
  [ETranslation.WORKLOG]: "Work log",
  [ETranslation.START_HOURS]: "Start hours",
  [ETranslation.START_MINUTES]: "Start minutes",
  [ETranslation.END_HOURS]: "End hours",
  [ETranslation.END_MINUTES]: "End minutes",
  [ETranslation.KILOMETERS]: "Kilometers",
  [ETranslation.PASSENGERS]: "Passengers",

  [ETranslation.SAVE_WORKLOG]: "Save work log",
  [ETranslation.WORKLOG_ACCEPT]: "Accept",
  [ETranslation.WORKLOG_RETURN]: "Reject",
  [ETranslation.MARK_WORKLOG_PENDING]: "Send for approval",
  [ETranslation.WORKLOG_ACCEPT_ALL]: "Accept all",

  [ETranslation.WORKLOG_CONFIRM_ACCEPT]: "Are you sure that you want to accept this compensation?",
  [ETranslation.WORKLOG_CONFIRM_RETURN]: "Are you sure that you want to reject this compensation?",
  [ETranslation.WORKLOG_CONFIRM_MARK_PENDING]: "Are you sure that you want to send this compensation for approval?",
  
  [ETranslation.WORKLOG_EXPORT_CSV]: "Export CSV",
  [ETranslation.WORKLOG_EXPORT_VISMA]: "Export Visma Payroll",

  [ETranslation.WORKLOG_SUMMARY]: "Summary",
  [ETranslation.WORK_HOURS]: "Work hours",
  [ETranslation.MILEAGE_ALLOWANCE]: "Mileage allowance",
  [ETranslation.EXPENSES_TOTAL]: "Expenses total",
  [ETranslation.OVERTIME_EXTRA]: "Overtime extra",
  [ETranslation.MIDWEEK_HOLIDAY_EXTRA]: "Midweek holiday extra",
  [ETranslation.WORKLOG_STATUS]: "Compensation status",
  [ETranslation.PLANNING]: "Planning (+1h)",
  [ETranslation.RESPONSIBILITY]: "Responsibility (+2h)", 

  [ETranslation.PERCENTAGE]: "Percentage",
  [ETranslation.EXPORT_DATE]: "Export date",
  [ETranslation.EXPORTED]: "Exported",
  [ETranslation.LINKED_WORK_LOGS]: "Linked compensations",
  [ETranslation.WORKLOGS_PERCENTAGE_ERROR]: "Percentages must total 100",
  [ETranslation.WORKLOGS_VISMA_ID_ERROR]: "Visma Payroll personnel id missing",
}

const workLogExpensesTranslations = {
  [ETranslation.EXPENSES]: "Expenses",
  [ETranslation.DESCRIPTION]: "Description",
  [ETranslation.AMOUNT]: "Amount (NOK)",
  [ETranslation.ATTACHMENTS]: "Attachments",

  [ETranslation.SAVE_EXPENSE]: "Save expense",
  [ETranslation.DELETE_EXPENSE]: "Delete expense",
  [ETranslation.DELETE_EXPENSE_CONFIRM_TEXT]: "Are you sure that you want to delete this expense?",
  [ETranslation.DELETE_EXPENSE_ATTACHMENT_CONFIRM_TEXT]: "Are you sure that you want to delete this attachment?",
  [ETranslation.NO_ATTACHMENTS]: "no receipt",
}

const testMissionViewTranslations = {
  [ETranslation.TEAM]: "Team",
}

const modocSyncTranslations = {
  [ETranslation.MODOC_SYNC_TITLE]: "Synchronize from MODOC",
  [ETranslation.MODOC_SYNC_TEXT]: "Are you sure you want to start MODOC synchronization?",
  [ETranslation.MODOC_SYNC_SUCCESS_TEXT]: "Successfully started synchronizing test missions from MODOC.",
  [ETranslation.MODOC_SYNC_BUFFER_NOTIFICATIONS]: "Buffer notifications",
}

const translationsEn = {
  ...commonTranslations,
  ...authTranslations,
  ...headerTranslations,
  ...testMissionsTranslations,
  ...testMissionTranslations,
  ...testMissionParticipantTranslations,
  ...testMissionCommentsTranslations,
  ...testMissionSearchTranslations,
  ...testMissionStatusTranslations,
  ...genderRequirementTranslations,
  ...fieldPersonsTranslations,
  ...fieldPersonTranslations,
  ...fieldPersonSearchTranslations,
  ...reactSelectTranslations,
  ...reactDropzoneTranslations,
  ...errorTranslations,
  ...validationTranslations,
  ...roleNameTranslations,
  ...genderTranslations,
  ...levelTranslations,
  ...sportsCategoryTranslations,
  ...unavailabilitiesTranslations,
  ...selectTranslations,
  ...importTranslations,
  ...confirmTranslations,
  ...myPageTranslations,
  ...statisticsTranslations,
  ...testMissionParticipantsDialogTranslations,
  ...testMissionSaveDialogTranslations,
  ...multiFactorAuthDialogTranslations,
  ...firebaseErrorTranslations,
  ...participantStatusTranslations,
  ...notificationTranslations,
  ...workLogTranslations,
  ...workLogExpensesTranslations,
  ...testMissionViewTranslations,
  ...modocSyncTranslations,
};

export default translationsEn;
