import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { useConfirmModal } from "../../../../../hooks/useConfirmModal";
import { useCreateInput } from "../../../../../hooks/useCreateInput";
import { useWorkLog } from "../../../../../hooks/useWorkLog";
import { IAttachment } from "../../../../../interfaces/domain/IAttachment";
import { IWorkLogExpense } from "../../../../../interfaces/domain/IWorkLog";
import { getWorkLogExpenseAttachment } from "../../../../../shared/attachment";
import { ETranslation } from "../../../../../translations/translation-keys";
import Button, { EButtonColor } from "../../../../UI/Button/Button";
import { EInputType, IInputField } from "../../../../UI/Input/Input";
import { getInputData, initForm } from "../../../../UI/Input/input-utils";
import InputGroup from "../../../../UI/InputGroup/InputGroup";
import classes from "./WorkLogExpense.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

enum EWorkLogExpense {
  description = "description",
  amount = "amount",
  attachments = "attachments",
}

interface IProps {
  expense: IWorkLogExpense;
}

const WorkLogExpense: React.FC<IProps> = ({ expense }) => {
  const {
    uploadAttachmentsHandler,
    updateWorkLogExpenseHandler,
    deleteWorkLogExpenseHandler,
    disableInputs,
    workLog,
    deleteWorkLogExpenseAttachmentHandler,
  } = useWorkLog();
  const {
    uploadLoadingId,
    updateLoadingId,
    deleteLoadingId,
    attachmentDeleteLoadingId,
  } = useAppSelector((state) => state.workLog);
  const { t } = useTranslation();
  const openConfirmDeleteModal = useConfirmModal();

  const [inputs, setInputs] = useState<IInputField>({
    [EWorkLogExpense.description]: {
      type: EInputType.text,
      labelTranslation: ETranslation.DESCRIPTION,
      placeholderTranslation: ETranslation.DESCRIPTION,
      value: "",
    },
    [EWorkLogExpense.amount]: {
      type: EInputType.number,
      labelTranslation: ETranslation.AMOUNT,
      placeholderTranslation: ETranslation.AMOUNT,
      value: "",
    },
    [EWorkLogExpense.attachments]: {
      type: EInputType.dropzone,
      labelTranslation: ETranslation.ATTACHMENTS,
      placeholderTranslation: ETranslation.ATTACHMENTS,
      value: "",
    },
  });

  useEffect(() => {
    if (expense) {
      initForm(setInputs, expense);
    }
  }, [expense]);

  const onDrop = useCallback(
    (_: string, acceptedFiles: File[]) => {
      console.log(acceptedFiles);
      if (acceptedFiles && acceptedFiles.length > 0) {
        uploadAttachmentsHandler(acceptedFiles, expense.id);
      }
    },
    [uploadAttachmentsHandler, expense.id]
  );

  const updateHandler = useCallback(() => {
    const data = getInputData<IWorkLogExpense>(inputs);
    updateWorkLogExpenseHandler(expense.id, data);
  }, [inputs, updateWorkLogExpenseHandler, expense.id]);

  const deleteHandler = useCallback(async () => {
    const success = await openConfirmDeleteModal(
      t(ETranslation.DELETE_EXPENSE_CONFIRM_TEXT)
    );
    if (success) {
      deleteWorkLogExpenseHandler(expense.id);
    }
  }, [deleteWorkLogExpenseHandler, expense.id, openConfirmDeleteModal, t]);

  const isUploadLoading = useMemo(
    () => uploadLoadingId === expense.id,
    [uploadLoadingId, expense.id]
  );

  const isUpdateLoading = useMemo(
    () => updateLoadingId === expense.id,
    [updateLoadingId, expense.id]
  );

  const isDeleteLoading = useMemo(
    () => deleteLoadingId === expense.id,
    [deleteLoadingId, expense.id]
  );

  const isLoading = useMemo(
    () => isUploadLoading || isUpdateLoading || isDeleteLoading,
    [isUploadLoading, isUpdateLoading, isDeleteLoading]
  );

  const createInput = useCreateInput(inputs, setInputs, {
    disabled: isLoading || disableInputs,
  });

  const getAttachmentHandler = useCallback(
    (attachment: IAttachment) => {
      getWorkLogExpenseAttachment(attachment.clientName, {
        id: workLog?.id,
        expenseId: expense.id,
        attachmentId: attachment.id,
      });
    },
    [workLog?.id, expense.id]
  );

  const deleteAttachmentHandler = useCallback(
    async (attachmentId: string) => {
      const success = await openConfirmDeleteModal(
        t(ETranslation.DELETE_EXPENSE_ATTACHMENT_CONFIRM_TEXT)
      );
      if (success) {
        deleteWorkLogExpenseAttachmentHandler(expense.id, attachmentId);
      }
    },
    [
      deleteWorkLogExpenseAttachmentHandler,
      expense.id,
      openConfirmDeleteModal,
      t,
    ]
  );

  return (
    <div style={{ wordBreak: "break-word", padding: "1rem 0" }} onClick={(e) => e.stopPropagation()}>
      <InputGroup>
        {createInput(EWorkLogExpense.description)}
        {createInput(EWorkLogExpense.amount)}
      </InputGroup>
      {!disableInputs &&
        createInput(EWorkLogExpense.attachments, {
          onDrop,
          loading: isUploadLoading,
          accept: {
            "image/jpeg": [],
            "image/png": [],
            "application/pdf": [],
          },
        })}
      {expense.attachments && (
        <div className={classes.Attachments}>
          {expense.attachments.map((attachment) => (
            <InputGroup key={attachment.id}>
              <Button onClick={() => getAttachmentHandler(attachment)} link>
                {attachment.clientName}
              </Button>
              {!disableInputs && (
                <Button
                  onClick={() => deleteAttachmentHandler(attachment.id)}
                  color={EButtonColor.NONE}
                  loading={attachmentDeleteLoadingId === attachment.id}
                  disabled={attachmentDeleteLoadingId === attachment.id}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </InputGroup>
          ))}
        </div>
      )}
      {!disableInputs && (
        <InputGroup>
          <Button
            onClick={updateHandler}
            loading={isUpdateLoading}
            disabled={isLoading}
          >
            {t(ETranslation.SAVE_EXPENSE)}
          </Button>
          <Button
            onClick={deleteHandler}
            loading={isDeleteLoading}
            disabled={isLoading}
            color={EButtonColor.DANGER}
          >
            {t(ETranslation.DELETE_EXPENSE)}
          </Button>
        </InputGroup>
      )}
    </div>
  );
};

export default WorkLogExpense;
