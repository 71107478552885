import ILogItemAction from "../../interfaces/action/ILogItemAction";
import ILogItemState from "../../interfaces/state/ILogItemState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = ILogItemState;
type ReducerSignature = (
  state: ReducerState,
  action: ILogItemAction
) => ReducerState;

const initialState: ReducerState = {
  logItems: null,
  count: null,
  countLoading: false,
  reports: null,
  reportsLoading: false,
  loading: false,
  error: null,
};

const incrementCount: ReducerSignature = (state, action): ReducerState => {
  let count = null;
  if (state.count && action.incrementCount) {
    count = {
      ...state.count,
      [action.incrementCount]: state.count[action.incrementCount] + 1,
    };
  }

  return { ...state, count };
};

const listTestMissionLogItemsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const listTestMissionLogItemsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    logItems: action.logItems || null,
    error: null,
    loading: false,
  };
};

const listTestMissionLogItemsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    logItems: null,
    loading: false,
    error: action.error,
  };
};

const getTestMissionCountStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, countLoading: true };
};

const getTestMissionCountSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    count: action.count || null,
    error: null,
    countLoading: false,
  };
};

const getTestMissionCountFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    count: null,
    countLoading: false,
    error: action.error,
  };
};

const getReportsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, reportsLoading: true };
};

const getReportsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    reports: action.reports || null,
    error: null,
    reportsLoading: false,
  };
};

const getReportsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    reports: null,
    reportsLoading: false,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: ILogItemAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.LOG_ITEM_INCREMENT_COUNT:
      return incrementCount(state, action);
    case EActionTypes.LOG_ITEM_TEST_MISSION_LIST_START:
      return listTestMissionLogItemsStart(state, action);
    case EActionTypes.LOG_ITEM_TEST_MISSION_LIST_SUCCESS:
      return listTestMissionLogItemsSuccess(state, action);
    case EActionTypes.LOG_ITEM_TEST_MISSION_LIST_FAIL:
      return listTestMissionLogItemsFail(state, action);
    case EActionTypes.LOG_ITEM_TEST_MISSION_COUNT_START:
      return getTestMissionCountStart(state, action);
    case EActionTypes.LOG_ITEM_TEST_MISSION_COUNT_SUCCESS:
      return getTestMissionCountSuccess(state, action);
    case EActionTypes.LOG_ITEM_TEST_MISSION_COUNT_FAIL:
      return getTestMissionCountFail(state, action);
    case EActionTypes.LOG_ITEM_GET_REPORTS_START:
      return getReportsStart(state, action);
    case EActionTypes.LOG_ITEM_GET_REPORTS_SUCCESS:
      return getReportsSuccess(state, action);
    case EActionTypes.LOG_ITEM_GET_REPORTS_FAIL:
      return getReportsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
