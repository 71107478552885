import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ModalContext, { EModalSize } from "../../../../../context/ModalContext";
import { useAuthUser } from "../../../../../hooks/useAuthUser";
import { IComment } from "../../../../../interfaces/domain/IComment";
import { ETranslation } from "../../../../../translations/translation-keys";
import Button, { } from "../../../../UI/Button/Button";
import ConfirmDialog from "../../../../UI/ConfirmDialog/ConfirmDialog";
import classes from './TestMissionComment.module.scss';

export enum ETestMissionComment {
  message = "message",
}

interface IProps {
  comment: IComment;
  onDelete: () => void;
}

const TestMissionComment: React.FC<IProps> = ({ comment, onDelete }) => {
  const { message, createdBy, createdDateOffset } = comment;
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const { setModal, closeModal } = useContext(ModalContext);

  const deleteHandler = () => {
    onDelete();
    closeModal();
  }

  const showDeleteDialog = () => setModal({
    isOpen: true,
    title: t(ETranslation.COMMON_DELETE),
    size: EModalSize.SMALL,
    content: (
      <ConfirmDialog
        onCancel={closeModal}
        onConfirm={() => deleteHandler()}
        body={<p>{t(ETranslation.CONFIRM_DELETE_COMMENT)}</p>}
      />
    ),
  });

  return (
    <div style={{ marginTop: '1rem' }}>
      <div>{createdDateOffset} {createdBy?.name}{" "}
        {(authUser?.isTestPlanner || authUser?.id.toString() === createdBy?.id) && (
          <Button link onClick={showDeleteDialog}
            //testplanner can delete anything, fieldperson can only with same id as createdBy
            disabled={!authUser?.isTestPlanner && authUser?.id.toString() !== createdBy?.id}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        )}
      </div>
      <div className={classes.TestMissionComment}>{message}</div>
    </div>
  )
}

export default TestMissionComment;
