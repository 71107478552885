import React, { useEffect, useState } from "react";
import { IWorkLogPassenger } from "../../../../interfaces/domain/IWorkLog";
import { EInputType, IInputField } from "../../../UI/Input/Input";
import { ETranslation } from "../../../../translations/translation-keys";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { initForm } from "../../../UI/Input/input-utils";
import Button, { EButtonColor, EButtonSize } from "../../../UI/Button/Button";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { EInputUpdateAction } from "../../../../context/InputContext";
import { useWorkLog } from "../../../../hooks/useWorkLog";

enum EInputs {
  name = "name",
  kilometers = "kilometers",
}

interface IProps {
  passenger: IWorkLogPassenger;
  onDelete: () => void;
}

const WorkLogPassenger: React.FC<IProps> = ({ passenger, onDelete }) => {
  const { disableInputs } = useWorkLog();
  const { createInput } = usePassengerInputs(passenger, disableInputs);

  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      {createInput(EInputs.name)}
      {createInput(EInputs.kilometers)}
      {!disableInputs && (
        <div>
          <Button
            onClick={onDelete}
            icon={faTrash}
            size={EButtonSize.SMALL}
            color={EButtonColor.DANGER}
          />
        </div>
      )}
    </div>
  );
};

const usePassengerInputs = (data?: IWorkLogPassenger, disabled?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      labelTranslation: ETranslation.NAME,
      placeholderTranslation: ETranslation.NAME,
      value: "",
    },
    [EInputs.kilometers]: {
      type: EInputType.number,
      labelTranslation: ETranslation.KILOMETERS,
      placeholderTranslation: ETranslation.KILOMETERS,
      value: "",
      min: "0",
    },
  });

  const createInput = useCreateInput(inputs, setInputs, {
    updateAction: EInputUpdateAction.WORKLOG_PASSENGER,
    data,
    disabled,
  });

  useEffect(() => {
    // console.log(data);
    if (data) {
      initForm(setInputs, data);
    }
  }, [data]);

  return { createInput };
};

export default WorkLogPassenger;
