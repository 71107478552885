import React, { CSSProperties, ReactNode } from "react";
import classes from "./Alert.module.scss";

export enum EAlertColor {
  DANGER,
  SUCCESS,
  PRIMARY,
  WARNING,
  SECONDARY,
  TERTIARY,
}

interface IProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  noHorizontalMargin?: boolean;
  color?: EAlertColor;
  label?: boolean;
}

const Alert: React.FC<IProps> = ({
  children,
  className,
  style,
  noHorizontalMargin,
  color,
  label,
}) => {
  const classNames = [classes.Container];
  if (className) {
    classNames.push(className);
  }

  switch (color) {
    case EAlertColor.PRIMARY:
      classNames.push(classes.Primary);
      break;
    case EAlertColor.SUCCESS:
      classNames.push(classes.Success);
      break;
    case EAlertColor.WARNING:
      classNames.push(classes.Warning);
      break;
    case EAlertColor.SECONDARY:
      classNames.push(classes.Secondary);
      break;
    case EAlertColor.TERTIARY:
      classNames.push(classes.Tertiary);
      break;
    default:
      classNames.push(classes.Danger);
      break;
  }

  if (noHorizontalMargin) {
    if (!style) style = {};
    style = {
      ...style,
      marginLeft: 0,
      marginRight: 0,
    };
  }

  if (label) classNames.push(classes.Label);
  return (
    <div style={style} className={classNames.join(" ")}>
      {children}
    </div>
  );
};

export default Alert;
