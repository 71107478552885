import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { IComment } from "../../../../interfaces/domain/IComment";
import { ETranslation } from "../../../../translations/translation-keys";
import Button, { EButtonColor } from "../../../UI/Button/Button";
import { EInputType, IInputField, TInputValue } from "../../../UI/Input/Input";
import { getInputData, updateInputHandler } from "../../../UI/Input/input-utils";
import InputGroup from "../../../UI/InputGroup/InputGroup";
import TestMissionComment, { ETestMissionComment } from "./TestMissionComment/TestMissionComment";

interface IProps {
  comments: IComment[];
  onAdd: (value: TInputValue) => void;
  onDelete: (id: string) => void;
}

const TestMissionComments: React.FC<IProps> = ({ comments, onAdd, onDelete }) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    [ETestMissionComment.message]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.ADD_COMMENT,
      placeholderTranslation: ETranslation.COMMENT,
      value: "",
    },
  });

  const saveHandler = () => {
    const data = getInputData<IComment>(inputs);
    onAdd(data.message);
    updateInputHandler(ETestMissionComment.message, "", setInputs);
  }

  const createInput = useCreateInput(inputs, setInputs, {});

  return (
    <div style={{ marginBottom: "1rem", marginTop: "3rem" }}>
      
      <label>
        {t(ETranslation.COMMENTS)}
      </label>
      {comments?.map((comment, index) => (
        <TestMissionComment
          key={index}
          comment={comment}
          onDelete={() => onDelete(comment.id)}
        />
      ))}
      <InputGroup style={{ marginTop: "1rem" }}>
        {createInput(ETestMissionComment.message)}
        <Button color={EButtonColor.SUCCESS} onClick={saveHandler}>
            {t(ETranslation.COMMON_ADD)}
        </Button>
      </InputGroup>
    </div>
  );
}

export default TestMissionComments;
