import ITestMissionAction from "../../interfaces/action/ITestMissionAction";
import ITestMissionState from "../../interfaces/state/ITestMissionState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = ITestMissionState;
type ReducerSignature = (
  state: ReducerState,
  action: ITestMissionAction
) => ReducerState;

const initialState: ReducerState = {
  testMission: null,
  testMissions: null,
  id: null,
  loading: false,
  error: null,
  paginationCursor: null,
  hasMoreTestMissions: false,
};

const listTestMissionsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const listTestMissionsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  const testMissions = action.testMissions
    ? [...(state.testMissions ?? []), ...action.testMissions]
    : null;

  return {
    ...state,
    testMissions,
    error: null,
    loading: false,
    paginationCursor: action.paginationCursor || null,
    hasMoreTestMissions: !!action.hasMoreTestMissions,
  };
};

const listTestMissionsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    testMissions: null,
    loading: false,
    error: action.error,
    paginationCursor: null,
    hasMoreTestMissions: false,
  };
};

const clearTestMissions: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    testMissions: null,
    error: null,
    loading: false,
    paginationCursor: null,
    hasMoreTestMissions: false,
  };
};

const getTestMissionStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, id: null, error: null, loading: true };
};

const getTestMissionSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    testMission: action.testMission || null,
    error: null,
    loading: false,
  };
};

const getTestMissionFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, testMission: null, loading: false, error: action.error };
};

const updateTestMissionStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const updateTestMissionSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    testMission: action.testMission || null,
    error: null,
    loading: false,
  };
};

const updateTestMissionFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, testMission: null, loading: false, error: action.error };
};

const saveTestMissionStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const saveTestMissionSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    id: action.id || null,
    error: null,
    loading: false,
  };
};

const saveTestMissionFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    id: null,
    loading: false,
    error: action.error,
  };
};

const updateTestMissionParticipantStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const updateTestMissionParticipantSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let testMission = null;
  if (state.testMission) {
    testMission = {
      ...state.testMission,
      participants: action.testMission?.participants || [],
    };
  }
  return {
    ...state,
    testMission,
    error: null,
    loading: false,
  };
};

const updateTestMissionParticipantFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, testMission: null, loading: false, error: action.error };
};
/*
const updateTestMissionCommentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const updateTestMissionCommentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let testMission = null;
  if (state.testMission) {
    testMission = {
      ...state.testMission,
      comments: action.testMission?.comments || [],
    };
  }
  return {
    ...state,
    testMission,
    error: null,
    loading: false,
  };
};

const updateTestMissionCommentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, testMission: null, loading: false, error: action.error };
};*/

const addTestMissionCommentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const addTestMissionCommentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let testMission = null;
  if (state.testMission) {
    testMission = {
      ...state.testMission,
      comments: action.testMission?.comments || [],
    };
  }
  return {
    ...state,
    testMission,
    error: null,
    loading: false,
  };
};

const addTestMissionCommentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, testMission: null, loading: false, error: action.error };
};

const deleteTestMissionCommentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const deleteTestMissionCommentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let testMission = null;
  if (state.testMission) {
    testMission = {
      ...state.testMission,
      comments: action.testMission?.comments || [],
    };
  }
  return {
    ...state,
    testMission,
    error: null,
    loading: false,
  };
};

const deleteTestMissionCommentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, testMission: null, loading: false, error: action.error };
};

const deleteTestMissionStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const deleteTestMissionSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    error: null,
    loading: false,
  };
};

const deleteTestMissionFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    id: null,
    loading: false,
    error: action.error,
  };
};

const deleteTestMissionParticipantStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const deleteTestMissionParticipantSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let testMission = null;
  if (state.testMission) {
    testMission = {
      ...state.testMission,
      participants: action.testMission?.participants || [],
    };
  }
  return {
    ...state,
    testMission,
    error: null,
    loading: false,
  };
};

const deleteTestMissionParticipantFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, testMission: null, loading: false, error: action.error };
};

const acceptTestMissionParticipantStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const acceptTestMissionParticipantSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let testMission = null;
  if (state.testMission) {
    testMission = {
      ...state.testMission,
      participants: action.testMission?.participants || [],
    };
  }
  return {
    ...state,
    testMission,
    error: null,
    loading: false,
  };
};

const acceptTestMissionParticipantFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, testMission: null, loading: false, error: action.error };
};

const reducer = (
  state: ReducerState = initialState,
  action: ITestMissionAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.TEST_MISSION_LIST_START:
      return listTestMissionsStart(state, action);
    case EActionTypes.TEST_MISSION_LIST_SUCCESS:
      return listTestMissionsSuccess(state, action);
    case EActionTypes.TEST_MISSION_LIST_FAIL:
      return listTestMissionsFail(state, action);
    case EActionTypes.TEST_MISSION_CLEAR:
      return clearTestMissions(state, action);
    case EActionTypes.TEST_MISSION_GET_START:
      return getTestMissionStart(state, action);
    case EActionTypes.TEST_MISSION_GET_SUCCESS:
      return getTestMissionSuccess(state, action);
    case EActionTypes.TEST_MISSION_GET_FAIL:
      return getTestMissionFail(state, action);
    case EActionTypes.TEST_MISSION_UPDATE_START:
      return updateTestMissionStart(state, action);
    case EActionTypes.TEST_MISSION_UPDATE_SUCCESS:
      return updateTestMissionSuccess(state, action);
    case EActionTypes.TEST_MISSION_UPDATE_FAIL:
      return updateTestMissionFail(state, action);
    case EActionTypes.TEST_MISSION_SAVE_START:
      return saveTestMissionStart(state, action);
    case EActionTypes.TEST_MISSION_SAVE_SUCCESS:
      return saveTestMissionSuccess(state, action);
    case EActionTypes.TEST_MISSION_SAVE_FAIL:
      return saveTestMissionFail(state, action);
    case EActionTypes.TEST_MISSION_PARTICIPANT_UPDATE_START:
      return updateTestMissionParticipantStart(state, action);
    case EActionTypes.TEST_MISSION_PARTICIPANT_UPDATE_SUCCESS:
      return updateTestMissionParticipantSuccess(state, action);
    case EActionTypes.TEST_MISSION_PARTICIPANT_UPDATE_FAIL:
      return updateTestMissionParticipantFail(state, action);
    /*case EActionTypes.TEST_MISSION_COMMENT_UPDATE_START:
      return updateTestMissionCommentStart(state, action);
    case EActionTypes.TEST_MISSION_COMMENT_UPDATE_SUCCESS:
      return updateTestMissionCommentSuccess(state, action);
    case EActionTypes.TEST_MISSION_COMMENT_UPDATE_FAIL:
      return updateTestMissionCommentFail(state, action);*/
    case EActionTypes.TEST_MISSION_COMMENT_ADD_START:
      return addTestMissionCommentStart(state, action);
    case EActionTypes.TEST_MISSION_COMMENT_ADD_SUCCESS:
      return addTestMissionCommentSuccess(state, action);
    case EActionTypes.TEST_MISSION_COMMENT_ADD_FAIL:
      return addTestMissionCommentFail(state, action);
    case EActionTypes.TEST_MISSION_COMMENT_DELETE_START:
      return deleteTestMissionCommentStart(state, action);
    case EActionTypes.TEST_MISSION_COMMENT_DELETE_SUCCESS:
      return deleteTestMissionCommentSuccess(state, action);
    case EActionTypes.TEST_MISSION_COMMENT_DELETE_FAIL:
      return deleteTestMissionCommentFail(state, action);
    case EActionTypes.TEST_MISSION_DELETE_START:
      return deleteTestMissionStart(state, action);
    case EActionTypes.TEST_MISSION_DELETE_SUCCESS:
      return deleteTestMissionSuccess(state, action);
    case EActionTypes.TEST_MISSION_DELETE_FAIL:
      return deleteTestMissionFail(state, action);
    case EActionTypes.TEST_MISSION_PARTICIPANT_DELETE_START:
      return deleteTestMissionParticipantStart(state, action);
    case EActionTypes.TEST_MISSION_PARTICIPANT_DELETE_SUCCESS:
      return deleteTestMissionParticipantSuccess(state, action);
    case EActionTypes.TEST_MISSION_PARTICIPANT_DELETE_FAIL:
      return deleteTestMissionParticipantFail(state, action);
    case EActionTypes.TEST_MISSION_PARTICIPANT_ACCEPT_START:
      return acceptTestMissionParticipantStart(state, action);
    case EActionTypes.TEST_MISSION_PARTICIPANT_ACCEPT_SUCCESS:
      return acceptTestMissionParticipantSuccess(state, action);
    case EActionTypes.TEST_MISSION_PARTICIPANT_ACCEPT_FAIL:
      return acceptTestMissionParticipantFail(state, action);
    default:
      return state;
  }
};

export default reducer;
