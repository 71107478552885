import React from "react";
import Button from "../../Button/Button";

import classes from "./RadioButtonOption.module.scss";
import { ETranslation } from "../../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { EAlertColor } from "../../Alert/Alert";

interface IProps {
  value: string;
  label?: string;
  labelTranslation?: ETranslation;
  isActive: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onClick: (value: string) => void;
  color?: EAlertColor;
}

const RadioButtonOption: React.FC<IProps> = ({
  value,
  label,
  labelTranslation,
  isActive,
  disabled,
  onClick,
  invalid,
  color,
}) => {
  const { t } = useTranslation();
  const classNames = [];

  switch (color) {
    case EAlertColor.PRIMARY:
      classNames.push(classes.Primary);
      break;
    case EAlertColor.SUCCESS:
      classNames.push(classes.Success);
      break;
    case EAlertColor.WARNING:
      classNames.push(classes.Warning);
      break;
    case EAlertColor.SECONDARY:
      classNames.push(classes.Secondary);
      break;
    case EAlertColor.TERTIARY:
      classNames.push(classes.Tertiary);
      break;
    case EAlertColor.DANGER:
      classNames.push(classes.Danger);
      break;
    default:
      break;
  }

  if (isActive) {
    classNames.push(classes.Active);
  } else {
    // classNames.push(classes.InActive);
  }
  if (invalid) {
    classNames.push(classes.Invalid);
  }

  return (
    <Button
      disabled={disabled}
      onClick={(event) => onClick(value)}
      className={classNames.join(" ")}
    >
      {labelTranslation ? t(labelTranslation) : label}
    </Button>
  );
};

export default RadioButtonOption;
