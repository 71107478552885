import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import LogItemSearch from "../../components/LogItems/LogItemSearch/LogItemSearch";
import Title from "../../components/Title/Title";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import { ILogItemSearch } from "../../store/actions/logItemActions";
import { ETranslation } from "../../translations/translation-keys";
import Table from "../../components/UI/Table/Table";

const Reports: React.FC = () => {
  const dispatch = useAppDispatch();
  const { reports, reportsLoading } = useAppSelector((state) => state.logItem);
  const { t } = useTranslation();

  const searchHandler = useCallback(
    (search: ILogItemSearch) => {
      dispatch(actions.getReports(search));
    },
    [dispatch]
  );

  return (
    <>
      <Container>
        <LogItemSearch onSearch={searchHandler} />
      </Container>
      <Container>
        <Title translation={ETranslation.DCO_REPORT} />
        {reportsLoading ? (
          <Spinner />
        ) : reports && reports?.length > 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th>{t(ETranslation.USER)}</th>
                <th>{t(ETranslation.STATISTICS_OFFERED)}</th>
                <th>{t(ETranslation.STATISTICS_REFUSED)}</th>
                <th>{t(ETranslation.STATISTICS_CANCELED)}</th>
                <th>{t(ETranslation.STATISTICS_COMPLETED)}</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report, i) => (
                <tr key={i}>
                  <td>{report.user}</td>
                  <td>{report.offered}</td>
                  <td>{report.refused}</td>
                  <td>{report.canceled}</td>
                  <td>{report.completed}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>{t(ETranslation.NOT_FOUND_ERROR)}</p>
        )}
      </Container>
    </>
  );
};

export default Reports;
