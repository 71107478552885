import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../classes/Routes";
import { ETranslation } from "../../translations/translation-keys";

interface IProps {
  translation: ETranslation;
  icon?: IconProp;
  route?: Routes;
}

const Title: React.FC<IProps> = ({ translation, icon = faAdd, route }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <h2 style={{ marginTop: 0 }}>
      {t(translation)}
      {route && (
        <>
          {" "}
          <FontAwesomeIcon
            icon={icon}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(route)}
          />
        </>
      )}
    </h2>
  );
};

export default Title;
