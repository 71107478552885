import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import Button, { EButtonColor } from "../../../components/UI/Button/Button";
import ConfirmDialog from "../../../components/UI/ConfirmDialog/ConfirmDialog";
import Container from "../../../components/UI/Container/Container";
import {
  EInputType,
  IInputField
} from "../../../components/UI/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs
} from "../../../components/UI/Input/input-utils";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ModalContext, { EModalSize } from "../../../context/ModalContext";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { useUserOptions } from "../../../hooks/useUserOptions";
import { IUnavailability } from "../../../interfaces/domain/IUnavailability";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";

export enum EUnavailability {
  startDate = "startDate",
  endDate = "endDate",
  info = "info",
  user = "user",
}

const Unavailability: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const { setModal, closeModal } = useContext(ModalContext);

  const { loading, unavailability, saveOrUpdateOk, saveOrUpdateLoading } =
    useAppSelector((state) => state.unavailability);

  const authUser = useAuthUser();
  const { usersLoading, userOptions } = useUserOptions();

  const [isValid, setIsValid] = useState(false);

  const id = params.id as string;

  useEffect(() => {
    if (saveOrUpdateOk) {
      navigate(Routes.UNAVAILABILITIES);
    }
    return () => {
      dispatch(actions.clearUnavailability());
    };
  }, [dispatch, saveOrUpdateOk, navigate]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getUnavailability(id));
    }
    if (authUser?.isTestPlanner) dispatch(actions.listUsers());
  }, [dispatch, id, authUser?.isTestPlanner]);

  const [inputs, setInputs] = useState<IInputField>({
    [EUnavailability.startDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.START_DATE,
      placeholderTranslation: ETranslation.START_DATE,
      value: "",
      validation: {
        required: true,
      },
    },
    [EUnavailability.endDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.END_DATE,
      placeholderTranslation: ETranslation.END_DATE,
      value: "",
      validation: {
        required: true,
      },
    },
    [EUnavailability.info]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.INFO,
      placeholderTranslation: ETranslation.INFO,
      value: "",
    },
    [EUnavailability.user]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.USER,
      placeholderTranslation: ETranslation.SELECT_USER_PLACEHOLDER,
      value: "",
      options: [],
      validation: {
        required: authUser?.isTestPlanner,
      },
    },
  });

  useEffect(() => {
    const start = inputs[EUnavailability.startDate].value as Date
    const end = inputs[EUnavailability.endDate].value as Date
    if (start && end && start <= end) {
      setIsValid(validateInputs(inputs));
    } else {
      setIsValid(false)
    }
  }, [inputs]);

  useEffect(() => {
    if (unavailability) initForm(setInputs, unavailability);
  }, [unavailability]);

  const deleteHandler = (id: string) => {
    dispatch(actions.deleteUnavailability(id));
    closeModal();
    navigate(-1);
  };

  const showDeleteDialog = (id: string) =>
    setModal({
      isOpen: true,
      title: t(ETranslation.COMMON_DELETE),
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          onCancel={closeModal}
          onConfirm={() => deleteHandler(id)}
          body={<p>{t(ETranslation.CONFIRM_DELETE_UNAVAILABILITY)}</p>}
        />
      ),
    });

  const saveHandler = () => {
    const data = getInputData<IUnavailability>(inputs);
    if (Routes.isNew(id)) {
      dispatch(actions.saveUnavailability(data));
    } else {
      data.id = id;
      dispatch(actions.updateUnavailability(data));
    }
  };

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation: !isValid,
    disabled: saveOrUpdateLoading,
  });

  return (
    <Container style={{width: "50%"}}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <InputGroup>
            {createInput(EUnavailability.startDate)}
            {createInput(EUnavailability.endDate)}
          </InputGroup>
          <InputGroup>
            {createInput(EUnavailability.info)}
            <></>
          </InputGroup>
          
          {authUser?.isTestPlanner &&
            createInput(EUnavailability.user, {
              options: userOptions,
              loading: usersLoading,
            })}
        </>
      )}
      <InputGroup>
        <Button onClick={() => navigate(-1)}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        <Button
          disabled={loading || !isValid}
          color={EButtonColor.SUCCESS}
          onClick={saveHandler}
          loading={saveOrUpdateLoading}
        >
          {t(ETranslation.COMMON_SAVE)}
        </Button>
        {Routes.isNotNew(id) && (
          <Button
            disabled={loading || saveOrUpdateLoading}
            color={EButtonColor.DANGER}
            onClick={() => showDeleteDialog(id)}
          >
            {t(ETranslation.COMMON_DELETE)}
          </Button>
        )}
      </InputGroup>
    </Container>
  );
};

export default Unavailability;
