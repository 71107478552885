import moment from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { IUnavailability } from "../../../interfaces/domain/IUnavailability";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../UI/Table/Table";
import { DATEPATTERN_FIN } from "../../../data/constants";

interface IProps {
  unavailabilities: IUnavailability[];
}

const UnavailabilitiesList: React.FC<IProps> = ({ unavailabilities }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  return (
    <Table hover>
      <thead>
        <tr>
          <th>{t(ETranslation.DATE)}</th>
          <th>{t(ETranslation.INFO)}</th>
          {authUser?.isTestPlanner && <th>{t(ETranslation.USER)}</th>}
        </tr>
      </thead>
      <tbody>
        {unavailabilities.map(unavailability => {
          const { id, startDate, endDate, info, user } = unavailability;
          return (
            <tr key={id} onClick={() => navigate(Routes.UNAVAILABILITY(id))}>
              <td>{moment(startDate).format("DD.MM.")} - {moment(endDate).format(DATEPATTERN_FIN)}</td>
              <td style={{maxWidth: '450px'}}>{info.substring(0,500)}</td>
              {authUser?.isTestPlanner && <td>{user.name}</td>}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default UnavailabilitiesList;
