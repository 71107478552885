import ICalendarAction from "../../interfaces/action/ICalendarAction";
import ICalendarState from "../../interfaces/state/ICalendarState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = ICalendarState;
type ReducerSignature = (
  state: ReducerState,
  action: ICalendarAction
) => ReducerState;

const initialState: ReducerState = {
  items: null,
  loading: false,
  error: null,
};

const getCalendarStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: true, error: null };
};

const getCalendarSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    items: action.items || null,
    error: null,
    loading: false,
  };
};

const getCalendarFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: ICalendarAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.CALENDAR_GET_START:
      return getCalendarStart(state, action);
    case EActionTypes.CALENDAR_GET_SUCCESS:
      return getCalendarSuccess(state, action);
    case EActionTypes.CALENDAR_GET_FAIL:
      return getCalendarFail(state, action);
    default:
      return state;
  }
};

export default reducer;
