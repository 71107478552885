import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor } from "../Button/Button";
import InputGroup from "../InputGroup/InputGroup";

interface IProps {
  children: ReactNode;
  onAccept: (checked: boolean) => void;
  onCancel: (checked: boolean) => void;
  checkboxLabel?: string;
}

const ConfirmModal: React.FC<IProps> = ({
  children,
  onAccept,
  onCancel,
  checkboxLabel,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  return (
    <>
      {children}
      {checkboxLabel && (
        <div style={{ display: "flex", gap: "0.25rem" }}>
          <label htmlFor="checkbox">{checkboxLabel}</label>{" "}
          <input
            id="checkbox"
            type="checkbox"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
        </div>
      )}
      <InputGroup style={{ marginTop: "1rem" }}>
        <Button onClick={() => onAccept(checked)} style={{ marginRight: ".5rem" }}>
          {t(ETranslation.COMMON_YES)}
        </Button>
        <Button onClick={() => onCancel(checked)} color={EButtonColor.DANGER}>
          {t(ETranslation.COMMON_NO)}
        </Button>
      </InputGroup>
    </>
  );
};

export default ConfirmModal;
