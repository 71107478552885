import { getAuth } from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ERoute } from "./classes/Routes";
import Layout from "./components/Layout/Layout";
import Modal from "./components/UI/Modal/Modal";
import Auth from "./containers/Auth/Auth";
import FieldPerson from "./containers/FieldPersons/FieldPerson/FieldPerson";
import FieldPersons from "./containers/FieldPersons/FieldPersons";
import Import from "./containers/Imports/Import/Import";
import Imports from "./containers/Imports/Imports";
import MissionDetails from "./containers/MissionDetails/MissionDetails";
import MyPage from "./containers/MyPage/MyPage";
import Log from "./containers/Log/Log";
import TestMission from "./containers/TestMission/TestMission/TestMission";
import TestMissions from "./containers/TestMission/TestMissions";
import Unavailabilities from "./containers/Unavailabilities/Unavailabilities";
import Unavailability from "./containers/Unavailabilities/Unavailability/Unavailability";
import ModalContext, { defaultModal, IModal } from "./context/ModalContext";
import firebase from "./firebase";
import GuardedRoute from "./GuardedRoute";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { useAuthentication } from "./hooks/useAuthentication";
import { useAuthUser } from "./hooks/useAuthUser";
import i18n from "./i18n";
import * as actions from "./store/actions";
import Reports from "./containers/Reports/Reports";
import Settings from "./containers/Settings/Settings";
import Notifications from "./containers/Notifications/Notifications";
import WorkLogs from "./containers/WorkLogs/WorkLogs";
import WorkLog from "./containers/WorkLogs/WorkLog/WorkLog";

const auth = getAuth(firebase);

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState(true);

  const authUser = useAuthUser();
  const { signOut } = useAuthentication();

  useEffect(() => {
    if (authUser && loading) {
      // When loading is true it's first time this is run, this should be done only once.
      setLoading(false);
      dispatch(actions.getSports());
      dispatch(actions.listMyNotifications());
      if (authUser?.preferredLanguage != null) {
        i18n.changeLanguage(authUser?.preferredLanguage);
      }
    }
  }, [dispatch, authUser, loading]);

  useEffect(() => {
    auth.onIdTokenChanged((fbUser) => {
      if (fbUser) {
        dispatch(actions.getCurrentUser());
      } else {
        setLoading(false);
      }
    });
  }, [dispatch]);

  const closeModal = useCallback(() => setModal(defaultModal), []);

  return (
    <ModalContext.Provider
      value={{
        setModal,
        closeModal,
      }}
    >
      <Modal modal={modal} />
      <Layout currentUser={authUser} onLogout={signOut}>
        {loading ? null : authUser ? (
          <Routes>
            <Route
              path={ERoute.TEST_MISSIONS}
              element={
                <GuardedRoute user={authUser} element={<TestMissions />} />
              }
            />
            <Route
              path={ERoute.TEST_MISSION}
              element={
                <GuardedRoute user={authUser} element={<TestMission />} />
              }
            />
            <Route
              path={ERoute.IMPORTS}
              element={<GuardedRoute user={authUser} element={<Imports />} />}
            />
            <Route
              path={ERoute.IMPORT}
              element={<GuardedRoute user={authUser} element={<Import />} />}
            />
            <Route
              path={ERoute.LOG}
              element={
                <GuardedRoute user={authUser} element={<Log />} />
              }
            />
            <Route
              path={ERoute.REPORTS}
              element={
                <GuardedRoute user={authUser} element={<Reports />} />
              }
            />
            <Route
              path={ERoute.FIELD_PERSONS}
              element={
                <GuardedRoute user={authUser} element={<FieldPersons />} />
              }
            />
            <Route
              path={ERoute.FIELD_PERSON}
              element={
                <GuardedRoute user={authUser} element={<FieldPerson />} />
              }
            />
            <Route
              path={ERoute.UNAVAILABILITIES}
              element={<Unavailabilities />}
            />
            <Route path={ERoute.UNAVAILABILITY} element={<Unavailability />} />
            <Route
              path={ERoute.MY_PAGE}
              element={<GuardedRoute user={authUser} element={<MyPage />} />}
            />
            <Route
              path={ERoute.MISSION_DETAILS}
              element={
                <GuardedRoute user={authUser} element={<MissionDetails />} />
              }
            />
            <Route
              path={ERoute.SETTINGS}
              element={
              <GuardedRoute user={authUser} element={<Settings />} />
              }
            />
            <Route
              path={ERoute.NOTIFICATIONS}
              element={
              <GuardedRoute user={authUser} element={<Notifications />} />
              }
            />
            {/* <Route
              path={ERoute.CALENDAR}
              element={<GuardedRoute user={authUser} element={<Calendar />} />}
            /> */}
            <Route
              path={ERoute.COMPENSATIONS}
              element={
              <GuardedRoute user={authUser} element={<WorkLogs />} />
              }
            />
            <Route
              path={ERoute.COMPENSATION}
              element={
              <GuardedRoute user={authUser} element={<WorkLog />} />
              }
            />
            <Route
              path="/*"
              element={<Navigate replace to={authUser.redirect} />}
            />
            <Route
              path={ERoute.LOGIN}
              element={<Navigate replace to={authUser.redirect} />}
            />
          </Routes>
        ) : (
          <Routes>
            <Route path={ERoute.LOGIN} element={<Auth />} />
            <Route path="/*" element={<Navigate replace to={ERoute.LOGIN} />} />
          </Routes>
        )}
      </Layout>
    </ModalContext.Provider>
  );
};

export default App;
