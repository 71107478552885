import React, { useEffect } from "react";
import MyPageStatistics from "../../components/MyPage/MyPageStatistics/MyPageStatistics";
import MyPageTestMissions from "../../components/MyPage/MyPageTestMissions/MyPageTestMissions";
import MyPageUnavailability from "../../components/MyPage/MyPageUnavailability/MyPageUnavailability";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAuthUser } from "../../hooks/useAuthUser";
import classes from "./MyPage.module.scss";
import * as actions from "../../store/actions";

const MyPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  useEffect(() => {
    if (authUser && authUser.isFieldPerson) {
      dispatch(actions.listMyUnavailabilities());
      dispatch(actions.listMyTestMissions());
      dispatch(actions.getTestMissionCount({
        year: new Date().getFullYear(),
        userId: authUser.id
      }));
    }
  }, [dispatch, authUser]);

  return (
    <div className={classes.Container}>
      <MyPageTestMissions />
      <div>
        <MyPageStatistics />
        <MyPageUnavailability />
      </div>
    </div>
  )
}

export default MyPage;
