import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { ITestMission } from "../../../interfaces/domain/ITestMission";
import { ETranslation } from "../../../translations/translation-keys";
import Spinner from "../../UI/Spinner/Spinner";
import MyPageSection from "../MyPageSection/MyPageSection";
import MyPageTestMissionsList from "./MyPageTestMissionsList/MyPageTestMissionsList";

const MyPageTestMissions: React.FC = () => {
  const {
    acceptedTestMissions,
    offeredTestMissions,
    completedTestMissions,
    loading,
  } = useAppSelector((state) => state.myTestMission);
  const { t } = useTranslation();
  const mapTestMissions = (testMissions: ITestMission[] | null) =>
    loading ? (
      <Spinner />
    ) : testMissions ? (
      <MyPageTestMissionsList testMissions={testMissions} />
    ) : (
      <p>{t(ETranslation.NO_TEST_MISSIONS)}</p>
    );

  return (
    <div>
      <MyPageSection>
        <h3>{t(ETranslation.OFFERED_TEST_MISSIONS)}</h3>
        {mapTestMissions(offeredTestMissions)}
      </MyPageSection>
      <MyPageSection>
        <h3>{t(ETranslation.MY_TEST_MISSIONS)}</h3>
        {mapTestMissions(acceptedTestMissions)}
      </MyPageSection>
      <MyPageSection style={{ marginBottom: "2rem" }}>
        <h3>{t(ETranslation.MY_COMPLETED_TEST_MISSIONS)}</h3>
        {mapTestMissions(completedTestMissions)}
      </MyPageSection>
    </div>
  );
};

export default MyPageTestMissions;
