import { TFunction } from "react-i18next";
import { ELevel } from "../interfaces/domain/IUser";
import { ETranslation } from "../translations/translation-keys";

export const getLevel = (t: TFunction, level: ELevel) => {
  switch (level) {
    case ELevel.TEST_PLANNER:
      return t(ETranslation.LEVEL_TEST_PLANNER);
    case ELevel.FIELD_PERSON:
      return t(ETranslation.LEVEL_FIELD_PERSON);
    case ELevel.REPORTING:
      return t(ETranslation.LEVEL_REPORTING);
  }
};
