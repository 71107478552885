import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { IParticipant } from "../../../../interfaces/domain/IParticipant";
import { ETranslation } from "../../../../translations/translation-keys";
import Button from "../../../UI/Button/Button";
import TestMissionParticipantsTable from "./TestMissionParticipantsTable/TestMissionParticipantsTable";

interface IProps {
  participants: IParticipant[];
  onEdit?: (participant?: IParticipant) => void;
  onAccept?: (participant?: IParticipant) => void;
}

const TestMissionParticipants: React.FC<IProps> = ({
  participants,
  onEdit,
  onAccept
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: "1rem" }}>
      <label>
        {t(ETranslation.PARTICIPANTS)}{" "}
        {onEdit && (
          <Button link onClick={() => onEdit()}>
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        )}
      </label>
      <TestMissionParticipantsTable
        participants={participants}
        onEdit={onEdit}
        onAccept={onAccept}
      />
    </div>
  );
};

export default TestMissionParticipants;
