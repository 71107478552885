import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import { IUser } from "../../../interfaces/domain/IUser";
import { getLevel } from "../../../shared/user";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../UI/Table/Table";

interface IProps {
  users: IUser[];
}

const FieldPersonList: React.FC<IProps> = ({ users }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Table hover>
      <thead>
        <tr>
          <th>{t(ETranslation.NAME)}</th>
          <th>{t(ETranslation.EMAIL)}</th>
          <th>{t(ETranslation.MOBILE)}</th>
          <th>{t(ETranslation.ROLES)}</th>
          <th>{t(ETranslation.REGION)}</th>
          <th>{t(ETranslation.LEVEL)}</th>
          <th>{t(ETranslation.ALLOW_LOGIN)}</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          const { id, name, email, mobile, roles, allowLogin, region, level } =
            user;
          return (
            <tr key={id} onClick={() => navigate(Routes.FIELD_PERSON(id))}>
              <td>{name}</td>
              <td>{email}</td>
              <td>{mobile}</td>
              <td>
                {roles?.length &&
                  roles
                    .map((role) => role.roleName && t(role.roleName))
                    .join(", ")}
              </td>
              <td>{region?.province}</td>
              <td>{level && getLevel(t, level)}</td>
              <td>{allowLogin ? <FontAwesomeIcon icon={faCheck} /> : ""}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default FieldPersonList;
