import React from "react";
import Table from "../../UI/Table/Table";
import {
  ENotificationAction,
  INotification,
} from "../../../interfaces/domain/INotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import * as actions from "../../../store/actions";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Spinner, { ESpinnerSize } from "../../UI/Spinner/Spinner";

interface IProps {
  notifications: INotification[];
}

const NotificationList: React.FC<IProps> = ({ notifications }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const { loadingId } = useAppSelector((state) => state.notification);

  const navigateHandler = (id?: string, action?: ENotificationAction) => {
    if (id) {
      if (authUser?.isTestPlanner) {
        let route = Routes.TEST_MISSION(id);
        if (action && [ENotificationAction.WORKLOG_PENDING].includes(action)) {
          route += "?view=compensation";
        }
        navigate(route);
      } else if (authUser?.isFieldPerson) {
        let route = Routes.MISSION_DETAILS(id);
        if (
          action &&
          [
            ENotificationAction.WORKLOG_CREATED,
            ENotificationAction.WORKLOG_ACCEPT,
            ENotificationAction.WORKLOG_RETURN,
          ].includes(action)
        ) {
          route += "?view=compensation";
        }
        navigate(route);
      }
    }
  };

  const updateToSeen = (id: string, seen?: boolean) => {
    if (id && !seen) {
      dispatch(actions.seenNotification(id));
    }
  };

  const updateToHidden = (id: string) => {
    if (id) {
      dispatch(actions.hideNotification(id));
    }
  };

  return (
    <Table hover>
      <tbody>
        {notifications.map((item) => {
          const { dateString, id, testMissionId, message, seen, action } = item;
          return loadingId === id ? (
            <tr key={id}>
              <td />
              <td style={{ margin: "auto" }}>
                <Spinner size={ESpinnerSize.SMALL} />
              </td>
              <td />
            </tr>
          ) : (
            <tr key={id}>
              <td onClick={() => updateToSeen(id, seen)}>
                {seen === false ? (
                  <FontAwesomeIcon icon={faCircle} color="red" />
                ) : (
                  ""
                )}
              </td>
              <td
                onClick={() => {
                  navigateHandler(testMissionId, action);
                }}
              >
                {dateString}: {message}
              </td>
              <td onClick={() => updateToHidden(id)}>
                <FontAwesomeIcon icon={faTrashCan} color="black" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default NotificationList;
