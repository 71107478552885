import { BaseDomain } from "./IBaseDomain";
import { IRole } from "./IRole";
import { IUser } from "./IUser";
import { EWorkLogStatus } from "./IWorkLog";

export enum EParticipantStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED",
}

export interface IParticipant extends BaseDomain {
  // testMissionId: string;
  participantType: IRole;
  isLeadDCO: boolean;
  // inCompetition: boolean;
  user: IUser | null;
  status: EParticipantStatus;
  offeredDate: string;
  workLogStatus?: EWorkLogStatus;
}

export const defaultParticipant = (
  participant?: IParticipant
): IParticipant => {
  return {
    id: participant?.id || "",
    // inCompetition: participant?.inCompetition || false,
    isLeadDCO: participant?.isLeadDCO || false,
    participantType: participant?.participantType || {
      inTraining: false,
      roleName: undefined,
    },
    status: participant?.status || EParticipantStatus.PENDING,
    // testMissionId: participant?.testMissionId || "",
    user: participant?.user || null,
    offeredDate: "",
  };
};
