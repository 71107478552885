import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DATEPATTERN_ISO_DATE } from "../../../data/constants";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { useUserOptions } from "../../../hooks/useUserOptions";
import { ELevel } from "../../../interfaces/domain/IUser";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonSize } from "../../UI/Button/Button";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData } from "../../UI/Input/input-utils";
import { IUnavailabilitySearch } from "../../../store/actions/unavailabilityActions";
import { useAuthUser } from "../../../hooks/useAuthUser";
import Container from "../../UI/Container/Container";

interface IProps {
  onSearch: (search: IUnavailabilitySearch) => void;
}

enum EUnavailabilitiesSearch {
  startDate = "startDate",
  userId = "userId",
}

const initialInputs: IInputField = {
  [EUnavailabilitiesSearch.startDate]: {
    type: EInputType.date,
    labelTranslation: ETranslation.START_DATE,
    placeholderTranslation: ETranslation.START_DATE,
    value: moment().subtract(1, "month").format(DATEPATTERN_ISO_DATE),
  },
  [EUnavailabilitiesSearch.userId]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.USER,
    placeholderTranslation: ETranslation.USER,
    value: "",
    options: [],
  },
};

const UnavailabilitiesSearch: React.FC<IProps> = ({ onSearch }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);
  const createInput = useCreateInput(inputs, setInputs);
  const { userOptions, usersLoading } = useUserOptions({
    level: ELevel.FIELD_PERSON,
  });
  const authUser = useAuthUser();

  useEffect(() => {
    onSearch(getInputData(initialInputs));
  }, [onSearch]);

  return (
    <Container>
      {createInput(EUnavailabilitiesSearch.startDate)}
      {authUser?.isTestPlanner &&
        createInput(EUnavailabilitiesSearch.userId, {
          options: userOptions,
          loading: usersLoading,
        })}
      <Button
        onClick={() => onSearch(getInputData(inputs))}
        size={EButtonSize.SMALL}
      >
        {t(ETranslation.COMMON_SEARCH)}
      </Button>
    </Container>
  );
};

export default UnavailabilitiesSearch;
