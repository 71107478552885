import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ModalContext, { EModalSize } from "../../../context/ModalContext";
import { IImport } from "../../../interfaces/domain/IImport";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../../UI/Button/Button";

interface IProps {
  summary: IImport;
}

const ImportSummary: React.FC<IProps> = ({ summary }) => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useContext(ModalContext);

  const { fileNames, generatedName, summaryMessages } = summary;

  const setCSVRowModal = (failedCSVRows: string[]) =>
    setModal({
      isOpen: true,
      title: generatedName,
      size: EModalSize.SMALL,
      autoScroll: true,
      content: (
        <>
          <b>Expected format</b>
          <br />
          description;startDate;endDate;id;status;tests;leadDCO;inCompetition;region
          <br />
          <br />
          <b>Csv rows</b>
          <br />
          <p style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {failedCSVRows.length && failedCSVRows.join("\n")}
          </p>
          <Button onClick={closeModal}>{t(ETranslation.COMMON_CLOSE)}</Button>
        </>
      ),
    });

  return (
    <>
      <h2>
        {fileNames && fileNames.length && fileNames.join(", ")} {t(ETranslation.PROCESSED)}
      </h2>
      {summaryMessages && summaryMessages.map((summaryMessage) => {
        const {
          importSummaryType,
          message,
          // testMissionCount,
          failedCSVRows,
        } = summaryMessage;
        return (
          <div key={importSummaryType}>
            <p style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>
              {importSummaryType}, {message}
              {failedCSVRows?.length && (
                <Button link onClick={() => setCSVRowModal(failedCSVRows)}>
                  {t(ETranslation.COMMON_CHECK)}
                </Button>
              )}
            </p>
          </div>
        );
      })}
    </>
  );
};

export default ImportSummary;
