import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../Button/Button";
import InputGroup from "../InputGroup/InputGroup";

interface IProps {
  body?: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: React.FC<IProps> = ({ body, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <>
      {body}
      <InputGroup>
        <Button onClick={onConfirm}>{t(ETranslation.COMMON_YES)}</Button>
        <Button onClick={onCancel}>{t(ETranslation.COMMON_NO)}</Button>
      </InputGroup>
    </>
  );
}

export default ConfirmDialog;
