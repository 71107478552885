export enum ETranslation {
  // Common
  COMMON_EMAIL = "COMMON_EMAIL",
  COMMON_SEND = "COMMON_SEND",
  COMMON_CANCEL = "COMMON_CANCEL",
  COMMON_CLOSE = "COMMON_CLOSE",
  COMMON_RETURN = "COMMON_RETURN",
  COMMON_DELETE = "COMMON_DELETE",
  COMMON_YES = "COMMON_YES",
  COMMON_NO = "COMMON_NO",
  COMMON_CHECK = "COMMON_CHECK",
  COMMON_SAVE = "COMMON_SAVE",
  COMMON_ACCEPT = "COMMON_ACCEPT",
  COMMON_REFUSE = "COMMON_REFUSE",
  COMMON_ADD = "COMMON_ADD",
  COMMON_SEARCH = "COMMON_SEARCH",
  COMMON_SETTINGS = "COMMON_SETTINGS",

  // Auth
  TITLE = "TITLE",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  PASSWORD_RESET_TEXT = "PASSWORD_RESET_TEXT",

  // Auth Inputs
  USERNAME = "USERNAME",
  PASSWORD = "PASSWORD",
  PASSWORD_MIN_LENGTH_MESSAGE = "PASSWORD_MIN_LENGTH_MESSAGE",

  // Routes
  MY_PAGE = "MY_PAGE",
  TEST_MISSIONS = "TEST_MISSIONS",
  IMPORT = "IMPORT",
  LOG = "LOG",
  FIELD_PERSONS = "FIELD_PERSONS",
  UNAVAILABILITIES = "UNAVAILABILITIES",
  UNAVAILABILITY = "UNAVAILABILITY",
  PROFILE = "PROFILE",
  REPORTS = "REPORTS",
  COMPENSATIONS = "COMPENSATIONS",
  COMPENSATION = "COMPENSATION",
  DCO_REPORT = "DCO_REPORT",

  // Test Missions
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  LEAD_DCO = "LEAD_DCO",
  ROLES = "ROLES",
  SPORT = "SPORT",
  NO_TEST_MISSIONS = "NO_TEST_MISSIONS",
  DATE = "DATE",
  TEST_MISSIONS_HAS_MORE = "TEST_MISSIONS_HAS_MORE",
  TEST_MISSIONS_ALL_FETCHED = "TEST_MISSIONS_ALL_FETCHED",
  TEST_MISSION_OPEN_IN_MODOC = "TEST_MISSION_OPEN_IN_MODOC",

  // Test Mission
  TEST_MISSION_ID = "TEST_MISSION_ID",
  STATUS = "STATUS",
  NUMBER_OF_TESTS = "NUMBER_OF_TESTS",
  IN_COMPETITION = "IN_COMPETITION",
  TEST_PLANNER = "TEST_PLANNER",
  PROVINCE = "PROVINCE",
  REGION = "REGION",
  PARTICIPANTS = "PARTICIPANTS",
  COMMENTS = "COMMENTS",
  DISCIPLINE = "DISCIPLINE",
  GENDER_REQUIREMENT = "GENDER_REQUIREMENT",
  URGENT = "URGENT",
  CITY = "CITY",
  COMPLETED_DATE = "COMPLETED_DATE",
  IGNORE_IN_COMPETITION = "IGNORE_IN_COMPETITION",

  // Test Mission Participant
  IS_LEAD_DCO = "IS_LEAD_DCO",
  ROLE_NAME = "ROLE_NAME",

  // Test Mission Comments
  MESSAGE = "MESSAGE",
  COMMENT = "COMMENT",
  ADD_COMMENT = "ADD_COMMENT",

  // Test Mission Search
  SEARCH_ORDER_BY = "SEARCH_ORDER_BY",
  SEARCH_FILTER = "SEARCH_FILTER",
  SEARCH_START_DATE = "SEARCH_START_DATE",
  SEARCH_SPORT = "SEARCH_SPORT",
  SEARCH_TEST_PLANNER = "SEARCH_TEST_PLANNER",
  SEARCH_STATUS = "SEARCH_STATUS",
  SEARCH_ASSIGNED_FIELD_PERSON = "SEARCH_ASSIGNED_FIELD_PERSON",
  SEARCH_UNFULFILLED_ROLES = "SEARCH_UNFULFILLED_ROLES",
  SEARCH_OVERDUE = "SEARCH_OVERDUE",
  SEARCH_DISCIPLINE = "SEARCH_DISCIPLINE",
  SEARCH_TEST_MISSION_ID = "SEARCH_TEST_MISSION_ID",

  // Test mission status
  STATUS_ISSUED = "STATUS_ISSUED",
  STATUS_MODOC = "STATUS_MODOC",
  STATUS_COMPLETE = "STATUS_COMPLETE",
  STATUS_CANCELLED = "STATUS_CANCELLED",
  STATUS_PLANNING = "STATUS_PLANNING",

  // Gender Requirement
  GENDER_REQUIREMENT_FEMALE = "GENDER_REQUIREMENT_FEMALE",
  GENDER_REQUIREMENT_MALE = "GENDER_REQUIREMENT_MALE",
  GENDER_REQUIREMENT_BOTH = "GENDER_REQUIREMENT_BOTH",
  GENDER_REQUIREMENT_ANY = "GENDER_REQUIREMENT_ANY",

  // Field persons
  NAME = "NAME",
  EMAIL = "EMAIL",
  MOBILE = "MOBILE",
  ALLOW_LOGIN = "ALLOW_LOGIN",
  NO_FIELD_PERSONS = "NO_FIELD_PERSONS",

  MOBILE_PLACEHOLDER = "MOBILE_PLACEHOLDER",

  // Field person
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  ADDRESS = "ADDRESS",
  POSTCODE = "POSTCODE",
  PLACE = "PLACE",
  WORK_TIME = "WORK_TIME",
  GENDER = "GENDER",
  LEVEL = "LEVEL",
  IN_TRAINING = "IN_TRAINING",
  NOTIFICATION_EMAIL = "NOTIFICATION_EMAIL",
  NOTIFICATION_SMS = "NOTIFICATION_SMS",
  TEST_SMS = "TEST_SMS",
  MODOC_USER_ID = "MODOC_USER_ID",
  VISMA_PAYROLL_PERSONNEL_ID = "VISMA_PAYROLL_PERSONNEL_ID",

  // Field person Search
  SEARCH_REGION = "SEARCH_REGION",
  SEARCH_ROLE = "SEARCH_ROLE",

  // React Select
  REACT_SELECT_PLACEHOLDER = "REACT_SELECT_PLACEHOLDER",
  REACT_SELECT_ADD_NEW = "REACT_SELECT_ADD_NEW",

  // React Dropzone
  REACT_DROPZONE_DRAG_TEXT = "REACT_DROPZONE_DRAG_TEXT",
  REACT_DROPZONE_DRAG_ACTIVE_TEXT = "REACT_DROPZONE_DRAG_ACTIVE_TEXT",

  // Errors
  NOT_FOUND_ERROR = "NOT_FOUND_ERROR",
  NO_ACCESS_ERROR = "NO_ACCESS_ERROR",

  // Validation
  VALIDATION_EMAIL = "VALIDATION_EMAIL",
  VALIDATION_MIN_LENGTH = "VALIDATION_MIN_LENGTH",
  VALIDATION_MAX_LENGTH = "VALIDATION_MAX_LENGTH",
  VALIDATION_MIN_AMOUNT = "VALIDATION_MIN_AMOUNT",
  VALIDATION_MAX_AMOUNT = "VALIDATION_MAX_AMOUNT",

  // RoleNames
  ADVISOR = "ADVISOR",
  ADVISOR_INTERNATIONAL = "ADVISOR_INTERNATIONAL",
  ASSISTANT = "ASSISTANT",
  ASSISTANT_TRAINING = "ASSISTANT_TRAINING",
  BLOOD_COLLECTION_OFFICER = "BLOOD_COLLECTION_OFFICER",
  BLOOD_COLLECTION_OFFICER_TRAINING = "BLOOD_COLLECTION_OFFICER_TRAINING",
  DCO = "DCO",
  DCO_TRAINING = "DCO_TRAINING",

  // Gender
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER_UNKNOWN = "OTHER_UNKNOWN",

  // Level
  LEVEL_FIELD_PERSON = "LEVEL_FIELD_PERSON",
  LEVEL_TEST_PLANNER = "LEVEL_TEST_PLANNER",
  LEVEL_REPORTING = "LEVEL_REPORTING",

  // SportsCategories
  AESTHETIC_GROUP_GYMNASTICS = "AESTHETIC_GROUP_GYMNASTICS",
  AIKIDO = "AIKIDO",
  AIR_SPORTS = "AIR_SPORTS",
  AMERICAN_FOOTBALL = "AMERICAN_FOOTBALL",
  AQUATICS = "AQUATICS",
  ARCHERY = "ARCHERY",
  ARM_WRESTLING = "ARM_WRESTLING",
  ATHLETICS = "ATHLETICS",
  AUSTRALIAN_RULES_FOOTBALL = "AUSTRALIAN_RULES_FOOTBALL",
  AUTOMOBILE_SPORTS = "AUTOMOBILE_SPORTS",
  BADMINTON = "BADMINTON",
  BALLE_AU_TAMBOURIN = "BALLE_AU_TAMBOURIN",
  BALLON_AU_POING = "BALLON_AU_POING",
  BANDY = "BANDY",
  BASEBALL = "BASEBALL",
  BASKETBALL = "BASKETBALL",
  BASQUE_PELOTA = "BASQUE_PELOTA",
  BASQUE_SPORTS = "BASQUE_SPORTS",
  BEACH_TENNIS = "BEACH_TENNIS",
  BIATHLON = "BIATHLON",
  BILLIARDS_SPORTS = "BILLIARDS_SPORTS",
  BOBSLEIGH = "BOBSLEIGH",
  BOCCIA = "BOCCIA",
  BODYBUILDING = "BODYBUILDING",
  BOOT_THROWING = "BOOT_THROWING",
  BOULES_SPORTS = "BOULES_SPORTS",
  BOWLING = "BOWLING",
  BOWLS = "BOWLS",
  BOXING = "BOXING",
  BRAZILIAN_JIU_JITSU = "BRAZILIAN_JIU_JITSU",
  BRIDGE = "BRIDGE",
  BROOMBALL = "BROOMBALL",
  BUDO = "BUDO",
  CISS_GOLF = "CISS_GOLF",
  CISS_SAILING = "CISS_SAILING",
  COLEO = "COLEO",
  CP_FOOTBALL = "CP_FOOTBALL",
  CAMEL_RACING = "CAMEL_RACING",
  CANICROSS = "CANICROSS",
  CANOE = "CANOE",
  CASTING = "CASTING",
  CHANBARA = "CHANBARA",
  CHEER = "CHEER",
  CHEERLEADING = "CHEERLEADING",
  CHESS = "CHESS",
  CHIN_UP = "CHIN_UP",
  CLAY_TARGET_SHOOTING = "CLAY_TARGET_SHOOTING",
  COLLEGE_SPORTS = "COLLEGE_SPORTS",
  COMBAT_SPORTS = "COMBAT_SPORTS",
  COMPANY_SPORTS = "COMPANY_SPORTS",
  COURSE_CAMARGUAISE = "COURSE_CAMARGUAISE",
  COURSE_LANDAISE = "COURSE_LANDAISE",
  CRICKET = "CRICKET",
  CROSSFIT = "CROSSFIT",
  CROSSBOW = "CROSSBOW",
  CROSSWORD = "CROSSWORD",
  CURLING = "CURLING",
  CYCLING = "CYCLING",
  DANCESPORT = "DANCESPORT",
  DARTS = "DARTS",
  DET_FRIVILLIGE_SKYTTERVESEN = "DET_FRIVILLIGE_SKYTTERVESEN",
  DODGEBALL = "DODGEBALL",
  DOMIMO = "DOMIMO",
  DRAGON_BOAT = "DRAGON_BOAT",
  DRAGON_AND_LION_DANCE = "DRAGON_AND_LION_DANCE",
  DRAUGHTS = "DRAUGHTS",
  ELECTRONIC_SPORTS = "ELECTRONIC_SPORTS",
  EQUESTRIAN = "EQUESTRIAN",
  EXTREME_SPORTS = "EXTREME_SPORTS",
  FIFA_ESPORTS = "FIFA_ESPORTS",
  FENCING = "FENCING",
  FIELD_HOCKEY = "FIELD_HOCKEY",
  FIREFIGHTING_AND_RESCUING = "FIREFIGHTING_AND_RESCUING",
  FISTBALL = "FISTBALL",
  FITNESS = "FITNESS",
  FLOORBALL = "FLOORBALL",
  FLYING_DISC = "FLYING_DISC",
  FOOT_VOLLEY = "FOOT_VOLLEY",
  FOOTBALL = "FOOTBALL",
  FOOTBALL_7_A_SIDE = "FOOTBALL_7_A_SIDE",
  FOOTBALL_IBSA_FOOTBALL = "FOOTBALL_IBSA_FOOTBALL",
  FRENCH_BOXING = "FRENCH_BOXING",
  FRISKIS_SVETTIS = "FRISKIS_SVETTIS",
  FULL_CONTACT = "FULL_CONTACT",
  FUNCTIONAL_FITNESS = "FUNCTIONAL_FITNESS",
  GAELIC_GAMES = "GAELIC_GAMES",
  GIGATHLON = "GIGATHLON",
  GIRA = "GIRA",
  GLORY_KICKBOXING = "GLORY_KICKBOXING",
  GO = "GO",
  GOALBALL = "GOALBALL",
  GOLF = "GOLF",
  GYMNASTICS = "GYMNASTICS",
  HANDBALL = "HANDBALL",
  HEAVY_ATHLETICS = "HEAVY_ATHLETICS",
  HEAVY_EVENTS = "HEAVY_EVENTS",
  HIGHLAND_GAMES = "HIGHLAND_GAMES",
  HORNUSSEN = "HORNUSSEN",
  HORSE_RACING = "HORSE_RACING",
  HUNTING_SPORTS = "HUNTING_SPORTS",
  ICE_HOCKEY = "ICE_HOCKEY",
  ICESTOCKSPORT = "ICESTOCKSPORT",
  JET_SKI = "JET_SKI",
  JIU_JITSU = "JIU_JITSU",
  JU_JITSU = "JU_JITSU",
  JUDO = "JUDO",
  JUMP_ROPE = "JUMP_ROPE",
  KAATSEN = "KAATSEN",
  KABADDI = "KABADDI",
  KARATE = "KARATE",
  KEMPO = "KEMPO",
  KENDO = "KENDO",
  KETTLEBELL_LIFTING = "KETTLEBELL_LIFTING",
  KHO_KHO = "KHO_KHO",
  KICKBOXING = "KICKBOXING",
  KIN_BALL = "KIN_BALL",
  KORFBALL = "KORFBALL",
  KRACHTBAL = "KRACHTBAL",
  KUDO = "KUDO",
  KUNG_FU = "KUNG_FU",
  KURASH = "KURASH",
  KYUDO = "KYUDO",
  LACROSSE = "LACROSSE",
  LEONES_BOWLS = "LEONES_BOWLS",
  LIFESAVING = "LIFESAVING",
  LONGUE_PAUME = "LONGUE_PAUME",
  LUGE = "LUGE",
  MODOC_TRAINING_SPORT = "MODOC_TRAINING_SPORT",
  MILITARY_PATROL = "MILITARY_PATROL",
  MILITARY_SPORT_PENTATHLON = "MILITARY_SPORT_PENTATHLON",
  MINIGOLF = "MINIGOLF",
  MIXED_MARTIAL_ARTS = "MIXED_MARTIAL_ARTS",
  MODERN_PENTATHLON = "MODERN_PENTATHLON",
  MOTOBALL = "MOTOBALL",
  MOTORCYCLE_RACING = "MOTORCYCLE_RACING",
  MOUNTAINEERING_AND_CLIMBING = "MOUNTAINEERING_AND_CLIMBING",
  MUAYTHAI = "MUAYTHAI",
  MULTIFUNCTIONAL_TRAINING = "MULTIFUNCTIONAL_TRAINING",
  NAGINATA = "NAGINATA",
  NETBALL = "NETBALL",
  NORGES_BILSPORTFORBUND = "NORGES_BILSPORTFORBUND",
  NORGES_RYTTERFORBUN = "NORGES_RYTTERFORBUN",
  NORSK_GALOPP = "NORSK_GALOPP",
  NORSKE_TRAVSELSKAP = "NORSKE_TRAVSELSKAP",
  OBSTACLE_SPORTS = "OBSTACLE_SPORTS",
  ORIENTEERING = "ORIENTEERING",
  PADEL = "PADEL",
  PARA_ALPINE_SKIING = "PARA_ALPINE_SKIING",
  PARA_ATHLETICS = "PARA_ATHLETICS",
  PARA_BIATHLON = "PARA_BIATHLON",
  PARA_BOWLING = "PARA_BOWLING",
  PARA_BOWLS = "PARA_BOWLS",
  PARA_CROSS_COUNTRY_SKIING = "PARA_CROSS_COUNTRY_SKIING",
  PARA_DANCESPORT = "PARA_DANCESPORT",
  PARA_GOLF = "PARA_GOLF",
  PARA_ICE_HOCKEY = "PARA_ICE_HOCKEY",
  PARA_NORDIC_SKIING = "PARA_NORDIC_SKIING",
  PARA_POWERLIFTING = "PARA_POWERLIFTING",
  PARA_SNOWBOARD = "PARA_SNOWBOARD",
  PARA_SWIMMING = "PARA_SWIMMING",
  PARAVOLLEY = "PARAVOLLEY",
  PENCAK_SILAT = "PENCAK_SILAT",
  POKER = "POKER",
  POLE_SPORTS = "POLE_SPORTS",
  POLO = "POLO",
  POWER_PRESS = "POWER_PRESS",
  POWERBOATING = "POWERBOATING",
  POWERLIFTING = "POWERLIFTING",
  PRACTICAL_SHOOTING = "PRACTICAL_SHOOTING",
  QAZAQ_KURESI = "QAZAQ_KURESI",
  RACQUETBALL = "RACQUETBALL",
  RAFTING = "RAFTING",
  RINGETTE = "RINGETTE",
  RINKBALL = "RINKBALL",
  ROLLER_SPORTS = "ROLLER_SPORTS",
  ROWING = "ROWING",
  RUGBY_LEAGUE = "RUGBY_LEAGUE",
  RUGBY_UNION = "RUGBY_UNION",
  SAILING = "SAILING",
  SAMBO = "SAMBO",
  SAVATE = "SAVATE",
  SCHOOL_SPORTS = "SCHOOL_SPORTS",
  SEPAKTAKRAW = "SEPAKTAKRAW",
  SHEARING_SPORTS = "SHEARING_SPORTS",
  SHOE_RACING = "SHOE_RACING",
  SHOOTING = "SHOOTING",
  SHOOTING_PARA_SPORT = "SHOOTING_PARA_SPORT",
  SHOWDOWN = "SHOWDOWN",
  SHUTTLECOCK = "SHUTTLECOCK",
  SITBALL = "SITBALL",
  SITTING_VOLLEYBALL = "SITTING_VOLLEYBALL",
  SKATING = "SKATING",
  SKI_MOUNTAINEERING = "SKI_MOUNTAINEERING",
  SKIBOB = "SKIBOB",
  SKIING = "SKIING",
  SKYBALL = "SKYBALL",
  SKYRUNNING = "SKYRUNNING",
  SLEDDOG = "SLEDDOG",
  SNOWMOBILE = "SNOWMOBILE",
  SOFT_TENNIS = "SOFT_TENNIS",
  SOFTBALL = "SOFTBALL",
  SPEEDBALL = "SPEEDBALL",
  SPELEOLOGY = "SPELEOLOGY",
  SPORT_CLIMBING = "SPORT_CLIMBING",
  SPORT_FISHING = "SPORT_FISHING",
  SQUASH = "SQUASH",
  STILTS_RACING = "STILTS_RACING",
  STREET_AND_BALL_HOCKEY = "STREET_AND_BALL_HOCKEY",
  STRONGEST_MAN = "STRONGEST_MAN",
  SUMO = "SUMO",
  SURFING = "SURFING",
  SWISS_NATIONAL_GYMNASTICS = "SWISS_NATIONAL_GYMNASTICS",
  TABLE_SOCCER = "TABLE_SOCCER",
  TABLE_TENNIS = "TABLE_TENNIS",
  TAEKWONDO = "TAEKWONDO",
  TAEKWONDO_ITF_AUSTRIA = "TAEKWONDO_ITF_AUSTRIA",
  TAIDO = "TAIDO",
  TARUNG_DERAJAT = "TARUNG_DERAJAT",
  TCHOUKBALL = "TCHOUKBALL",
  TENNIS = "TENNIS",
  TEQBALL = "TEQBALL",
  TEST_SPORT = "TEST_SPORT",
  THAI_TRADITIONAL_SPORTS = "THAI_TRADITIONAL_SPORTS",
  THE_NORWEGIAN_POLICE_ACADEMY = "THE_NORWEGIAN_POLICE_ACADEMY",
  TOUCH_FOOTBALL = "TOUCH_FOOTBALL",
  TRADITIONAL_ARCHERY = "TRADITIONAL_ARCHERY",
  TRADITIONAL_KARATE = "TRADITIONAL_KARATE",
  TRADITIONAL_MARTIAL_ARTS = "TRADITIONAL_MARTIAL_ARTS",
  TRAPEZE = "TRAPEZE",
  TRIATHLON = "TRIATHLON",
  TUG_OF_WAR = "TUG_OF_WAR",
  TWIRLING = "TWIRLING",
  ULTIMATE_FIGHTING = "ULTIMATE_FIGHTING",
  ULTRA_TRIATHLON = "ULTRA_TRIATHLON",
  UNDERWATER_SPORTS = "UNDERWATER_SPORTS",
  UNICYCLING = "UNICYCLING",
  UNIFIGHT = "UNIFIGHT",
  VA_A = "VA_A",
  VOLLEYBALL = "VOLLEYBALL",
  VOVINAM = "VOVINAM",
  WSLEAGUE_SURFING = "WSLEAGUE_SURFING",
  WATERSKIING = "WATERSKIING",
  WEIGHTLIFTING = "WEIGHTLIFTING",
  WHEELCHAIR_SPORTS = "WHEELCHAIR_SPORTS",
  WOODBALL = "WOODBALL",
  WOODSMAN = "WOODSMAN",
  WRESTLING = "WRESTLING",
  WUSHU = "WUSHU",
  XIANGQI = "XIANGQI",
  CROSS_TRAINING_MILITARY_CROSS_TRAINING = "CROSS_TRAINING_MILITARY_CROSS_TRAINING",
  PARA_BADMINTON = "PARA_BADMINTON",
  PARA_BASKETBALL = "PARA_BASKETBALL",
  BILSPORT = "BILSPORT",
  CANOE_KAYAK = "CANOE_KAYAK",
  PARA_CURLING = "PARA_CURLING",
  DFS = "DFS",
  FLERIDRETT = "FLERIDRETT",
  FLY_CASTING = "FLY_CASTING",
  GALOPP = "GALOPP",
  PARA_GYMNASTICS = "PARA_GYMNASTICS",
  PARA_JUDO = "PARA_JUDO",
  PHS = "PHS",
  POLESPORT = "POLESPORT",
  PARA_SAILING = "PARA_SAILING",
  PARA_TABLE_TENNIS = "PARA_TABLE_TENNIS",
  TAEKWONDO_NTN = "TAEKWONDO_NTN",
  PARA_TENNIS = "PARA_TENNIS",
  TRAV = "TRAV",
  TRENINGSSENTER = "TRENINGSSENTER",
  STUDENT_SPORTS = "STUDENT_SPORTS",

  // Unavailabilities
  INFO = "INFO",
  USER = "USER",
  NO_UNAVAILABILITIES = "NO_UNAVAILABILITIES",

  // Select
  SELECT_USER_PLACEHOLDER = "SELECT_USER_PLACEHOLDER",
  SELECT_ROLE_PLACEHOLDER = "SELECT_ROLE_PLACEHOLDER",
  SELECT_REGION_PLACEHOLDER = "SELECT_REGION_PLACEHOLDER",
  SELECT_GENDER_PLACEHOLDER = "SELECT_GENDER_PLACEHOLDER",
  SELECT_SPORT_PLACEHOLDER = "SELECT_SPORT_PLACEHOLDER",
  SELECT_STATUS_PLACEHOLDER = "SELECT_STATUS_PLACEHOLDER",
  SELECT_LEVEL_PLACEHOLDER = "SELECT_LEVEL_PLACEHOLDER",
  SELECT_DISCIPLINE_PLACEHOLDER = "SELECT_DISCIPLINE_PLACEHOLDER",

  // Import
  IMPORT_HISTORY = "IMPORT_HISTORY",
  PROCESSED = "PROCESSED",
  IMPORT_TEST_MISSIONS = "IMPORT_TEST_MISSIONS",
  IMPORT_PARTICIPANTS = "IMPORT_PARTICIPANTS",
  IMPORT_START_AUTOMATCH = "IMPORT_START_AUTOMATCH",
  IMPORT_DELETE = "IMPORT_DELETE",

  // Confirm
  CONFIRM_DELETE_UNAVAILABILITY = "CONFIRM_DELETE_UNAVAILABILITY",
  CONFIRM_DELETE_COMMENT = "CONFIRM_DELETE_COMMENT",
  CONFIRM_TEST_MISSION_ACCEPT = "CONFIRM_TEST_MISSION_ACCEPT",
  CONFIRM_TEST_MISSION_REFUSE = "CONFIRM_TEST_MISSION_REFUSE",
  CONFIRM_TEST_MISSION_COMPLETE = "CONFIRM_TEST_MISSION_COMPLETE",
  CONFIRM_TEST_MISSION_CANCEL = "CONFIRM_TEST_MISSION_CANCEL",
  CONFIRM_TEST_MISSION_DELETE = "CONFIRM_TEST_MISSION_DELETE",
  testMissionStatusOptions = "testMissionStatusOptions",

  // My page
  OFFERED_TEST_MISSIONS = "OFFERED_TEST_MISSIONS",
  MY_TEST_MISSIONS = "MY_TEST_MISSIONS",
  MY_COMPLETED_TEST_MISSIONS = "MY_COMPLETED_TEST_MISSIONS",
  MARK_COMPLETED = "MARK_COMPLETED",
  SHOW_MORE = "SHOW_MORE",
  SHOW_LESS = "SHOW_LESS",

  // Statistics
  YEAR = "YEAR",
  ACTION = "ACTION",
  ACTION_TRIGGER = "ACTION_TRIGGER",
  COUNT_CREATED = "COUNT_CREATED",
  COUNT_CANCELED = "COUNT_CANCELED",
  COUNT_OFFERED = "COUNT_OFFERED",
  COUNT_REFUSED = "COUNT_REFUSED",
  COUNT_ACCEPTED = "COUNT_ACCEPTED",
  COUNT_COMPLETED = "COUNT_COMPLETED",
  COUNT_TOTAL = "COUNT_TOTAL",
  STATISTICS_OFFERED = "STATISTICS_OFFERED",
  STATISTICS_REFUSED_CANCELED = "STATISTICS_REFUSED_CANCELED",
  STATISTICS_COMPLETED = "STATISTICS_COMPLETED",
  STATISTICS_REFUSED = "STATISTICS_REFUSED",
  STATISTICS_CANCELED = "STATISTICS_CANCELED",

  // Test Mission Participants Dialog
  TEST_MISSION_FILL_REQUIRED_FIELDS = "TEST_MISSION_FILL_REQUIRED_FIELDS",
  TEST_MISSION_AUTOMATCH_RECOMMENDED_USERS = "TEST_MISSION_AUTOMATCH_RECOMMENDED_USERS",
  TEST_MISSION_AUTOMATCH_FOUND_NO_USERS = "TEST_MISSION_AUTOMATCH_FOUND_NO_USERS",
  TEST_MISSION_PARTICIPANT_ACCEPT = "TEST_MISSION_PARTICIPANT_ACCEPT",

  // Test Mission Save Dialog
  TEST_MISSION_SAVE = "TEST_MISSION_SAVE",

  // MultiFactorAuthDialog
  MULTI_FACTOR_AUTH_DIALOG_TITLE = "MULTI_FACTOR_AUTH_DIALOG_TITLE",
  MULTI_FACTOR_AUTH_DIALOG_CODE = "MULTI_FACTOR_AUTH_DIALOG_CODE",
  MULTI_FACTOR_AUTH_DIALOG_CODE_PLACEHOLDER = "MULTI_FACTOR_AUTH_DIALOG_CODE_PLACEHOLDER",

  // Firebase errors
  FIREBASE_INVALID_PASSWORD = "auth/wrong-password",
  FIREBASE_INVALID_EMAIL = "auth/invalid-email",
  FIREBASE_INVALID_CODE = "auth/invalid-verification-code",
  FIREBASE_TOO_MANY_REQUESTS = "auth/too-many-requests",
  FIREBASE_USER_DISABLED = "auth/user-disabled",

  // Participant Status
  PARTICIPANT_STATUS_COMPLETED = "PARTICIPANT_STATUS_COMPLETED",
  PARTICIPANT_STATUS_ACCEPTED = "PARTICIPANT_STATUS_ACCEPTED",
  PARTICIPANT_STATUS_PENDING = "PARTICIPANT_STATUS_PENDING",
  PARTICIPANT_STATUS_REFUSED = "PARTICIPANT_STATUS_REFUSED",

  // Notifications
  NOTIFICATIONS = "NOTIFICATIONS",
  NO_NOTIFICATIONS = "NO_NOTIFICATIONS",
  READ_ALL_NOTIFICATIONS = "READ_ALL_NOTIFICATIONS",

  // WorkLog
  WORKLOG = "WORKLOG",
  START_HOURS = "START_HOURS",
  START_MINUTES = "START_MINUTES",
  END_HOURS = "END_HOURS",
  END_MINUTES = "END_MINUTES",
  KILOMETERS = "KILOMETERS",
  PASSENGERS = "PASSENGERS",

  SAVE_WORKLOG = "SAVE_WORKLOG",
  WORKLOG_ACCEPT = "WORKLOG_ACCEPT",
  WORKLOG_RETURN = "WORKLOG_RETURN",
  MARK_WORKLOG_PENDING = "MARK_WORKLOG_PENDING",
  WORKLOG_ACCEPT_ALL = "WORKLOG_ACCEPT_ALL",

  WORKLOG_CONFIRM_ACCEPT = "WORKLOG_CONFIRM_ACCEPT",
  WORKLOG_CONFIRM_RETURN = "WORKLOG_CONFIRM_RETURN",
  WORKLOG_CONFIRM_MARK_PENDING = "WORKLOG_CONFIRM_MARK_PENDING",

  WORKLOG_EXPORT_CSV = "WORKLOG_EXPORT_CSV",
  WORKLOG_EXPORT_VISMA = "WORKLOG_EXPORT_VISMA",

  WORKLOG_SUMMARY = "WORKLOG_SUMMARY",
  WORK_HOURS = "WORK_HOURS",
  MILEAGE_ALLOWANCE = "MILEAGE_ALLOWANCE",
  EXPENSES_TOTAL = "EXPENSES_TOTAL",
  OVERTIME_EXTRA = "OVERTIME_EXTRA",
  MIDWEEK_HOLIDAY_EXTRA = "MIDWEEK_HOLIDAY_EXTRA",
  WORKLOG_STATUS = "WORKLOG_STATUS",
  PLANNING = "PLANNING",
  RESPONSIBILITY = "RESPONSIBILITY",

  PERCENTAGE = "PERCENTAGE",
  EXPORT_DATE = "EXPORT_DATE",
  EXPORTED = "EXPORTED",
  LINKED_WORK_LOGS = "LINKED_WORK_LOGS",
  WORKLOGS_PERCENTAGE_ERROR = "WORKLOGS_PERCENTAGE_ERROR",
  WORKLOGS_VISMA_ID_ERROR = "WORKLOGS_VISMA_ID_ERROR",

  // WorkLogExpense
  EXPENSES = "EXPENSES",
  DESCRIPTION = "DESCRIPTION",
  AMOUNT = "AMOUNT",
  ATTACHMENTS = "ATTACHMENTS",

  SAVE_EXPENSE = "SAVE_EXPENSE",
  DELETE_EXPENSE = "DELETE_EXPENSE",
  DELETE_EXPENSE_CONFIRM_TEXT = "DELETE_EXPENSE_CONFIRM_TEXT",
  DELETE_EXPENSE_ATTACHMENT_CONFIRM_TEXT = "DELETE_EXPENSE_ATTACHMENT_CONFIRM_TEXT",
  NO_ATTACHMENTS = "NO_ATTACHMENTS",

  // Test Mission View
  TEAM = "TEAM",

  // Modoc sync
  MODOC_SYNC_TITLE = "MODOC_SYNC_TITLE",
  MODOC_SYNC_TEXT = "MODOC_SYNC_TEXT",
  MODOC_SYNC_SUCCESS_TEXT = "MODOC_SYNC_SUCCESS_TEXT",
  MODOC_SYNC_BUFFER_NOTIFICATIONS = "MODOC_SYNC_BUFFER_NOTIFICATIONS",
}
