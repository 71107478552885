import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import IUserAction from "../../interfaces/action/IUserAction";
import { ERoleName } from "../../interfaces/domain/IRole";
import { ELevel, IUser } from "../../interfaces/domain/IUser";
import { EActionTypes } from "../EActionTypes";

type TAction = IUserAction;
const apiPath = "/json/user";

export const clearUser = () => {
  return {
    type: EActionTypes.USER_CLEAR,
  };
};

const listUsersStart = (): TAction => {
  return {
    type: EActionTypes.USER_LIST_START,
  };
};

const listUsersSuccess = (users: IUser[]): TAction => {
  return {
    type: EActionTypes.USER_LIST_SUCCESS,
    users,
  };
};

const listUsersFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_LIST_FAIL,
    error,
  };
};

export const listUsers = (
  role?: ERoleName,
  region?: string,
  level?: ELevel
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listUsersStart());
    try {
      const res = await axios.get(
        `${apiPath}/list?role=${role ?? ""}&region=${region ?? ""}&level=${level ?? ""}`
      );
      dispatch(listUsersSuccess(res.data.users));
    } catch ({ response }) {
      dispatch(listUsersFail((response as AxiosResponse).data));
    }
  };
};

const listUsersSearchStart = (): TAction => {
  return {
    type: EActionTypes.USER_LIST_SEARCH_START,
  };
};

const listUsersSearchSuccess = (
  searchedUsers: IUser[],
  top5Users: IUser[]
): TAction => {
  return {
    type: EActionTypes.USER_LIST_SEARCH_SUCCESS,
    searchedUsers,
    top5Users,
  };
};

const listUsersSearchFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_LIST_SEARCH_FAIL,
    error,
  };
};

export const listUsersSearch = (
  role?: ERoleName,
  region?: string,
  id?: string,
  level?: ELevel
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listUsersSearchStart());
    try {
      const res = await axios.get(
        `${apiPath}/list/search?role=${role ?? ""}&region=${region ?? ""}&id=${
          id ?? ""
        }&level=${level ?? ""}`
      );
      dispatch(listUsersSearchSuccess(res.data.users, res.data.top5Users));
    } catch ({ response }) {
      dispatch(listUsersSearchFail((response as AxiosResponse).data));
    }
  };
};

const getUserStart = (): TAction => {
  return {
    type: EActionTypes.USER_GET_START,
  };
};

const getUserSuccess = (user: IUser): TAction => {
  return {
    type: EActionTypes.USER_GET_SUCCESS,
    user,
  };
};

const getUserFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_GET_FAIL,
    error,
  };
};

export const getUser = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getUserStart());
    try {
      const res = await axios.get<IUser>(`${apiPath}/get?id=${id}`);
      dispatch(getUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(getUserFail((response as AxiosResponse).data));
    }
  };
};

const updateUserStart = (): TAction => {
  return {
    type: EActionTypes.USER_UPDATE_START,
  };
};

const updateUserSuccess = (user: IUser): TAction => {
  return {
    type: EActionTypes.USER_UPDATE_SUCCESS,
    user,
  };
};

const updateUserFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_UPDATE_FAIL,
    error,
  };
};

export const updateUser = (user: IUser) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateUserStart());
    try {
      const res = await axios.put<IUser>(`${apiPath}/update`, user);
      dispatch(updateUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateUserFail((response as AxiosResponse).data));
    }
  };
};

const updateUserSettingsStart = (): TAction => {
  return {
    type: EActionTypes.USER_UPDATE_START,
  };
};

const updateUserSettingsSuccess = (user: IUser): TAction => {
  return {
    type: EActionTypes.USER_UPDATE_SUCCESS,
    user,
  };
};

const updateUserSettingsFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_UPDATE_FAIL,
    error,
  };
};

export const updateUserSettings = (user: IUser) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateUserSettingsStart());
    try {
      const res = await axios.put<IUser>(`${apiPath}/update-settings`, user);
      dispatch(updateUserSettingsSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateUserSettingsFail((response as AxiosResponse).data));
    }
  };
};

const saveUserStart = (): TAction => {
  return {
    type: EActionTypes.USER_SAVE_START,
  };
};

const saveUserSuccess = (user: IUser): TAction => {
  return {
    type: EActionTypes.USER_SAVE_SUCCESS,
    user,
  };
};

const saveUserFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_SAVE_FAIL,
    error,
  };
};

export const saveUser = (user: IUser) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(saveUserStart());
    try {
      const res = await axios.post<IUser>(`${apiPath}/add`, user);
      dispatch(saveUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveUserFail((response as AxiosResponse).data));
    }
  };
};

const listTestPlannersStart = (): TAction => {
  return {
    type: EActionTypes.USER_LIST_TEST_PLANNERS_START,
  };
};

const listTestPlannersSuccess = (users: IUser[]): TAction => {
  return {
    type: EActionTypes.USER_LIST_TEST_PLANNERS_SUCCESS,
    users,
  };
};

const listTestPlannersFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_LIST_TEST_PLANNERS_FAIL,
    error,
  };
};

export const listTestPlanners = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listTestPlannersStart());
    try {
      const res = await axios.get(
        `${apiPath}/list?level=${ELevel.TEST_PLANNER}`
      );
      dispatch(listTestPlannersSuccess(res.data.users));
    } catch ({ response }) {
      dispatch(listTestPlannersFail((response as AxiosResponse).data));
    }
  };
};

export const testSMS = (mobile: string) => {
  return async (): Promise<void> => {
    try {
      await axios.get(`${apiPath}/sms?mobile=${mobile}`);
      window.alert("SMS sending successful")
    } catch ({ response }) {
      window.alert("SMS sending failed")
    }
  };
};