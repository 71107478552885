import INotificationAction from "../../interfaces/action/INotificationAction";
import INotificationState from "../../interfaces/state/INotificationState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = INotificationState;
type ReducerSignature = (
  state: ReducerState,
  action: INotificationAction
) => ReducerState;

const initialState: ReducerState = {
  notifications: null,
  notSeen: null,
  loading: false,
  loadingId: null,
  error: null,
};

const listMyNotificationsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const listMyNotificationsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    notifications: action.notifications || null,
    notSeen: action.notSeen || null,
    error: null,
    loading: false,
  };
};

const listMyNotificationsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    notifications: null,
    notSeen: null,
    loading: false,
    error: action.error,
  };
};

const seenAllNotificationsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const seenAllNotificationsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    notifications: action.notifications || null,
    notSeen: action.notSeen || null,
    error: null,
    loading: false,
  };
};

const seenAllNotificationsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    notifications: null,
    notSeen: null,
    loading: false,
    error: action.error,
  };
};

const updateNotificationStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loadingId: action.id };
};

const updateNotificationSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    notifications: action.notifications || null,
    notSeen: action.notSeen || null,
    loadingId: null,
    error: null,
  };
};

const updateNotificationFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    notifications: null,
    notSeen: null,
    loadingId: null,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: INotificationAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.NOTIFICATION_MY_START:
      return listMyNotificationsStart(state, action);
    case EActionTypes.NOTIFICATION_MY_SUCCESS:
      return listMyNotificationsSuccess(state, action);
    case EActionTypes.NOTIFICATION_MY_FAIL:
      return listMyNotificationsFail(state, action);
    case EActionTypes.NOTIFICATION_SEEN_ALL_START:
      return seenAllNotificationsStart(state, action);
    case EActionTypes.NOTIFICATION_SEEN_ALL_SUCCESS:
      return seenAllNotificationsSuccess(state, action);
    case EActionTypes.NOTIFICATION_SEEN_ALL_FAIL:
      return seenAllNotificationsFail(state, action);
    case EActionTypes.NOTIFICATION_UPDATE_START:
      return updateNotificationStart(state, action);
    case EActionTypes.NOTIFICATION_UPDATE_SUCCESS:
      return updateNotificationSuccess(state, action);
    case EActionTypes.NOTIFICATION_UPDATE_FAIL:
      return updateNotificationFail(state, action);
    default:
      return state;
  }
};

export default reducer;