import { faBars, faBell, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { } from 'react-router-dom';
import { AuthUser } from '../../classes/AuthUser';
import { Routes } from '../../classes/Routes';
import HeaderContext from '../../context/HeaderContext';
import { ETranslation } from '../../translations/translation-keys';
import classes from './Header.module.scss';
import Menu, { EMenuType, IMenuItem } from './Menu/Menu';
import logo from "../../assets/logo.svg";
import { useAppSelector } from '../../hooks/useAppSelector';

interface IProps {
  currentUser: AuthUser;
  logout: () => void;
}

const Header: React.FC<IProps> = ({ currentUser, logout }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen((prevState) => !prevState);
  const { t } = useTranslation();
  
  const { notSeen } = useAppSelector((state) => state.notification);

  const clickHandler = (link: string) => navigate(link);

  const menuItems: IMenuItem[] = [
    { text: t(ETranslation.MY_PAGE), to: Routes.MY_PAGE },
    { text: t(ETranslation.TEST_MISSIONS), to: Routes.TEST_MISSIONS },
    { text: t(ETranslation.IMPORT), to: Routes.IMPORTS },
    { text: t(ETranslation.REPORTS), subMenuItems: [
        { text: t(ETranslation.DCO_REPORT), to: Routes.REPORTS },
        { text: t(ETranslation.LOG), to: Routes.LOG },
        // { text: t(ETranslation.COMPENSATION), to: Routes.COMPENSATIONS },
      ]
    },
    { text: t(ETranslation.FIELD_PERSONS), to: Routes.FIELD_PERSONS },
    { text: t(ETranslation.UNAVAILABILITY), to: Routes.UNAVAILABILITIES },
  ];

  const dropdownMenuItems: IMenuItem[] = [
    {
      text: currentUser?.name || "",
      subMenuItems: [
        { text: t(ETranslation.LOGOUT), action: logout },
        { text: t(ETranslation.COMMON_SETTINGS), action: () => navigate(Routes.SETTINGS) },
      ]
    }
  ];

  return (
    <HeaderContext.Provider value={{ menuItems, dropdownMenuItems }}>
      <Menu type={EMenuType.MOBILE} isOpen={isOpen} toggleMenu={toggleMenu} currentUser={currentUser} />
      <header className={classes.Container}>
        <div className={classes.Wrapper}>
          <div className={classes.ToggleMenu} onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} className={classes.Icon} />
          </div>
          <div className={classes.Logo} onClick={() => clickHandler(currentUser.redirect)}>
            <img src={logo} alt="Antidoping Norge" />
          </div>
          <Menu type={EMenuType.DESKTOP} isOpen={false} toggleMenu={toggleMenu} currentUser={currentUser} />
          <div style={{paddingBottom:"2rem", paddingLeft:"2rem", paddingRight:"3rem", marginLeft:"auto", position: "relative"}} 
            onClick={() => navigate(Routes.NOTIFICATIONS)}>
            <div style={{position: "absolute", zIndex: "9", margin:"6px"}}>
              {notSeen && notSeen>0 &&
              <FontAwesomeIcon icon={faCircleExclamation}  color="red" cursor="pointer"/>
              }
            </div>
            <div style={{position: "absolute", }}>
              <FontAwesomeIcon icon={faBell} size='2x' color="white" cursor="pointer"/>
            </div>
          </div>
        </div>
      </header>
    </HeaderContext.Provider>
  );
};

export default React.memo(Header);
