import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonSize } from "../../UI/Button/Button";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData } from "../../UI/Input/input-utils";
import { yearOptions } from "../../../data/select-data";
import { useUserOptions } from "../../../hooks/useUserOptions";
import { ELevel } from "../../../interfaces/domain/IUser";
import { ILogItemSearch } from "../../../store/actions/logItemActions";

interface IProps {
  onSearch: (search: ILogItemSearch) => void;
  log?: boolean;
}

enum ELogItemSearch {
  year = "year",
  userId = "userId",
  testMissionId = "testMissionId"
}

const initialInputs: IInputField = {
  [ELogItemSearch.year]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.YEAR,
    placeholderTranslation: ETranslation.YEAR,
    value: new Date().getFullYear().toString(),
    options: yearOptions,
  },
  [ELogItemSearch.userId]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.USER,
    placeholderTranslation: ETranslation.USER,
    value: "",
    options: [],
  },
  [ELogItemSearch.testMissionId]: {
    type: EInputType.text,
    labelTranslation: ETranslation.SEARCH_TEST_MISSION_ID,
    placeholderTranslation: ETranslation.SEARCH_TEST_MISSION_ID,
    value: "",
    options: [],
  },
};

const LogItemSearch: React.FC<IProps> = ({ onSearch, log }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);
  const createInput = useCreateInput(inputs, setInputs);
  const { userOptions, usersLoading } = useUserOptions({
    level: ELevel.FIELD_PERSON,
  });

  useEffect(() => {
    onSearch(getInputData(initialInputs));
  }, [onSearch]);

  return (
    <>
      {createInput(ELogItemSearch.year)}
      {createInput(ELogItemSearch.userId, {
        options: userOptions,
        loading: usersLoading,
      })}
      {log && createInput(ELogItemSearch.testMissionId)}
      <Button
        onClick={() => onSearch(getInputData(inputs))}
        size={EButtonSize.SMALL}
      >
        {t(ETranslation.COMMON_SEARCH)}
      </Button>
    </>
  );
};

export default LogItemSearch;
