import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { regionOptions } from "../../../data/select-data";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { useUserOptions } from "../../../hooks/useUserOptions";
import { ELevel } from "../../../interfaces/domain/IUser";
import { IWorkLogSearchOptions } from "../../../store/actions/workLogActions";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonSize } from "../../UI/Button/Button";
import Container from "../../UI/Container/Container";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData } from "../../UI/Input/input-utils";
import InputGroup from "../../UI/InputGroup/InputGroup";
import { ExportType, exportWorkLogs } from "../../../shared/export";
import { useAuthUser } from "../../../hooks/useAuthUser";
import { EWorkLogStatus, WORKLOG_STATUS_OPTIONS } from "../../../interfaces/domain/IWorkLog";

interface IProps {
  onSearch: (search: IWorkLogSearchOptions) => void;
}

enum EWorkLogSearch {
  userId = "userId",
  startDate = "startDate",
  endDate = "endDate",
  status = "status",
  exported = "exported",
}

const initialInputs: IInputField = {
  [EWorkLogSearch.userId]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.USER,
    placeholderTranslation: ETranslation.SELECT_USER_PLACEHOLDER,
    value: "",
    options: regionOptions,
  },
  [EWorkLogSearch.startDate]: {
    type: EInputType.date,
    labelTranslation: ETranslation.START_DATE,
    placeholderTranslation: ETranslation.START_DATE,
    value: "",
    options: regionOptions,
  },
  [EWorkLogSearch.endDate]: {
    type: EInputType.date,
    labelTranslation: ETranslation.END_DATE,
    placeholderTranslation: ETranslation.END_DATE,
    value: "",
    options: regionOptions,
  },
  [EWorkLogSearch.status]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.STATUS,
    placeholderTranslation: ETranslation.STATUS,
    value: EWorkLogStatus.accepted,
    options: WORKLOG_STATUS_OPTIONS,
  },
  [EWorkLogSearch.exported]: {
    type: EInputType.checkbox,
    labelTranslation: ETranslation.EXPORTED,
    placeholderTranslation: ETranslation.EXPORTED,
    value: "",
  },
};

const WorkLogSearch: React.FC<IProps> = ({ onSearch }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);
  const createInput = useCreateInput(inputs, setInputs);
  
  const authUser = useAuthUser();
  const isFieldPerson = useMemo(() => authUser?.isFieldPerson, [authUser?.isFieldPerson]);

  const { userOptions, usersLoading } = useUserOptions({
    level: ELevel.FIELD_PERSON,
  });

  useEffect(() => {
    onSearch(getInputData(initialInputs));
  }, [onSearch]);

  const exportHandler = useCallback((exportType: ExportType) => {
    exportWorkLogs({ ...getInputData(inputs), exportType });
  }, [inputs]);

  return (
    <Container>
      <InputGroup>
        {createInput(EWorkLogSearch.status)}
        {!isFieldPerson && createInput(EWorkLogSearch.userId, {
          options: userOptions,
          loading: usersLoading,
        })}
      </InputGroup>
      <InputGroup>
        {createInput(EWorkLogSearch.startDate)}
        {createInput(EWorkLogSearch.endDate)}
      </InputGroup>
      {createInput(EWorkLogSearch.exported)}
      <InputGroup>
        <Button
          onClick={() => onSearch(getInputData(inputs))}
          size={EButtonSize.SMALL}
        >
          {t(ETranslation.COMMON_SEARCH)}
        </Button>
        {!isFieldPerson && (
          <>
            <Button onClick={() => exportHandler("CSV")} size={EButtonSize.SMALL}>
              {t(ETranslation.WORKLOG_EXPORT_CSV)}
            </Button>
            <Button onClick={() => exportHandler("VISMA")} size={EButtonSize.SMALL}>
              {t(ETranslation.WORKLOG_EXPORT_VISMA)}
            </Button>
          </>
        )}
      </InputGroup>
    </Container>
  );
};

export default WorkLogSearch;
