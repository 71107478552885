export enum EActionTypes {
    // Auth
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,

    AUTH_GET_START,
    AUTH_GET_SUCCESS,
    AUTH_GET_FAIL,

    AUTH_GET_CLEAR,

    // Test missions
    TEST_MISSION_LIST_START,
    TEST_MISSION_LIST_SUCCESS,
    TEST_MISSION_LIST_FAIL,

    TEST_MISSION_CLEAR,

    TEST_MISSION_GET_START,
    TEST_MISSION_GET_SUCCESS,
    TEST_MISSION_GET_FAIL,

    TEST_MISSION_UPDATE_START,
    TEST_MISSION_UPDATE_SUCCESS,
    TEST_MISSION_UPDATE_FAIL,

    TEST_MISSION_SAVE_START,
    TEST_MISSION_SAVE_SUCCESS,
    TEST_MISSION_SAVE_FAIL,

    TEST_MISSION_PARTICIPANT_UPDATE_START,
    TEST_MISSION_PARTICIPANT_UPDATE_SUCCESS,
    TEST_MISSION_PARTICIPANT_UPDATE_FAIL,

    TEST_MISSION_PARTICIPANT_DELETE_START,
    TEST_MISSION_PARTICIPANT_DELETE_SUCCESS,
    TEST_MISSION_PARTICIPANT_DELETE_FAIL,

    TEST_MISSION_PARTICIPANT_ACCEPT_START,
    TEST_MISSION_PARTICIPANT_ACCEPT_SUCCESS,
    TEST_MISSION_PARTICIPANT_ACCEPT_FAIL,

    //TEST_MISSION_COMMENT_UPDATE_START,
    //TEST_MISSION_COMMENT_UPDATE_SUCCESS,
    //TEST_MISSION_COMMENT_UPDATE_FAIL,
    TEST_MISSION_COMMENT_ADD_START,
    TEST_MISSION_COMMENT_ADD_SUCCESS,
    TEST_MISSION_COMMENT_ADD_FAIL,

    TEST_MISSION_COMMENT_DELETE_START,
    TEST_MISSION_COMMENT_DELETE_SUCCESS,
    TEST_MISSION_COMMENT_DELETE_FAIL,

    TEST_MISSION_ACCEPT_START,
    TEST_MISSION_ACCEPT_SUCCESS,
    TEST_MISSION_ACCEPT_FAIL,

    TEST_MISSION_REFUSE_START,
    TEST_MISSION_REFUSE_SUCCESS,
    TEST_MISSION_REFUSE_FAIL,

    TEST_MISSION_MY_START,
    TEST_MISSION_MY_SUCCESS,
    TEST_MISSION_MY_FAIL,

    TEST_MISSION_MARK_COMPLETE_START,
    TEST_MISSION_MARK_COMPLETE_SUCCESS,
    TEST_MISSION_MARK_COMPLETE_FAIL,

    TEST_MISSION_DELETE_START,
    TEST_MISSION_DELETE_SUCCESS,
    TEST_MISSION_DELETE_FAIL,

    // Import
    IMPORT_UPLOAD_START,
    IMPORT_UPLOAD_SUCCESS,
    IMPORT_UPLOAD_FAIL,
    IMPORT_UPLOAD_PROGRESS,

    IMPORT_LIST_START,
    IMPORT_LIST_SUCCESS,
    IMPORT_LIST_FAIL,

    IMPORT_GET_START,
    IMPORT_GET_SUCCESS,
    IMPORT_GET_FAIL,

    IMPORT_AUTOMATCH_START,
    IMPORT_AUTOMATCH_SUCCESS,
    IMPORT_AUTOMATCH_FAIL,

    IMPORT_DELETE_START,
    IMPORT_DELETE_SUCCESS,
    IMPORT_DELETE_FAIL,

    // Unavailability
    UNAVAILABILITY_CLEAR,

    UNAVAILABILITY_LIST_START,
    UNAVAILABILITY_LIST_SUCCESS,
    UNAVAILABILITY_LIST_FAIL,

    UNAVAILABILITY_GET_START,
    UNAVAILABILITY_GET_SUCCESS,
    UNAVAILABILITY_GET_FAIL,

    UNAVAILABILITY_UPDATE_START,
    UNAVAILABILITY_UPDATE_SUCCESS,
    UNAVAILABILITY_UPDATE_FAIL,

    UNAVAILABILITY_SAVE_START,
    UNAVAILABILITY_SAVE_SUCCESS,
    UNAVAILABILITY_SAVE_FAIL,

    UNAVAILABILITY_DELETE_START,
    UNAVAILABILITY_DELETE_SUCCESS,
    UNAVAILABILITY_DELETE_FAIL,

    UNAVAILABILITY_MY_LIST_START,
    UNAVAILABILITY_MY_LIST_SUCCESS,
    UNAVAILABILITY_MY_LIST_FAIL,

    // Log Item
    LOG_ITEM_TEST_MISSION_LIST_START,
    LOG_ITEM_TEST_MISSION_LIST_SUCCESS,
    LOG_ITEM_TEST_MISSION_LIST_FAIL,

    LOG_ITEM_TEST_MISSION_COUNT_START,
    LOG_ITEM_TEST_MISSION_COUNT_SUCCESS,
    LOG_ITEM_TEST_MISSION_COUNT_FAIL,

    LOG_ITEM_GET_REPORTS_START,
    LOG_ITEM_GET_REPORTS_SUCCESS,
    LOG_ITEM_GET_REPORTS_FAIL,

    LOG_ITEM_INCREMENT_COUNT,

    // User
    USER_LIST_START,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,

    USER_LIST_SEARCH_START,
    USER_LIST_SEARCH_SUCCESS,
    USER_LIST_SEARCH_FAIL,

    USER_GET_START,
    USER_GET_SUCCESS,
    USER_GET_FAIL,

    USER_UPDATE_START,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,

    USER_UPDATE_SETTINGS_START,
    USER_UPDATE_SETTINGS_SUCCESS,
    USER_UPDATE_SETTINGS_FAIL,

    USER_SAVE_START,
    USER_SAVE_SUCCESS,
    USER_SAVE_FAIL,

    USER_LIST_TEST_PLANNERS_START,
    USER_LIST_TEST_PLANNERS_SUCCESS,
    USER_LIST_TEST_PLANNERS_FAIL,

    USER_CLEAR,

    // Sports
    GET_SPORTS_START,
    GET_SPORTS_SUCCESS,
    GET_SPORTS_FAIL,

    CALENDAR_GET_START,
    CALENDAR_GET_SUCCESS,
    CALENDAR_GET_FAIL,

    // Notification
    NOTIFICATION_MY_START,
    NOTIFICATION_MY_SUCCESS,
    NOTIFICATION_MY_FAIL,

    NOTIFICATION_SEEN_ALL_START,
    NOTIFICATION_SEEN_ALL_SUCCESS,
    NOTIFICATION_SEEN_ALL_FAIL,

    NOTIFICATION_UPDATE_START,
    NOTIFICATION_UPDATE_SUCCESS,
    NOTIFICATION_UPDATE_FAIL,

    // Work Log
    WORKLOG_GET_START,
    WORKLOG_GET_SUCCESS,
    WORKLOG_GET_FAIL,

    WORKLOG_LIST_START,
    WORKLOG_LIST_SUCCESS,
    WORKLOG_LIST_FAIL,

    WORKLOG_SAVE_START,
    WORKLOG_SAVE_SUCCESS,
    WORKLOG_SAVE_FAIL,

    WORKLOG_UPDATE_START,
    WORKLOG_UPDATE_SUCCESS,
    WORKLOG_UPDATE_FAIL,

    WORKLOG_MY_START,
    WORKLOG_MY_SUCCESS,
    WORKLOG_MY_FAIL,

    WORKLOG_EXPENSE_SAVE_START,
    WORKLOG_EXPENSE_SAVE_SUCCESS,
    WORKLOG_EXPENSE_SAVE_FAIL,

    WORKLOG_EXPENSE_UPLOAD_START,
    WORKLOG_EXPENSE_UPLOAD_SUCCESS,
    WORKLOG_EXPENSE_UPLOAD_FAIL,
    
    WORKLOG_EXPENSE_UPDATE_START,
    WORKLOG_EXPENSE_UPDATE_SUCCESS,
    WORKLOG_EXPENSE_UPDATE_FAIL,

    WORKLOG_EXPENSE_DELETE_START,
    WORKLOG_EXPENSE_DELETE_SUCCESS,
    WORKLOG_EXPENSE_DELETE_FAIL,

    WORKLOG_CLEAR,

    WORKLOG_ACCEPT_START,
    WORKLOG_ACCEPT_SUCCESS,
    WORKLOG_ACCEPT_FAIL,

    WORKLOG_RETURN_START,
    WORKLOG_RETURN_SUCCESS,
    WORKLOG_RETURN_FAIL,

    WORKLOG_CLEAR_ERROR,

    WORKLOG_MARK_PENDING_START,
    WORKLOG_MARK_PENDING_SUCCESS,
    WORKLOG_MARK_PENDING_FAIL,

    WORKLOG_EXPENSE_ATTACHMENT_DELETE_START,
    WORKLOG_EXPENSE_ATTACHMENT_DELETE_SUCCESS,
    WORKLOG_EXPENSE_ATTACHMENT_DELETE_FAIL,
    
    WORKLOG_LIST_LINK_OPTIONS_START,
    WORKLOG_LIST_LINK_OPTIONS_SUCCESS,
    WORKLOG_LIST_LINK_OPTIONS_FAIL,
}
