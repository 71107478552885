import moment from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { ETranslation } from "../../../translations/translation-keys";
import Spinner from "../../UI/Spinner/Spinner";
import MyPageSection from "../MyPageSection/MyPageSection";
import { DATEPATTERN_FIN } from "../../../data/constants";

const MyPageUnavailability: React.FC = () => {
  const {
    myUnavailabilities: unavailabilities,
    myUnavailabilitiesLoading: loading,
  } = useAppSelector((state) => state.unavailability);
  const { t } = useTranslation();
  return (
    <MyPageSection style={{ marginBottom: "2rem" }}>
      <h3>{t(ETranslation.UNAVAILABILITY)}</h3>
      {loading ? (
        <Spinner />
      ) : unavailabilities?.length ? (
        unavailabilities?.map((unavailability) => (
          <div key={unavailability.id}>
            <p>
              {moment(unavailability.startDate).format("DD.MM.")} -{" "}
              {moment(unavailability.endDate).format(DATEPATTERN_FIN)}{" "}
              {unavailability.info.substring(0, 120)}
            </p>
          </div>
        ))
      ) : (
        <p>{t(ETranslation.NO_UNAVAILABILITIES)}</p>
      )}
    </MyPageSection>
  );
};

export default MyPageUnavailability;
