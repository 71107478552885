import React, { useEffect, useState } from "react";
import Container from "../../components/UI/Container/Container";
import { EFieldPerson } from "../FieldPersons/FieldPerson/FieldPerson";
import { EInputType, IInputField } from "../../components/UI/Input/Input";
import { ETranslation } from "../../translations/translation-keys";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useCreateInput } from "../../hooks/useCreateInput";
import Button, { EButtonColor } from "../../components/UI/Button/Button";
import { useTranslation } from "react-i18next";
import { getInputData, initForm } from "../../components/UI/Input/input-utils";
import { IUser } from "../../interfaces/domain/IUser";
import * as actions from "../../store/actions";
import { useAppDispatch } from "../../hooks/useAppDispatch";

const Settings: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { loading, authUser } = useAppSelector(
        (state) => state.auth
    );

    const { saveOrUpdateLoading, saveOrUpdateOk } = useAppSelector(
        (state) => state.user
    );

    useEffect(() => {
    if (saveOrUpdateOk) {
      dispatch(actions.getCurrentUser());
    }
  }, [dispatch, saveOrUpdateOk]);

    const [inputs, setInputs] = useState<IInputField>({
    [EFieldPerson.notificationEmail]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.NOTIFICATION_EMAIL,
      placeholderTranslation: ETranslation.NOTIFICATION_EMAIL,
      value: "",
      validation: {
        required: false,
      },
    },
    [EFieldPerson.notificationSMS]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.NOTIFICATION_SMS,
      placeholderTranslation: ETranslation.NOTIFICATION_SMS,
      value: "",
      validation: {
        required: false,
      },
    },
    });

    useEffect(() => {
        if (authUser) initForm(setInputs, authUser);
    }, [authUser]);

    const saveHandler = () => {
        const data = getInputData<IUser>(inputs);
        dispatch(actions.updateUserSettings(data));
    }

    const createInput = useCreateInput(inputs, setInputs, {
        disabled: saveOrUpdateLoading,
    });

    return (
    <Container>
        {createInput(EFieldPerson.notificationEmail)}
        {createInput(EFieldPerson.notificationSMS)}
        <Button
          disabled={loading || saveOrUpdateLoading
        }
          color={EButtonColor.SUCCESS}
          onClick={saveHandler}
          loading={saveOrUpdateLoading}
        >
          {t(ETranslation.COMMON_SAVE)}
        </Button>
    </Container>
  )
}

export default Settings;
