import IMyTestMissionAction from "../../interfaces/action/IMyTestMissionAction";
import IMyTestMissionState from "../../interfaces/state/IMyTestMissionState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = IMyTestMissionState;
type ReducerSignature = (
  state: ReducerState,
  action: IMyTestMissionAction
) => ReducerState;

const initialState: ReducerState = {
  testMission: null,
  offeredTestMissions: null,
  acceptedTestMissions: null,
  completedTestMissions: null,
  loading: false,
  error: null,
};

const acceptTestMissionStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const acceptTestMissionSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  const offeredTestMissions =
    state.offeredTestMissions?.filter((t) => t.id !== action.testMission?.id) ||
    null;
  const acceptedTestMissions = action.testMission
    ? [...(state.acceptedTestMissions || []), action.testMission]
    : state.acceptedTestMissions;
  return {
    ...state,
    offeredTestMissions,
    acceptedTestMissions,
    testMission: action.testMission || null,
    error: null,
    loading: false,
  };
};

const acceptTestMissionFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const refuseTestMissionStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const refuseTestMissionSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  const offeredTestMissions =
    state.offeredTestMissions?.filter((t) => t.id !== action.testMission?.id) ||
    null;
  const acceptedTestMissions =
    state.acceptedTestMissions?.filter(
      (t) => t.id !== action.testMission?.id
    ) || null;
  const completedTestMissions =
    state.completedTestMissions?.filter(
      (t) => t.id !== action.testMission?.id
    ) || null;
  return {
    ...state,
    offeredTestMissions,
    acceptedTestMissions,
    completedTestMissions,
    testMission: action.testMission || null,
    error: null,
    loading: false,
  };
};

const refuseTestMissionFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const listMyTestMissionsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const listMyTestMissionsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    acceptedTestMissions: action.acceptedTestMissions || null,
    offeredTestMissions: action.offeredTestMissions || null,
    completedTestMissions: action.completedTestMissions || null,
    error: null,
    loading: false,
  };
};

const listMyTestMissionsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    acceptedTestMissions: null,
    offeredTestMissions: null,
    completedTestMissions: null,
    loading: false,
    error: action.error,
  };
};

const testMissionMarkCompleteStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const testMissionMarkCompleteSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  const acceptedTestMissions =
    state.acceptedTestMissions?.filter((t) => t.id !== action.testMission?.id) ||
    null;
    const completedTestMissions = [...(state.completedTestMissions ?? [])];
  if (action.testMission) completedTestMissions?.push(action.testMission);
  return {
    ...state,
    acceptedTestMissions,
    completedTestMissions,
    testMission: action.testMission || null,
    error: null,
    loading: false,
  };
};

const testMissionMarkCompleteFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: IMyTestMissionAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.TEST_MISSION_ACCEPT_START:
      return acceptTestMissionStart(state, action);
    case EActionTypes.TEST_MISSION_ACCEPT_SUCCESS:
      return acceptTestMissionSuccess(state, action);
    case EActionTypes.TEST_MISSION_ACCEPT_FAIL:
      return acceptTestMissionFail(state, action);
    case EActionTypes.TEST_MISSION_REFUSE_START:
      return refuseTestMissionStart(state, action);
    case EActionTypes.TEST_MISSION_REFUSE_SUCCESS:
      return refuseTestMissionSuccess(state, action);
    case EActionTypes.TEST_MISSION_REFUSE_FAIL:
      return refuseTestMissionFail(state, action);
    case EActionTypes.TEST_MISSION_MY_START:
      return listMyTestMissionsStart(state, action);
    case EActionTypes.TEST_MISSION_MY_SUCCESS:
      return listMyTestMissionsSuccess(state, action);
    case EActionTypes.TEST_MISSION_MY_FAIL:
      return listMyTestMissionsFail(state, action);
    case EActionTypes.TEST_MISSION_MARK_COMPLETE_START:
      return testMissionMarkCompleteStart(state, action);
    case EActionTypes.TEST_MISSION_MARK_COMPLETE_SUCCESS:
      return testMissionMarkCompleteSuccess(state, action);
    case EActionTypes.TEST_MISSION_MARK_COMPLETE_FAIL:
      return testMissionMarkCompleteFail(state, action);
    default:
      return state;
  }
};

export default reducer;
