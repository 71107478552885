import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../components/Title/Title";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import WorkLogSearch from "../../components/WorkLogs/WorkLogSearch/WorkLogSearch";
import WorkLogsTable from "../../components/WorkLogs/WorkLogsTable/WorkLogsTable";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import { IWorkLogSearchOptions } from "../../store/actions/workLogActions";

const WorkLogs: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { workLogs, loading } = useAppSelector((state) => state.workLog);

  const searchHandler = useCallback(
    (search: IWorkLogSearchOptions) => {
      dispatch(actions.listWorkLog(search));
    },
    [dispatch]
  );

  return (
    <>
      <WorkLogSearch onSearch={searchHandler} />
      <Container>
        <Title translation={ETranslation.COMPENSATIONS} />
        {loading ? (
          <Spinner />
        ) : workLogs ? (
          <WorkLogsTable workLogs={workLogs} isList />
        ) : (
          <p>{t(ETranslation.NOT_FOUND_ERROR)}</p>
        )}
      </Container>
    </>
  );
};

export default WorkLogs;
