export enum ERoleName {
  // ADVISOR = "ADVISOR",
  // ADVISOR_INTERNATIONAL = "ADVISOR_INTERNATIONAL",
  ASSISTANT = "ASSISTANT",
  BLOOD_COLLECTION_OFFICER = "BLOOD_COLLECTION_OFFICER",
  DCO = "DCO",
  LEAD_DCO = "LEAD_DCO",
}

export interface IRole {
  roleName?: ERoleName;
  inTraining: boolean;
}
