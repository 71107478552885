import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import INotificationAction from "../../interfaces/action/INotificationAction";
import { EActionTypes } from "../EActionTypes";
import { INotificationJson } from "./../../interfaces/domain/INotification";

type TAction = INotificationAction;
const apiPath = "/json/notification";

const listMyNotificationsStart = (): TAction => {
  return {
    type: EActionTypes.NOTIFICATION_MY_START,
  };
};

const listMyNotificationsSuccess = (
  json: INotificationJson
): TAction => {
  return {
    type: EActionTypes.NOTIFICATION_MY_SUCCESS,
    notifications: json.notifications,
    notSeen: json.notSeen
  };
};

const listMyNotificationsFail = (error: string): TAction => {
  return {
    type: EActionTypes.NOTIFICATION_MY_FAIL,
    error,
  };
};

export const listMyNotifications = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listMyNotificationsStart());
    try {
      const res = await axios.get(`${apiPath}/my`);
      dispatch(listMyNotificationsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listMyNotificationsFail((response as AxiosResponse).data));
    }
  };
};

const seenAllNotificationsStart = (): TAction => {
  return {
    type: EActionTypes.NOTIFICATION_SEEN_ALL_START,
  };
};

const seenAllNotificationsSuccess = (
  json: INotificationJson
): TAction => {
  return {
    type: EActionTypes.NOTIFICATION_SEEN_ALL_SUCCESS,
    notifications: json.notifications,
    notSeen: json.notSeen
  };
};

const seenAllNotificationsFail = (error: string): TAction => {
  return {
    type: EActionTypes.NOTIFICATION_SEEN_ALL_FAIL,
    error,
  };
};

export const seenAllNotifications = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(seenAllNotificationsStart());
    try {
      const res = await axios.put(`${apiPath}/seen-all`);
      dispatch(seenAllNotificationsSuccess(res.data));
    } catch ({ response }) {
      dispatch(seenAllNotificationsFail((response as AxiosResponse).data));
    }
  };
};

const updateNotificationStart = (id:string): TAction => {
  return {
    type: EActionTypes.NOTIFICATION_UPDATE_START,
    id
  };
};

const updateNotificationSuccess = (
  json: INotificationJson,
  id: string
): TAction => {
  return {
    type: EActionTypes.NOTIFICATION_UPDATE_SUCCESS,
    notifications: json.notifications,
    notSeen: json.notSeen,
    id
  };
};

const updateNotificationFail = (error: string, id:string): TAction => {
  return {
    type: EActionTypes.NOTIFICATION_UPDATE_FAIL,
    error,
    id
  };
};

export const hideNotification = (id:string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateNotificationStart(id));
    try {
      const res = await axios.put(`${apiPath}/hide?id=${id}`);
      dispatch(updateNotificationSuccess(res.data, id));
    } catch ({ response }) {
      dispatch(updateNotificationFail((response as AxiosResponse).data, id));
    }
  };
};

export const seenNotification = (id:string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateNotificationStart(id));
    try {
      const res = await axios.put(`${apiPath}/seen?id=${id}`);
      dispatch(updateNotificationSuccess(res.data, id));
    } catch ({ response }) {
      dispatch(updateNotificationFail((response as AxiosResponse).data, id));
    }
  };
};