import { AxiosResponse } from "axios";
import axios from "../axios";
import { objectToQueryString } from "./qs-utils";

interface IAttachmentParams {
  id?: string;
  expenseId?: string;
  attachmentId?: string;
}

export const getWorkLogExpenseAttachment = async (
  fileName: string,
  params: IAttachmentParams
) => {
  try {
    const res = await axios({
      url: `/json/worklog/expenses/get-attachment${objectToQueryString(
        params
      )}`,
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    return null;
  } catch ({ response }: any) {
    return (response as AxiosResponse).data;
  }
};
