import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { ETranslation } from "../../../translations/translation-keys";
import Spinner from "../../UI/Spinner/Spinner";
import MyPageSection from "../MyPageSection/MyPageSection";

const MyPageStatistics: React.FC = () => {
  const { t } = useTranslation();
  const { count, countLoading: loading } = useAppSelector(state => state.logItem);
  return (
    <MyPageSection>
      <h3>{t(ETranslation.TEST_MISSIONS)} {new Date().getFullYear()}</h3>
      {loading
        ? <Spinner />
        : count
          ? <div style={{ marginLeft: '3rem' }}>
            <div>{t(ETranslation.COUNT_OFFERED)}: {count.offered}</div>
            <div>{t(ETranslation.COUNT_REFUSED)}: {count.refusedOrcanceled}</div>
            <div>{t(ETranslation.COUNT_COMPLETED)}: {count.completed}</div>
          </div>
          : <p>{t(ETranslation.NOT_FOUND_ERROR)}</p>}
    </MyPageSection>
  )
}

export default MyPageStatistics;
