import React from "react";

interface IProps {
  name: string;
  value: string | JSX.Element;
}

const FieldPersonBoxText: React.FC<IProps> = ({ name, value }) => (
  <div>
    {name}:
    {' '}
    {value}
  </div>
);

export default FieldPersonBoxText;
