import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useWorkLog } from "../../../../hooks/useWorkLog";
import { IWorkLogExpense } from "../../../../interfaces/domain/IWorkLog";
import { ETranslation } from "../../../../translations/translation-keys";
import Fieldset from "../../../Fieldset/Fieldset";
import Spinner, { ESpinnerSize } from "../../../UI/Spinner/Spinner";
import WorkLogExpense from "./WorkLogExpense/WorkLogExpense";

interface IProps {
  expenses: IWorkLogExpense[];
}

const WorkLogExpenses: React.FC<IProps> = ({ expenses }) => {
  const { saveWorkLogExpenseHandler, disableInputs } = useWorkLog();
  const { saveWorkLogExpenseLoading } = useAppSelector(
    (state) => state.workLog
  );
  const { t } = useTranslation();
  return (
    <>
      <div style={{ marginTop: "1rem", fontWeight: "bold" }}>
        {t(ETranslation.EXPENSES)}{" "}
        {saveWorkLogExpenseLoading ? (
          <Spinner size={ESpinnerSize.SMALL} style={{ display: "inline" }} />
        ) : (
          !disableInputs && (
            <FontAwesomeIcon
              icon={faAdd}
              style={{ cursor: "pointer" }}
              onClick={saveWorkLogExpenseHandler}
            />
          )
        )}
      </div>
      {expenses.map((expense, i) => (
        <Fieldset
          label={`${i + 1}. ${expense.description ?? ""} ${
            expense.amount ? `(${expense.amount} NOK)` : ""
          } ${!expense.attachments?.length ? `- ${t(ETranslation.NO_ATTACHMENTS)}` : ""}`}
          key={expense.id}
          accordion
          initialOpen={expense.open}
          // legendStyle={!expense.attachments?.length ? { color: "red" } : {}}
        >
          <WorkLogExpense expense={expense} />
        </Fieldset>
      ))}
    </>
  );
};

export default WorkLogExpenses;
