import { ETranslation } from "../../translations/translation-keys";
import { BaseDomain } from "./IBaseDomain";
import { IComment } from "./IComment";
import { IParticipant } from "./IParticipant";
import { IRegion } from "./IRegion";
import { IUser } from "./IUser";

export enum ETestMissionStatus {
  ISSUED = "ISSUED",
  MODOC = "MODOC",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  PLANNING = "PLANNING",
}

export const testMissionStatus = {
  [ETestMissionStatus.ISSUED]: ETranslation.STATUS_ISSUED,
  [ETestMissionStatus.MODOC]: ETranslation.STATUS_MODOC,
  [ETestMissionStatus.COMPLETE]: ETranslation.STATUS_COMPLETE,
  [ETestMissionStatus.CANCELLED]: ETranslation.STATUS_CANCELLED,
  [ETestMissionStatus.PLANNING]: ETranslation.STATUS_PLANNING,
};


export enum ETestMissionSearchOption {
  filter = "filter",
  startDate = "startDate",
  sportsCategory = "sportsCategory",
  sportsDiscipline = "sportsDiscipline",
  testPlanner = "testPlanner",
  status = "status",
  participants = "participants",
  unfulfilledRoles = "unfulfilledRoles",
  overdue = "overdue",
  region = "region",
  testMissionId = "testMissionId"
}

export enum EGenderRequirement {
  FEMALE = "FEMALE",
  MALE = "MALE",
  BOTH = "BOTH",
  ANY = "ANY"
}

export enum ESportsCategory {
  AESTHETIC_GROUP_GYMNASTICS = "AESTHETIC_GROUP_GYMNASTICS",
  AIKIDO = "AIKIDO",
  AIR_SPORTS = "AIR_SPORTS",
  AMERICAN_FOOTBALL = "AMERICAN_FOOTBALL",
  AQUATICS = "AQUATICS",
  ARCHERY = "ARCHERY",
  ARM_WRESTLING = "ARM_WRESTLING",
  ATHLETICS = "ATHLETICS",
  AUSTRALIAN_RULES_FOOTBALL = "AUSTRALIAN_RULES_FOOTBALL",
  AUTOMOBILE_SPORTS = "AUTOMOBILE_SPORTS",
  BADMINTON = "BADMINTON",
  BALLE_AU_TAMBOURIN = "BALLE_AU_TAMBOURIN",
  BALLON_AU_POING = "BALLON_AU_POING",
  BANDY = "BANDY",
  BASEBALL = "BASEBALL",
  BASKETBALL = "BASKETBALL",
  BASQUE_PELOTA = "BASQUE_PELOTA",
  BASQUE_SPORTS = "BASQUE_SPORTS",
  BEACH_TENNIS = "BEACH_TENNIS",
  BIATHLON = "BIATHLON",
  BILLIARDS_SPORTS = "BILLIARDS_SPORTS",
  BOBSLEIGH = "BOBSLEIGH",
  BOCCIA = "BOCCIA",
  BODYBUILDING = "BODYBUILDING",
  BOOT_THROWING = "BOOT_THROWING",
  BOULES_SPORTS = "BOULES_SPORTS",
  BOWLING = "BOWLING",
  BOWLS = "BOWLS",
  BOXING = "BOXING",
  BRAZILIAN_JIU_JITSU = "BRAZILIAN_JIU_JITSU",
  BRIDGE = "BRIDGE",
  BROOMBALL = "BROOMBALL",
  BUDO = "BUDO",
  CISS_GOLF = "CISS_GOLF",
  CISS_SAILING = "CISS_SAILING",
  COLEO = "COLEO",
  CP_FOOTBALL = "CP_FOOTBALL",
  CAMEL_RACING = "CAMEL_RACING",
  CANICROSS = "CANICROSS",
  CANOE = "CANOE",
  CASTING = "CASTING",
  CHANBARA = "CHANBARA",
  CHEER = "CHEER",
  CHEERLEADING = "CHEERLEADING",
  CHESS = "CHESS",
  CHIN_UP = "CHIN_UP",
  CLAY_TARGET_SHOOTING = "CLAY_TARGET_SHOOTING",
  COLLEGE_SPORTS = "COLLEGE_SPORTS",
  COMBAT_SPORTS = "COMBAT_SPORTS",
  COMPANY_SPORTS = "COMPANY_SPORTS",
  COURSE_CAMARGUAISE = "COURSE_CAMARGUAISE",
  COURSE_LANDAISE = "COURSE_LANDAISE",
  CRICKET = "CRICKET",
  CROSSFIT = "CROSSFIT",
  CROSSBOW = "CROSSBOW",
  CROSSWORD = "CROSSWORD",
  CURLING = "CURLING",
  CYCLING = "CYCLING",
  DANCESPORT = "DANCESPORT",
  DARTS = "DARTS",
  DET_FRIVILLIGE_SKYTTERVESEN = "DET_FRIVILLIGE_SKYTTERVESEN",
  DODGEBALL = "DODGEBALL",
  DOMIMO = "DOMIMO",
  DRAGON_BOAT = "DRAGON_BOAT",
  DRAGON_AND_LION_DANCE = "DRAGON_AND_LION_DANCE",
  DRAUGHTS = "DRAUGHTS",
  ELECTRONIC_SPORTS = "ELECTRONIC_SPORTS",
  EQUESTRIAN = "EQUESTRIAN",
  EXTREME_SPORTS = "EXTREME_SPORTS",
  FIFA_ESPORTS = "FIFA_ESPORTS",
  FENCING = "FENCING",
  FIELD_HOCKEY = "FIELD_HOCKEY",
  FIREFIGHTING_AND_RESCUING = "FIREFIGHTING_AND_RESCUING",
  FISTBALL = "FISTBALL",
  FITNESS = "FITNESS",
  FLOORBALL = "FLOORBALL",
  FLYING_DISC = "FLYING_DISC",
  FOOT_VOLLEY = "FOOT_VOLLEY",
  FOOTBALL = "FOOTBALL",
  FOOTBALL_7_A_SIDE = "FOOTBALL_7_A_SIDE",
  FOOTBALL_IBSA_FOOTBALL = "FOOTBALL_IBSA_FOOTBALL",
  FRENCH_BOXING = "FRENCH_BOXING",
  FRISKIS_SVETTIS = "FRISKIS_SVETTIS",
  FULL_CONTACT = "FULL_CONTACT",
  FUNCTIONAL_FITNESS = "FUNCTIONAL_FITNESS",
  GAELIC_GAMES = "GAELIC_GAMES",
  GIGATHLON = "GIGATHLON",
  GIRA = "GIRA",
  GLORY_KICKBOXING = "GLORY_KICKBOXING",
  GO = "GO",
  GOALBALL = "GOALBALL",
  GOLF = "GOLF",
  GYMNASTICS = "GYMNASTICS",
  HANDBALL = "HANDBALL",
  HEAVY_ATHLETICS = "HEAVY_ATHLETICS",
  HEAVY_EVENTS = "HEAVY_EVENTS",
  HIGHLAND_GAMES = "HIGHLAND_GAMES",
  HORNUSSEN = "HORNUSSEN",
  HORSE_RACING = "HORSE_RACING",
  HUNTING_SPORTS = "HUNTING_SPORTS",
  ICE_HOCKEY = "ICE_HOCKEY",
  ICESTOCKSPORT = "ICESTOCKSPORT",
  JET_SKI = "JET_SKI",
  JIU_JITSU = "JIU_JITSU",
  JU_JITSU = "JU_JITSU",
  JUDO = "JUDO",
  JUMP_ROPE = "JUMP_ROPE",
  KAATSEN = "KAATSEN",
  KABADDI = "KABADDI",
  KARATE = "KARATE",
  KEMPO = "KEMPO",
  KENDO = "KENDO",
  KETTLEBELL_LIFTING = "KETTLEBELL_LIFTING",
  KHO_KHO = "KHO_KHO",
  KICKBOXING = "KICKBOXING",
  KIN_BALL = "KIN_BALL",
  KORFBALL = "KORFBALL",
  KRACHTBAL = "KRACHTBAL",
  KUDO = "KUDO",
  KUNG_FU = "KUNG_FU",
  KURASH = "KURASH",
  KYUDO = "KYUDO",
  LACROSSE = "LACROSSE",
  LEONES_BOWLS = "LEONES_BOWLS",
  LIFESAVING = "LIFESAVING",
  LONGUE_PAUME = "LONGUE_PAUME",
  LUGE = "LUGE",
  MODOC_TRAINING_SPORT = "MODOC_TRAINING_SPORT",
  MILITARY_PATROL = "MILITARY_PATROL",
  MILITARY_SPORT_PENTATHLON = "MILITARY_SPORT_PENTATHLON",
  MINIGOLF = "MINIGOLF",
  MIXED_MARTIAL_ARTS = "MIXED_MARTIAL_ARTS",
  MODERN_PENTATHLON = "MODERN_PENTATHLON",
  MOTOBALL = "MOTOBALL",
  MOTORCYCLE_RACING = "MOTORCYCLE_RACING",
  MOUNTAINEERING_AND_CLIMBING = "MOUNTAINEERING_AND_CLIMBING",
  MUAYTHAI = "MUAYTHAI",
  MULTIFUNCTIONAL_TRAINING = "MULTIFUNCTIONAL_TRAINING",
  NAGINATA = "NAGINATA",
  NETBALL = "NETBALL",
  NORGES_BILSPORTFORBUND = "NORGES_BILSPORTFORBUND",
  NORGES_RYTTERFORBUN = "NORGES_RYTTERFORBUN",
  NORSK_GALOPP = "NORSK_GALOPP",
  NORSKE_TRAVSELSKAP = "NORSKE_TRAVSELSKAP",
  OBSTACLE_SPORTS = "OBSTACLE_SPORTS",
  ORIENTEERING = "ORIENTEERING",
  PADEL = "PADEL",
  PARA_ALPINE_SKIING = "PARA_ALPINE_SKIING",
  PARA_ATHLETICS = "PARA_ATHLETICS",
  PARA_BIATHLON = "PARA_BIATHLON",
  PARA_BOWLING = "PARA_BOWLING",
  PARA_BOWLS = "PARA_BOWLS",
  PARA_CROSS_COUNTRY_SKIING = "PARA_CROSS_COUNTRY_SKIING",
  PARA_DANCESPORT = "PARA_DANCESPORT",
  PARA_GOLF = "PARA_GOLF",
  PARA_ICE_HOCKEY = "PARA_ICE_HOCKEY",
  PARA_NORDIC_SKIING = "PARA_NORDIC_SKIING",
  PARA_POWERLIFTING = "PARA_POWERLIFTING",
  PARA_SNOWBOARD = "PARA_SNOWBOARD",
  PARA_SWIMMING = "PARA_SWIMMING",
  PARAVOLLEY = "PARAVOLLEY",
  PENCAK_SILAT = "PENCAK_SILAT",
  POKER = "POKER",
  POLE_SPORTS = "POLE_SPORTS",
  POLO = "POLO",
  POWER_PRESS = "POWER_PRESS",
  POWERBOATING = "POWERBOATING",
  POWERLIFTING = "POWERLIFTING",
  PRACTICAL_SHOOTING = "PRACTICAL_SHOOTING",
  QAZAQ_KURESI = "QAZAQ_KURESI",
  RACQUETBALL = "RACQUETBALL",
  RAFTING = "RAFTING",
  RINGETTE = "RINGETTE",
  RINKBALL = "RINKBALL",
  ROLLER_SPORTS = "ROLLER_SPORTS",
  ROWING = "ROWING",
  RUGBY_LEAGUE = "RUGBY_LEAGUE",
  RUGBY_UNION = "RUGBY_UNION",
  SAILING = "SAILING",
  SAMBO = "SAMBO",
  SAVATE = "SAVATE",
  SCHOOL_SPORTS = "SCHOOL_SPORTS",
  SEPAKTAKRAW = "SEPAKTAKRAW",
  SHEARING_SPORTS = "SHEARING_SPORTS",
  SHOE_RACING = "SHOE_RACING",
  SHOOTING = "SHOOTING",
  SHOOTING_PARA_SPORT = "SHOOTING_PARA_SPORT",
  SHOWDOWN = "SHOWDOWN",
  SHUTTLECOCK = "SHUTTLECOCK",
  SITBALL = "SITBALL",
  SITTING_VOLLEYBALL = "SITTING_VOLLEYBALL",
  SKATING = "SKATING",
  SKI_MOUNTAINEERING = "SKI_MOUNTAINEERING",
  SKIBOB = "SKIBOB",
  SKIING = "SKIING",
  SKYBALL = "SKYBALL",
  SKYRUNNING = "SKYRUNNING",
  SLEDDOG = "SLEDDOG",
  SNOWMOBILE = "SNOWMOBILE",
  SOFT_TENNIS = "SOFT_TENNIS",
  SOFTBALL = "SOFTBALL",
  SPEEDBALL = "SPEEDBALL",
  SPELEOLOGY = "SPELEOLOGY",
  SPORT_CLIMBING = "SPORT_CLIMBING",
  SPORT_FISHING = "SPORT_FISHING",
  SQUASH = "SQUASH",
  STILTS_RACING = "STILTS_RACING",
  STREET_AND_BALL_HOCKEY = "STREET_AND_BALL_HOCKEY",
  STRONGEST_MAN = "STRONGEST_MAN",
  SUMO = "SUMO",
  SURFING = "SURFING",
  SWISS_NATIONAL_GYMNASTICS = "SWISS_NATIONAL_GYMNASTICS",
  TABLE_SOCCER = "TABLE_SOCCER",
  TABLE_TENNIS = "TABLE_TENNIS",
  TAEKWONDO = "TAEKWONDO",
  TAEKWONDO_ITF_AUSTRIA = "TAEKWONDO_ITF_AUSTRIA",
  TAIDO = "TAIDO",
  TARUNG_DERAJAT = "TARUNG_DERAJAT",
  TCHOUKBALL = "TCHOUKBALL",
  TENNIS = "TENNIS",
  TEQBALL = "TEQBALL",
  TEST_SPORT = "TEST_SPORT",
  THAI_TRADITIONAL_SPORTS = "THAI_TRADITIONAL_SPORTS",
  THE_NORWEGIAN_POLICE_ACADEMY = "THE_NORWEGIAN_POLICE_ACADEMY",
  TOUCH_FOOTBALL = "TOUCH_FOOTBALL",
  TRADITIONAL_ARCHERY = "TRADITIONAL_ARCHERY",
  TRADITIONAL_KARATE = "TRADITIONAL_KARATE",
  TRADITIONAL_MARTIAL_ARTS = "TRADITIONAL_MARTIAL_ARTS",
  TRAPEZE = "TRAPEZE",
  TRIATHLON = "TRIATHLON",
  TUG_OF_WAR = "TUG_OF_WAR",
  TWIRLING = "TWIRLING",
  ULTIMATE_FIGHTING = "ULTIMATE_FIGHTING",
  ULTRA_TRIATHLON = "ULTRA_TRIATHLON",
  UNDERWATER_SPORTS = "UNDERWATER_SPORTS",
  UNICYCLING = "UNICYCLING",
  UNIFIGHT = "UNIFIGHT",
  VA_A = "VA_A",
  VOLLEYBALL = "VOLLEYBALL",
  VOVINAM = "VOVINAM",
  WSLEAGUE_SURFING = "WSLEAGUE_SURFING",
  WATERSKIING = "WATERSKIING",
  WEIGHTLIFTING = "WEIGHTLIFTING",
  WHEELCHAIR_SPORTS = "WHEELCHAIR_SPORTS",
  WOODBALL = "WOODBALL",
  WOODSMAN = "WOODSMAN",
  WRESTLING = "WRESTLING",
  WUSHU = "WUSHU",
  XIANGQI = "XIANGQI",
  CROSS_TRAINING_MILITARY_CROSS_TRAINING = "CROSS_TRAINING_MILITARY_CROSS_TRAINING",
  PARA_BADMINTON = "PARA_BADMINTON",
  PARA_BASKETBALL = "PARA_BASKETBALL",
  BILSPORT = "BILSPORT",
  CANOE_KAYAK = "CANOE_KAYAK",
  PARA_CURLING = "PARA_CURLING",
  DFS = "DFS",
  FLERIDRETT = "FLERIDRETT",
  FLY_CASTING = "FLY_CASTING",
  GALOPP = "GALOPP",
  PARA_GYMNASTICS = "PARA_GYMNASTICS",
  PARA_JUDO = "PARA_JUDO",
  PHS = "PHS",
  POLESPORT = "POLESPORT",
  PARA_SAILING = "PARA_SAILING",
  PARA_TABLE_TENNIS = "PARA_TABLE_TENNIS",
  TAEKWONDO_NTN = "TAEKWONDO_NTN",
  PARA_TENNIS = "PARA_TENNIS",
  TRAV = "TRAV",
  TRENINGSSENTER = "TRENINGSSENTER",
  STUDENT_SPORTS = "STUDENT_SPORTS",
}

export interface ITestMission extends BaseDomain {
  testMissionId: string;
  status: ETestMissionStatus;
  description: string;
  startDate: string;
  endDate: string;
  numberOfTests: number;
  leadDCO: IUser;
  inCompetition: boolean;
  region: IRegion;
  regionString: string;
  participants: IParticipant[];
  testPlanner: IUser;
  sportsDescription: string;
  sportsCategory: ESportsCategory;
  sportsDiscipline: string;
  // csvString: ;
  // importDetails;
  genderRequirement: EGenderRequirement;
  comments: IComment[];
  completedDate: string;
  completedUser: IUser;
  urgent: boolean;
  city: string;
  modocMissionId: string
  testTypes: string;
  ignoreInCompetition: boolean;
}
