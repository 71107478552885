import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import InputContextProvider from "../../../../context/InputContext";
import { IWorkLogPassenger } from "../../../../interfaces/domain/IWorkLog";
import { ETranslation } from "../../../../translations/translation-keys";
import { TInputValue } from "../../../UI/Input/Input";
import WorkLogPassenger from "./WorkLogPassenger";
import { useWorkLog } from "../../../../hooks/useWorkLog";

interface IProps {
  passengers: IWorkLogPassenger[];
  setPassengers: React.Dispatch<React.SetStateAction<IWorkLogPassenger[]>>;
}

const WorkLogPassengers: React.FC<IProps> = ({ passengers, setPassengers }) => {
  const { t } = useTranslation();
  const { disableInputs } = useWorkLog();

  const addHandler = useCallback(() => {
    const id = uuid();
    setPassengers((passengers) => [
      ...passengers,
      { id, name: "", kilometers: "" },
    ]);
  }, [setPassengers]);

  const deleteHandler = useCallback(
    (id: string) => {
      setPassengers((passengers) => {
        return [...passengers].filter((passenger) => passenger.id !== id);
      });
    },
    [setPassengers]
  );

  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      const data = _ as IWorkLogPassenger;
      setPassengers((passengers) => {
        const newPassengers = [...passengers];
        const index = newPassengers.findIndex(
          (passenger) => passenger.id === data.id
        );
        console.log("index", index);
        if (index > -1) {
          console.log("update passenger", data);
          newPassengers[index] = {
            ...data,
            [inputName]: value,
          };
        }
        return newPassengers;
      });
      return Promise.resolve(true);
    },
    [setPassengers]
  );

  return (
    <div style={{ margin: "1rem 0" }}>
      <p style={{ fontWeight: "bold" }}>
        {t(ETranslation.PASSENGERS)}{" "}
        {!disableInputs && (
          <FontAwesomeIcon
            icon={faPlus}
            onClick={addHandler}
            style={{ cursor: "pointer" }}
          />
        )}
      </p>
      <InputContextProvider initDone onAutoUpdate={updateHandler}>
        {passengers?.length > 0 &&
          passengers.map((passenger) => (
            <WorkLogPassenger
              key={passenger.id}
              passenger={passenger}
              onDelete={() => deleteHandler(passenger.id!)}
            />
          ))}
      </InputContextProvider>
    </div>
  );
};

export default WorkLogPassengers;
