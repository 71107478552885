import { faEdit, faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IParticipant } from "../../../../../interfaces/domain/IParticipant";
import { TestMissionParticipantBadge } from "../../../../../shared/test-mission";
import { ETranslation } from "../../../../../translations/translation-keys";
import Table from "../../../../UI/Table/Table";

interface IProps {
  participants?: IParticipant[];
  onEdit?: (participant?: IParticipant) => void;
  onAccept?: (participant?: IParticipant) => void;
}

const TestMissionParticipantsTable: React.FC<IProps> = ({
  participants,
  onEdit,
  onAccept
}) => {
  const { t } = useTranslation();
  const showWorkLogStatus = useMemo(() => participants?.some(p => p.workLogStatus), [participants]);
  return (
    <Table>
      <thead>
        <tr>
          <th>{t(ETranslation.STATUS)}</th>
          <th>{t(ETranslation.ROLE_NAME)}</th>
          <th>{t(ETranslation.NAME)}</th>
          {showWorkLogStatus && <th>{t(ETranslation.WORKLOG_STATUS)}</th>}
          {onEdit && <th></th>}
          {onAccept && <th></th>}
        </tr>
      </thead>
      <tbody>
        {participants?.map((participant, i) => (
          <tr key={participant.id || i}>
            <td>
              {participant.user && (
                <TestMissionParticipantBadge status={participant.status} />
              )}
            </td>
            <td>
              {participant.participantType.roleName &&
                t(participant.participantType.roleName)}
            </td>
            <td>{participant.user?.name}</td>
            {showWorkLogStatus && <td>{participant.workLogStatus}</td>}
            {onEdit && (
              <td style={{ width: "1px" }}>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faEdit}
                  onClick={() => onEdit(participant)}
                />
              </td>
            )}
            {onAccept && (
              <td style={{ width: "1px" }}>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faClipboardCheck}
                  onClick={() => onAccept(participant)}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TestMissionParticipantsTable;
