import { BaseDomain } from "./IBaseDomain";

export enum ENotificationAction {
  NEW_COMMENT = "NEW_COMMENT",
  SELECTED_ROLE = "SELECTED_ROLE",
  REMOVED_ROLE = "REMOVED_ROLE",
  CANCELED_ROLE = "CANCELED_ROLE",
  REFUSED_ROLE = "REFUSED_ROLE",
  ACCEPTED_ROLE = "ACCEPTED_ROLE",
  WORKLOG_CREATED = "WORKLOG_CREATED",
  WORKLOG_PENDING = "WORKLOG_PENDING",
  WORKLOG_ACCEPT = "WORKLOG_ACCEPT",
  WORKLOG_RETURN = "WORKLOG_RETURN",
}

export interface INotification extends BaseDomain {
  userId?: string;
  testMissionId?: string;
  seen?: boolean;
  hidden?: boolean;
  action?: ENotificationAction;
  message: string;
  dateString: string;
}

export interface INotificationJson {
  notifications?: INotification[];
  notSeen?: number;
}
