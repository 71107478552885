import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import IImportAction from "../../interfaces/action/IImportAction";
import { EActionTypes } from "../EActionTypes";
import { IImport } from './../../interfaces/domain/IImport';

type TAction = IImportAction;
const apiPath = "/json/import";

const uploadStart = (): TAction => {
  return {
    type: EActionTypes.IMPORT_UPLOAD_START,
  };
};

const uploadSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.IMPORT_UPLOAD_SUCCESS,
    id,
  };
};

const uploadFail = (error: string): TAction => {
  return {
    type: EActionTypes.IMPORT_UPLOAD_FAIL,
    error,
  };
};

const uploadProgress = (progress: number): TAction => {
  return {
    type: EActionTypes.IMPORT_UPLOAD_PROGRESS,
    progress,
  };
};

export const uploadImport = (files: File[]) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(uploadStart());
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      const res = await axios.post(`${apiPath}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progress) => {
          dispatch(
            uploadProgress(Math.round((progress.loaded / progress.total) * 100))
          );
        },
      });
      dispatch(uploadSuccess(res.data.id));
    } catch ({ response }) {
      dispatch(uploadFail((response as AxiosResponse).data));
    }
  };
};

const listImportsStart = (): TAction => {
  return {
    type: EActionTypes.IMPORT_LIST_START,
  };
};

const listImportsSuccess = (summaries: IImport[]): TAction => {
  return {
    type: EActionTypes.IMPORT_LIST_SUCCESS,
    summaries,
  };
};

const listImportsFail = (error: string): TAction => {
  return {
    type: EActionTypes.IMPORT_LIST_FAIL,
    error,
  };
};

export const listImports = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listImportsStart());
    try {
      const res = await axios.get<IImport[]>(`${apiPath}/list`);
      dispatch(listImportsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listImportsFail((response as AxiosResponse).data));
    }
  };
};

const getImportStart = (): TAction => {
  return {
    type: EActionTypes.IMPORT_GET_START,
  };
};

const getImportSuccess = (summary: IImport): TAction => {
  return {
    type: EActionTypes.IMPORT_GET_SUCCESS,
    summary,
  };
};

const getImportFail = (error: string): TAction => {
  return {
    type: EActionTypes.IMPORT_GET_FAIL,
    error,
  };
};

export const getImport = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getImportStart());
    try {
      const res = await axios.get<IImport>(`${apiPath}/get?id=${id}`);
      dispatch(getImportSuccess(res.data));
    } catch ({ response }) {
      dispatch(getImportFail((response as AxiosResponse).data));
    }
  };
};

const importAutomatchStart = (): TAction => {
  return {
    type: EActionTypes.IMPORT_AUTOMATCH_START,
  };
};

const importAutomatchSuccess = (): TAction => {
  return {
    type: EActionTypes.IMPORT_AUTOMATCH_SUCCESS,
  };
};

const importAutomatchFail = (error: string): TAction => {
  return {
    type: EActionTypes.IMPORT_AUTOMATCH_FAIL,
    error,
  };
};

export const importAutomatch = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(importAutomatchStart());
    try {
      await axios.put(`${apiPath}/automatch?id=${id}`);
      dispatch(importAutomatchSuccess());
    } catch ({ response }) {
      dispatch(importAutomatchFail((response as AxiosResponse).data));
    }
  };
};

const deleteImportStart = (): TAction => {
  return {
    type: EActionTypes.IMPORT_DELETE_START,
  };
};

const deleteImportSuccess = (): TAction => {
  return {
    type: EActionTypes.IMPORT_DELETE_SUCCESS,
  };
};

const deleteImportFail = (error: string): TAction => {
  return {
    type: EActionTypes.IMPORT_DELETE_FAIL,
    error,
  };
};

export const deleteImport = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteImportStart());
    try {
      await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteImportSuccess());
    } catch ({ response }) {
      dispatch(deleteImportFail((response as AxiosResponse).data));
    }
  };
};