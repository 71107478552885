import ISportsAction from "../../interfaces/action/ISportsAction";
import ISportsState from "../../interfaces/state/ISportsState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = ISportsState;
type ReducerSignature = (
  state: ReducerState,
  action: ISportsAction
) => ReducerState;

const initialState: ReducerState = {
  sports: null,
  loading: false,
  error: null,
};

const getSportsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getSportsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    sports: action.sports || null,
    error: null,
    loading: false,
  };
};

const getSportsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    sports: null,
    loading: false,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: ISportsAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.GET_SPORTS_START:
      return getSportsStart(state, action);
    case EActionTypes.GET_SPORTS_SUCCESS:
      return getSportsSuccess(state, action);
    case EActionTypes.GET_SPORTS_FAIL:
      return getSportsFail(state, action);
    default:
      return state;
  }
};

export default reducer;