import axios from "../../axios";
import { EActionTypes } from "../EActionTypes";

import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import ISportsAction from "../../interfaces/action/ISportsAction";
import { ISports } from "../../interfaces/domain/ISports";

const getSportsStart = (): ISportsAction => {
  return {
    type: EActionTypes.GET_SPORTS_START,
  };
};

const getSportsSuccess = (sports: ISports[]): ISportsAction => {
  return {
    type: EActionTypes.GET_SPORTS_SUCCESS,
    sports,
  };
};

const getSportsFail = (error: string): ISportsAction => {
  return {
    type: EActionTypes.GET_SPORTS_FAIL,
    error,
  };
};

export const getSports = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getSportsStart());
    try {
      const res = await axios.get(`/json/testmission/sports`);
      dispatch(getSportsSuccess(res.data));
    } catch ({ response }) {
      dispatch(getSportsFail((response as AxiosResponse).data));
    }
  };
};