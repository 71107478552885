export const DATEPATTERN_ISO_DATE = "yyyy-MM-DD";
export const DATEPATTERN_FIN = "DD.MM.yyyy";

export enum ESearchStorageKey {
    TEST_MISSIONS_SPORTS_CATEGORY = "TEST_MISSIONS_SPORTS_CATEGORY",
    TEST_MISSIONS_TEST_PLANNER = "TEST_MISSIONS_TEST_PLANNER",
    TEST_MISSIONS_STATUS = "TEST_MISSIONS_STATUS",
    TEST_MISSIONS_PARTICIPANT = "TEST_MISSIONS_PARTICIPANT",
    TEST_MISSIONS_REGION = "TEST_MISSIONS_REGION",
    TEST_MISSIONS_TEST_MISSION_ID = "TEST_MISSIONS_TEST_MISSION_ID",
    TEST_MISSIONS_START_DATE = "TEST_MISSIONS_START_DATE",
}