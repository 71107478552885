import { FirebaseError } from "firebase/app";
import {
  getAuth,
  getMultiFactorResolver,
  MultiFactorError,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MultiFactorAuthDialog from "../components/MultiFactorAuthDialog/MultiFactorAuthDialog";
import ModalContext, { EModalSize } from "../context/ModalContext";
import firebase from "../firebase";
import { ETranslation } from "../translations/translation-keys";
import { useAppDispatch } from "./useAppDispatch";
import * as actions from "../store/actions";

const auth = getAuth(firebase);

export const useAuthentication = () => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useContext(ModalContext);
  const dispatch = useAppDispatch();

  const [authLoading, setAuthLoading] = useState(false);
  const [verifier, setVerifier] = useState<RecaptchaVerifier | null>(null);
  const [authError, setAuthError] = useState("");

  const recaptchaRef = useRef(null);

  const resolveSignIn = useCallback(
    (
      verificationId: string,
      resolver: MultiFactorResolver,
      verificationCode: string
    ): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        const cred = PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        // Complete sign-in.
        resolver
          .resolveSignIn(multiFactorAssertion)
          .then(() => {
            // User successfully signed in with the second factor phone number.
            setAuthLoading(false);
            resolve(true);
          })
          .catch((error) => {
            setAuthLoading(false);
            reject(error.code);
          });
      });
    },
    []
  );

  const setMultiFactorAuthDialog = useCallback(
    (verificationId: string, resolver: MultiFactorResolver) =>
      setModal({
        isOpen: true,
        title: t(ETranslation.MULTI_FACTOR_AUTH_DIALOG_TITLE),
        size: EModalSize.SMALL,
        content: (
          <MultiFactorAuthDialog
            onCancel={() => {
              closeModal();
              setAuthLoading(false);
            }}
            onConfirm={(verificationCode) =>
              resolveSignIn(verificationId, resolver, verificationCode)
            }
          />
        ),
      }),
    [closeModal, resolveSignIn, setModal, t]
  );

  const multiFactorAuth = useCallback(
    async (verifier: RecaptchaVerifier, error: MultiFactorError) => {
      setAuthLoading(true);

      if (error.code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(auth, error);
        // Ask user which second factor to use.
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        // Send SMS verification code
        new PhoneAuthProvider(auth)
          .verifyPhoneNumber(phoneInfoOptions, verifier)
          .then(function (verificationId) {
            // Ask user for the SMS verification code. Then:
            setMultiFactorAuthDialog(verificationId, resolver);
          })
          .catch((error) => {
            setAuthLoading(false);
            setAuthError(error.code);
          });
      } else {
        setAuthLoading(false);
        setAuthError(error.code);
      }
    },
    [setMultiFactorAuthDialog]
  );

  useEffect(() => {
    if (recaptchaRef.current) {
      setVerifier(
        new RecaptchaVerifier(recaptchaRef.current, { size: "invisible" }, auth)
      );
    }
  }, [recaptchaRef]);

  const signIn = async (username: string, password: string) => {
    await signInWithEmailAndPassword(auth, username, password)
      .catch((error: MultiFactorError) => {
        if (verifier && error) multiFactorAuth(verifier, error);
      })
      .catch((error: FirebaseError) => {
        setAuthError(error.code);
      })
      .catch((error) => {
        setAuthError(error.code);
      });
  };

  const signOut = () => {
    auth.signOut();
    dispatch(actions.getCurrentUserClear());
  };

  return { signIn, authLoading, authError, recaptchaRef, signOut };
};
