import moment from "moment-timezone";
import React, { ReactNode, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ETranslation } from "../../translations/translation-keys";
import Button from "../UI/Button/Button";
import { EInputType, IInputField } from "../UI/Input/Input";
import { getInputData, validateInputs } from "../UI/Input/input-utils";
import InputGroup from "../UI/InputGroup/InputGroup";

interface IProps {
  body?: ReactNode;
  onConfirm: (completedDate: string) => void;
  onCancel: () => void;
}

enum EMarkCompleteDialog {
  completedDate = "completedDate",
}

const MarkCompleteDialog: React.FC<IProps> = ({
  body,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);

  const [inputs, setInputs] = useState<IInputField>({
    [EMarkCompleteDialog.completedDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.COMPLETED_DATE,
      placeholderTranslation: ETranslation.COMPLETED_DATE,
      value: moment().format("yyy-MM-DD"),
      validation: {
        required: true,
      },
    },
  });

  const submitHandler = () => {
    const data = getInputData(inputs);
    onConfirm(data.completedDate);
  };

  const createInput = useCreateInput(inputs, setInputs);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  return (
    <>
      {body}
      {createInput(EMarkCompleteDialog.completedDate)}
      <InputGroup>
        <Button onClick={submitHandler} disabled={!isValid}>
          {t(ETranslation.COMMON_YES)}
        </Button>
        <Button onClick={onCancel}>{t(ETranslation.COMMON_NO)}</Button>
      </InputGroup>
    </>
  );
};

export default MarkCompleteDialog;
