import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { useWorkLog } from "../../../hooks/useWorkLog";
import { EWorkLogStatus, ILinkedWorkLog, IWorkLog, IWorkLogPassenger } from "../../../interfaces/domain/IWorkLog";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor } from "../../UI/Button/Button";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData, initForm } from "../../UI/Input/input-utils";
import InputGroup from "../../UI/InputGroup/InputGroup";
import WorkLogExpenses from "./WorkLogExpenses/WorkLogExpenses";
import WorkLogSummary from "./WorkLogSummary/WorkLogSummary";
import WorkLogPassengers from "./WorkLogPassengers/WorkLogPassengers";
import LinkedWorkLogs from "./LinkedWorkLogs/LinkedWorkLogs";

enum EWorkLog {
  startDate = "startDate",
  startHours = "startHours",
  startMinutes = "startMinutes",
  endDate = "endDate",
  endHours = "endHours",
  endMinutes = "endMinutes",
  kilometers = "kilometers",
  expenses = "expenses",
  //   status,
  //   testMissionId,
  //   userId,
  //   userName,
  // passengers = "passengers",
  route = "route",
  departmentNumber = "departmentNumber",
  projectNumber = "projectNumber",
  additionalInformation = "additionalInformation",
  includePlanningHours = "includePlanningHours",
  includeResponsibilityHours = "includeResponsibilityHours",
}

const WorkLogEdit: React.FC = () => {
  const { workLog, updateWorkLogHandler, disableInputs } = useWorkLog();
  const [passengers, setPassengers] = useState<IWorkLogPassenger[]>([]);
  const [linkedWorkLogs, setLinkedWorkLogs] = useState<ILinkedWorkLog[]>([]);
  const [linkedWorkLogsValid, setLinkedWorkLogsValid] = useState(true);
  
  const {
    updateWorkLogLoading,
    testMissionStartDate,
    testMissionEndDate,
    testMissionStartDateString,
    testMissionEndDateString,
  } = useAppSelector((state) => state.workLog);

  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    [EWorkLog.startDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.START_DATE,
      placeholderTranslation: ETranslation.START_DATE,
      value: "",
    },
    [EWorkLog.startHours]: {
      type: EInputType.number,
      labelTranslation: ETranslation.START_HOURS,
      placeholderTranslation: ETranslation.START_HOURS,
      value: "",
      min: "0",
      max: "23",
    },
    [EWorkLog.startMinutes]: {
      type: EInputType.number,
      labelTranslation: ETranslation.START_MINUTES,
      placeholderTranslation: ETranslation.START_MINUTES,
      value: "",
      min: "0",
      max: "59",
    },
    [EWorkLog.endDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.END_DATE,
      placeholderTranslation: ETranslation.END_DATE,
      value: "",
    },
    [EWorkLog.endHours]: {
      type: EInputType.number,
      labelTranslation: ETranslation.END_HOURS,
      placeholderTranslation: ETranslation.END_HOURS,
      value: "",
      min: "0",
      max: "23",
    },
    [EWorkLog.endMinutes]: {
      type: EInputType.number,
      labelTranslation: ETranslation.END_MINUTES,
      placeholderTranslation: ETranslation.END_MINUTES,
      value: "",
      min: "0",
      max: "59",
    },
    [EWorkLog.kilometers]: {
      type: EInputType.number,
      labelTranslation: ETranslation.KILOMETERS,
      placeholderTranslation: ETranslation.KILOMETERS,
      value: "",
      min: "0",
    },
    [EWorkLog.route]: {
      type: EInputType.textarea,
      label: "Route",
      placeholder: "Route",
      value: "",
    },
    [EWorkLog.departmentNumber]: {
      type: EInputType.select,
      label: "Department number",
      placeholder: "Department number",
      value: "",
      options: [
        { value: "4", label: "4" },
        { value: "8", label: "8" },
      ],
    },
    [EWorkLog.projectNumber]: {
      type: EInputType.select,
      label: "Project number",
      placeholder: "Project number",
      value: "",
      options: [
        { value: "04", label: "04" },
        { value: "08", label: "08" },
      ],
    },
    [EWorkLog.additionalInformation]: {
      type: EInputType.textarea,
      label: "Additional information",
      placeholder: "Additional information",
      value: "",
      rows: 2,
    },
    [EWorkLog.includePlanningHours]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.PLANNING,
      value: "",
    },
    [EWorkLog.includeResponsibilityHours]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.RESPONSIBILITY,
      value: "",
    },
  });

  useEffect(() => {
    if (workLog) {
      initForm(setInputs, workLog);
      setPassengers(workLog.passengers ?? []);
      setLinkedWorkLogs(workLog.linkedWorkLogs ?? []);
    }
  }, [workLog]);

  useEffect(() => {
    if (linkedWorkLogs && linkedWorkLogs.length > 0) {
      let percentageTotal = 0;
      for (let i = 0; i < linkedWorkLogs.length; i++) {
        percentageTotal += +linkedWorkLogs[i].percentage ?? 0;
      }
      setLinkedWorkLogsValid(percentageTotal === 100);
    }
  }, [linkedWorkLogs]);

  const submitHandler = useCallback(() => {
    const data = getInputData<IWorkLog>(inputs);
    data.passengers = passengers.map((p) => ({ ...p, id: typeof p.id === "number" ? p.id : undefined }));
    data.linkedWorkLogs = linkedWorkLogs.filter(log => typeof log.id === "number");
    updateWorkLogHandler(data);
  }, [inputs, updateWorkLogHandler, passengers, linkedWorkLogs]);

  const createInput = useCreateInput(inputs, setInputs, {
    disabled: updateWorkLogLoading || disableInputs,
  });

  const setTestMissionDate = useCallback((date?: string | null) => {
    if (!workLog || disableInputs || !date) return;
    initForm(setInputs, {
      ...workLog,
      startDate: date,
      endDate: date,
    });
  }, [workLog, disableInputs]);

  return (
    <>
      <p>
        {workLog?.testMissionId} {t(ETranslation.COMPENSATION)}
      </p>
      <p style={{ fontWeight: "bold" }}>{t(ETranslation.WORKLOG)}</p>
      {workLog?.status &&
        [EWorkLogStatus.draft, EWorkLogStatus.returned].includes(
          workLog.status
        ) ? (
          <div>
            <Button onClick={() => setTestMissionDate(testMissionStartDate)} color={EButtonColor.NONE} style={{ padding: "0 0 1rem 0", cursor: "pointer", color: "blue", textDecoration: "underline" }}>
              {testMissionStartDateString}
            </Button>
            {" "}-{" "}
            <Button onClick={() => setTestMissionDate(testMissionEndDate)} color={EButtonColor.NONE} style={{ padding: "0 0 1rem 0", cursor: "pointer", color: "blue", textDecoration: "underline" }}>
              {testMissionEndDateString}
            </Button>
          </div>
        ) : (
          <p>{testMissionStartDateString} - {testMissionEndDateString}</p>
        )}
        
        <hr/>
        {workLog?.id && <LinkedWorkLogs linkedWorkLogs={linkedWorkLogs} setLinkedWorkLogs={setLinkedWorkLogs} userId={workLog?.userId} replacementId={workLog.id} isError={!linkedWorkLogsValid} />}

      <InputGroup>
        {createInput(EWorkLog.startDate)}
        <InputGroup style={{ flexDirection: "row", margin: "0" }}>
          {createInput(EWorkLog.startHours)}
          {createInput(EWorkLog.startMinutes)}
        </InputGroup>
      </InputGroup>
      <InputGroup>
        {createInput(EWorkLog.endDate)}
        <InputGroup style={{ flexDirection: "row", margin: "0" }}>
          {createInput(EWorkLog.endHours)}
          {createInput(EWorkLog.endMinutes)}
        </InputGroup>
      </InputGroup>
      <InputGroup>
        {createInput(EWorkLog.includePlanningHours)}
        {createInput(EWorkLog.includeResponsibilityHours)}
      </InputGroup>
      {createInput(EWorkLog.kilometers)}
      <WorkLogPassengers passengers={passengers} setPassengers={setPassengers} />
      {createInput(EWorkLog.route)}
      {createInput(EWorkLog.additionalInformation)}
      {!disableInputs && (
        <InputGroup>
          <Button
            onClick={submitHandler}
            loading={updateWorkLogLoading}
            disabled={updateWorkLogLoading || !linkedWorkLogsValid}
          >
            {t(ETranslation.SAVE_WORKLOG)}
          </Button>
          <></>
        </InputGroup>
      )}
      <hr />
      <WorkLogExpenses expenses={workLog?.expenses ?? []} />
      <hr />
      <WorkLogSummary workLog={workLog} style={{ paddingBottom: "1rem" }} />
    </>
  );
};

export default WorkLogEdit;
