import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo.svg";
import PasswordResetDialog from "../../components/Auth/PasswordResetDialog/PasswordResetDialog";
import Alert from "../../components/UI/Alert/Alert";
import Button, { EButtonColor } from "../../components/UI/Button/Button";
import { EInputType, IInputField } from "../../components/UI/Input/Input";
import { getInputData } from "../../components/UI/Input/input-utils";
import Spinner, { ESpinnerColor } from "../../components/UI/Spinner/Spinner";
import ModalContext, { EModalSize } from "../../context/ModalContext";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAuthentication } from "../../hooks/useAuthentication";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ETranslation } from "../../translations/translation-keys";
import classes from "./Auth.module.scss";

export enum EAuth {
  username = "username",
  password = "password",
}

const Auth: React.FC = () => {
  const { setModal, closeModal } = useContext(ModalContext);
  const { loading } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const { signIn, authLoading, authError, recaptchaRef } = useAuthentication();

  const [inputs, setInputs] = useState<IInputField>({
    [EAuth.username]: {
      type: EInputType.email,
      labelTranslation: ETranslation.USERNAME,
      placeholderTranslation: ETranslation.USERNAME,
      value: "",
    },
    [EAuth.password]: {
      type: EInputType.password,
      labelTranslation: ETranslation.PASSWORD,
      placeholderTranslation: ETranslation.PASSWORD,
      value: "",
    },
  });

  const createInput = useCreateInput(inputs, setInputs);

  const submitHandler = async (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = getInputData(inputs);
    // const preferredLanguage = i18n.language;
    signIn(data.username, data.password);
  };

  const setPasswordResetDialog = () =>
    setModal({
      isOpen: true,
      title: t(ETranslation.RESET_PASSWORD),
      size: EModalSize.SMALL,
      content: <PasswordResetDialog onCancel={closeModal} />,
    });

  return (
    <div className={classes.Container}>
      <div className={classes.LoginBox}>
        <div className={classes.Title}>
          <img src={logo} alt="Antidoping Norge" />
        </div>
        <div ref={recaptchaRef} />
        {((loading || authLoading) && (
          <Spinner color={ESpinnerColor.SECONDARY} />
        )) || (
          <form onSubmit={submitHandler}>
            <div className={classes.Inputs}>
              {createInput(EAuth.username)}
              {createInput(EAuth.password)}
            </div>
            <div className={classes.ButtonContainer}>
              <Button
                color={EButtonColor.SECONDARY}
                onClick={submitHandler}
                className={classes.Button}
                type="submit"
              >
                {t(ETranslation.LOGIN)}
              </Button>
            </div>
            <div className={classes.ButtonContainer}>
              {/* {i18n.language === "fi" ? (
                <Button onClick={() => i18n.changeLanguage('en')} color={EButtonColor.NONE}>{t(ETranslation.LANGUAGE_IN_ENGLISH)}</Button>
              ) : (
                <Button onClick={() => i18n.changeLanguage('fi')} color={EButtonColor.NONE}>{t(ETranslation.LANGUAGE_IN_FINNISH)}</Button>
              )} */}
              <Button
                onClick={setPasswordResetDialog}
                color={EButtonColor.NONE}
              >
                {t(ETranslation.FORGOT_PASSWORD)}
              </Button>
            </div>
            {(authError) && (
              <Alert noHorizontalMargin>
                {t(authError)}
              </Alert>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Auth;
