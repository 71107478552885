// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
// import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
// import "firebase/auth";

import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyC_KDvjEcTftOKZviRh9RuvS_XGCkO8m1Y",
  authDomain: "adno-booking.firebaseapp.com",
  projectId: "adno-booking",
  storageBucket: "adno-booking.appspot.com",
  messagingSenderId: "174999584378",
  appId: "1:174999584378:web:687f790f04307c638405c3"
};

// Initialize Firebase
export default initializeApp(firebaseConfig);
