import React, { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";

import ConfirmModal from "../components/UI/ConfirmModal/ConfirmModal";
import ModalContext, { EModalSize } from "../context/ModalContext";
import { ETranslation } from "../translations/translation-keys";

export const useConfirmModal = () => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useContext(ModalContext);

  const handler = (text: ReactNode, title?: string): Promise<boolean> => {
    return new Promise((resolve) => {
      setModal({
        isOpen: true,
        size: EModalSize.SMALL,
        title: title ?? t(ETranslation.COMMON_DELETE),
        content: (
          <ConfirmModal
            onAccept={() => {
              closeModal();
              resolve(true);
            }}
            onCancel={() => {
              closeModal();
              resolve(false);
            }}
          >
            {text}{" "}
          </ConfirmModal>
        ),
      });
    });
  };

  return handler;
};
