export enum ERoute {
  LOGIN = "/login",
  TEST_MISSIONS = "/test-missions",
  TEST_MISSION = "/test-missions/:id",
  IMPORTS = "/imports",
  IMPORT = "/imports/:id",
  LOG = "/log",
  FIELD_PERSONS = "/field-persons",
  FIELD_PERSON = "/field-persons/:id",
  UNAVAILABILITIES = "/unavailabilities",
  UNAVAILABILITY = "/unavailabilities/:id",
  MY_PAGE = "/my-page",
  MISSION_DETAILS = "/mission-details/:id",
  CALENDAR = "/calendar",
  REPORTS = "/reports",
  SETTINGS = "/settings",
  NOTIFICATIONS = "/notifications",
  COMPENSATIONS = "/compensations",
  COMPENSATION = "/compensation/:id",
}

export enum ERouteStaticParams {
  new = 'new'
}

type TParam = string | ERouteStaticParams;

interface IParams {
  [key: string]: TParam;
}

export class Routes {
  public static LOGIN = ERoute.LOGIN;
  public static TEST_MISSIONS = ERoute.TEST_MISSIONS;
  public static TEST_MISSION = (id: TParam) => Routes.withParams(ERoute.TEST_MISSION, { id });
  public static IMPORTS = ERoute.IMPORTS;
  public static IMPORT = (id: TParam) => Routes.withParams(ERoute.IMPORT, { id });
  public static LOG = ERoute.LOG;
  public static FIELD_PERSONS = ERoute.FIELD_PERSONS;
  public static FIELD_PERSON = (id: TParam) => Routes.withParams(ERoute.FIELD_PERSON, { id });
  public static UNAVAILABILITIES = ERoute.UNAVAILABILITIES;
  public static UNAVAILABILITY = (id: TParam) => Routes.withParams(ERoute.UNAVAILABILITY, { id });
  public static MY_PAGE = ERoute.MY_PAGE;
  public static MISSION_DETAILS = (id: TParam) => Routes.withParams(ERoute.MISSION_DETAILS, { id });
  public static CALENDAR = ERoute.CALENDAR;
  public static REPORTS = ERoute.REPORTS;
  public static SETTINGS = ERoute.SETTINGS;
  public static NOTIFICATIONS = ERoute.NOTIFICATIONS;
  public static COMPENSATIONS = ERoute.COMPENSATIONS;
  public static COMPENSATION = (id: TParam) => Routes.withParams(ERoute.COMPENSATION, { id });

  public static withParams(route: ERoute, params: IParams): string {
    let routeWithParams: string = route;
    for(let key in params) {
      routeWithParams = routeWithParams.replace(`:${key}`, params[key] as string);
    }
    return routeWithParams;
  }

  public static isNew(id: TParam): boolean {
    return id === ERouteStaticParams.new;
  }

  public static isNotNew(id: TParam): boolean {
    return id !== ERouteStaticParams.new;
  }

}
