import React, { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";

import ConfirmModal from "../components/UI/ConfirmModal/ConfirmModal";
import ModalContext, { EModalSize } from "../context/ModalContext";
import { ETranslation } from "../translations/translation-keys";

export const useCheckboxConfirmModal = () => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useContext(ModalContext);

  const handler = (
    text: ReactNode,
    title?: string,
    checkboxLabel?: string
  ): Promise<{ success: boolean; checked: boolean }> => {
    return new Promise((resolve) => {
      setModal({
        isOpen: true,
        size: EModalSize.SMALL,
        title: title ?? t(ETranslation.COMMON_DELETE),
        content: (
          <ConfirmModal
            onAccept={(checked) => {
              closeModal();
              resolve({ success: true, checked });
            }}
            onCancel={(checked) => {
              closeModal();
              resolve({ success: false, checked });
            }}
            checkboxLabel={checkboxLabel}
          >
            {text}{" "}
          </ConfirmModal>
        ),
      });
    });
  };

  return handler;
};
