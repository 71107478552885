import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../classes/Routes";
import PaginationObserver from "../../components/PaginationObserver/PaginationObserver";
import TestMissionBox from "../../components/TestMissions/TestMission/TestMissionBox/TestMissionBox";
import TestMissionSaveDialog from "../../components/TestMissions/TestMission/TestMissionSaveDialog/TestMissionSaveDialog";
import TestMissionList from "../../components/TestMissions/TestMissionList/TestMissionList";
import TestMissionSearch from "../../components/TestMissions/TestMissionSearch/TestMissionSearch";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import ModalContext, { EModalSize } from "../../context/ModalContext";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMediaQuery from "../../hooks/useMediaQuery";
import { usePagination } from "../../hooks/usePagination";
import { ELevel } from "../../interfaces/domain/IUser";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";

const TestMissions: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { setModal, closeModal } = useContext(ModalContext);

  const {
    testMissions,
    loading,
    hasMoreTestMissions,
    searchHandler,
    handleObserver,
  } = usePagination();

  const { id } = useAppSelector((state) => state.testMission);

  useEffect(() => {
    dispatch(actions.listUsers(undefined, undefined, ELevel.FIELD_PERSON));
    dispatch(
      actions.listUsersSearch(
        undefined,
        undefined,
        undefined,
        ELevel.TEST_PLANNER
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      navigate(Routes.TEST_MISSION(id));
    }
  }, [id, navigate]);

  const saveHandler = useCallback(
    () =>
      setModal({
        isOpen: true,
        title: t(ETranslation.TEST_MISSION_SAVE),
        size: EModalSize.SMALL,
        content: (
          <TestMissionSaveDialog
            onCancel={closeModal}
            onConfirm={(testMission) => {
              dispatch(actions.saveTestMission(testMission));
              closeModal();
            }}
          />
        ),
      }),
    [dispatch, t, closeModal, setModal]
  );

  return (
    <>
      <TestMissionSearch onSearch={searchHandler} />
      {isMobile ? (
        testMissions ? (
          testMissions.map((testMission) => (
            <TestMissionBox
              key={testMission.id}
              testMission={testMission}
              onClick={() => navigate(Routes.TEST_MISSION(testMission.id))}
            />
          ))
        ) : loading ? null : (
          <Container>{t(ETranslation.NO_TEST_MISSIONS)}</Container>
        )
      ) : (
        <Container>
          <h2 style={{ marginTop: 0 }}>
            {t(ETranslation.TEST_MISSIONS)}{" "}
            <FontAwesomeIcon
              icon={faAdd}
              style={{ cursor: "pointer" }}
              onClick={saveHandler}
            />
          </h2>
          {testMissions && testMissions.length > 0 ? (
            <TestMissionList testMissions={testMissions} />
          ) : loading ? null : (
            t(ETranslation.NO_TEST_MISSIONS)
          )}
          {loading && (
            <div style={{ textAlign: "center", padding: "1rem 0" }}>
              <Spinner />
            </div>
          )}
          {testMissions && testMissions.length > 0 && (
            <PaginationObserver
              loading={loading}
              hasMoreTestMissions={hasMoreTestMissions}
              handleObserver={handleObserver}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default TestMissions;
