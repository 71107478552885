import React, { ReactNode } from "react";
import classes from "./NotificationContainer.module.scss";

interface IProps {
  children: ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const NotificationContainer: React.FC<IProps> = ({ children, style, onClick }) => {
  return (
    <div
      className={classes.Container}
      style={style}
      onClick={onClick ? onClick : undefined}
    >
      {children}
    </div>
  );
};

export default NotificationContainer;
