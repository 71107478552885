import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ModalContext, { EModalSize } from "../../../context/ModalContext";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { EWorkLogStatus, IWorkLog } from "../../../interfaces/domain/IWorkLog";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../../UI/Button/Button";
import Table from "../../UI/Table/Table";
import WorkLogsTableRow from "./WorkLogsTableRow/WorkLogsTableRow";
import { useAuthUser } from "../../../hooks/useAuthUser";

interface IProps {
  workLogs: IWorkLog[];
  testMissionId?: string;
  isList?: boolean;
}

const WorkLogsTable: React.FC<IProps> = ({ workLogs, testMissionId, isList }) => {
  const { t } = useTranslation();
  const openConfirmModal = useConfirmModal();
  const dispatch = useAppDispatch();
  const { setModal } = useContext(ModalContext);

  const { acceptError, returnError } = useAppSelector((state) => state.workLog);
  const authUser = useAuthUser();
  const isFieldPerson = useMemo(() => authUser?.isFieldPerson, [authUser?.isFieldPerson]);

  useEffect(() => {
    if (acceptError || returnError) {
      setModal({
        isOpen: true,
        title: "Error",
        size: EModalSize.SMALL,
        autoScroll: true,
        content: acceptError || returnError,
      });
    }
    return () => {
      dispatch(actions.clearWorkLogError());
    };
  }, [acceptError, returnError, setModal, dispatch]);

  const acceptHandler = useCallback(
    async (e: React.MouseEvent, id: string, testMissionId?: string) => {
      e.stopPropagation();
      const success = await openConfirmModal(
        t(ETranslation.WORKLOG_CONFIRM_ACCEPT),
        t(ETranslation.WORKLOG_ACCEPT)
      );
      if (success) {
        dispatch(actions.acceptWorkLog(id, testMissionId));
      }
    },
    [openConfirmModal, dispatch, t]
  );

  const returnHandler = useCallback(
    async (e: React.MouseEvent, id: string) => {
      e.stopPropagation();
      const success = await openConfirmModal(
        t(ETranslation.WORKLOG_CONFIRM_RETURN),
        t(ETranslation.WORKLOG_RETURN)
      );
      if (success) {
        dispatch(actions.returnWorkLog(id));
      }
    },
    [openConfirmModal, dispatch, t]
  );

  const showAcceptAll = workLogs?.some(log => log.status !== EWorkLogStatus.accepted);

  return (
    <div style={{ margin: "1rem 0" }}>
      <Table hover>
        <thead>
          <tr>
            {isList && <th>{t(ETranslation.TEST_MISSION_ID)}</th>}
            <th>{t(ETranslation.NAME)}</th>
            <th>{t(ETranslation.STATUS)}</th>
            <th>{t(ETranslation.DATE)}</th>
            <th>{t(ETranslation.KILOMETERS)}</th>
            <th colSpan={2}>{t(ETranslation.EXPENSES)}</th>
            {isList && <th>{t(ETranslation.EXPORT_DATE)}</th>}
          </tr>
        </thead>
        <tbody>
          {workLogs &&
            workLogs.map((workLog) => (
              <WorkLogsTableRow
                key={workLog.id}
                workLog={workLog}
                acceptHandler={isFieldPerson ? undefined : acceptHandler}
                returnHandler={isFieldPerson ? undefined : returnHandler}
                isList={isList}
              />
            ))}
        </tbody>
      </Table>
      {testMissionId && showAcceptAll && (
        <Button
          onClick={(e) => acceptHandler(e, "", testMissionId)}
          style={{ marginTop: "1rem" }}
        >
          {t(ETranslation.WORKLOG_ACCEPT_ALL)}
        </Button>
      )}
    </div>
  );
};

export default WorkLogsTable;
