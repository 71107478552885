import {
  faCaretDown,
  faCaretUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, ReactNode, useState } from "react";
import classes from "./Fieldset.module.scss";

interface IProps {
  label: string;
  children: ReactNode;
  style?: CSSProperties;
  legendStyle?: CSSProperties;
  onRemove?: () => void;
  hidden?: boolean;
  transparent?: boolean;
  accordion?: boolean;
  initialOpen?: boolean;
}

const Fieldset: React.FC<IProps> = ({
  label,
  children,
  style,
  legendStyle,
  onRemove,
  hidden,
  transparent,
  accordion,
  initialOpen,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  if (hidden) return null;
  let containerStyles: CSSProperties = {};
  if (style) {
    containerStyles = style;
  }
  if (transparent) {
    containerStyles.background = "transparent";
  }

  const toggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!accordion) return;
    setIsOpen((open) => !open);
  };

  return (
    <fieldset className={classes.Container} style={containerStyles} onClick={toggle}>
      {onRemove && (
        <span className={classes.RemoveButton} onClick={onRemove}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      )}
      <legend className={classes.Legend} style={legendStyle}>
        {label}
        {accordion && (
          <FontAwesomeIcon
            icon={isOpen ? faCaretUp : faCaretDown}
            onClick={toggle}
            className={classes.Toggle}
            size="lg"
          />
        )}
      </legend>
      {isOpen && children}
    </fieldset>
  );
};

export default Fieldset;
