import { DATEPATTERN_FIN } from "../../data/constants";
import { AxiosResponse } from "axios";
import moment from "moment-timezone";
import { Dispatch } from "redux";
import axios from "../../axios";
import { ITestMissionSearch } from "../../components/TestMissions/TestMissionSearch/TestMissionSearch";
import { TInputValue } from "../../components/UI/Input/Input";
import { ETestMission } from "../../containers/TestMission/TestMission/TestMission";
import ITestMissionAction from "../../interfaces/action/ITestMissionAction";
import { ETestMissionSearchOption, ITestMission } from "../../interfaces/domain/ITestMission";
import { EActionTypes } from "../EActionTypes";
import { objectToQueryString } from "../../shared/qs-utils";

type TAction = ITestMissionAction;
const apiPath = "/json/testmission";

const listTestMissionsStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_LIST_START,
  };
};

const listTestMissionsSuccess = (
  testMissions: ITestMission[],
  paginationCursor: string,
  hasMoreTestMissions: boolean
): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_LIST_SUCCESS,
    testMissions,
    paginationCursor,
    hasMoreTestMissions,
  };
};

const listTestMissionsFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_LIST_FAIL,
    error,
  };
};

export const listTestMissions = (search: ITestMissionSearch) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listTestMissionsStart());
    try {
      const res = await axios.get<ITestMission[]>(
        `${apiPath}/list${objectToQueryString(search)}`
      );
      let paginationCursor = "";
      if (res.headers)
        paginationCursor = (res.headers["pagination-cursor"] as string) ?? "";

      const hasMoreTestMissions =
        search.orderBy === ETestMissionSearchOption.filter &&
        !search.testMissionId &&
        res.data.length > 0;

      dispatch(
        listTestMissionsSuccess(res.data, paginationCursor, hasMoreTestMissions)
      );
    } catch ({ response }) {
      dispatch(listTestMissionsFail((response as AxiosResponse).data));
    }
  };
};

export const clearTestMissions = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_CLEAR,
  }
}

const getTestMissionStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_GET_START,
  };
};

const getTestMissionSuccess = (testMission: ITestMission): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_GET_SUCCESS,
    testMission,
  };
};

const getTestMissionFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_GET_FAIL,
    error,
  };
};

export const getTestMission = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getTestMissionStart());
    try {
      const res = await axios.get<ITestMission>(`${apiPath}/get?id=${id}`);
      dispatch(getTestMissionSuccess(res.data));
    } catch ({ response }) {
      dispatch(getTestMissionFail((response as AxiosResponse).data));
    }
  };
};

const updateTestMissionStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_UPDATE_START,
  };
};

const updateTestMissionSuccess = (testMission: ITestMission): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_UPDATE_SUCCESS,
    testMission,
  };
};

const updateTestMissionFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_UPDATE_FAIL,
    error,
  };
};

export const updateTestMission = (
  id: string,
  property: ETestMission,
  value: TInputValue
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateTestMissionStart());
    try {
      const res = await axios.put<ITestMission>(`${apiPath}/update`, {
        id,
        property,
        value:
          value &&
          [ETestMission.startDate, ETestMission.endDate].includes(property)
            ? moment(value as Date).format(DATEPATTERN_FIN)
            : value,
      });
      dispatch(updateTestMissionSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateTestMissionFail((response as AxiosResponse).data));
    }
  };
};

const saveTestMissionStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_SAVE_START,
  };
};

const saveTestMissionSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_SAVE_SUCCESS,
    id,
  };
};

const saveTestMissionFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_SAVE_FAIL,
    error,
  };
};

export const saveTestMission = (testMission: ITestMission) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(saveTestMissionStart());
    try {
      const res = await axios.post<ITestMission>(`${apiPath}/add`, testMission);
      dispatch(saveTestMissionSuccess(res.data.id));
    } catch ({ response }) {
      dispatch(saveTestMissionFail((response as AxiosResponse).data));
    }
  };
};

const updateTestMissionParticipantsStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_PARTICIPANT_UPDATE_START,
  };
};

const updateTestMissionParticipantsSuccess = (
  testMission: ITestMission
): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_PARTICIPANT_UPDATE_SUCCESS,
    testMission,
  };
};

const updateTestMissionParticipantsFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_PARTICIPANT_UPDATE_FAIL,
    error,
  };
};

export const updateTestMissionParticipants = (
  id: string,
  participantId: string,
  roleName?: string,
  userId?: string,
  offeredDate?: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateTestMissionParticipantsStart());
    try {
      const res = await axios.put<ITestMission>(
        `${apiPath}/participant/update`,
        { id, participantId, roleName, userId, offeredDate }
      );
      dispatch(updateTestMissionParticipantsSuccess(res.data));
    } catch ({ response }) {
      dispatch(
        updateTestMissionParticipantsFail((response as AxiosResponse).data)
      );
    }
  };
};
/*
const updateTestMissionCommentsStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_COMMENT_UPDATE_START,
  };
};

const updateTestMissionCommentsSuccess = (
  testMission: ITestMission
): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_COMMENT_UPDATE_SUCCESS,
    testMission,
  };
};

const updateTestMissionCommentsFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_COMMENT_UPDATE_FAIL,
    error,
  };
};

export const updateTestMissionComments = (id: string, comments: IComment[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateTestMissionCommentsStart());
    try {
      const res = await axios.put<ITestMission>(`${apiPath}/comment/update`, {
        id,
        comments,
      });
      dispatch(updateTestMissionCommentsSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateTestMissionCommentsFail((response as AxiosResponse).data));
    }
  };
};*/

const addTestMissionCommentStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_COMMENT_ADD_START,
  };
};

const addTestMissionCommentSuccess = (testMission: ITestMission): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_COMMENT_ADD_SUCCESS,
    testMission,
  };
};

const addTestMissionCommentFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_COMMENT_ADD_FAIL,
    error,
  };
};

export const addTestMissionComment = (id: string, message: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(addTestMissionCommentStart());
    try {
      const res = await axios.put<ITestMission>(`${apiPath}/comment/add`, {
        id,
        message,
      });
      dispatch(addTestMissionCommentSuccess(res.data));
    } catch ({ response }) {
      dispatch(addTestMissionCommentFail((response as AxiosResponse).data));
    }
  };
};

const deleteTestMissionCommentStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_COMMENT_DELETE_START,
  };
};

const deleteTestMissionCommentSuccess = (
  testMission: ITestMission
): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_COMMENT_DELETE_SUCCESS,
    testMission,
  };
};

const deleteTestMissionCommentFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_COMMENT_DELETE_FAIL,
    error,
  };
};

export const deleteTestMissionComment = (
  testMissionId: string,
  commentId: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteTestMissionCommentStart());
    try {
      const res = await axios.put<ITestMission>(`${apiPath}/comment/delete`, {
        testMissionId,
        commentId,
      });
      dispatch(deleteTestMissionCommentSuccess(res.data));
    } catch ({ response }) {
      dispatch(deleteTestMissionCommentFail((response as AxiosResponse).data));
    }
  };
};

const deleteTestMissionStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_DELETE_START,
  };
};

const deleteTestMissionSuccess = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_DELETE_SUCCESS,
  };
};

const deleteTestMissionFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_DELETE_FAIL,
    error,
  };
};

export const deleteTestMission = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteTestMissionStart());
    try {
      await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteTestMissionSuccess());
    } catch ({ response }) {
      dispatch(deleteTestMissionFail((response as AxiosResponse).data));
    }
  };
};

const deleteTestMissionParticipantStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_PARTICIPANT_DELETE_START,
  };
};

const deleteTestMissionParticipantSuccess = (
  testMission: ITestMission
): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_PARTICIPANT_DELETE_SUCCESS,
    testMission,
  };
};

const deleteTestMissionParticipantFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_PARTICIPANT_DELETE_FAIL,
    error,
  };
};

export const deleteTestMissionParticipant = (
  id: string,
  participantId: string,
  participantUserId?: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteTestMissionParticipantStart());
    try {
      const res = await axios.delete<ITestMission>(
        `${apiPath}/participant/delete?id=${id}&participantId=${participantId}&participantUserId=${participantUserId ?? ""}`
      );
      dispatch(deleteTestMissionParticipantSuccess(res.data));
    } catch ({ response }) {
      dispatch(
        deleteTestMissionParticipantFail((response as AxiosResponse).data)
      );
    }
  };
};

const acceptTestMissionParticipantStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_PARTICIPANT_ACCEPT_START,
  };
};

const acceptTestMissionParticipantSuccess = (testMission: ITestMission): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_PARTICIPANT_ACCEPT_SUCCESS,
    testMission,
  };
};

const acceptTestMissionParticipantFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_PARTICIPANT_ACCEPT_FAIL,
    error,
  };
};

export const acceptTestMissionParticipant = (id: string, participantUserId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(acceptTestMissionParticipantStart());
    try {
      const res = await axios.put<ITestMission>(
        `${apiPath}/participant/accept?id=${id}&participantUserId=${participantUserId}`);
      dispatch(acceptTestMissionParticipantSuccess(res.data));
    } catch ({ response }) {
      dispatch(acceptTestMissionParticipantFail((response as AxiosResponse).data));
    }
  };
};
