import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import IMyTestMissionAction from "../../interfaces/action/IMyTestMissionAction";
import { ITestMission } from "../../interfaces/domain/ITestMission";
import { EActionTypes } from "../EActionTypes";

type TAction = IMyTestMissionAction;
const apiPath = "/json/testmission";

const acceptTestMissionStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_ACCEPT_START,
  };
};

const acceptTestMissionSuccess = (testMission: ITestMission): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_ACCEPT_SUCCESS,
    testMission,
  };
};

const acceptTestMissionFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_ACCEPT_FAIL,
    error,
  };
};

export const acceptTestMission = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(acceptTestMissionStart());
    try {
      const res = await axios.put<ITestMission>(`${apiPath}/accept?id=${id}`);
      dispatch(acceptTestMissionSuccess(res.data));
    } catch ({ response }) {
      dispatch(acceptTestMissionFail((response as AxiosResponse).data));
    }
  };
};

const refuseTestMissionStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_REFUSE_START,
  };
};

const refuseTestMissionSuccess = (testMission: ITestMission): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_REFUSE_SUCCESS,
    testMission,
  };
};

const refuseTestMissionFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_REFUSE_FAIL,
    error,
  };
};

export const refuseTestMission = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(refuseTestMissionStart());
    try {
      const res = await axios.put<ITestMission>(`${apiPath}/refuse?id=${id}`);
      dispatch(refuseTestMissionSuccess(res.data));
    } catch ({ response }) {
      dispatch(refuseTestMissionFail((response as AxiosResponse).data));
    }
  };
};

const listMyTestMissionsStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_MY_START,
  };
};

const listMyTestMissionsSuccess = (
  acceptedTestMissions: ITestMission[],
  offeredTestMissions: ITestMission[],
  completedTestMissions: ITestMission[]
): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_MY_SUCCESS,
    acceptedTestMissions,
    offeredTestMissions,
    completedTestMissions,
  };
};

const listMyTestMissionsFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_MY_FAIL,
    error,
  };
};

export const listMyTestMissions = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listMyTestMissionsStart());
    try {
      const res = await axios.get(`${apiPath}/my`);
      dispatch(listMyTestMissionsSuccess(res.data.accepted, res.data.offered, res.data.completed));
    } catch ({ response }) {
      dispatch(listMyTestMissionsFail((response as AxiosResponse).data));
    }
  };
};

const testMissionMarkCompleteStart = (): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_MARK_COMPLETE_START,
  };
};

const testMissionMarkCompleteSuccess = (testMission: ITestMission): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_MARK_COMPLETE_SUCCESS,
    testMission,
  };
};

const testMissionMarkCompleteFail = (error: string): TAction => {
  return {
    type: EActionTypes.TEST_MISSION_MARK_COMPLETE_FAIL,
    error,
  };
};

export const testMissionMarkComplete = (id: string, completedDate: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(testMissionMarkCompleteStart());
    try {
      const res = await axios.put<ITestMission>(
        `${apiPath}/mark-complete?id=${id}&completedDate=${completedDate}`
      );
      dispatch(testMissionMarkCompleteSuccess(res.data));
    } catch ({ response }) {
      dispatch(testMissionMarkCompleteFail((response as AxiosResponse).data));
    }
  };
};
