import { ELanguage } from "../i18n";
import { ERoute } from "./Routes";

enum EUserLevel {
  FIELD_PERSON = "FIELD_PERSON",
  TEST_PLANNER = "TEST_PLANNER",
  SUPERADMIN = "SUPERADMIN",
  REPORTING = "REPORTING",
}

interface IAuthUser {
  id: number;
  firebaseUserId: string;
  name: string;
  email: string;
  isTermsOfUseAccepted: boolean;
  preferredLanguage: ELanguage;
  level: EUserLevel;
  notificationEmail: boolean;
  notificationSMS: boolean;
}

export class AuthUser implements IAuthUser {
  public id: number;
  public firebaseUserId: string;
  public name: string;
  public email: string;
  public isTermsOfUseAccepted: boolean;
  public preferredLanguage: ELanguage;
  public level: EUserLevel;
  public notificationEmail: boolean;
  public notificationSMS: boolean;

  public constructor(authUser: IAuthUser) {
    this.id = authUser.id;
    this.firebaseUserId = authUser.firebaseUserId;
    this.name = authUser.name;
    this.email = authUser.email;
    this.isTermsOfUseAccepted = authUser.isTermsOfUseAccepted || false;
    this.preferredLanguage = authUser.preferredLanguage;
    this.level = authUser.level;
    this.notificationEmail = authUser.notificationEmail;
    this.notificationSMS = authUser.notificationSMS;
  }

  public get routes(): ERoute[] {
    const routes: ERoute[] = [ERoute.LOGIN];

    const { isTestPlanner, isFieldPerson, isReporting } = this;

    if (isFieldPerson || isTestPlanner || isReporting) {
      routes.push.apply(routes, [ERoute.SETTINGS]);
    }

    if (isFieldPerson || isTestPlanner) {
      routes.push.apply(routes, [
        ERoute.NOTIFICATIONS,
        ERoute.UNAVAILABILITIES,
      ]);
    }

    if (isTestPlanner || isReporting || isFieldPerson) {
      routes.push.apply(routes, [ERoute.COMPENSATIONS, ERoute.COMPENSATION]);
    }

    if (isFieldPerson) {
      routes.push.apply(routes, [ERoute.MY_PAGE, ERoute.MISSION_DETAILS]);
    }

    if (isTestPlanner) {
      routes.push.apply(routes, [
        ERoute.TEST_MISSIONS,
        ERoute.TEST_MISSION,
        ERoute.IMPORTS,
        ERoute.IMPORT,
        ERoute.LOG,
        ERoute.FIELD_PERSONS,
        ERoute.FIELD_PERSON,
        ERoute.CALENDAR,
        ERoute.REPORTS,
      ]);
    }

    return routes;
  }

  public get isTestPlanner(): boolean {
    return (
      this.level === EUserLevel.TEST_PLANNER ||
      this.level === EUserLevel.SUPERADMIN
    );
  }

  public get isFieldPerson(): boolean {
    return this.level === EUserLevel.FIELD_PERSON;
  }

  public get isReporting() {
    return (
      this.level === EUserLevel.REPORTING ||
      this.level === EUserLevel.SUPERADMIN
    );
  }

  public get redirect(): ERoute {
    if (this.isTestPlanner) {
      return ERoute.TEST_MISSIONS;
    }
    if (this.isFieldPerson) {
      return ERoute.MY_PAGE;
    }
    if (this.isReporting) {
      return ERoute.COMPENSATIONS;
    }
    return ERoute.TEST_MISSIONS;
  }
}
