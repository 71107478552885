import React from "react";
import { useTranslation } from "react-i18next";
import {
  ETestMissionStatus,
  testMissionStatus,
} from "../../../interfaces/domain/ITestMission";
import Alert, { EAlertColor } from "../../UI/Alert/Alert";

interface IProps {
  status: ETestMissionStatus;
}

const StatusLabel: React.FC<IProps> = ({ status }) => {
  const { t } = useTranslation();

  let color: EAlertColor;
  switch (status) {
    case ETestMissionStatus.ISSUED:
    case ETestMissionStatus.MODOC:
      color = EAlertColor.TERTIARY;
      break;
    case ETestMissionStatus.COMPLETE:
      color = EAlertColor.SUCCESS;
      break;
    case ETestMissionStatus.CANCELLED:
      color = EAlertColor.SECONDARY;
      break;
    case ETestMissionStatus.PLANNING:
      color = EAlertColor.SECONDARY;
      break;
  }

  return (
    <Alert color={color} label>
      {t(testMissionStatus[status])}
    </Alert>
  );
};

export default StatusLabel;
