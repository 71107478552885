import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../classes/Routes";
import Dropzone from "../../components/Dropzone/Dropzone";
import ImportHistory from "../../components/Import/ImportHistory/ImportHistory";
import Title from "../../components/Title/Title";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import { Accept } from "react-dropzone";

export enum EImportType {
  testmission = "testmission",
  participants = "participants",
}

const accept: Accept = { "text/csv": [] };

const Imports: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [testMissionFile, setTestMissionFile] = useState<File | null>(null);
  const [participantsFile, setParticipantFile] = useState<File | null>(null);

  const { loading, summaries, summariesLoading, id, error } = useAppSelector(
    (state) => state.import
  );

  useEffect(() => {
    dispatch(actions.listImports());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      navigate(Routes.IMPORT(id));
    }
  }, [id, navigate]);

  const uploadHandler = useCallback(
    (testMissionFile: File | null, participantsFile: File | null) => {
      if (testMissionFile && participantsFile) {
        dispatch(actions.uploadImport([testMissionFile, participantsFile]));
      }
    },
    [dispatch]
  );

  return (
    <Container>
      <Title translation={ETranslation.IMPORT} />

      <h3>{t(ETranslation.IMPORT_TEST_MISSIONS)}</h3>
      {testMissionFile && <p>{testMissionFile.name}</p>}
      <Dropzone
        onDrop={(files) => setTestMissionFile(files[0])}
        disabled={summariesLoading}
        accept={accept}
      />

      <h3>{t(ETranslation.IMPORT_PARTICIPANTS)}</h3>
      {participantsFile && <p>{participantsFile.name}</p>}
      <Dropzone
        onDrop={(files) => setParticipantFile(files[0])}
        disabled={summariesLoading}
        accept={accept}
      />

      {error && (
        <Alert noHorizontalMargin style={{ marginBottom: 0 }}>
          {error}
        </Alert>
      )}

      <Button
        onClick={() => uploadHandler(testMissionFile, participantsFile)}
        disabled={!testMissionFile || !participantsFile || summariesLoading}
        style={{ marginTop: "1rem" }}
        loading={summariesLoading}
      >
        {t(ETranslation.COMMON_SEND)}
      </Button>

      {loading ? (
        <Spinner />
      ) : (
        summaries && <ImportHistory summaries={summaries} />
      )}
    </Container>
  );
};

export default Imports;
