import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import IUnavailabilityAction from "../../interfaces/action/IUnavailabilityAction";
import { IUnavailability } from "../../interfaces/domain/IUnavailability";
import { EActionTypes } from "../EActionTypes";

type TAction = IUnavailabilityAction;
const apiPath = "/json/unavailability";

export const clearUnavailability = () => {
  return {
    type: EActionTypes.UNAVAILABILITY_CLEAR,
  };
};

const listUnavailabilitiesStart = (): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_LIST_START,
  };
};

const listUnavailabilitiesSuccess = (unavailabilities: IUnavailability[]): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_LIST_SUCCESS,
    unavailabilities,
  };
};

const listUnavailabilitiesFail = (error: string): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_LIST_FAIL,
    error,
  };
};

export interface IUnavailabilitySearch {
  startDate?: string;
  userId?: string;
}

export const listUnavailabilities = (search: IUnavailabilitySearch) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listUnavailabilitiesStart());
    try {
      const res = await axios.get<IUnavailability[]>(`${apiPath}/list?startDate=${search.startDate ?? ""}&userId=${search.userId ?? ""}`);
      dispatch(listUnavailabilitiesSuccess(res.data));
    } catch ({ response }) {
      dispatch(listUnavailabilitiesFail((response as AxiosResponse).data));
    }
  };
};

const getUnavailabilityStart = (): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_GET_START,
  };
};

const getUnavailabilitySuccess = (unavailability: IUnavailability): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_GET_SUCCESS,
    unavailability,
  };
};

const getUnavailabilityFail = (error: string): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_GET_FAIL,
    error,
  };
};

export const getUnavailability = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getUnavailabilityStart());
    try {
      const res = await axios.get<IUnavailability>(`${apiPath}/get?id=${id}`);
      dispatch(getUnavailabilitySuccess(res.data));
    } catch ({ response }) {
      dispatch(getUnavailabilityFail((response as AxiosResponse).data));
    }
  };
};

const updateUnavailabilityStart = (): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_UPDATE_START,
  };
};

const updateUnavailabilitySuccess = (unavailability: IUnavailability): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_UPDATE_SUCCESS,
    unavailability,
  };
};

const updateUnavailabilityFail = (error: string): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_UPDATE_FAIL,
    error,
  };
};

export const updateUnavailability = (unavailability: IUnavailability) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateUnavailabilityStart());
    try {
      const res = await axios.put<IUnavailability>(
        `${apiPath}/update`,
        unavailability
      );
      dispatch(updateUnavailabilitySuccess(res.data));
    } catch ({ response }) {
      dispatch(updateUnavailabilityFail((response as AxiosResponse).data));
    }
  };
};

const saveUnavailabilityStart = (): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_SAVE_START,
  };
};

const saveUnavailabilitySuccess = (unavailability: IUnavailability): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_SAVE_SUCCESS,
    unavailability,
  };
};

const saveUnavailabilityFail = (error: string): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_SAVE_FAIL,
    error,
  };
};

export const saveUnavailability = (unavailability: IUnavailability) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(saveUnavailabilityStart());
    try {
      const res = await axios.post<IUnavailability>(
        `${apiPath}/add`,
        unavailability
      );
      dispatch(saveUnavailabilitySuccess(res.data));
    } catch ({ response }) {
      dispatch(saveUnavailabilityFail((response as AxiosResponse).data));
    }
  };
};

const deleteUnavailabilityStart = (): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_DELETE_START,
  };
};

const deleteUnavailabilitySuccess = (): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_DELETE_SUCCESS,
  };
};

const deleteUnavailabilityFail = (error: string): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_DELETE_FAIL,
    error,
  };
};

export const deleteUnavailability = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteUnavailabilityStart());
    try {
      await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteUnavailabilitySuccess());
    } catch ({ response }) {
      dispatch(deleteUnavailabilityFail((response as AxiosResponse).data));
    }
  };
};

const listMyUnavailabilitiesStart = (): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_MY_LIST_START,
  };
};

const listMyUnavailabilitiesSuccess = (unavailabilities: IUnavailability[]): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_MY_LIST_SUCCESS,
    unavailabilities,
  };
};

const listMyUnavailabilitiesFail = (error: string): TAction => {
  return {
    type: EActionTypes.UNAVAILABILITY_MY_LIST_FAIL,
    error,
  };
};

export const listMyUnavailabilities = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listMyUnavailabilitiesStart());
    try {
      const res = await axios.get<IUnavailability[]>(`${apiPath}/my`);
      dispatch(listMyUnavailabilitiesSuccess(res.data));
    } catch ({ response }) {
      dispatch(listMyUnavailabilitiesFail((response as AxiosResponse).data));
    }
  };
};
