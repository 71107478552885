import { faCheck, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Routes } from "../../../../classes/Routes";
import ModalContext, { EModalSize } from "../../../../context/ModalContext";
import { DATEPATTERN_FIN } from "../../../../data/constants";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAuthUser } from "../../../../hooks/useAuthUser";
import { ECounts } from "../../../../interfaces/domain/ICounts";
import { EParticipantStatus } from "../../../../interfaces/domain/IParticipant";
import {
  ETestMissionStatus,
  ITestMission,
} from "../../../../interfaces/domain/ITestMission";
import {
  TestMissionParticipantBadge,
  getRegion,
} from "../../../../shared/test-mission";
import * as actions from "../../../../store/actions";
import { ETranslation } from "../../../../translations/translation-keys";
import MarkCompleteDialog from "../../../MarkCompleteDialog/MarkCompleteDialog";
import Button, { EButtonColor, EButtonSize } from "../../../UI/Button/Button";
import ConfirmDialog from "../../../UI/ConfirmDialog/ConfirmDialog";
import InputGroup from "../../../UI/InputGroup/InputGroup";
import classes from "./MyPageTestMission.module.scss";

interface IProps {
  testMission: ITestMission;
  clickable?: boolean;
  status?: EParticipantStatus;
}

const MyPageTestMission: React.FC<IProps> = ({
  testMission,
  clickable,
  status,
}) => {
  const {
    id,
    testMissionId,
    sportsDescription,
    startDate,
    endDate,
    region,
    inCompetition,
    numberOfTests,
    city,
    status: testMissionStatus,
    leadDCO,
    urgent,
    testTypes,
  } = testMission;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { setModal, closeModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const location = [];
  if (region) location.push(getRegion(t, region.id));
  if (city) location.push(city);

  const testMissionContent = (
    <>
      {testMissionStatus === ETestMissionStatus.CANCELLED && (
        <p>{t(ETranslation.STATUS_CANCELLED)}</p>
      )}
      <p>
        {moment(startDate).format("DD.MM.")} -{" "}
        {moment(endDate).format(DATEPATTERN_FIN)}
      </p>
      <p>{testMissionId}</p>
      <p>{location.join(", ")}</p>
      <p>
        {sportsDescription}
        {inCompetition ? ", in competition" : ""}
        {numberOfTests ? ", " + numberOfTests + " tests" : ""}
        {testTypes ? ": " + testTypes : ""}
        <span style={{ fontWeight: "bold" }}>{urgent ? ", URGENT" : ""} </span>
      </p>
      <p>{leadDCO && "Lead DCO: " + leadDCO.name}</p>
    </>
  );

  const showDialog = (
    event: React.MouseEvent<HTMLButtonElement>,
    title: string,
    text: string,
    onConfirm: () => void
  ) => {
    event.stopPropagation();
    setModal({
      isOpen: true,
      title,
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          onCancel={closeModal}
          onConfirm={() => {
            onConfirm();
            closeModal();
            if (!clickable) navigate(Routes.MY_PAGE);
          }}
          body={
            <>
              <p>{text}</p>
              {testMissionContent}
            </>
          }
        />
      ),
    });
  };

  const showMarkCompleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setModal({
      isOpen: true,
      title: t(ETranslation.MARK_COMPLETED),
      size: EModalSize.SMALL,
      content: (
        <MarkCompleteDialog
          onCancel={closeModal}
          onConfirm={(completedDate) => {
            markTestMissionComplete(id, completedDate);
            closeModal();
            if (!clickable) navigate(Routes.MY_PAGE);
          }}
          body={
            <>
              <p>{t(ETranslation.CONFIRM_TEST_MISSION_COMPLETE)}</p>
              {testMissionContent}
            </>
          }
        />
      ),
    });
  };

  const refuseTestMission = (id: string) => {
    dispatch(actions.refuseTestMission(id));
    dispatch(actions.incrementCount(ECounts.refusedOrcanceled));
  };

  const markTestMissionComplete = (id: string, completedDate: string) => {
    dispatch(actions.testMissionMarkComplete(id, completedDate));
    dispatch(actions.incrementCount(ECounts.completed));
  };

  const canMarkComplete =
    [ETestMissionStatus.ISSUED, ETestMissionStatus.MODOC].includes(
      testMissionStatus
    ) &&
    status === EParticipantStatus.ACCEPTED &&
    testMission.leadDCO?.id === (authUser?.id || ("" as string));

  return (
    <div
      className={classes.Container}
      onClick={
        clickable ? () => navigate(Routes.MISSION_DETAILS(id)) : undefined
      }
      style={clickable ? { cursor: "pointer" } : {}}
    >
      <div className={classes.Content}>
        {status && (
          <TestMissionParticipantBadge
            status={status}
            testMissionStatus={testMission.status}
            size={"2x"}
          />
        )}
        <div>{testMissionContent}</div>
      </div>
      {testMissionStatus !== ETestMissionStatus.CANCELLED &&
        ((!clickable && status !== EParticipantStatus.REFUSED) ||
          (clickable && status === EParticipantStatus.PENDING)) && (
          <InputGroup className={classes.Buttons}>
            {status === EParticipantStatus.ACCEPTED && (
              <Button
                size={EButtonSize.SMALL}
                color={EButtonColor.DANGER}
                nowrap
                onClick={(event) => {
                  showDialog(
                    event,
                    t(ETranslation.COMMON_CANCEL),
                    t(ETranslation.CONFIRM_TEST_MISSION_CANCEL),
                    () => refuseTestMission(id)
                  );
                }}
              >
                <FontAwesomeIcon icon={faMinus} />{" "}
                {t(ETranslation.COMMON_CANCEL)}
              </Button>
            )}
            {status === EParticipantStatus.PENDING && (
              <>
                <Button
                  size={EButtonSize.SMALL}
                  color={EButtonColor.DANGER}
                  nowrap
                  onClick={(event) => {
                    showDialog(
                      event,
                      t(ETranslation.COMMON_REFUSE),
                      t(ETranslation.CONFIRM_TEST_MISSION_REFUSE),
                      () => refuseTestMission(id)
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} />{" "}
                  {t(ETranslation.COMMON_REFUSE)}
                </Button>
                <Button
                  size={EButtonSize.SMALL}
                  color={EButtonColor.SUCCESS}
                  nowrap
                  onClick={(event) => {
                    showDialog(
                      event,
                      t(ETranslation.COMMON_ACCEPT),
                      t(ETranslation.CONFIRM_TEST_MISSION_ACCEPT),
                      () => dispatch(actions.acceptTestMission(id))
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t(ETranslation.COMMON_ACCEPT)}
                </Button>
              </>
            )}
            {canMarkComplete && (
              <Button
                size={EButtonSize.SMALL}
                color={EButtonColor.SUCCESS}
                nowrap
                onClick={(e) => showMarkCompleteDialog(e)}
              >
                <FontAwesomeIcon icon={faCheck} />{" "}
                {t(ETranslation.MARK_COMPLETED)}
              </Button>
            )}
          </InputGroup>
        )}
    </div>
  );
};

export default MyPageTestMission;
