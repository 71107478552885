import { IOption } from "../../components/UI/Input/Input";
import { IAttachment } from "./IAttachment";
import { BaseDomain } from "./IBaseDomain";

export enum EWorkLogStatus {
  draft = "draft",
  pending = "pending",
  accepted = "accepted",
  returned = "returned",
  replaced = "replaced",
}

export const WORKLOG_STATUS_OPTIONS: IOption[] = [
  { value: EWorkLogStatus.draft, label: EWorkLogStatus.draft },
  { value: EWorkLogStatus.pending, label: EWorkLogStatus.pending },
  { value: EWorkLogStatus.accepted, label: EWorkLogStatus.accepted },
  { value: EWorkLogStatus.returned, label: EWorkLogStatus.returned },
  { value: EWorkLogStatus.replaced, label: EWorkLogStatus.replaced },
];

export interface IWorkLog extends BaseDomain {
  startDate: string;
  startHours: number;
  startMinutes: number;
  endDate: string;
  endHours: number;
  endMinutes: number;
  kilometers: number;
  expenses: IWorkLogExpense[];
  status: EWorkLogStatus;
  testMissionId: string;
  userId: string;
  userName: string;
  dateString: string;
  passengers: IWorkLogPassenger[];
  workHours: string;
  mileageAllowance: number;
  expensesTotal: number;
  overtimeExtra: number;
  midweekHolidayExtra: number;
  testMissionStartDate: string;
  testMissionEndDate: string;
  testMissionStartDateString: string;
  testMissionEndDateString: string;
  linkedWorkLogs: ILinkedWorkLog[];
  exportDate: string;
  vismaPayrollPersonnelId: string;
}

export interface IWorkLogExpense {
  id: string;
  description: string;
  amount: number;
  attachments: IAttachment[];
  open?: boolean;
}

export interface IWorkLogPassenger {
  id?: string;
  name: string;
  kilometers: string;
}

export interface ILinkedWorkLog {
  id: string;
  // workLogId: string;
  testMissionId: string;
  percentage: string;
  status: string;
}
