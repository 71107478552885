import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import InputContextProvider from "../../../../context/InputContext";
import { useWorkLog } from "../../../../hooks/useWorkLog";
import { useWorkLogLinkOptions } from "../../../../hooks/useWorkLogLinkOptions";
import { EWorkLogStatus, ILinkedWorkLog } from "../../../../interfaces/domain/IWorkLog";
import { ETranslation } from "../../../../translations/translation-keys";
import { TInputValue } from "../../../UI/Input/Input";
import LinkedWorkLog from "./LinkedWorkLog";
import Alert from "../../../UI/Alert/Alert";

interface IProps {
  linkedWorkLogs: ILinkedWorkLog[];
  setLinkedWorkLogs: React.Dispatch<React.SetStateAction<ILinkedWorkLog[]>>;
  userId?: string;
  replacementId?: string;
  isError?: boolean;
}

const LinkedWorkLogs: React.FC<IProps> = ({ linkedWorkLogs, setLinkedWorkLogs, userId, replacementId, isError }) => {
  const { t } = useTranslation();
  const { disableInputs } = useWorkLog();

  const addHandler = useCallback(() => {
    const id = uuid();
    setLinkedWorkLogs((workLogs) => [
      ...workLogs,
      { id, percentage: "", status: "", testMissionId: "" },
    ]);
  }, [setLinkedWorkLogs]);

  const deleteHandler = useCallback(
    (id: string) => {
      setLinkedWorkLogs((workLogs) => {
        return [...workLogs].filter((workLog) => workLog.id !== id);
      });
    },
    [setLinkedWorkLogs]
  );

  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      const data = _ as ILinkedWorkLog;
      setLinkedWorkLogs((workLogs) => {
        const newWorkLogs = [...workLogs];
        const index = newWorkLogs.findIndex(
          (workLog) => workLog.id === data.id
        );
        console.log("index", index);
        if (index > -1) {
          console.log("update linked work log", data, inputName, value);
          newWorkLogs[index] = {
            ...data,
            [inputName]: value,
          };
        }
        return newWorkLogs;
      });
      return Promise.resolve(true);
    },
    [setLinkedWorkLogs]
  );

  const { workLogOptions, workLogOptionsLoading } = useWorkLogLinkOptions({ status: EWorkLogStatus.draft, userId, replacementId });

  const linkedWorkLogIds = useMemo(() => linkedWorkLogs.map(log => log.id), [linkedWorkLogs]);

  return (
    <div style={{ margin: "1rem 0" }}>
      <p style={{ fontWeight: "bold" }}>
        {t(ETranslation.LINKED_WORK_LOGS)}{" "}
        {!disableInputs && (
          <FontAwesomeIcon
            icon={faPlus}
            onClick={addHandler}
            style={{ cursor: "pointer" }}
          />
        )}
      </p>
      {isError && <Alert noHorizontalMargin>{t(ETranslation.WORKLOGS_PERCENTAGE_ERROR)}</Alert>}
      <InputContextProvider initDone onAutoUpdate={updateHandler}>
        {linkedWorkLogs?.length > 0 &&
          linkedWorkLogs.map((workLog) => (
            <LinkedWorkLog
              key={workLog.id}
              workLog={workLog}
              onDelete={() => deleteHandler(workLog.id!)}
              workLogOptions={workLogOptions}
              workLogOptionsLoading={workLogOptionsLoading}
              linkedWorkLogIds={linkedWorkLogIds}
            />
          ))}
      </InputContextProvider>
    </div>
  );
};

export default LinkedWorkLogs;
