import React, { createContext, ReactNode, useContext } from "react";
import { TInputValue } from "../components/UI/Input/Input";

export enum EInputUpdateAction {
  WORKLOG_PASSENGER = "WORKLOG_PASSENGER",
}

export type TAutoUpdate = (
  inputName: string,
  value: TInputValue,
  action: string,
  data?: Object | undefined
) => Promise<boolean>;

type InputType = {
  onAutoUpdate: TAutoUpdate;
  initDone: boolean;
  showValidation?: boolean;
  setShowValidation?: React.Dispatch<React.SetStateAction<boolean>>;
};

const InputContext = createContext<InputType>({
  onAutoUpdate: async () => {
    return false;
  },
  initDone: false,
});

export const useInputContext = () => useContext(InputContext);

const InputContextProvider: React.FC<InputType & { children: ReactNode }> = ({
  initDone,
  onAutoUpdate,
  setShowValidation,
  showValidation,
  children,
}) => {
  return (
    <InputContext.Provider
      value={{
        initDone,
        onAutoUpdate,
        setShowValidation,
        showValidation,
      }}
    >
      {children}
    </InputContext.Provider>
  );
};

export default InputContextProvider;
