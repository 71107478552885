import { AxiosResponse } from "axios";
import axios from "../axios";
import { objectToQueryString } from "./qs-utils";

export type ExportType = "CSV" | "VISMA";

interface IExportParams {
  testMissionId?: string;
  userId?: string;
  startDate?: string;
  endDate?: string;
  exportType: ExportType;
}

export const exportWorkLogs = async (params: IExportParams) => {
  try {
    const res = await axios({
      method: "POST",
      url: `/json/worklog/export${objectToQueryString(params)}`,
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;

    const fileName = `${
      new Date().toISOString().split("T")[0]
    }_worklog_export.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    return null;
  } catch ({ response }: any) {
    return (response as AxiosResponse).data;
  }
};
