import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";

interface IProps {
  loading: boolean;
  hasMoreTestMissions: boolean;
  handleObserver: (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => void;
}

const PaginationObserver: React.FC<IProps> = ({
  hasMoreTestMissions,
  loading,
  handleObserver,
}) => {
  const { t } = useTranslation();
  const loaderRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!loaderRef.current) return;
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "",
      threshold: 1,
    });
    observer.observe(loaderRef.current);
    return () => observer.disconnect();
  }, [handleObserver]);

  return (
    <p ref={loaderRef} style={{ color: "#ccc" }}>
      {loading
        ? null
        : hasMoreTestMissions
        ? t(ETranslation.TEST_MISSIONS_HAS_MORE)
        : t(ETranslation.TEST_MISSIONS_ALL_FETCHED)}
    </p>
  );
};

export default PaginationObserver;
