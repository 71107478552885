import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../components/Title/Title";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import NotificationList from "../../components/Notifications/NotificationList/NotificationList";
import NotificationContainer from "../../components/Notifications/NotificationsContainer/NotificationContainer";

const Notifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const { notifications, loading } = useAppSelector((state) => state.notification);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.listMyNotifications());
  }, [dispatch]);

  const readAll = () => {
    dispatch(actions.seenAllNotifications());
  }

  return (
    <>
      <NotificationContainer>
        <Title translation={ETranslation.NOTIFICATIONS} />
        <div style={{ margin: '1rem',cursor: 'pointer', textDecoration:'underline'}} onClick={()=> readAll()}>{t(ETranslation.READ_ALL_NOTIFICATIONS)}</div>
        {loading ? (
          <Spinner />
        ) : notifications ? (
          <NotificationList notifications={notifications} />
        ) : (
          <p>{t(ETranslation.NO_NOTIFICATIONS)}</p>
        )}
      </NotificationContainer>
    </>
  );
};

export default Notifications;