import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../classes/Routes";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  EWorkLogStatus,
  IWorkLog,
} from "../../../../interfaces/domain/IWorkLog";
import { ETranslation } from "../../../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../../../UI/Button/Button";
import InputGroup from "../../../UI/InputGroup/InputGroup";
import { useAuthUser } from "../../../../hooks/useAuthUser";

interface IProps {
  workLog: IWorkLog;
  acceptHandler?: (e: React.MouseEvent, id: string) => Promise<void>;
  returnHandler?: (e: React.MouseEvent, id: string) => Promise<void>;
  isList?: boolean;
}

const WorkLogsTableRow: React.FC<IProps> = ({
  workLog,
  acceptHandler,
  returnHandler,
  isList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const { acceptLoadingId, returnLoadingId, allLoading } = useAppSelector(
    (state) => state.workLog
  );

  const isAcceptLoading = useMemo(
    () => acceptLoadingId === workLog.id,
    [acceptLoadingId, workLog.id]
  );

  const isReturnLoading = useMemo(
    () => returnLoadingId === workLog.id,
    [returnLoadingId, workLog.id]
  );

  return (
    <tr onClick={() => navigate(Routes.COMPENSATION(workLog.id))}>
      {isList && <td>{workLog.testMissionId}</td>}
      <td>{workLog.userName} {isList && authUser?.isTestPlanner && !workLog.vismaPayrollPersonnelId && <span style={{ color: "red" }} title={t(ETranslation.WORKLOGS_VISMA_ID_ERROR)}>!</span>}</td>
      <td>{workLog.status}</td>
      <td>{workLog.dateString ?? ""}</td>
      <td>{workLog.kilometers && `${workLog.kilometers} km`}</td>
      <td>
        {workLog.expenses &&
          workLog.expenses
            .filter((expense) => !!expense.description)
            .map(
              (expense) =>
                `${expense.description}${
                  expense.amount ? ` (${expense.amount} NOK)` : ""
                }`
            )
            .join(", ")}
      </td>
      <td>
        <InputGroup>
          {acceptHandler && [EWorkLogStatus.pending, EWorkLogStatus.returned].includes(
            workLog.status
          ) && (
            <Button
              onClick={(e) => acceptHandler(e, workLog.id)}
              size={EButtonSize.SMALL}
              color={EButtonColor.SUCCESS}
              loading={isAcceptLoading || allLoading}
            >
              {t(ETranslation.WORKLOG_ACCEPT)}
            </Button>
          )}
          {returnHandler && [EWorkLogStatus.pending].includes(
            workLog.status
          ) && (
            <Button
              onClick={(e) => returnHandler(e, workLog.id)}
              size={EButtonSize.SMALL}
              color={EButtonColor.DANGER}
              loading={isReturnLoading}
            >
              {t(ETranslation.WORKLOG_RETURN)}
            </Button>
          )}
        </InputGroup>
      </td>
      {isList && <td>{workLog.exportDate}</td>}
    </tr>
  );
};

export default WorkLogsTableRow;
