import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import LogItemList from "../../components/LogItems/LogItemList/LogItemList";
import LogItemSearch from "../../components/LogItems/LogItemSearch/LogItemSearch";
import Title from "../../components/Title/Title";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import { ILogItemSearch } from "../../store/actions/logItemActions";
import { ETranslation } from "../../translations/translation-keys";

const Log: React.FC = () => {
  const dispatch = useAppDispatch();
  const { logItems, loading } = useAppSelector((state) => state.logItem);
  const { t } = useTranslation();

  const searchHandler = useCallback(
    (search: ILogItemSearch) => {
      dispatch(actions.listTestMissionLogItems(search));
    },
    [dispatch]
  );

  return (
    <>
      <Container>
        <LogItemSearch onSearch={searchHandler} log />
      </Container>
      <Container>
        <Title translation={ETranslation.LOG} />
        {loading ? (
          <Spinner />
        ) : logItems ? (
          <LogItemList logItems={logItems} />
        ) : (
          <p>{t(ETranslation.NOT_FOUND_ERROR)}</p>
        )}
      </Container>
    </>
  );
};

export default Log;
