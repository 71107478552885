import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ERouteStaticParams, Routes } from "../../classes/Routes";
import Title from "../../components/Title/Title";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import UnavailabilitiesList from "../../components/Unavailabilities/UnavailabilitiesList/UnavailabilitiesList";
import UnavailabilitiesSearch from "../../components/Unavailabilities/UnavailabilitiesSearch/UnavailabilitiesSearch";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import { IUnavailabilitySearch } from "../../store/actions/unavailabilityActions";
import { ETranslation } from "../../translations/translation-keys";

const Unavailabilities: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { loading, unavailabilities } = useAppSelector(
    (state) => state.unavailability
  );

  const searchHandler = useCallback(
    (search: IUnavailabilitySearch) => {
      dispatch(actions.listUnavailabilities(search));
    },
    [dispatch]
  );

  return (
    <>
      <UnavailabilitiesSearch onSearch={searchHandler} />
      <Container>
        <Title
          translation={ETranslation.UNAVAILABILITIES}
          route={Routes.UNAVAILABILITY(ERouteStaticParams.new)}
        />
        {loading ? (
          <Spinner />
        ) : (
          <>
            {loading ? (
              <Spinner />
            ) : unavailabilities ? (
              <UnavailabilitiesList unavailabilities={unavailabilities} />
            ) : (
              t(ETranslation.NO_UNAVAILABILITIES)
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Unavailabilities;
